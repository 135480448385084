import React from 'react';

import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Button, Flex, Space } from 'antd';

import { EventTypesEnum } from '../../data/product/productEnum';
import { parseForDisplayWithPercentSign } from '../../utils/stringUtils';
import { EditableText } from '../EditableText/EditableText';

import type { ProductEvent } from '../../data/ProductType';

const labelToClassName : Map<string, string> = new Map([
  [EventTypesEnum.Coupon, '--coupon'],
  [EventTypesEnum.Maturity, '--maturity'],
  [EventTypesEnum.AutocallAndCoupon, '--autocall'],
  [EventTypesEnum.FinalFixing, '--finalfixing'],
  [EventTypesEnum.Issue, '--issue'],
  [EventTypesEnum.InitialFixing, '--initialfixing'],
]);

type CalendarEventCardProps = {
  event               : ProductEvent,
  isCurrentEditing    : boolean,
  className           : string | undefined,
  editionMode         : boolean | undefined,
  addOrDeleteEvent ?  : (eventIndex : number, action : 'edit' | 'remove')=> void,
  eventIndex          : number,
  isPastEvent         : boolean,
  isToday             : boolean,
  isNextEvent         : boolean,
  isCanceledEvent     : boolean,
  isLastReedemEvent   : boolean,
  onChangeEventInfo ? : (updatedProductEvent : ProductEvent)=> void,
};

const disallowedEventTypes = [
  EventTypesEnum.Autocall.toString(),
  EventTypesEnum.InitialFixing.toString(),
  EventTypesEnum.Issue.toString(),
  EventTypesEnum.Maturity.toString(),
  EventTypesEnum.Today.toString(),
];

const CalendarEventCard = ({
  event, isCurrentEditing, className, editionMode, eventIndex, addOrDeleteEvent: onChangeEvent, isPastEvent, isToday, isNextEvent, isCanceledEvent, isLastReedemEvent, onChangeEventInfo,
} : CalendarEventCardProps) : React.ReactElement => {
  const labelClassName = labelToClassName.get(event.eventType) ?? '';

  const showOutcome = !disallowedEventTypes.includes(event.eventType);

  return (
    isToday
      ? <p className = {'timeline__item__label'}>{event.eventType}</p>
      : (
        <div className = {`timeline__item timeline__item${labelClassName} ${className ?? ''} ${isCurrentEditing && 'timeline__item--editing'} 
        ${isPastEvent && 'timeline__item--passed'} ${isNextEvent && 'timeline__item--nextEvent'} ${isCanceledEvent && 'timeline__item--canceled'} 
        ${isLastReedemEvent && 'timeline__item--lastRedeem'}`}
        >
          {editionMode && (
            <Space className = {'timeline__item__actions'}>
              <Button
                size = {'small'}
                icon = {<EditFilled size = {12} />}
                type = {'primary'}
                style = {{
                  width  : 20,
                  height : 20,
                }}
                styles = {{
                  icon : {
                    width  : 12,
                    height : 12,
                  },
                }}
                onClick = {() : void => onChangeEvent && onChangeEvent(eventIndex, 'edit')}
              />

              <Button
                size = {'small'}
                icon = {<DeleteFilled />}
                type = {'primary'}
                style = {{
                  width  : 20,
                  height : 20,
                }}
                styles = {{
                  icon : {
                    width  : 12,
                    height : 12,
                  },
                }}
                onClick = {() : void => onChangeEvent && onChangeEvent(eventIndex, 'remove')}
              />
            </Space>
          )}

          {isNextEvent && <p className = {'timeline__item__value'}>Next Event</p>}

          <p className = {'timeline__item__label'}>{event.eventType}</p>

          {showOutcome && ((isPastEvent && event.actualFlow) || (!isPastEvent && event.potentialFlow))
            ? (
              <>
                <Flex gap = {4} align = {'center'}>
                  <span className = {'timeline__item__flow-label'}>{isPastEvent ? 'Payment: ' : 'Potential flow: '}</span>

                  <p className = {'timeline__item__value'}>

                    {isPastEvent
                      ? parseForDisplayWithPercentSign(event.actualFlow)
                      : parseForDisplayWithPercentSign(event.potentialFlow)}

                  </p>

                </Flex>

                {event.autocallTrigger
                  ? (
                    <Flex gap = {4} align = {'center'}>
                      <span className = {'timeline__item__flow-label'}>
                        Autocall Trigger:
                      </span>

                      <EditableText
                        editable = {Boolean(editionMode)}
                        defaultValue = {event.autocallTrigger.toString()}
                        defaultOpen = {false}
                        valueType = {'number'}
                        changeCallback = {(changedValue) : void => onChangeEventInfo && onChangeEventInfo({
                          ...event,
                          autocallTrigger : Number(changedValue),
                        })}
                      />
                    </Flex>
                  )
                  : null}

                {event.couponTrigger
                  ? (
                    <Flex gap = {4} align = {'center'}>
                      <span className = {'timeline__item__flow-label'}>
                        Coupon Trigger:
                      </span>

                      <EditableText
                        editable = {Boolean(editionMode)}
                        defaultValue = {event.couponTrigger.toString()}
                        defaultOpen = {false}
                        valueType = {'number'}
                        changeCallback = {(changedValue) : void => onChangeEventInfo && onChangeEventInfo({
                          ...event,
                          couponTrigger : Number(changedValue),
                        })}
                      />
                    </Flex>
                  )
                  : null}
              </>
            )
            : null}

        </div>
      )
  );
};

export { CalendarEventCard };

