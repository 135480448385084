import React, {
  useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useMatch, useNavigate, useParams
} from 'react-router-dom';

import { BankOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  message
} from 'antd';
import type { FormProps } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import type { SelectProps } from 'antd/lib';

import dayjs from 'dayjs';

import { CurrencyInput } from '../../../Shared/CurrencyInput/CurrencyInput';
import { myFetch } from '../../../config/api';
import { haveOneOfTheRoles, keycloakInstance } from '../../../config/keycloak';
import { EditTradePageRoute } from '../../../data/Routes';
import { UserRoleEnum } from '../../../data/enums/cloak';
import { getSingleTrade } from '../../../store/tradesStore/tradeApi';
import {
  dynamicOrange, goldenYellow, organicBlue, whiteColor
} from '../../../styles/colors';
import { parseCurrency, sortCurrenciesForSelectOptions } from '../../../utils/currencyUtils';

import { EditCustodianModal } from './EditCustodianModal';

import type { AllRef, ClientCompany } from '../../../data/Ref';
import type {
  ProductSearchResultTrade as ProductSearchResultForTrade, TradeFormFieldType,
  TradeFormSubmissionData, UserListItemForTrade
} from '../../../data/TradesTypes';
import type { ClientDisplay } from '../../../data/administration/client';
import type { AppDispatch, RootStateType } from '../../../store/store';

import './CreateTradePage.scss';

import { GlobalContext } from '../../../context/GlobalContext';
import { toUtcIsoFormat } from '../../../utils/time';
import { MESSAGE_API_DURATION } from '../../../config/config';
import { isValidIsin } from '../../../utils/validator';

export const CreateTradePage = () : React.ReactElement => {
  const navigate = useNavigate();

  const [form] = Form.useForm<TradeFormFieldType>();
  const [selectedCurrency, setselectedCurrency] = useState<string>('EUR');
  const [showEditCustodianModal, setShowEditCustodianModal] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage({ duration : MESSAGE_API_DURATION });
  const [isCreating, setIsCreating] = useState(false);
  const [externalsOnly, setExternalsOnly] = useState(false);
  const [salesList, setSalesList] = useState<UserListItemForTrade[]>([]);
  const [productSearchResult, setProductSearchResult] = useState<SelectProps['options']>([]);
  const [isin, setIsin] = useState<string>();
  const [selectedProductId, setSelectedProductId] = useState<number>(0);
  const [clientCompanies, setClientCompanies] = useState<ClientCompany[]>([]);
  const [availableCustodian, setAvailableCustodian] = useState<AllRef['CustodiansList']>([]);

  const isExistingProduct = Form.useWatch<boolean>('isExistingProduct', form);
  const notionalAmountFormValue = Form.useWatch<number>('notionalAmount', form);
  const isAdminOrSuperAdminRole = haveOneOfTheRoles([UserRoleEnum.SuperAdmin, UserRoleEnum.InternalAdmin]);

  const dispatch = useDispatch<AppDispatch>();
  const tradeItem = useSelector((state : RootStateType) => state.tradesReducer.trade);
  const {
    isLoading, item: trade,
  } = tradeItem;

  const sortedClientCompanies = useMemo(() => clientCompanies.sort((a, b) => a.fullName.localeCompare(b.fullName)), [clientCompanies]);

  const [selectedCustodians, setSelectedCustodians] = useState<TradeFormSubmissionData['custodians']>([]);

  const [inputOptions, setInputOptions] = useState({
    clientCommissionOption : false,
    businessProviderOption : false,
    bridgeOption           : false,
  });

  const globalContext = useContext(GlobalContext);
  if (!globalContext) {
    throw new Error('You probably forgot to put <GlobalProvider>.');
  }
  const { allRefData } = globalContext;

  const onValuesChange = useCallback((_ : unknown, allValues : TradeFormFieldType) : void => {
    const {
      notionalAmount, reofferPrice, clientPrice, clientCommissionFees, businessProviderFees, bridgeFees,
    } = allValues;

    if (!notionalAmount) {
      return;
    }

    let clientAmount = 0;
    let cashAmount = 0;
    let bridgeAmount = 0;
    let clientCommissionAmount = 0;
    let businessProviderAmount = 0;

    const newValues : Partial<TradeFormFieldType> = {};

    if (reofferPrice) {
      cashAmount = (reofferPrice * notionalAmount) / 100;
      newValues.cashAmount = cashAmount;
    }
    if (clientPrice) {
      clientAmount = (clientPrice * notionalAmount) / 100;
      newValues.clientAmount = clientAmount;
    }
    if (clientCommissionFees) {
      clientCommissionAmount = (clientCommissionFees * notionalAmount) / 100;
      newValues.clientCommissionAmount = clientCommissionAmount;
    }
    if (businessProviderFees) {
      businessProviderAmount = (businessProviderFees * notionalAmount) / 100;
      newValues.businessProviderAmount = businessProviderAmount;
      newValues.businessProviderCommission = businessProviderAmount;
    }
    if (bridgeFees) {
      bridgeAmount = (bridgeFees * notionalAmount) / 100;
      newValues.bridgeAmount = bridgeAmount;
    }

    const totalCommission = clientAmount - (cashAmount + bridgeAmount);

    newValues.totalCommissionAmount = totalCommission;
    newValues.aydoCommission = totalCommission - (clientCommissionAmount + businessProviderAmount);

    form.setFieldsValue(newValues);
  }, []);

  const onToggleTradeOptions = (checked : boolean, name : string) : void => {
    setInputOptions({
      ...inputOptions,
      [name] : checked,
    });
  };
  const { tradeId } = useParams<{ tradeId : string }>();
  const isEditing = Boolean(useMatch({ path : EditTradePageRoute.path }));

  useEffect(() => {
    if (isEditing && tradeId) {
      void dispatch(getSingleTrade({ tradeId }));
    }
  }, []);

  const fillEditForm = () : void => {
    if (!trade) {
      return;
    }
    form.setFieldsValue({
      isExistingProduct    : Boolean(trade.productId),
      isinCode             : trade.isin,
      currency             : trade.currency,
      notionalAmount       : trade.notionalAmount,
      productName          : trade.productName,
      denomination         : trade.denomination,
      owner                : trade.salesmanOwnerId,
      issuer               : trade.issuerRefId,
      tradeDate            : trade.tradeDate,
      issueDate            : trade.settementDate,
      tradingEntity        : trade.tradingEntity.id,
      reofferPrice         : trade.sellingPrice,
      clientCompany        : trade.clientCompany.id,
      clientPrice          : trade.buyingPrice,
      businessProvider     : trade.businessProvider ? trade.businessProvider.id : null,
      businessProviderFees : trade.businessProviderCommission,
      bridge               : trade.bridge ? trade.bridge.id : null,
      bridgeFees           : trade.bridgeCommission,
      clientCommissionFees : trade.clientCommission,
    });
    setselectedCurrency(trade.currency);

    setSelectedCustodians(trade.custodians.map((c) => ({
      custodianId   : c.id,
      amount        : c.amount,
      orderReceived : c.orderReceived,
    })));

    setSelectedProductId(trade.productId);

    setInputOptions({
      clientCommissionOption : Boolean(trade.clientCommission),
      businessProviderOption : Boolean(trade.businessProvider),
      bridgeOption           : Boolean(trade.bridge),
    });
  };

  const refreshCustodianList = (clientId : number) : void => {
    myFetch<ClientDisplay>('GET', `/client/${clientId}`).then((client) => {
      const allowedIds = client.custodianList.map((c) => c.id);
      setAvailableCustodian(allRefData?.CustodiansList.filter((e) => allowedIds.includes(e.id)) ?? []);
    })
      .catch((error : unknown) => {
        messageApi.error(`Couldn't fetch custodians : ${error}`);
      });
  };

  useEffect(() => {
    // If editing, prefill the form
    if (!(isEditing && trade)) {
      return;
    }

    fillEditForm();
    refreshCustodianList(trade.clientCompany.id);
  }, [form, isEditing, trade]);

  useEffect(() => {
    // When the trade data is loaded, recalculate the commission values
    if (isEditing && trade) {
      const allValues = form.getFieldsValue();
      onValuesChange(null, allValues);
    }
  }, [trade, inputOptions, isEditing, form, onValuesChange]);

  const onFinish : FormProps<TradeFormFieldType>['onFinish'] = (values) : void => {
    const currencyId = allRefData?.CurrencyRefList.find((currency) => currency.IsoNomination === selectedCurrency)?.Id;

    const newTrade : TradeFormSubmissionData = {
      isExistingProduct : values.isExistingProduct,
      product           : {
        productId     : selectedProductId,
        isinCode      : selectedProductId === 0 ? values.isinCode : null,
        productName   : values.productName,
        denomination  : values.denomination,
        issuerRefId   : values.issuer,
        currencyRefId : currencyId as number,
      },
      tradeSalesmanOwnerId          : values.owner,
      tradeStatus                   : 'Booked',
      clientInvoiceStatus           : values.clientCommissionAmount > 0 ? 'Pending' : null,
      businessProviderInvoiceStatus : values.businessProviderCommission > 0 ? 'Pending' : null,
      tradeDate                     : values.tradeDate ? toUtcIsoFormat(values.tradeDate) : null,
      settlementDate                : values.issueDate ? toUtcIsoFormat(values.issueDate) : null,
      sellingPrice                  : values.reofferPrice,
      buyingPrice                   : values.clientPrice,
      notionalAmount                : values.notionalAmount,
      clientCompanyId               : values.clientCompany,
      clientCommission              : values.clientCommissionFees,
      businessProviderCommission    : values.businessProviderFees,
      bridgeId                      : values.bridge,
      bridgeCommission              : values.bridgeFees,
      tradingEntityRefId            : values.tradingEntity,
      businessProviderRefId         : values.businessProvider,
      custodians                    : selectedCustodians,
      signedClientTermsheet         : trade?.signClientTermsheet ?? null,
    };

    setIsCreating(true);

    if (isEditing) {
      myFetch('PUT', `/trades/${tradeId}`, {
        body  : newTrade,
        query : null,
      })
        .then(() : void => {
          navigate(`/trades/${tradeId}`);
        })
        .catch((e : unknown) : void => {
          if (e instanceof Error) {
            messageApi.open({
              type    : 'error',
              content : e.message,
            });
          } else {
            console.error('Edit error: ', e);
          }
        })
        .finally(() => {
          setIsCreating(false);
        });
    } else {
      myFetch('POST', '/trades', {
        body  : newTrade,
        query : null,
      })
        .then((response) : void => {
          const insertedTrade = response as { tradeId : number };
          navigate(`/trades/${insertedTrade.tradeId.toString()}`);
        })
        .catch((e : unknown) : void => {
          if (e instanceof Error) {
            messageApi.open({
              type    : 'error',
              content : e.message,
            });
          } else {
            console.error('Creation error: ', e);
          }
        })
        .finally(() => {
          setIsCreating(false);
        });
    }
  };

  const fetchSales = () : void => {
    myFetch<UserListItemForTrade[]>('GET', '/user/salesmen')
      .then((response) : void => {
        setSalesList(response);
        if (!isEditing) {
          form.setFieldValue('owner', response.find((sale) => sale.email === keycloakInstance.profile?.email)?.id);
        }
      })
      .catch(() : void => {
        setSalesList([]);
      });
  };

  const getClientCompanies = () : void => {
    myFetch<ClientCompany[]>('GET', '/companies/client-companies')
      .then((response) : void => {
        setClientCompanies(response);
      })
      .catch(() : void => {
        setSalesList([]);
      });
  };

  useEffect(() => {
    fetchSales();
    getClientCompanies();
  }, []);

  const handleSearchProductByIsin = (queryValue : string) : void => {
    setIsin(queryValue);
    if (queryValue.length < 2) {
      return;
    }
    myFetch<{ products : ProductSearchResultForTrade[] }>('GET', '/products/search', {
      body  : null,
      query : {
        searchBy : 'isin',
        isin     : queryValue,
      },
    })
      .then((response) : void => {
        const results = response.products;
        setProductSearchResult(results.map((product) => ({
          value        : product.isin,
          label        : `${product.isin} (${product.productName})`,
          isin         : product.isin,
          denomination : product.denomination,
          issuerId     : product.issuerId,
          currency     : product.currency,
          productName  : product.productName,
          productId    : product.productId,
        })));
      })
      .catch(() : void => {
        setProductSearchResult([]);
      });
  };

  const {
    bridgeOption, businessProviderOption, clientCommissionOption,
  } = inputOptions;

  const currencySymbol = allRefData?.CurrencyRefList.find((currency) => currency.IsoNomination === selectedCurrency)?.Symbol;

  if (isLoading) {
    return (
      <Flex
        align = {'center'}
        justify = {'center'}
        gap = {'middle'}
        style = {{ height : '80vh' }}
      >
        <Spin indicator = {(
          <LoadingOutlined
            spin
            style = {{
              fontSize : 48,
              color    : whiteColor,
            }}
          />
        )}
        />
      </Flex>
    );
  }

  return (
    <>
      <EditCustodianModal
        selectedCurrency = {selectedCurrency}
        selectedCustodians = {selectedCustodians}
        setSelectedCustodians = {setSelectedCustodians}
        custodiansList = {availableCustodian}
        isModalOpen = {showEditCustodianModal}
        currentNotionalAmount = {notionalAmountFormValue}
        close = {() : void => setShowEditCustodianModal(false)}
        onFinishSelectCustodians = {() : void => {
          setShowEditCustodianModal(false);
        }}
      />

      {contextHolder}

      <div className = {'createTradePage'}>
        <Form
          name = {'createTrade'}
          layout = {'vertical'}
          form = {form}
          autoComplete = {'off'}
          requiredMark = {false}
          initialValues = {{
            denomination : 1000,
            currency     : 'EUR',
            clientPrice  : 100,
            tradeDate    : dayjs(),
            issueDate    : dayjs().add(7, 'day'),
          }}
          onValuesChange = {onValuesChange}
          onFinish = {onFinish}
        >

          <h2>{isEditing ? 'Edit Trade' : 'Create Trade'}</h2>

          <Form.Item
            colon = {false}
            label = {'Owner'}
            layout = {'horizontal'}
            name = {'owner'}
            rules = {[{ required : true }]}
          >
            <Select
              showSearch
              className = {'trade-select-input'}
              placeholder = {'Owner'}
              disabled = {!isAdminOrSuperAdminRole}
              style = {{ width : '20%' }}
              options = {(externalsOnly ? salesList.filter((sale) => sale.isExternal) : salesList).map((sale) => ({
                value : sale.id,
                label : sale.fullName,
              }))}
              filterOption = {(input, option) : boolean => String(option?.label).toLowerCase()
                .includes(input.toLowerCase())}
            />

          </Form.Item>

          <Row gutter = {90}>
            <Col className = {'createTradePage__formcol'} md = {8} sm = {24}>

              <Flex
                gap = {8}
                justify = {'space-between'}
                align = {'center'}
                style = {{
                  width   : '100%',
                  padding : '15px 0',
                }}
              >
                <h4 className = {'section__title'} style = {{ marginBottom : 0 }}>Product Features</h4>

                {isEditing && (
                  <Link to = {`/products/${selectedProductId}/edit`}>
                    <Button
                      type = {'primary'}
                      size = {'middle'}
                      className = {'submit_button'}
                    >
                      Edit product
                    </Button>
                  </Link>
                )}
              </Flex>

              <Form.Item layout = {'horizontal'} name = {'isExistingProduct'} hidden = {isEditing}>
                <Switch
                  checkedChildren = {'Existing product'}
                  unCheckedChildren = {'New product'}
                  style = {{ backgroundColor : isExistingProduct ? dynamicOrange : organicBlue }}
                  onChange = {() : void => {
                    form.resetFields(['isinCode', 'productName', 'denomination', 'issuer', 'currency']);
                  }}
                />
              </Form.Item>

              <Form.Item
                label = {'ISIN Code'}
                name = {'isinCode'}
                rules = {[
                  {
                    validator : async (_, value : string) : Promise<void> => {
                      if ((isEditing && tradeId) || !value || isExistingProduct) {
                        return Promise.resolve();
                      }
                      const isinValid = await isValidIsin(value);
                      if (!isinValid) {
                        return Promise.reject(Error('ISIN is invalid or already exists.'));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {
                  isExistingProduct
                    ? (
                      <Select
                        showSearch
                        open = {isSearchOpen}
                        value = {isin}
                        placeholder = {'Select ISIN'}
                        style = {{ width : '100%' }}
                        defaultActiveFirstOption = {false}
                        suffixIcon = {null}
                        filterOption = {false}
                        notFoundContent = {null}
                        options = {productSearchResult ?? []}
                        disabled = {isEditing}
                        onSearch = {handleSearchProductByIsin}
                        onDropdownVisibleChange = {(visible) : void => setIsSearchOpen(visible)}
                        onChange = {(_, option : DefaultOptionType[] | DefaultOptionType) : void => {
                          const {
                            isin : isinCode, denomination, issuerId, currency, productName, productId,
                          } = option as ProductSearchResultForTrade;
                          if (productId) {
                            setSelectedProductId(productId);
                            form.setFieldsValue({
                              isinCode,
                              denomination,
                              issuer : issuerId,
                              currency,
                              productName,
                            });
                          } else {
                            form.setFieldValue('isinCode', isinCode);
                          }
                        }}
                      />
                    )
                    : (
                      <Input
                        placeholder = {'ISIN'}
                        disabled = {isEditing}
                      />
                    )

                }
              </Form.Item>

              <Form.Item label = {'Product Name'} name = {'productName'}>
                <Input placeholder = {'Product Name'} disabled = {isExistingProduct} />
              </Form.Item>

              <Form.Item label = {'Denomination'} rules = {[{ required : true }]} name = {'denomination'}>
                <InputNumber style = {{ width : '100%' }} disabled = {isExistingProduct} />
              </Form.Item>

              <Form.Item label = {'Issuer'} name = {'issuer'}>
                <Select
                  showSearch
                  placeholder = {'Select...'}
                  disabled = {isExistingProduct}
                  options = {(allRefData?.IssuerRefList ?? []).map((issuer) => ({
                    value : issuer.id,
                    label : issuer.fullName,
                  }))}
                  filterOption = {(input, option) : boolean => String(option?.label).toLowerCase()
                    .includes(input.toLowerCase())}
                />
              </Form.Item>

              <Form.Item label = {'Currency'} name = {'currency'}>
                <Select
                  showSearch
                  placeholder = {'Select currency'}
                  disabled = {isExistingProduct}
                  options = {sortCurrenciesForSelectOptions(allRefData?.CurrencyRefList ?? [])}
                  onChange = {(currency : number) : void => {
                    const selectedCurrencyIso = allRefData?.CurrencyRefList.find((c) => c.Id === currency)?.IsoNomination ?? '';
                    setselectedCurrency(selectedCurrencyIso);
                  }}
                />
              </Form.Item>

            </Col>

            <Col md = {8} sm = {24}>
              <Flex vertical>
                <h4 className = {'section__title '}>Notional Amount</h4>

                <Form.Item name = {'notionalAmount'} rules = {[{ required : true }]}>
                  <CurrencyInput className = {'trade-form-input'} suffix = {currencySymbol} />
                </Form.Item>

                <h4 className = {'section__title section__title-topmargin'}>Date</h4>

                <Space className = {'select_date'}>
                  <Form.Item
                    label = {'Trade Date'}
                    name = {'tradeDate'}
                    getValueProps = {(v : string) : ({ value : dayjs.Dayjs | string }) => ({ value : v && dayjs(v) })}
                  >
                    <DatePicker
                      className = {'trade-form-input'}
                      format = {'DD/MM/YYYY'}
                      placeholder = {'DD/MM/YYYY'}
                      allowClear = {false}
                    />
                  </Form.Item>

                  <Form.Item
                    label = {'Issue Date'}
                    name = {'issueDate'}
                    getValueProps = {(v : string) : ({ value : dayjs.Dayjs | string }) => ({ value : v && dayjs(v) })}
                  >
                    <DatePicker className = {'trade-form-input'} format = {'DD/MM/YYYY'} placeholder = {'DD/MM/YYYY'} />
                  </Form.Item>
                </Space>

                <h4 className = {'section__title section__title-topmargin'}>AYDO {'<>'} Trading Entity</h4>

                <Form.Item label = {'Trading entity'} name = {'tradingEntity'} rules = {[{ required : true }]}>
                  <Select
                    showSearch
                    className = {'trade-select-input'}
                    placeholder = {'Select...'}
                    options = {(allRefData?.TradingEntitiesList ?? []).map((te) => ({
                      value : te.id,
                      label : te.fullName,
                    }))}
                    filterOption = {(input, option) : boolean => String(option?.label).toLowerCase()
                      .includes(input.toLowerCase())}
                  />
                </Form.Item>

                <Space>
                  <Form.Item label = {'Reoffer Price'} name = {'reofferPrice'} rules = {[{ required : true }]}>
                    <InputNumber
                      placeholder = {'0'}
                      style = {{ width : '100%' }}
                      min = {0}
                      max = {100}
                      suffix = {'%'}
                      className = {'trade-form-input'}
                    />
                  </Form.Item>

                  <Form.Item label = {'Cash Amount'} name = {'cashAmount'}>
                    <CurrencyInput disabled className = {'trade-form-input'} suffix = {currencySymbol} />
                  </Form.Item>
                </Space>

                <h4 className = {'section__title section__title-topmargin'}>AYDO {'<>'} Client</h4>

                <Form.Item label = {'Client Name'} name = {'clientCompany'} rules = {[{ required : true }]}>
                  <Select
                    showSearch
                    className = {'trade-select-input'}
                    placeholder = {'Select...'}
                    options = {sortedClientCompanies.map((clientComp) => ({
                      value : clientComp.id,
                      label : clientComp.fullName,
                    }))}
                    filterOption = {(input, option) : boolean => String(option?.label).toLowerCase()
                      .includes(input.toLowerCase())}
                    onChange = {(newClientCompId : number) : void => {
                      refreshCustodianList(newClientCompId);
                    }}
                  />
                </Form.Item>

                <Space>
                  <Form.Item label = {'Client Price'} name = {'clientPrice'} rules = {[{ required : true }]}>
                    <InputNumber
                      placeholder = {'0'}
                      style = {{ width : '100%' }}
                      min = {0}
                      max = {100}
                      suffix = {'%'}
                      className = {'trade-form-input'}
                    />
                  </Form.Item>

                  <Form.Item label = {'Client Amount'} name = {'clientAmount'}>
                    <CurrencyInput disabled = {true} className = {'trade-form-input'} suffix = {currencySymbol} />
                  </Form.Item>
                </Space>

                <Space className = {'section__check section__check_topmargin'}>
                  <h4 className = {'section__check__title'}>Commission</h4>

                  <Switch
                    checkedChildren = {'ON'}
                    unCheckedChildren = {'OFF'}
                    style = {{ backgroundColor : clientCommissionOption ? goldenYellow : dynamicOrange }}
                    checked = {clientCommissionOption}
                    onChange = {(checked) : void => onToggleTradeOptions(checked, 'clientCommissionOption')}
                  />
                </Space>

                {clientCommissionOption && (
                  <Form.Item label = {'Commission'} name = {'clientCommissionFees'} rules = {[{ required : true }]}>
                    <InputNumber
                      placeholder = {'0'}
                      style = {{ width : '100%' }}
                      className = {'trade-form-input'}
                      min = {0}
                      max = {100}
                      suffix = {'%'}
                    />
                  </Form.Item>
                )}
              </Flex>
            </Col>

            <Col md = {8} sm = {24}>
              <Flex vertical>
                <Space className = {'section__check'}>
                  <h4 className = {'section__check__title'}>Business Provider</h4>

                  <Switch
                    checkedChildren = {'ON'}
                    unCheckedChildren = {'OFF'}
                    style = {{ backgroundColor : clientCommissionOption ? goldenYellow : dynamicOrange }}
                    checked = {businessProviderOption}
                    onChange = {(checked) : void => onToggleTradeOptions(checked, 'businessProviderOption')}
                  />
                </Space>

                {businessProviderOption && (
                  <>
                    <Form.Item label = {'Name'} name = {'businessProvider'} rules = {[{ required : true }]}>
                      <Select
                        showSearch
                        placeholder = {'Select...'}
                        className = {'trade-select-input'}
                        options = {(allRefData?.BusinessProvidersList ?? []).map((bp) => ({
                          value : bp.id,
                          label : bp.fullName,
                        }))}
                        filterOption = {(input, option) : boolean => String(option?.label).toLowerCase()
                          .includes(input.toLowerCase())}
                      />
                    </Form.Item>

                    <Space>
                      <Form.Item label = {'Fees'} name = {'businessProviderFees'} rules = {[{ required : true }]}>
                        <InputNumber
                          placeholder = {'0'}
                          style = {{ width : '100%' }}
                          min = {0}
                          max = {100}
                          suffix = {'%'}
                          className = {'trade-form-input'}
                        />
                      </Form.Item>

                      <Form.Item label = {'Fees Amount'} name = {'businessProviderAmount'}>
                        <CurrencyInput disabled = {true} className = {'trade-form-input'} suffix = {currencySymbol} />
                      </Form.Item>
                    </Space>
                  </>
                )}

                <Space className = {'section__check section__check_topmargin'}>
                  <h4 className = {'section__check__title'}>Bridge</h4>

                  <Switch
                    checkedChildren = {'ON'}
                    unCheckedChildren = {'OFF'}
                    style = {{ backgroundColor : clientCommissionOption ? goldenYellow : dynamicOrange }}
                    checked = {bridgeOption}
                    onChange = {(checked) : void => onToggleTradeOptions(checked, 'bridgeOption')}
                  />
                </Space>

                {bridgeOption && (
                  <>
                    <p className = {'section_info'}>Total Commission = Total Upfront - Bridge Fee</p>

                    <Form.Item label = {'Name'} name = {'bridge'} rules = {[{ required : true }]}>
                      <Select
                        showSearch
                        placeholder = {'Select...'}
                        className = {'trade-select-input'}
                        options = {(allRefData?.BridgesList ?? []).map((bridge) => ({
                          value : bridge.id,
                          label : bridge.fullName,
                        }))}
                        filterOption = {(input, option) : boolean => String(option?.label).toLowerCase()
                          .includes(input.toLowerCase())}
                      />
                    </Form.Item>

                    <Space>
                      <Form.Item label = {'Fees'} name = {'bridgeFees'} rules = {[{ required : true }]}>
                        <InputNumber
                          placeholder = {'0'}
                          style = {{ width : '100%' }}
                          min = {0}
                          max = {100}
                          suffix = {'%'}
                          className = {'trade-form-input'}
                        />
                      </Form.Item>

                      <Form.Item label = {'Fees Amount'} name = {'bridgeAmount'}>
                        <CurrencyInput disabled = {true} className = {'trade-form-input'} suffix = {currencySymbol} />
                      </Form.Item>
                    </Space>
                  </>
                )}

                <div className = {'custodians__infos'}>
                  <Space style = {{ marginRight : '10px' }}>
                    <BankOutlined />

                    <p>Custodians:</p>
                  </Space>

                  {selectedCustodians.map((custodian) => (
                    <Flex key = {custodian.custodianId} align = {'center'} gap = {8}>
                      <p className = {'custodian__name'}>
                        {allRefData?.CustodiansList.find((c) => c.id === custodian.custodianId)?.fullName}
                      </p>

                      <p className = {'amount'}>
                        {parseCurrency(custodian.amount, selectedCurrency)}
                      </p>
                    </Flex>
                  ))}

                  <Button type = {'link'} style = {{ padding : 0 }} onClick = {() : void => setShowEditCustodianModal(true)}>
                    Edit
                  </Button>
                </div>

                <h4 className = {'section__title section__title-topmargin'}>Commission Details</h4>

                <Form.Item label = {'Total Commission:'} name = {'totalCommissionAmount'}>
                  <CurrencyInput
                    min = {0}
                    disabled = {true}
                    className = {'trade-form-input'}
                    suffix = {currencySymbol}
                  />
                </Form.Item>

                {clientCommissionOption && (
                  <Form.Item label = {'- Client Commission:'} name = {'clientCommissionAmount'}>
                    <CurrencyInput
                      min = {0}
                      disabled = {true}
                      className = {'trade-form-input'}
                      suffix = {currencySymbol}
                    />
                  </Form.Item>
                )}

                {businessProviderOption && (
                  <Form.Item label = {'- Business Provider Fee:'} name = {'businessProviderCommission'}>
                    <CurrencyInput
                      min = {0}
                      disabled = {true}
                      className = {'trade-form-input'}
                      suffix = {currencySymbol}
                    />
                  </Form.Item>
                )}

                <Form.Item label = {'= AYDO Commission'} name = {'aydoCommission'}>
                  <CurrencyInput
                    min = {0}
                    disabled = {true}
                    className = {'trade-form-input'}
                    suffix = {currencySymbol}
                  />
                </Form.Item>
              </Flex>
            </Col>
          </Row>

          <Flex justify = {'flex-end'}>
            <Space>
              {isEditing && (
                <Form.Item>
                  <Button
                    size = {'large'}
                    type = {'link'}
                    onClick = {() : void => navigate(`/trades/${tradeId}`)}
                  >
                    Back
                  </Button>
                </Form.Item>
              )}

              <Form.Item>
                {isEditing
                  ? (
                    <Button size = {'large'} type = {'link'} onClick = {fillEditForm}>
                      Reset
                    </Button>
                  )
                  : (
                    <Button size = {'large'} type = {'link'} htmlType = {'reset'}>
                      Reset
                    </Button>
                  )}
              </Form.Item>

              <Form.Item>
                <Button
                  size = {'large'}
                  className = {'submit_button'}
                  type = {'primary'}
                  htmlType = {'submit'}
                  disabled = {isCreating}
                  loading = {isCreating}
                >
                  {isEditing ? 'Update' : 'Book'}
                </Button>
              </Form.Item>
            </Space>
          </Flex>
        </Form>
      </div>
    </>
  );
};
