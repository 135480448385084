export function generateInvoiceFilename (isin : string, tradeId : number, entityName : string) : string {
  return `${isin}_AYDO Invoice ${tradeId}_${entityName}.pdf`;
}

export function downloadFile (blob : Blob, fileName : string) : void {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}
