import React from 'react';

import { Form, type FormInstance, Table } from 'antd';
import type { TableProps } from 'antd/lib';

import { hideField } from '../../../../data/ProductType';
import { FieldsNameEnum, type PricingFields } from '../../../../data/enums/pricing';

import type { ScheduleData } from '../../../../data/Schedule';

type SchedulePricingFormProps = {
  form   : FormInstance<PricingFields>,
  nbStep : number,
};

export const SchedulePricingForm = ({
  form, nbStep,
} : SchedulePricingFormProps)
: React.ReactElement => {
  const productTypeId = Form.useWatch<string>(FieldsNameEnum.productType, form);
  const nonCallValue = Form.useWatch<number>([FieldsNameEnum.nonCallPeriod], form);
  const autocallTriggerValue = Form.useWatch<number>([FieldsNameEnum.autocallTrigger], form);
  const autocallStepdownValue = Form.useWatch<number>([FieldsNameEnum.autocallStepdown], form);
  const autocallFloorValue = Form.useWatch<number>([FieldsNameEnum.autocallFloor], form);
  const couponTriggerValue = Form.useWatch<number>([FieldsNameEnum.couponTrigger], form);
  const couponStepdownValue = Form.useWatch<number>([FieldsNameEnum.couponStepdown], form);
  const couponFloorValue = Form.useWatch<number>([FieldsNameEnum.couponFloor], form);
  const nbCouponPerYear = Form.useWatch<number>([FieldsNameEnum.numberPerYear], form);

  let scheduleData : ScheduleData[] = [];

  const getPeriodePrefix = () : string => {
    switch (nbCouponPerYear) {
      case 1:
        return 'Year ';
      case 2:
        return 'Semester ';
      case 4:
        return 'Quarter ';
      case 12:
        return 'Month ';
      default:
        return '';
    }
  };

  if (nbStep > 0) {
    scheduleData = Array(nbStep)
      .fill(0)
      .map((_, i) : ScheduleData => ({
        key    : i,
        period : getPeriodePrefix() + (i + 1).toString(),
        autocallTriggerValue :
            nonCallValue !== 0 && nonCallValue > i
              ? 'NC'
              : `${Math.max(
                autocallTriggerValue - ((i - nonCallValue) * autocallStepdownValue),
                autocallFloorValue || 0
              ).toFixed(2)}%`,
        couponTriggerValue : `${Math.max(couponTriggerValue - (i * couponStepdownValue), couponFloorValue).toFixed(2)}%`,
      }));
  }

  const scheduleColumn : TableProps<ScheduleData>['columns'] = [
    {
      title     : 'Period',
      dataIndex : 'period',
      key       : 'period',
      align     : 'center',
    },
    {
      title     : 'Autocall Trigger',
      dataIndex : 'autocallTriggerValue',
      key       : 'autocallTriggerValue',
      align     : 'center',
      hidden    : hideField(FieldsNameEnum.autocallTrigger, productTypeId),
    },
    {
      title     : 'Coupon Trigger',
      dataIndex : 'couponTriggerValue',
      key       : 'couponTriggerValue',
      align     : 'center',
      hidden    : hideField(FieldsNameEnum.couponTrigger, productTypeId),
    },
  ];

  return (
    <div className = {'scheduleWrapper cleverPurple'}>
      <div className = {'scheduleCard'}>
        <div className = {'cardHeader cleverPurple'}>
          <h2>Schedule</h2>
        </div>

        <Table
          size = {'small'}
          columns = {scheduleColumn}
          dataSource = {scheduleData}
          pagination = {false}
          rowHoverable = {false}
          rowClassName = {(_, i) : string => (i % 2 === 1 ? 'rowClassname' : 'rowClassnameLight')}
          scroll = {{
            scrollToFirstRowOnChange : false,
            y                        : '56vh',
          }}
        />
      </div>
    </div>
  );
};
