import { createSlice } from '@reduxjs/toolkit';

import { fetchTradesList, getSingleTrade } from './tradeApi';

import type { TradeListPageViewType } from './tradesStoreTypes';
import type { IssuerRef } from '../../data/Ref';
import type { SingleTradeType, TradeListItemType } from '../../data/TradesTypes';
import type { PayloadAction } from '@reduxjs/toolkit';

type TradesInitialStateType = {
  trades : {
    list       : TradeListItemType[],
    isLoading  : boolean,
    totalCount : number,
    error      : string,
  },
  trade : {
    item      : SingleTradeType | null,
    isLoading : boolean,
    error     : string,
  },
  issuers : {
    list      : IssuerRef[],
    isLoading : boolean,
    error     : string | null,
  },

  // To maintain the state of the trade list page
  tradeListPageView : TradeListPageViewType,
};

const initialState : TradesInitialStateType = {
  trades : {
    list       : [],
    isLoading  : false,
    totalCount : 0,
    error      : '',
  },
  trade : {
    item      : null,
    isLoading : false,
    error     : '',
  },
  issuers : {
    list      : [],
    isLoading : false,
    error     : null,
  },
  tradeListPageView : {
    pageNumber      : 1,
    expandedRowKeys : [],
    limit           : 60,
  },
};

export const tradesSlice = createSlice({
  name     : 'trades',
  initialState,
  reducers : {
    setTradeListPageViewState : (state, action : PayloadAction<TradeListPageViewType>) : void => {
      state.tradeListPageView.pageNumber = action.payload.pageNumber;
      state.tradeListPageView.expandedRowKeys = action.payload.expandedRowKeys;
      state.tradeListPageView.limit = action.payload.limit;
    },
  },
  extraReducers : (builder) : void => {
    builder.addCase(fetchTradesList.pending, (state) => {
      state.trades.isLoading = true;
    });
    builder.addCase(fetchTradesList.fulfilled, (state, action) => {
      state.trades.isLoading = false;
      state.trades.list = action.payload.trades;
      state.trades.totalCount = action.payload.totalCount;
    });
    builder.addCase(fetchTradesList.rejected, (state, action) => {
      state.trades.isLoading = false;
      state.trades.error = action.error.message ?? '';
    });

    builder.addCase(getSingleTrade.pending, (state) => {
      state.trade.isLoading = true;
      state.trade.error = '';
    });
    builder.addCase(getSingleTrade.fulfilled, (state, action) => {
      state.trade.isLoading = false;
      state.trade.item = action.payload.trade;
    });
    builder.addCase(getSingleTrade.rejected, (state, action) => {
      state.trade.isLoading = false;
      state.trade.error = action.error.message ?? '';
      state.trade.item = null;
    });
  },
});

export const { setTradeListPageViewState } = tradesSlice.actions;

export default tradesSlice.reducer;
