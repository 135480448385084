import type { FormInstance } from 'antd';

import dayjs from 'dayjs';

import { EventStatusEnum, EventTypesEnum, FrequencyEnum } from '../../../data/product/productEnum';

import type {
  CustomField, ProductEvent, ProductEventForm, ProductField, ProductFormFieldType
} from '../../../data/ProductType';
import type { ProductFieldType } from '../CreateProductPage/productInputFields';

type KeyValue = { [key : string] : string };

export const getFrequencyMultiplier = (frequency : string | undefined) : number => {
  switch (frequency) {
    case FrequencyEnum.Annual.toString():
      return 1;
    case FrequencyEnum.SemiAnnual.toString():
      return 2;
    case FrequencyEnum.Quarterly.toString():
      return 4;
    case FrequencyEnum.Monthly.toString():
      return 12;
    // eslint-disable-next-line no-undefined
    case undefined:
    default:
      console.error('Invalid frequency multiplier');
      return 1;
  }
};

export const getFrequencyString = (frequency : number) : string => {
  switch (frequency) {
    case 1:
      return FrequencyEnum.Annual;
    case 2:
      return FrequencyEnum.SemiAnnual;
    case 4:
      return FrequencyEnum.Quarterly;
    case 12:
      return FrequencyEnum.Monthly;
    default:
      console.error('Invalid frequency string');
      return FrequencyEnum.Annual;
  }
};

const generatedEvents = [EventTypesEnum.Today];

// Filter events to only include coupon, autocall and custom events and set the status of the events based on the current date
export const cleanEvents = (events : ProductEvent[]) : ProductEventForm[] => events
  .filter((e) => !generatedEvents.includes(e.eventType))
  .map((event) => {
    const current : ProductEventForm = {
      id              : event.id,
      actualFlow      : event.actualFlow,
      potentialFlow   : event.potentialFlow,
      eventType       : event.eventType,
      status          : event.status,
      autocallTrigger : event.autocallTrigger,
      couponTrigger   : event.couponTrigger,
      valuationDate   : event.valuationDate.format('YYYY-MM-DD'),
      paymentDate     : event.valuationDate.format('YYYY-MM-DD'),
    };

    if (dayjs(current.valuationDate).isBefore(dayjs())) {
      current.status = EventStatusEnum.Past;
    }

    return current;
  });

export const setNextEvent = (events : ProductEventForm[]) : ProductEventForm[] => {
  const hasNextEvent = events.some((e) => e.status === EventStatusEnum.NextEvent);
  if (!hasNextEvent) {
    const nextEvent = events.filter((e) => e.status === EventStatusEnum.Future)
      .sort((left, right) => dayjs(left.valuationDate).diff(dayjs(right.valuationDate), 'days'))
      .shift();
    if (nextEvent) {
      nextEvent.status = EventStatusEnum.NextEvent;
    }
  }
  return events;
};

/** Extracts all values of 'autocallTrigger' or 'couponTrigger'
 * @param events ProductEvent list
 * @param field 'autocallTrigger' or 'couponTrigger'
 * @returns extracted values
 */
export const extractStepdownValues = (events : ProductEvent[], field : 'autocallTrigger' | 'couponTrigger') : number[] => events
  .filter((e) => e[field]).map((e) => e[field] as number);

/**
   * Given an array of ProductField, groups the fields by the first word of their keys.
   * If a field has no spaces in its key, it will be grouped under the same key.
   * @param {ProductField[]} customFields
   * @returns { { [key : string] : KeyValue[] }[] }
   */
export const groupByFirstWord = (customFields : ProductField[]) : { [key : string] : KeyValue[] }[] => {
  const result : { [key : string] : KeyValue[] } = {};

  customFields.forEach((field) => {
    const fieldValue = field.customValue;
    const [firstWord] = field.customKey.split(' ');

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!result[firstWord]) {
      result[firstWord] = [];
    }
    result[firstWord].push({ [field.customKey] : fieldValue });
  });

  return Object.entries(result).map(([key, fieldValue]) => ({ [key] : fieldValue }));
};

export function mapCustomFields (field : ProductFieldType, form : FormInstance<ProductFormFieldType>) : CustomField {
  return {
    customKey   : field.fieldName,
    customValue : String(form.getFieldValue(field.fieldKey)),
  };
}
