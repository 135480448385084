import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Flex, type FormInstance } from 'antd';

import { PricingRoute } from '../../../../data/Routes';
import { FieldsNameEnum, type PricingFields } from '../../../../data/enums/pricing';

type ResultPricingFormProps = {
  form                 : FormInstance<PricingFields>,
  currencyInitialValue : number,
  setIsLoading         : (value : boolean)=> void,
};

export const ResultPricingForm = ({
  form, currencyInitialValue, setIsLoading,
} : ResultPricingFormProps)
: React.ReactElement => {
  const navigate = useNavigate();
  return (
    <Flex className = {'cardContent cardContentResult'} justify = {'space-around'}>
      <Button
        size = {'large'}
        type = {'primary'}
        disabled = {false}
        onClick = {() : void => {
          navigate(PricingRoute.path);
          setIsLoading(false);
        }}
      >
        Re-Quote
      </Button>

      <Button
        size = {'large'}
        type = {'primary'}
        htmlType = {'reset'}
        disabled = {false}
        onClick = {() : void => {
          navigate(PricingRoute.path);
          form.resetFields();
          form.setFieldValue([FieldsNameEnum.currencyRefId], currencyInitialValue);
          setIsLoading(false);
        }}
      >
        New Quote
      </Button>
    </Flex>
  );
};

