import React, { useEffect, useState } from 'react';

import { Timeline } from 'antd';

import dayjs from 'dayjs';

import { EventStatusEnum, EventTypesEnum } from '../../data/product/productEnum';
import { goldenYellow, greyColor, whiteColor } from '../../styles/colors';
import { EditableText } from '../EditableText/EditableText';

import { CalendarEventCard } from './CalendarEventCard';

import type { ProductEvent } from '../../data/ProductType';

import './CalendarEvents.scss';

type CalendarEventsPropsType = {
  events                : ProductEvent[],
  currentEditingIndex   : number | null,
  editionMode ?         : boolean,
  updateOrDeleteEvent ? : (eventIndex : number, action : 'edit' | 'remove')=> void,
  inlineEditEvent ?     : (updatedProductEvent : ProductEvent)=> void,
};

const CalendarEvents = ({
  events, currentEditingIndex, editionMode, updateOrDeleteEvent, inlineEditEvent,
} : CalendarEventsPropsType) : React.ReactElement => {

  const sortedEvents = events.sort((left, right) => dayjs(right.valuationDate).diff(dayjs(left.valuationDate)));

  const onChangeEventInfo = (updatedProductEvent : ProductEvent) : void => {
    if (inlineEditEvent) {
      inlineEditEvent(updatedProductEvent);
    }
  };

  const getStepColor = ({
    isBeforeNextEvent, isTodayEvent,
  } : { isBeforeNextEvent : boolean,
    isTodayEvent          : boolean, }) : string => {
    if (isTodayEvent) {
      return goldenYellow;
    }
    if (isBeforeNextEvent) {
      return greyColor;
    }
    return whiteColor;
  };

  const nextEvent = sortedEvents.find((event) => event.status === EventStatusEnum.NextEvent);
  const lastRedeemEventIndex = sortedEvents.reverse().findIndex((event) => event.status === EventStatusEnum.Past);
  return (
    <Timeline
      className = {'product__timeline'}
      mode = {'alternate'}
      items = {
        sortedEvents.map((event, index) => {
          const isBeforeNextEvent = nextEvent ? dayjs(event.valuationDate).isBefore(dayjs(nextEvent.valuationDate)) : false;
          const isNextEvent = event.status === EventStatusEnum.NextEvent;
          const isCanceledEvent = event.status === EventStatusEnum.Cancel;
          const isTodayEvent = event.eventType === EventTypesEnum.Today;
          const isLastReedemEvent = lastRedeemEventIndex === index;
          return {
            label : !isTodayEvent
              && (
                <EditableText
                  editable = {Boolean(editionMode)}
                  defaultValue = {dayjs(event.valuationDate).format('YYYY-MM-DD')}
                  valueType = {'date'}
                  defaultOpen = {false}
                  changeCallback = {(changedValue) : void => onChangeEventInfo({
                    ...event,
                    valuationDate : dayjs(changedValue),
                    paymentDate   : dayjs(changedValue),
                  })}
                />
              ),
            children :
            (<CalendarEventCard
              editionMode = {editionMode}
              isCurrentEditing = {currentEditingIndex === index}
              event = {event}
              eventIndex = {index}
              className = {'single_event'}
              isPastEvent = {isBeforeNextEvent}
              isToday = {isTodayEvent}
              isNextEvent = {isNextEvent}
              isCanceledEvent = {isCanceledEvent}
              isLastReedemEvent = {isLastReedemEvent}
              addOrDeleteEvent = {updateOrDeleteEvent}
              onChangeEventInfo = {onChangeEventInfo}
            />),
            position  : isTodayEvent ? 'right' : 'left',
            className : isTodayEvent ? 'today-event' : '',
            color     : getStepColor({
              isBeforeNextEvent,
              isTodayEvent,
            }),
          };
        })
      }
    />
  );
};

export { CalendarEvents };
