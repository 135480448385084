import efgLogo from '../assets/EFG.png';
import GSLogo from '../assets/GS.png';
import BarclaysLogo from '../assets/barclays.png';
import BBVALogo from '../assets/bbva.png';
import BNPLogo from '../assets/bnp.png';
import BOALogo from '../assets/boa.png';
import CIBCLogo from '../assets/cibc.png';
import citiLogo from '../assets/citi.png';
import leonteqLogo from '../assets/leonteq.png';
import MarexLogo from '../assets/marexLogo2.png';
import MorganStanleyLogo from '../assets/morganStanleyLogo.png';
import NomuraLogo from '../assets/nomura.svg';
import raiffeisenLogo from '../assets/raiffeisen.png';
import RBCLogo from '../assets/rbc.png';
import SocieteGeneraleLogo from '../assets/sgLogo.png';
import UBSLogo from '../assets/ubs.png';

import type { IssuerRef } from './Ref';

export type Issuer = {
  id          : number,
  name        : string,
  logo        : string,
  pipedriveId : number,
};

export function issuerRefToIssuer (r : IssuerRef) : Issuer {
  let logo : string;
  switch (r.pipedriveId) {
    case 2191:
      logo = BOALogo;
      break;
    case 483:
      logo = efgLogo;
      break;
    case 281:
      logo = BBVALogo;
      break;
    case 157:
      logo = BNPLogo;
      break;
    case 30:
      logo = CIBCLogo;
      break;
    case 491:
      logo = citiLogo;
      break;
    case 207:
      logo = GSLogo;
      break;
    case 263:
      logo = MarexLogo;
      break;
    case 212:
      logo = MorganStanleyLogo;
      break;
    case 1240:
      logo = RBCLogo;
      break;
    case 158:
      logo = SocieteGeneraleLogo;
      break;
    case 204:
      logo = UBSLogo;
      break;
    case 209:
      logo = leonteqLogo;
      break;
    case 275:
      logo = raiffeisenLogo;
      break;
    case 273:
      logo = NomuraLogo;
      break;
    default: {
      logo = '';
    }
  }
  return {
    id          : r.id,
    name        : r.fullName,
    logo,
    pipedriveId : r.pipedriveId,
  };
}

export const defaultIssuerSort = (l : Issuer, r : Issuer) : number => (l.name && r.name ? l.name.localeCompare(r.name) : 0);
