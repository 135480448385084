import { UserRoleEnum } from './enums/cloak';
import { HeaderTabsEnum } from './enums/header';

// If the roles are empty, it means that the route is public
export type AccessRule = {
  roles : UserRoleEnum[],
};

export type Route = {
  tab  : HeaderTabsEnum | null,
  path : string,
  rule : AccessRule,
};

export const HomeRoute : Route = {
  tab  : null,
  path : '/',
  rule : {
    roles : [],
  },
};

export const UserRoute : Route = {
  tab  : null,
  path : '/user',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.InternalAssistant,
      UserRoleEnum.LegalObserver,
    ],
  },
};

export const PricingRoute : Route = {
  tab  : HeaderTabsEnum.ayDeal,
  path : '/pricing',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.InternalAssistant,
      UserRoleEnum.LegalObserver,
      UserRoleEnum.UsePricingModule,
    ],
  },
};

export const PricingResultRoute : Route = {
  tab  : HeaderTabsEnum.ayDeal,
  path : '/pricing/:priceId',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.InternalAssistant,
      UserRoleEnum.LegalObserver,
      UserRoleEnum.UsePricingModule,
    ],
  },
};

export const ProductEventManagerRoute : Route = {
  tab  : HeaderTabsEnum.eventsManager,
  path : '/products/events/manager',
  rule : {
    roles : [
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.InternalAssistant,
    ],
  },
};

export const ProductsRoute : Route = {
  tab  : HeaderTabsEnum.products,
  path : '/products',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.InternalAssistant,
      UserRoleEnum.LegalObserver,
    ],
  },
};

export const TradesListRoute : Route = {
  tab  : HeaderTabsEnum.tradesList,
  path : '/trades',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.LegalObserver,
      UserRoleEnum.InternalAssistant,
    ],
  },
};

export const CreateTradePageRoute : Route = {
  tab  : null,
  path : '/trades/new',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.LegalObserver,
    ],
  },
};

export const EditTradePageRoute : Route = {
  tab  : null,
  path : '/trades/:tradeId/edit',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.LegalObserver,
    ],
  },
};

export const TradePageRoute : Route = {
  tab  : null,
  path : '/trades/:tradeId',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.LegalObserver,
      UserRoleEnum.InternalAssistant,
    ],
  },
};

export const ProductPageRoute : Route = {
  tab  : null,
  path : '/products/:productId',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.LegalObserver,
      UserRoleEnum.InternalAssistant,
    ],
  },
};

export const CreateProductPageRoute : Route = {
  tab  : null,
  path : '/products/new',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.InternalAssistant,
    ],
  },
};

export const EditProductPageRoute : Route = {
  tab  : null,
  path : '/products/:productId/edit',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.InternalAssistant,
    ],
  },
};

export const UnwindPageRoute : Route = {
  tab  : null,
  path : '/unwind',
  rule : {
    roles : [],
  },
};

export const ClientListPageRoute : Route = {
  tab  : HeaderTabsEnum.client,
  path : '/client',
  rule : {
    roles : [
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.InternalSalesman,
    ],
  },
};

export const ClientPageRoute : Route = {
  tab  : null,
  path : '/client/:clientId',
  rule : {
    roles : [
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.InternalSalesman,
    ],
  },
};

export const UnderlyingListPageRoute : Route = {
  tab  : HeaderTabsEnum.underlying,
  path : '/underlying',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.InternalAssistant,
    ],
  },
};

export const UnderlyingPageRoute : Route = {
  tab  : null,
  path : '/underlying/:isin',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.InternalAssistant,
    ],
  },
};

export const ExplorerRoute : Route = {
  tab  : HeaderTabsEnum.explorer,
  path : 'https://www.aydofinance.com/shape',
  rule : {
    roles : [],
  },
};

export const ProductOpenForSubscriptionRoute : Route = {
  tab  : HeaderTabsEnum.productsOpenForSubscription,
  path : '/products/openForSubscription',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.InternalAssistant,
    ],
  },
};

export const CLNPageRoute : Route = {
  tab  : HeaderTabsEnum.cln,
  path : '/cln',
  rule : {
    roles : [
      UserRoleEnum.InternalAdmin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.InternalSalesman,
      UserRoleEnum.InternalAssistant,
    ],
  },
};

export const getAllRoutes = () : Route[] => [
  HomeRoute,
  UserRoute,
  PricingRoute,
  PricingResultRoute,
  ProductEventManagerRoute,
  ProductsRoute,
  TradesListRoute,
  CreateTradePageRoute,
  EditTradePageRoute,
  TradePageRoute,
  ProductPageRoute,
  CreateProductPageRoute,
  EditProductPageRoute,
  UnwindPageRoute,
  ClientListPageRoute,
  ClientPageRoute,
  ExplorerRoute,
  ProductOpenForSubscriptionRoute,
  CLNPageRoute,
];
