import React from 'react';

import { Flex } from 'antd';

import { isBoolean } from 'lodash';

import { FrequencyEnum } from '../../../data/product/productEnum';
import { isBooleanString, isNumberString, parseForDisplayWithPercentSign } from '../../../utils/stringUtils';
import { groupByFirstWord } from '../lifecycleHelpers/lifecycleHelpers';

import type { ProductField, SingleProductType } from '../../../data/ProductType';

export const ProductCustomFields = ({
  customFields, product,
} : {
  customFields : ProductField[],
  product      : SingleProductType,
}) : React.ReactElement => {
  const getPeriodString = (couponValue : number) : string => {
    const frequencyValue = customFields.find((field) => field.customKey === 'Frequency');
    if (!frequencyValue) {
      return `${couponValue}% p.a.`;
    }
    switch (frequencyValue.customValue as FrequencyEnum) {
      case FrequencyEnum.SemiAnnual:
        return `${couponValue / 2}% p.s.`;
      case FrequencyEnum.Quarterly:
        return `${couponValue / 4}% p.q.`;
      case FrequencyEnum.Monthly:
        return `${couponValue / 12}% p.m.`;
      case FrequencyEnum.Annual:
      default:
        return `${couponValue}% p.a.`;
    }
  };

  const isAnnualy = () : boolean => {
    const frequencyValue = customFields.find((field) => field.customKey === 'Frequency')?.customValue;
    if (!frequencyValue) {
      return true;
    }
    return (frequencyValue as FrequencyEnum) === FrequencyEnum.Annual;
  };

  // Remove Coupon Floor and Autocall Floor fields
  const groupedFields = groupByFirstWord(customFields.filter((field) => {
    const fieldKey = Object.keys(field)[0];
    return fieldKey !== 'Coupon Floor' && fieldKey !== 'Autocall Floor';
  }));

  const formatValue = (fieldKey : string, fieldValue : string) : React.ReactElement => {

    if (!isBooleanString(fieldValue)) {
      if (fieldKey === 'NonCall') {
        return (
          <p>
            {fieldValue}
          </p>
        );
      }
      if (fieldKey === 'Coupon') {
        return (
          <p>
            {`${getPeriodString(parseFloat(fieldValue))} ${isAnnualy() ? '' : `(${parseForDisplayWithPercentSign(fieldValue)} p.a.)`}`}
          </p>
        );
      }
      if (isNumberString(fieldValue)) {
        if (fieldKey === 'Stepdown') {
          return (
            <p>
              -{`${Math.round(parseFloat(fieldValue) * 100) / 100}% ${getPeriodString(-parseFloat(fieldValue))}`}
            </p>
          );
        }
        return (
          <p>
            {`${Math.round(parseFloat(fieldValue) * 100) / 100}%`}
          </p>
        );
      }
      return (
        <p>{fieldValue}</p>
      );
    }

    return (
      <p>{isBoolean(fieldValue) ? 'Yes' : 'No'}</p>
    );
  };

  return (
    <div>
      <h4 className = {'section__title_margin'}>Parameters</h4>

      <div className = {'content__box__left_section'}>

        {groupedFields.map((group, groupIndex) => {
          const category = Object.keys(group)[0];
          const items = group[category];

          // Don't display the category if there is only one item, but display if it the first
          return items.length === 1 && groupIndex !== 0
            ? items.map((item, index) => {
              const fieldKey : string = Object.keys(item)[0];
              const fieldValue = Object.values(item)[0];
              return (
                <Flex
                  key = {fieldKey}
                  wrap = {false}
                  className = {`info__item ${index % 2 ? 'info__item__odd' : 'info__item__even'}`}
                >
                  <label>{fieldKey === 'Coupon' ? 'Value' : fieldKey}</label>

                  {formatValue(fieldKey, fieldValue)}
                </Flex>
              );
            })
            : (
              <div key = {category}>
                <h4 className = {'info__item__title'}>{category}</h4>

                <ul>
                  {items.map((item, index) => {
                    const fieldKey = Object.keys(item)[0];
                    const fieldValue = Object.values(item)[0];
                    return (
                      <Flex
                        key = {fieldKey}
                        wrap = {false}
                        className = {`info__item ${index % 2 ? 'info__item__odd' : 'info__item__even'}`}
                      >
                        <label>{fieldKey === 'Coupon' ? 'Value' : fieldKey}</label>

                        {formatValue(fieldKey, fieldValue)}
                      </Flex>
                    );
                  })}
                </ul>
              </div>
            );
        })}
      </div>
    </div>
  );
};

