import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Flex,
  type TableColumnsType, Tag
} from 'antd';
import type { ColumnFilterItem } from 'antd/es/table/interface';

import dayjs from 'dayjs';
import { t } from 'i18next';

import { ClipboardCopy } from '../../../Shared/ClipboardCopy/ClipboardCopy';
import { haveOneOfTheRoles } from '../../../config/keycloak';
import { Valuation100, Valuation105, Valuation90 } from '../../../data/ProductType';
import { UserRoleEnum } from '../../../data/enums/cloak';
import { ValuationLevel } from '../../../data/enums/labels';
import {
  greenColor, redColor, whiteColor
} from '../../../styles/colors';
import { parseCurrency } from '../../../utils/currencyUtils';
import { ProductValuation } from '../Shared/ProductValuation/ProductValuation';

import type { ProductGroupedByAttribute, ProductListItem } from '../../../data/ProductType';
import type { TradeListItemForProduct } from '../../../data/TradesTypes';

export const productsColumns = (productList : ProductListItem[]) : TableColumnsType<ProductListItem> => [
  {
    title     : 'table.columns.isin',
    key       : 'isin',
    dataIndex : 'isin',
    width     : 130,
    render    : (renderValue : string) : React.ReactNode => <ClipboardCopy text = {renderValue} />,
  },
  {
    title     : 'table.columns.name',
    key       : 'name',
    dataIndex : 'name',
    width     : 300,
    sorter    : (left, right) : number => left.name.localeCompare(right.name),
    render    : (renderValue : string) : React.ReactNode => <ClipboardCopy text = {renderValue} />,
  },
  {
    title     : 'table.columns.productType',
    key       : 'productType',
    dataIndex : 'productType',
    width     : 240,
    sorter    : (left, right) : number => left.productType.localeCompare(right.productType),
    filters   : Object.values(
      // eslint-disable-next-line @stylistic/block-spacing
      productList.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
        acc[current.productType] = acc[current.productType] ?? {
          text  : current.productType,
          value : current.productType,
        };
        return acc;
      }, {})
    ),
    onFilter     : (filterValue, record) : boolean => record.productType.startsWith(filterValue as string),
    filterSearch : true,
  },
  {
    title     : 'table.columns.issuer',
    key       : 'issuerFullName',
    dataIndex : 'issuerFullName',
    width     : 240,
    sorter    : (left, right) : number => left.issuerFullName.localeCompare(right.issuerFullName),
    filters   : Object.values(
      // eslint-disable-next-line @stylistic/block-spacing
      productList.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
        acc[current.issuerFullName] = acc[current.productType] ?? {
          text : current.issuerFullName,

          value : current.issuerFullName,
        };
        return acc;
      }, {})
    ),
    onFilter     : (filterValue, record) : boolean => record.issuerFullName.startsWith(filterValue as string),
    filterSearch : true,
  },
  {
    title     : 'table.columns.issueDate',
    key       : 'issueDate',
    dataIndex : 'issueDate',
    align     : 'right',
    width     : 150,
    sorter    : (left, right) : number => new Date(left.issueDate).getTime() - new Date(right.issueDate).getTime(),
    render    : (renderValue : string) : React.ReactNode => dayjs(renderValue).format('DD/MM/YYYY'),
  },
  {
    title     : 'table.columns.maturityDate',
    key       : 'maturityDate',
    dataIndex : 'maturityDate',
    align     : 'right',
    width     : 150,
    sorter    : (left, right) : number => new Date(left.maturityDate).getTime() - new Date(right.maturityDate).getTime(),
    render    : (renderValue : string) : React.ReactNode => dayjs(renderValue).format('DD/MM/YYYY'),
  },
  {
    title     : 'table.columns.valuation',
    key       : 'valuation',
    dataIndex : 'valuation',
    align     : 'center',
    width     : 190,
    sorter    : (left, right) : number => left.valuation - right.valuation,
    filters   : [
      {
        text  : '>= 105',
        value : ValuationLevel.greenPos,
      },
      {
        text  : '100 <= val < 105',
        value : ValuationLevel.greenNormal,
      },
      {
        text  : '90 <= val < 100',
        value : ValuationLevel.orange,
      },
      {
        text  : 'val < 90',
        value : ValuationLevel.red,
      },
    ],

    onFilter : (filterValue, record) : boolean => {
      switch (filterValue as ValuationLevel) {
        case ValuationLevel.greenPos:
          return record.valuation >= Valuation105;
        case ValuationLevel.greenNormal:
          return record.valuation >= Valuation100 && record.valuation < Valuation105;
        case ValuationLevel.orange:
          return record.valuation >= Valuation90 && record.valuation < Valuation100;
        case ValuationLevel.red:
          return record.valuation < Valuation90;
        default:
          return true;
      }
    },

    render : (value : number | null, item : ProductListItem) : React.ReactNode => (
      <Flex justify = {'center'} align = {'center'} gap = {5}>
        <ProductValuation valuationValue = {value} fromList = {true} />

        {Boolean(value)
        && haveOneOfTheRoles([UserRoleEnum.InternalAssistant, UserRoleEnum.SuperAdmin])
        && item.sixListingName
        && <p> ({item.sixListingName})</p>}
      </Flex>
    ),
  },
  {
    title     : 'table.columns.outstanding',
    dataIndex : 'outstanding',
    key       : 'outstanding',
    sorter    : (left : ProductListItem, right : ProductListItem) : number => {
      const aSum = left.trades.reduce((acc, obj) => acc + obj.outstandingAmount, 0);
      const bSum = right.trades.reduce((acc, obj) => acc + obj.outstandingAmount, 0);
      return aSum - bSum;
    },
    align : 'right',

    render : (_, record) : React.ReactNode => parseCurrency(record.trades.reduce((acc, obj) => acc + obj.outstandingAmount, 0), record.currency),
  },
  {
    title     : 'table.columns.nextEventDate',
    key       : 'nextEventDate',
    dataIndex : 'nextEventDate',
    align     : 'right',
    width     : 170,
    sorter    : (left, right) : number => new Date(left.nextEventDate).getTime() - new Date(right.nextEventDate).getTime(),
    render    : (renderValue : string) : string => dayjs(renderValue).format('DD/MM/YYYY'),
  },
  {
    title     : 'table.columns.status',
    key       : 'status',
    dataIndex : 'status',
    align     : 'center',
    width     : 90,
    render    : (_, record : ProductListItem) : React.ReactNode => (
      <Tag
        color = {record.status === 'Alive' ? 'green' : 'red'}
        style = {{
          background : record.status === 'Alive' ? greenColor : redColor,
          border     : 'none',
          fontSize   : '13px',
          color      : whiteColor,
        }}
      >{record.status}
      </Tag>
    ),
    filters : [
      {
        text  : 'Alive',
        value : 'Alive',
      },
      {
        text  : 'Expired',
        value : 'expired',
      },
    ],
  },
];

export const tradesListcolumns = (currency : string) : TableColumnsType<TradeListItemForProduct> => [
  {
    title     : 'Trade Id',
    dataIndex : 'id',
    key       : 'id',
  },
  {
    title     : 'Owner',
    dataIndex : 'ownerName',
    key       : 'ownerName',
  },
  {
    title     : 'Client Name',
    dataIndex : 'clientName',
    key       : 'clientName',
  },
  {
    title     : 'Trade Date ',
    dataIndex : 'settlementDate',
    key       : 'settlementDate',
    render    : (date) : React.ReactNode => <>{dayjs(date as string).format('DD/MM/YYYY')}</>,
  },
  {
    title     : 'Outstanding Amount',
    dataIndex : 'outstandingAmount',
    key       : 'outstandingAmount',
    render    : (_, record) : React.ReactNode => parseCurrency(record.custodians.reduce((acc, obj) => acc + obj.amount, 0), currency),
  },
];

export const generateGroupedListColumns = (title : string) : TableColumnsType<ProductGroupedByAttribute> => [
  {
    title,
    dataIndex : 'groupName',
    render    : (val, record) : React.ReactNode => (
      <p>
        <span className = {'group-name'}>
          {val}

          {' '}
        </span>

        <span className = {'product-group-count'}>({record.groupCount} products)</span>
      </p>

    ),
  },
];
