import type { ComponentProps } from 'react';
import React, { useRef, useState } from 'react';

import type { InputRef } from 'antd';
import {
  Button, DatePicker, Divider, Flex, Input, Select, Space
} from 'antd';

import dayjs from 'dayjs';

import { CalendarEvents } from '../../../../Shared/CalendarEvents/CalendarEvents';
import { EventStatusEnum, EventTypesEnum, eventTypesSelectableList } from '../../../../data/product/productEnum';

import type { ProductEvent } from '../../../../data/ProductType';

type CalendarEvent = {
  setHasCustomSchedule : React.Dispatch<React.SetStateAction<boolean>>,
  setCalendarEvents    : React.Dispatch<React.SetStateAction<ProductEvent[]>>,
  calendarEvents       : ProductEvent[],
};

export default function CreateProductCalendar ({
  setHasCustomSchedule, setCalendarEvents, calendarEvents,
} : CalendarEvent) : React.ReactElement {
  const inputRef = useRef<InputRef>(null);

  const [calendarEventForm, setCalendarEventForm] = useState<ProductEvent>({
    id            : 1,
    eventType     : EventTypesEnum.Coupon,
    paymentDate   : dayjs(),
    valuationDate : dayjs(),
    status        : EventStatusEnum.Future,
    potentialFlow : '',
  });
  const [name, setName] = useState('');
  const [items, setItems] = useState<string[]>(eventTypesSelectableList);
  const inputEventDateRef : ComponentProps<typeof DatePicker>['ref'] = useRef(null);
  const [currentEventIndex, setCurrentEventIndex] = useState<number | null>(null);
  const onNameChange = (event : React.ChangeEvent<HTMLInputElement>) : void => {
    setName(event.target.value);
  };
  const resetEventForm = () : void => {
    setCalendarEventForm({
      id            : calendarEvents.length + 1,
      eventType     : EventTypesEnum.Coupon,
      paymentDate   : dayjs(),
      valuationDate : dayjs(),
      status        : EventStatusEnum.Future,
      potentialFlow : '',
    });
  };
  const onChangeCalendarEventInfo = (updatedEvent : ProductEvent) : void => {
    setHasCustomSchedule(true);
    setCalendarEvents(
      calendarEvents.map((event) => (event.id === updatedEvent.id ? { ...updatedEvent } : { ...event }))
    );
  };

  const onAddCalendarEvent = () : void => {
    // add and sort the events by date
    const updatedCalendarEvents = [
      ...calendarEvents,
      calendarEventForm,
    ];
    setCalendarEvents(updatedCalendarEvents);
    setHasCustomSchedule(true);
    setCurrentEventIndex(null);

    // reset the form inputs
    resetEventForm();
  };

  const onUpdateCalendarEvent = () : void => {
    setCalendarEvents(calendarEvents.map((event, index) => (index === currentEventIndex ? calendarEventForm : event)));
    setCurrentEventIndex(null);
    setHasCustomSchedule(true);
    resetEventForm();
  };

  const onChangecalendarEventForm = (key : string, inputValue : string | number | dayjs.Dayjs) : void => {
    setHasCustomSchedule(true);
    setCalendarEventForm({
      ...calendarEventForm,
      [key] : inputValue,
    });
  };

  const onChangeCalendarEvent = (eventIndex : number, action : 'edit' | 'remove') : void => {
    setHasCustomSchedule(true);
    if (action === 'remove') {
      setCalendarEvents(calendarEvents.filter((_, index) => eventIndex !== index));
    } else {
      setCurrentEventIndex(eventIndex);
      setCalendarEventForm(calendarEvents[eventIndex]);
      inputEventDateRef.current?.focus();
    }
  };
  const addItem = (e : React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) : void => {
    e.preventDefault();
    setItems([...items, name]);
    setName('');
    inputRef.current?.focus();
  };

  return (
    <div className = {'form__group form__group_calendar'}>
      <Flex vertical = {false} justify = {'space-between'}>
        <h4 className = {'section__title section__title__background'}>Calendar</h4>

        <Button
          style = {{
            marginTop   : '10px',
            marginRight : '10px',
          }}
          onClick = {() : void => setHasCustomSchedule(false)}
        >Generate new calendar
        </Button>
      </Flex>

      {calendarEvents.length > 0
      && (
        <div className = {'product_timeline_wrapper'}>
          <CalendarEvents
            inlineEditEvent = {(updatedEvent) : void => onChangeCalendarEventInfo(updatedEvent)}
            editionMode = {true}
            events = {calendarEvents}
            updateOrDeleteEvent = {onChangeCalendarEvent}
            currentEditingIndex = {currentEventIndex}
          />
        </div>
      )}

      <div className = {'form__group_content'}>

        <p className = {'input_event_type__label'}>Event type & Date</p>

        <Space.Compact className = {'customField'}>

          <Select
            showSearch
            placeholder = {'Select Type...'}
            style = {{ width : '60%' }}
            className = {'product-select-input'}
            value = {calendarEventForm.eventType}
            options = {items.map((eventType) => ({

              value : eventType,
              label : <span>{eventType}</span>,
            }))}
            dropdownRender = {(menu) : React.ReactElement => (
              <>
                {menu}

                <Divider style = {{ margin : '8px 0' }} />

                <Space.Compact>
                  <Input
                    className = {'product-form-input'}
                    placeholder = {'Enter custom...'}
                    ref = {inputRef}
                    value = {name}
                    onChange = {onNameChange}
                    onKeyDown = {(e) : void => e.stopPropagation()}
                  />

                  <Button type = {'text'} onClick = {addItem}>
                    Add
                  </Button>
                </Space.Compact>
              </>
            )}
            onChange = {(type : string) : void => onChangecalendarEventForm('eventType', type)}
          />

          <DatePicker
            style = {{ width : '40%' }}
            ref = {inputEventDateRef}
            className = {'product-form-input'}
            value = {calendarEventForm.valuationDate}
            maxDate = {calendarEvents.find((event) => event.eventType === EventTypesEnum.Maturity)?.valuationDate}
            onChange = {(date) : void => onChangecalendarEventForm('valuationDate', date)}
          />
        </Space.Compact>

        {![
          EventTypesEnum.InitialFixing,
          EventTypesEnum.Issue,
          EventTypesEnum.Today,
          EventTypesEnum.Maturity,
        ].includes(calendarEventForm.eventType) && (
          <>
            <p>Potential Flow</p>

            <Input
              min = {0}
              placeholder = {'0'}
              className = {'product-form-input'}
              value = {calendarEventForm.potentialFlow}
              onChange = {(e) : void => onChangecalendarEventForm('potentialFlow', e.target.value)}
            />
          </>
        )}

        <Flex justify = {'flex-end'} className = {'addNewEvent_wrapper'}>
          {Number.isInteger(currentEventIndex)
          && (
            <Button
              type = {'default'}
              onClick = {() : void => {
                setCurrentEventIndex(null);
                resetEventForm();
              }}
            >
              Cancel
            </Button>
          )}

          <Button
            type = {'default'}
            onClick = {() : void => (Number.isInteger(currentEventIndex) ? onUpdateCalendarEvent() : onAddCalendarEvent())}
          >
            {Number.isInteger(currentEventIndex) ? 'Update' : 'Add'}
          </Button>
        </Flex>
      </div>
    </div>
  );
}
