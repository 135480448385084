import React, { useState } from 'react';

import {
  Button, Flex, Form, InputNumber
} from 'antd';

import { SolveForEnum } from '../../../data/enums/pricing';

import { MonteCarloLaunch } from './MonteCarloLaunch';
import MonteCarloResults from './MonteCarloResults';

import type { MonteCarloResponse } from '../../../data/pricing/MonteCarlo';

import './MonteCarlo.css';

type MonteCarloProps = {
  pricingId : string | undefined,
  solveFor  : SolveForEnum,
};

const MonteCarlo = (props : MonteCarloProps) : React.ReactElement => {
  const {
    pricingId, solveFor,
  } = props;

  const pas = 0.05;

  const initializeHistogram = () : { [key : number] : number } => {
    const data : { [key : number] : number } = {};
    for (let i = -1; i <= 1; i = i + pas) {
      const roundedValue = Math.round(i * (1 / pas)) / (1 / pas);
      data[roundedValue] = 0;
    }
    return data;
  };

  const [hasData, setHasData] = useState<boolean>(false);
  const [isComputing, setisComputing] = useState<boolean>(false);
  const [valueField, setValueField] = useState<number>(100);
  const [couponValue, setCouponValue] = useState<number>(10);
  const [nbTotalSim, setNbTotalSim] = useState<number>(0);
  const [nbDoneSim, setNbDoneSim] = useState<number>(0);
  const [monteCarloData, setMonteCarloData] = useState<MonteCarloResponse>({
    autocallRates          : {},
    autocallProbability    : 0,
    averageMaturity        : 0,
    averageCouponPaid      : 0,
    averageRedemption      : 0,
    belowBarrierPercentage : 0,
    maturityCouponedCount  : 0,
    updateBatchSize        : 0,
    totalFlows             : [],
  });
  const [histogramData, setHistogramData] = useState<{ [key : number] : number }>(initializeHistogram());
  const [cdfData, setCdfData] = useState<{ flow : number,
    probability                                 : number, }[]>([]);

  const closeConnection = () : void => {
    setisComputing(false);
  };

  const handlerGraph = (data : MonteCarloResponse) : void => {
    setNbDoneSim((prev) => prev + data.updateBatchSize);

    setMonteCarloData((prevData) => {
      const updatedAutocallRates = { ...prevData.autocallRates };
      Object.entries(data.autocallRates).forEach(([key, value]) => {
        const maturity = Number(key);
        updatedAutocallRates[maturity] = (updatedAutocallRates[maturity] || 0) + value;
      });

      return {
        autocallRates          : updatedAutocallRates,
        autocallProbability    : prevData.autocallProbability + data.autocallProbability,
        averageMaturity        : prevData.averageMaturity + data.averageMaturity,
        averageCouponPaid      : prevData.averageCouponPaid + data.averageCouponPaid,
        averageRedemption      : prevData.averageRedemption + data.averageRedemption,
        belowBarrierPercentage : prevData.belowBarrierPercentage + data.belowBarrierPercentage,
        maturityCouponedCount  : prevData.maturityCouponedCount + data.maturityCouponedCount,
        updateBatchSize        : data.updateBatchSize,
        totalFlows             : [...prevData.totalFlows, ...data.totalFlows],
      };
    });

    setHistogramData((prevHistogramData) => {
      const newHistogramData = { ...prevHistogramData };
      data.totalFlows.forEach((value) => {
        const roundedValue = Math.floor(value * (1 / pas)) / (1 / pas);
        newHistogramData[roundedValue] = (newHistogramData[roundedValue] || 0) + 1;
      });

      return newHistogramData;
    });

    setCdfData(() => {
      let cumulativeCount = 0;

      const totalFlowsChartData = Object.entries(histogramData)
        .map(([key, count]) => ({
          flow : parseFloat(key),
          count,
        }))
        .sort((a, b) => a.flow - b.flow);

      const totalCount = totalFlowsChartData.reduce((sum, entry) => sum + entry.count, 0);

      return totalFlowsChartData.map(({
        flow, count,
      }) => {
        cumulativeCount = cumulativeCount + count;
        return {
          flow,
          probability : cumulativeCount / totalCount,
        };
      });
    });
  };

  const showButtonTitle = () : string => {
    if (isComputing) {
      return 'Cancel';
    }
    if (hasData) {
      return 'Add simulations';
    }
    return 'Run simulations';
  };

  const mcCard = () : React.JSX.Element => (
    <Flex
      vertical
      style = {{ padding : '20px' }}
      gap = {16}
      className = {'cardWrapperSubmit cleverPurple'}
    >
      <Flex
        vertical
        justify = {'space-between'}
        align = {'center'}
      >
        <h2>Monte Carlos</h2>

        <Flex style = {{ padding : '20px' }} gap = {8} align = {'center'}>
          <Form.Item label = {'Number of simulations'}>

            <InputNumber
              controls = {false}
              disabled = {isComputing}
              value = {valueField}
              min = {100}
              max = {10_000}
              onChange = {(v) : void => setValueField(v ?? 10)}
            />
          </Form.Item>

          <Form.Item label = {'Coupon value'} hidden = {solveFor !== SolveForEnum.coupon}>
            <InputNumber
              controls = {false}
              disabled = {hasData}
              value = {couponValue}
              suffix = {'% p. a.'}
              onChange = {(v) : void => setCouponValue(v ?? 10)}
            />
          </Form.Item>

        </Flex>

        <Button
          disabled = {false}
          type = {'primary'}
          size = {'large'}
          onClick = {() : void => {
            if (isComputing) {
              closeConnection();
              setNbTotalSim(nbDoneSim);
            } else {
              setisComputing(true);
              if (hasData) {
                setNbTotalSim((prev) => prev + valueField);
              } else {
                setNbTotalSim(valueField);
                setHasData(true);
              }
            }
          }}
        >
          {showButtonTitle()}
        </Button>
      </Flex>

      {pricingId && isComputing
      && (
        <MonteCarloLaunch
          pricingId = {pricingId}
          valueField = {valueField}
          solveFor = {solveFor}
          couponValue = {couponValue}
          closeConnection = {closeConnection}
          setGraphData = {handlerGraph}
        />
      )}

      {pricingId && hasData
      && (
        <MonteCarloResults
          pricingId = {pricingId}
          nbTotalSim = {nbTotalSim}
          nbDoneSim = {nbDoneSim}
          histogramData = {histogramData}
          cdfData = {cdfData}
          monteCarloData = {monteCarloData}
        />
      )}
    </Flex>
  );
  return (
    <>
      {pricingId && (solveFor === SolveForEnum.coupon || solveFor === SolveForEnum.reoffer) ? mcCard() : <></>}
    </>
  );

};

export default MonteCarlo;
