import React, { useContext } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import type { FormInstance } from 'antd';
import {
  Button, DatePicker, Flex, Form, Input, InputNumber, Select, Space
} from 'antd';
import type { MessageInstance } from 'antd/es/message/interface';

import dayjs from 'dayjs';

import { UnderlyingEquityForm } from '../../../../Shared/AddUnderlyingsForm/AddEquityLikeForm/UnderlyingEquityForm';
import { AddUnderlyingRateAndCDSForm } from '../../../../Shared/AddUnderlyingsForm/AddRatesAndCDSForm/AddUnderlyingRateAndCDSForm';
import { myFetch } from '../../../../config/api';
import { GlobalContext } from '../../../../context/GlobalContext';
import { issuerRefToIssuer } from '../../../../data/Issuers';
import { BasketTypeEnum, ProductTypeEnum, productTypeMapping } from '../../../../data/product/productEnum';
import { sortCurrenciesForSelectOptions } from '../../../../utils/currencyUtils';
import { isValidIsin } from '../../../../utils/validator';

import type { Issuer } from '../../../../data/Issuers';
import type { ProductField, ProductFormFieldType, UnderlyingEquity } from '../../../../data/ProductType';

type CreateProductGeneralSectionProps = {
  form                     : FormInstance<ProductFormFieldType>,
  defaultCustomFieldValues : ProductField[],
  isEditing                : boolean,
  messageApi               : MessageInstance,
  initialRates             : string[],
  setUnderlyingsRates      : React.Dispatch<React.SetStateAction<string[]>>,
  setUnderlyingsEquity     : React.Dispatch<React.SetStateAction<UnderlyingEquity[]>>,
  underlyingsEquity        : UnderlyingEquity[],
  setCustomFields          : React.Dispatch<React.SetStateAction<ProductField[]>>,
  customFields             : ProductField[],
  productId              ? : string,
};

export default function CreateProductGeneralSection ({
  form,
  isEditing,
  productId,
  messageApi,
  initialRates,
  setUnderlyingsRates,
  setUnderlyingsEquity,
  setCustomFields,
  underlyingsEquity,
  customFields,
  defaultCustomFieldValues,
} : CreateProductGeneralSectionProps) : React.ReactElement {
  const productType = Form.useWatch<string>('productType', form);
  const productSubType = Form.useWatch<string>('productSubType', form);

  const globalContext = useContext(GlobalContext);
  if (!globalContext) {
    throw new Error('You probably forgot to put <GlobalProvider>.');
  }
  const { allRefData } = globalContext;
  const issuersList : Issuer[] = allRefData?.IssuerRefList.map((e) => issuerRefToIssuer(e)) ?? [];

  const onChangeInputOptions = (paramKey : keyof ProductField, paramValue : string, paramIndex : number) : void => {
    setCustomFields(
      customFields.map((field, index) => {
        if (paramIndex === index) {
          return {
            ...field,
            [paramKey] : paramValue,
          };
        }
        return field;
      })
    );
  };

  const addNewCustomField = () : void => {
    setCustomFields([
      ...customFields,
      ...defaultCustomFieldValues,
      {
        customKey   : '',
        customValue : '',
      },
    ]);
  };

  return (
    <div className = {'form__group form__group_general'}>
      <h4 className = {'section__title section__title__background'}>General</h4>

      <div className = {'form__group_content'}>

        <Form.Item label = {'Product Type'} name = {'productType'} rules = {[{ required : true }]}>
          <Select
            showSearch
            placeholder = {'Select Product Type'}
            className = {'product-select-input'}
            options = {Object.keys(productTypeMapping).map((type) => ({
              value : type,
              label : <span>{type}</span>,
            }))}
            onChange =
              {(newProductType) : void => form.setFieldsValue({ productSubType : productTypeMapping[newProductType as ProductTypeEnum][0] })}
          />
        </Form.Item>

        <Form.Item label = {'Product Subtype'} name = {'productSubType'} rules = {[{ required : true }]}>
          <Select
            showSearch
            placeholder = {'Select subType'}
            className = {'product-select-input'}
            options = {
              productType
                ? productTypeMapping[productType as ProductTypeEnum].map((subType) => ({
                  value : subType,
                  label : <span>{subType}</span>,
                }))
                : []
            }
          />
        </Form.Item>

        <Form.Item
          label = {'ISIN Code'}
          name = {'isin'}
          rules = {[
            {
              validator : async (_, value : string) : Promise<void> => {
                if (isEditing && productId) {
                  return Promise.resolve();
                }
                const isinValid = await isValidIsin(value);
                if (!isinValid) {
                  return Promise.reject(Error('ISIN is invalid or already exists.'));
                }
                return Promise.resolve();
              },
            },
            {
              required : productType !== ProductTypeEnum.Draft.toString(),
            },
          ]}
        >
          <Input className = {'product-form-input'} placeholder = {'Enter ISIN...'} />
        </Form.Item>

        <Form.Item
          name = {'name'}
          label = {'Product Name'}
          rules = {[{ required : productType !== ProductTypeEnum.Draft.toString() }]}
        >
          <Input className = {'product-form-input'} placeholder = {'Product Name...'} />
        </Form.Item>

        <Form.Item label = {'Issuer'} name = {'issuerId'} rules = {[{ required : true }]}>
          <Select
            showSearch
            placeholder = {'Select Issuer'}
            className = {'product-select-input'}
            options = {issuersList.map((issuer) => ({
              value : issuer.id,
              label : issuer.name,
            }))}
            filterOption = {(input, option) : boolean => {
              const label = option?.label ?? '';
              return Boolean(label.toLowerCase().includes(input.toLowerCase()));
            }}
          />
        </Form.Item>

        <Form.Item
          name = {'denomination'}
          label = {'Denomination'}
          rules = {[{ required : true }]}
        >
          <InputNumber
            className = {'product-form-input'}
            style = {{ width : '100%' }}
            placeholder = {'1000'}
            min = {1}
          />
        </Form.Item>

        <Flex gap = {8}>
          <Form.Item
            label = {'Initial Fixing'}
            name = {'initialFixingDate'}
            style = {{ flexBasis : '100%' }}
            rules = {[{ required : productType !== ProductTypeEnum.Draft.toString() }]}
            getValueProps = {(v : string) : ({ value : dayjs.Dayjs }) => ({ value : dayjs(v) })}
          >
            <DatePicker
              className = {'product-form-input'}
              style = {{ width : '100%' }}
              format = {'DD/MM/YYYY'}
              allowClear = {false}
            />
          </Form.Item>

          <Form.Item
            label = {'Issue'}
            style = {{ flexBasis : '100%' }}
            name = {'issueDate'}
            rules = {[{ required : productType !== ProductTypeEnum.Draft.toString() }]}
            getValueProps = {(v : string) : ({ value : dayjs.Dayjs }) => ({ value : dayjs(v) })}
          >
            <DatePicker
              className = {'product-form-input'}
              style = {{ width : '100%' }}
              format = {'DD/MM/YYYY'}
              allowClear = {false}
            />
          </Form.Item>
        </Flex>

        <Flex gap = {8}>
          <Form.Item
            label = {'Maturity'}
            name = {'maturityLength'}
            rules = {[{ required : productType !== ProductTypeEnum.Draft.toString() }]}
            style = {{ flexBasis : '100%' }}
          >
            <InputNumber
              className = {'product-form-input'}
              style = {{ width : '100%' }}
              placeholder = {'0'}
              suffix = {'months'}
              min = {1}
            />
          </Form.Item>

          <Form.Item
            label = {'Currency'}
            style = {{ width : '100%' }}
            name = {'currencyRefId'}
            rules = {[{ required : productType !== ProductTypeEnum.Draft.toString() }]}
          >
            <Select
              showSearch
              style = {{ width : '100%' }}
              placeholder = {'Select Currency'}
              className = {'product-select-input'}
              options = {sortCurrenciesForSelectOptions(allRefData?.CurrencyRefList ?? [])}
              filterOption = {(input, option) : boolean => {
                const label = (option?.label ?? '') as string;
                return Boolean(label.toLowerCase().includes(input.toLowerCase()));
              }}
            />
          </Form.Item>
        </Flex>

        {productType !== ProductTypeEnum.CreditLinkedNote.toString() && (
          <>
            <Form.Item
              name = {'basketType'}
              style = {{ flexBasis : '100%' }}
              label = {'Basket Type'}
              rules = {[{ required : productType !== ProductTypeEnum.Draft.toString() }]}
            >
              <Select
                showSearch
                placeholder = {'Select...'}
                className = {'product-select-input'}
                options = {Object.values(BasketTypeEnum).map((type) => ({
                  value : type,
                  label : <span>{type}</span>,
                }))}
              />
            </Form.Item>

            <p>Underlyings Equity</p>

            {productSubType !== 'Fixed Rate Note'
            && (
              <UnderlyingEquityForm
                underlyingList = {underlyingsEquity}
                onAddUnderlying = {(list) : void => setUnderlyingsEquity(list)}
              />
            )}
          </>
        )}

        <p style = {{
          marginTop    : '10px',
          marginBottom : '4px',
        }}
        >Underlyings Rates and CDS
        </p>

        {productSubType !== 'Fixed Rate Note'
        && (
          <AddUnderlyingRateAndCDSForm
            initialRates = {initialRates}
            onAddUnderlying = {(list) : void => setUnderlyingsRates(list)}
          />
        )}

        <h4 className = {'section__title-topmargin'}>Additional data</h4>

        {customFields.map((customField, index) => (
          <Space.Compact key = {index} className = {'customField'}>
            <Input
              className = {'product-form-input'}
              value = {customField.customKey}
              style = {{ width : '48%' }}
              placeholder = {'Enter param name...'}
              onChange = {(e) : void => onChangeInputOptions('customKey', e.target.value, index)}
            />

            <Input
              className = {'product-form-input'}
              value = {customField.customValue}
              style = {{ width : '48%' }}
              placeholder = {'Enter param value...'}
              onChange = {(e) : void => onChangeInputOptions('customValue', e.target.value, index)}
            />

            <Button type = {'dashed'} onClick = {() : void => setCustomFields(customFields.filter((_, i) => i !== index))}>
              <CloseOutlined />
            </Button>
          </Space.Compact>
        ))}

        <Flex className = {'addNewField'} justify = {'flex-end'}>
          <Button
            type = {'dashed'}
            onClick = {addNewCustomField}
          >
            Add New Field
          </Button>
        </Flex>
      </div>
    </div>
  );
}
