import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { ConfigProvider, theme } from 'antd';
import locale from 'antd/locale/en_GB';

import { ClientPage } from './Modules/Administration/Client/Client';
import { ClientList } from './Modules/Administration/ClientList/ClientList';
import { EditUnderlying } from './Modules/Administration/Underlyings/UnderlyingEdit/EditUnderlying';
import { UnderlyingsList } from './Modules/Administration/Underlyings/UnderlyingList/UnderlyingsList';
import { UserPage } from './Modules/Administration/UserPage/UserPage';
import { CreateProductPage } from './Modules/LifeCycleManagement/CreateProductPage/CreateProductPage';
import { CreateTradePage } from './Modules/LifeCycleManagement/CreateTradePage/CreateTradePage';
import { ProductEventManager } from './Modules/LifeCycleManagement/ProductEventManager/ProductEventManager';
import { ProductOpenForSubscriptionList } from './Modules/LifeCycleManagement/ProductOpenForSubscriptionList/ProductOpenForSubscriptionList';
import { ProductPage } from './Modules/LifeCycleManagement/ProductPage/ProductPage';
import { ProductsList } from './Modules/LifeCycleManagement/ProductsList/ProductsList';
import { TradePage } from './Modules/LifeCycleManagement/TradePage/TradePage';
import { TradeList } from './Modules/LifeCycleManagement/TradesList/TradesList';
import { UnwindPage } from './Modules/LifeCycleManagement/UnwindPage/UnwindPage';
import { CLNPage } from './Modules/Pricing/CLN/CLNPage';
import { PricingForm } from './Modules/Pricing/Form/PricingForm';
import { PricingResult } from './Modules/Pricing/Result/PricingResult';
import PrivateRoutes from './Shared/PrivateRoutes/PrivateRoutes';
import { AppLayout } from './Shared/layout/layout';
import { GlobalProvider } from './context/GlobalContext';
import {
  CLNPageRoute,
  ClientListPageRoute,
  ClientPageRoute,
  CreateProductPageRoute,
  CreateTradePageRoute,
  EditProductPageRoute,
  EditTradePageRoute,
  HomeRoute,
  PricingRoute,
  ProductEventManagerRoute,
  ProductOpenForSubscriptionRoute,
  ProductPageRoute,
  ProductsRoute,
  TradePageRoute,
  TradesListRoute, UnderlyingListPageRoute, UnderlyingPageRoute,
  UnwindPageRoute, UserRoute
} from './data/Routes';
import { ErrorPage } from './errors/ErrorPage';
import './i18n/config.ts';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import {
  expertGreen, goldenYellow, whiteColor
} from './styles/colors';

import './index.css';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const router = createBrowserRouter([
  {
    path    : HomeRoute.path,
    element : (<PrivateRoutes>
      <AppLayout />
    </PrivateRoutes>),
    errorElement : <ErrorPage />,
    children     : [
      {
        path         : PricingRoute.path,
        element      : <PricingForm />,
        errorElement : <ErrorPage />,
        children     : [
          {
            path    : ':pricingId',
            element : <PricingResult />,
          },
        ],
      },
      {
        path    : ProductsRoute.path,
        element : <ProductsList />,
      },
      {
        path    : TradesListRoute.path,
        element : <TradeList />,
      },
      {
        path    : ProductEventManagerRoute.path,
        element : <ProductEventManager />,
      },
      {
        path    : CreateProductPageRoute.path,
        element : <CreateProductPage />,
      },
      {
        path    : EditProductPageRoute.path,
        element : <CreateProductPage />,
      },
      {
        path    : ProductPageRoute.path,
        element : <ProductPage />,
      },
      {
        path    : TradePageRoute.path,
        element : <TradePage />,
      },
      {
        path    : EditTradePageRoute.path,
        element : <CreateTradePage />,
      },
      {
        path    : CreateTradePageRoute.path,
        element : <CreateTradePage />,
      },
      {
        path    : UnwindPageRoute.path,
        element : <UnwindPage />,
      },
      {
        path    : ClientListPageRoute.path,
        element : <ClientList />,
      },
      {
        path    : ClientPageRoute.path,
        element : <ClientPage />,
      },
      {
        path    : UserRoute.path,
        element : <UserPage />,
      },
      {
        path    : ProductOpenForSubscriptionRoute.path,
        element : <ProductOpenForSubscriptionList />,
      },
      {
        path    : CLNPageRoute.path,
        element : <CLNPage />,
      },
      {
        path    : UnderlyingListPageRoute.path,
        element : <UnderlyingsList />,
      },
      {
        path    : UnderlyingPageRoute.path,
        element : <EditUnderlying />,
      },
    ],
  },
], {
  future : {
    v7_fetcherPersist              : true,
    v7_normalizeFormMethod         : true,
    v7_partialHydration            : true,
    v7_relativeSplatPath           : true,
    v7_skipActionErrorRevalidation : true,
  },
});

root.render(
  <React.StrictMode>
    <Provider store = {store}>
      <GlobalProvider>
        <ConfigProvider
          locale = {locale} // TODO: use i18n when translation will be ready
          theme = {{
            algorithm : theme.darkAlgorithm,
            token     : {
              fontFamily    : 'Work Sans, sans-serif',
              colorPrimary  : expertGreen,
              colorBgBase   : expertGreen,
              colorText     : whiteColor,
              colorTextBase : whiteColor,
              colorLink     : whiteColor,
              screenXSMax   : 360,
              screenXS      : 300,
              screenXSMin   : 280,
            },
            components : {
              Table : {
                cellFontSize      : 13,
                cellPaddingBlock  : 12,
                cellPaddingInline : 8,
              },
              Button : {
                fontSize   : 13,
                fontWeight : 600,
              },
              Select : {
                optionFontSize : 12,
                fontSize       : 13,
              },
              Menu : {
                horizontalItemSelectedColor : goldenYellow,
              },
            },
          }}
        >
          <RouterProvider
            router = {router}
            future = {{
              v7_startTransition : true,
            }}
          />
        </ConfigProvider>
      </GlobalProvider>
    </Provider>
  </React.StrictMode>
);

/* If you want to start measuring performance in your app, pass a function
   to log results (for example: reportWebVitals(console.log))
   or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals */
reportWebVitals();
