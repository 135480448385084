import { createAsyncThunk } from '@reduxjs/toolkit';

import { myFetch } from '../../config/api';

import type { GetTradePayloadType, GetTradesSuccessPayload } from './tradesStoreTypes';
import type { SingleTradeType } from '../../data/TradesTypes';

export const fetchTradesList = createAsyncThunk<
  GetTradesSuccessPayload,
  GetTradePayloadType,
  { rejectValue : string }
>(
  'trades/fetchTradesList',
  async ({
    offset, limit, orderBy, toSearch,
  } : GetTradePayloadType, { rejectWithValue }) => {
    try {
      const response = await myFetch<GetTradesSuccessPayload>('GET', '/trades', {
        body  : null,
        query : {
          offset,
          limit,
          orderBy,
          toSearch,
        },
      });
      return {
        trades     : response.trades,
        totalCount : response.totalCount,
      };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getSingleTrade = createAsyncThunk<
  { trade : SingleTradeType },
  { tradeId : string },
  { rejectValue : string }
>(
  'trades/getSingleTrade',
  async ({ tradeId } : { tradeId : string }, { rejectWithValue }) => {
    try {
      const response = await myFetch<SingleTradeType>('GET', `/trades/${tradeId}`);
      return { trade : response };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
