import React, { useEffect } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import {
  Button, Modal, Select, Space
} from 'antd';

import { CurrencyInput } from '../../../Shared/CurrencyInput/CurrencyInput';
import { goldenYellow } from '../../../styles/colors';

import type { AllRef } from '../../../data/Ref';
import type { TradeFormSubmissionData } from '../../../data/TradesTypes';

type EditCustodianModalProps = {
  custodiansList           : AllRef['CustodiansList'],
  selectedCustodians       : TradeFormSubmissionData['custodians'],
  setSelectedCustodians    : (custodians : TradeFormSubmissionData['custodians'])=> void,
  onFinishSelectCustodians : ()=> void,
  selectedCurrency         : string,
  currentNotionalAmount    : number,
  isModalOpen              : boolean,
  close                    : ()=> void,
};

export const EditCustodianModal = ({
  isModalOpen, close, custodiansList, selectedCurrency, currentNotionalAmount, selectedCustodians, setSelectedCustodians, onFinishSelectCustodians,
} : EditCustodianModalProps) : React.ReactElement => {

  const onValueChange = (itemIndex : number, key : keyof TradeFormSubmissionData['custodians'][0], inputValue : number) : void => {
    const currentSelectedCustodians = [...selectedCustodians];
    currentSelectedCustodians[itemIndex] = {
      ...currentSelectedCustodians[itemIndex],
      [key] : inputValue,
    };
    setSelectedCustodians(currentSelectedCustodians);
  };

  useEffect(
    () => {
      if (isModalOpen && (selectedCustodians.length === 0 || selectedCustodians[0].custodianId === null)) {
        setSelectedCustodians([
          {
            custodianId   : null,
            amount        : currentNotionalAmount,
            orderReceived : false,
          },
        ]);
      }
    }
    , [isModalOpen]
  );

  return (
    <Modal
      title = {'Bank Allocations'}
      width = {800}
      open = {isModalOpen}
      footer = {[
        <Button key = {'cancel'} type = {'text'} onClick = {close}>
          Cancel
        </Button>,
        <Button
          key = {'submit'}
          type = {'primary'}
          onClick = {onFinishSelectCustodians}
        >
          OK
        </Button>,
      ]}
      onCancel = {close}
    >

      {selectedCustodians.map((item, index) => (
        <Space.Compact
          key = {index}
          style = {{
            width        : '100%',
            marginBottom : '10px',
          }}
        >
          <Select
            showSearch
            className = {'trade-select-input'}
            style = {{ width : '100%' }}
            placeholder = {'Select custodian...'}
            value = {item.custodianId}
            options = {custodiansList.map((custodian) => ({
              value : custodian.id,
              label : custodian.fullName,
            }))}
            filterOption = {(input, option) : boolean => String(option?.label).toLowerCase()
              .includes(input.toLowerCase())}
            onChange = {(selected : number) : void => onValueChange(index, 'custodianId', selected)}
          />

          <CurrencyInput
            className = {'trade-form-input'}
            value = {item.amount}
            placeholder = {'Amount'}
            style = {{ width : '40%' }}
            suffix = {selectedCurrency}
            onChange = {(e) : void => onValueChange(index, 'amount', Number(e))}
          />

          <Button
            type = {'text'}
            icon = {<DeleteOutlined />}
            onClick = {() : void => {
              const currentSelectedCustodians = [...selectedCustodians];
              currentSelectedCustodians.splice(index, 1);
              setSelectedCustodians(currentSelectedCustodians);
            }}
          />
        </Space.Compact>
      ))}

      <Button
        type = {'primary'}
        style = {{
          backgroundColor : goldenYellow,
          marginTop       : '10px',
        }}
        onClick = {() : void => {
          const currentSelectedCustodians = [...selectedCustodians];
          const custodianSumAmount = selectedCustodians.reduce((acc, item) => acc + item.amount, 0);
          currentSelectedCustodians.push({
            custodianId   : null,
            amount        : currentNotionalAmount - custodianSumAmount,
            orderReceived : false,
          });
          setSelectedCustodians(currentSelectedCustodians);
        }}
      >
        Add new one
      </Button>
    </Modal>
  );
};
