import React from 'react';

import type { FormInstance } from 'antd';
import {
  Flex, Form, InputNumber, Select,
  Switch
} from 'antd';

import { hideField } from '../../../../data/ProductType';
import { FieldsNameEnum, type PricingFields, SolveForEnum } from '../../../../data/enums/pricing';
import { organicBlue } from '../../../../styles/colors';
type AutocallPricingFormProps = {
  form                       : FormInstance<PricingFields>,
  nbStep                     : number,
  setSavedAutocallFloorValue : (value : number)=> void,
  savedAutocallFloorValue    : number,
  setAutocallFloorDisabled   : (value : boolean)=> void,
  autocallFloorDisabled      : boolean,
  pricingId ?                : string,
};

export const AutocallPricingForm = ({
  form, pricingId, nbStep, setSavedAutocallFloorValue, savedAutocallFloorValue, setAutocallFloorDisabled, autocallFloorDisabled,
} : AutocallPricingFormProps) : React.ReactElement => {

  const solveForValue = Form.useWatch<SolveForEnum>(FieldsNameEnum.solveFor, form);
  const productTypeId = Form.useWatch<string>(FieldsNameEnum.productType, form);
  const couponTriggerValue = Form.useWatch<number>([FieldsNameEnum.couponTrigger], form);
  const couponFloorValue = Form.useWatch<number>([FieldsNameEnum.couponFloor], form);
  const autocallTriggerValue = Form.useWatch<number>([FieldsNameEnum.autocallTrigger], form);
  const autocallFloorValue = Form.useWatch<number>([FieldsNameEnum.autocallFloor], form);
  const couponStepdownValue = Form.useWatch<number>([FieldsNameEnum.couponStepdown], form);
  const nonCallValue = Form.useWatch<number>(FieldsNameEnum.nonCallPeriod, form);

  const toggleAutocallFloorDisabled = () : void => {
    setAutocallFloorDisabled(!autocallFloorDisabled);
    if (autocallFloorDisabled) {
      form.setFieldValue([FieldsNameEnum.autocallFloor], savedAutocallFloorValue);
    } else {
      setSavedAutocallFloorValue(form.getFieldValue([FieldsNameEnum.autocallFloor]) as number);
      form.setFieldValue([FieldsNameEnum.autocallFloor], 0);
    }
  };
  return (
    <div
      className = {'cardWrapper organicBlueWrapper'}
    >
      <div className = {'card'}>
        <div className = {'cardHeader organicBlue'}>
          <h2>Autocall</h2>
        </div>

        <Flex vertical className = {'cardContent'}>
          <Form.Item
            name = {[FieldsNameEnum.autocallTrigger]}
            label = {<span className = {'label white'}>Autocall Trigger</span>}
            dependencies = {[FieldsNameEnum.solveFor]}
            rules = {[
              {
                required : solveForValue !== SolveForEnum.autocall,
                message  : 'Please input autocall trigger',
              },
            ]}
          >
            <InputNumber
              className = {'fieldBgColor pricer-field'}
              controls = {false}
              suffix = {'%'}
              min = {couponTriggerValue}
              disabled = {solveForValue === SolveForEnum.autocall || Boolean(pricingId)}
            />
          </Form.Item>

          <Form.Item
            name = {[FieldsNameEnum.autocallStepdown]}
            label = {<span className = {'label white'}>Autocall Stepdown</span>}
            dependencies = {
              [
                FieldsNameEnum.autocallTrigger,
                FieldsNameEnum.couponTrigger,
                FieldsNameEnum.solveFor,
                FieldsNameEnum.autocallFloor,
                FieldsNameEnum.numberPerYear,
              ]
            }
          >
            <InputNumber
              className = {'fieldBgColor pricer-field'}
              controls = {false}
              suffix = {'%'}
              disabled = {solveForValue === SolveForEnum.autocall || Boolean(pricingId)}
            />
          </Form.Item>

          <Flex justify = {'space-between'} className = {`form-switch-and-field ${autocallFloorDisabled ? 'form-switch-disabled' : ''}`}>
            <div>
              <span className = {'label white'}>Autocall Floor</span>

              <Switch
                checked = {!autocallFloorDisabled}
                style = {{
                  backgroundColor : autocallFloorDisabled ? 'grey' : organicBlue,
                }}
                onChange = {() : void => toggleAutocallFloorDisabled()}
              />
            </div>

            <Form.Item
              name = {[FieldsNameEnum.autocallFloor]}
            >
              <InputNumber
                className = {'fieldBgColor pricer-field'}
                suffix = {'%'}
                controls = {false}
                min = {Math.min(couponFloorValue, couponTriggerValue - ((nbStep - 1) * couponStepdownValue))}
                style = {{ display : autocallFloorDisabled ? 'none' : 'inline-flex' }}
                disabled = {autocallFloorDisabled || Boolean(pricingId)}
              />
            </Form.Item>
          </Flex>

          <Form.Item
            name = {[FieldsNameEnum.nonCallPeriod]}
            label = {<span className = {'label white'}>NonCall</span>}
            hidden = {hideField(FieldsNameEnum.nonCallPeriod, productTypeId)}
          >
            <Select>
              {nbStep > 0
                ? Array(nbStep)
                  .fill(0)
                  .map((_, i) : React.JSX.Element => (
                    <Select.Option key = {i} value = {i}>
                      {i}
                    </Select.Option>
                  ))
                : <></>}
            </Select>
          </Form.Item>
        </Flex>
      </div>
    </div>
  );
};
