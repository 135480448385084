import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breakpoint } from 'react-socks';

import type {
  TablePaginationConfig, TableProps
} from 'antd';
import {
  Button, Card, Flex, Select, Switch, Table
} from 'antd';
import type { ColumnFilterItem } from 'antd/es/table/interface';

import { TableCard } from '../../../Shared/TableCard/TableCard';
import { myFetch } from '../../../config/api';
import { GlobalContext } from '../../../context/GlobalContext';

import { RenderPricingCard } from './HistoryComponent/RenderPricingCard';

import type { UserListItemForPricing } from '../../../data/ProductType';
import type { PricingProduct, PricingProductResult } from '../../../data/pricing/GetAllPricing';

type PricingHistoryProps = {
  onReloadPricing : ()=> void,
  isOpen ?        : boolean,
};

export const PricingHistory = ({
  onReloadPricing, isOpen,
} : PricingHistoryProps) : React.ReactElement => {
  const [tableData, setTableData] = useState<PricingProduct[]>([]);
  const [fullCount, setFullCount] = useState<number>(0);
  const [pricingUserList, setPricingUserList] = useState<UserListItemForPricing[]>([]);
  const [filterUser, setFilterUser] = useState<number | null>(null);
  const [viewOwn, setViewOwn] = useState<boolean>(false);

  const globalContext = useContext(GlobalContext);
  if (!globalContext) {
    throw new Error('You probably forgot to put <GlobalProvider>.');
  }
  const { user } = globalContext;

  const getData = (offset : number, limit : number) : void => {
    const ownerId = viewOwn ? user?.id : filterUser;
    myFetch<PricingProductResult>('GET', '/price/all', {
      body  : null,
      query : {
        offset,
        limit,
        ownerId,
      },
    }).then((result) => {
      setTableData(result.pricingProductList);
      setFullCount(result.fullCount);
    })
      .catch((error : unknown) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getData(0, 25);
    if (viewOwn) {
      setFilterUser(null);
    }
  }, [filterUser, viewOwn]);

  useEffect(() => {
    myFetch<UserListItemForPricing[]>('GET', '/user/salesAndAssistant')
      .then((response) : void => {
        setPricingUserList(response);
      })
      .catch(() : void => {
        setPricingUserList([]);
      });
  }, [isOpen]);

  const handleChange = (pagination : TablePaginationConfig) : void => {
    const pageSize = pagination.pageSize ?? 10;
    const offset = (((pagination.current ?? 1) * pageSize) - pageSize) || 0;

    getData(offset, pageSize);
  };

  const columns : TableProps<PricingProduct>['columns'] = [
    {
      dataIndex : 'creationDate',
      title     : 'Creation',
      key       : 'creationDate',
      render    : (e : string) : React.JSX.Element => <span className = {'tableLabel'}>{new Date(Date.parse(e)).toLocaleString()}</span>,
    },
    {
      dataIndex : 'marketingName',
      title     : 'Name',
      key       : 'marketingName',
    },
    {
      dataIndex : 'productType',
      title     : 'Type',
      key       : 'productType',
      filters   : tableData.length === 0
        ? []
        : Object.values(
          tableData.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
            acc[current.productType] = acc[current.productType] ?? {
              text  : current.productType,
              value : current.productType,
            };
            return acc;
          }, {})
        ),
      onFilter     : (filterValue, record) : boolean => record.productType.startsWith(filterValue as string),
      filterSearch : true,
      render       : (e) : React.JSX.Element => <span className = {'tableLabel'}>{e}</span>,
    },
    {
      dataIndex : 'productSubType',
      title     : 'Sub type',
      key       : 'productSubType',
      filters   : tableData.length === 0
        ? []
        : Object.values(
          tableData.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
            acc[current.productSubType] = acc[current.productSubType] ?? {
              text  : current.productSubType,
              value : current.productSubType,
            };
            return acc;
          }, {})
        ),
      onFilter     : (filterValue, record) : boolean => record.productSubType.startsWith(filterValue as string),
      filterSearch : true,
      render       : (e) : React.JSX.Element => <span className = {'tableLabel'}>{e}</span>,
    },
    {
      dataIndex : 'ownerName',
      title     : 'Owner',
      key       : 'ownerName',
      filters   : tableData.length === 0
        ? []
        : Object.values(
          tableData.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
            acc[current.ownerName] = acc[current.ownerName] ?? {
              text  : current.ownerName,
              value : current.ownerName,
            };
            return acc;
          }, {})
        ),
      onFilter     : (filterValue, record) : boolean => record.ownerName.startsWith(filterValue as string),
      filterSearch : true,
      render       : (e) : React.JSX.Element => <span className = {'tableLabel'}>{e}</span>,
    },
    {
      dataIndex : 'solveFor',
      title     : 'Solve for',
      key       : 'solveFor',
      filters   : tableData.length === 0
        ? []
        : Object.values(
          tableData.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
            acc[current.solveFor] = acc[current.solveFor] ?? {
              text  : current.solveFor,
              value : current.solveFor,
            };
            return acc;
          }, {})
        ),
      onFilter     : (filterValue, record) : boolean => record.solveFor.startsWith(filterValue as string),
      filterSearch : true,
      render       : (e) : React.JSX.Element => <span className = {'tableLabel'}>{e}</span>,
    },
    {
      dataIndex : 'bestPrice',
      title     : 'Best price',
      key       : 'bestPrice',
      render    : (e) : React.JSX.Element => <span className = {'tableLabel'}>{e ? (e as number).toFixed(2) : 'In Progress'}</span>,
    },
    {
      dataIndex : 'quote',
      title     : 'Quote',
      key       : 'quote',
      render    : (_, record : PricingProduct) : React.JSX.Element => (
        <span className = {'tableLabel'}>
          {`${record.finishedCount ? record.finishedCount : '0'} / ${record.totalQuoteCount}`}
        </span>
      ),
    },
    {
      title  : 'Action',
      key    : 'action',
      render : (e : PricingProduct) : React.JSX.Element => (
        <Link to = {`/pricing/${e.uuid}`} onClick = {onReloadPricing}>
          <Button>
            Reload Pricing
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <Flex
        justify = {'start'}
        align = {'center'}
        style = {{ marginBottom : 20 }}
        gap = {10}
      >
        <label>Filter by user:</label>

        <Select
          placeholder = {'Filter by user'}
          style = {{ width : 200 }}
          value = {filterUser}
          disabled = {viewOwn}
          onChange = {setFilterUser}
        >
          <Select.Option key = {null} value = {null}>
            All
          </Select.Option>

          {pricingUserList.map((item) => (
            <Select.Option key = {item.id} value = {item.id}>
              {item.fullName}
            </Select.Option>
          ))}
        </Select>

      </Flex>

      <Flex justify = {'start'} style = {{ marginBottom : 20 }} gap = {10}>
        <p>Mine only:</p>

        <Switch checked = {viewOwn} onChange = {() : void => setViewOwn(!viewOwn)} />
      </Flex>

      <Breakpoint customQuery = {'(max-width: 860px)'}>
        <TableCard
          data = {tableData}
          total = {fullCount}
          pageSize = {10}
          renderItem = {(item : PricingProduct) : React.ReactNode => (
            <RenderPricingCard
              item = {item}
              onReloadPricing = {onReloadPricing}
            />
          )}
          onChange = {handleChange}
        />
      </Breakpoint>

      <Breakpoint customQuery = {'(min-width: 860px)'}>
        <Card>
          <Table
            columns = {columns}
            rowKey = {'id'}
            dataSource = {tableData}
            size = {'large'}
            pagination = {{
              total    : fullCount,
              pageSize : 25,
            }}
            onChange = {handleChange}
          />
        </Card>
      </Breakpoint>

    </>
  );
};

