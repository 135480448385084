import React, { useEffect, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import {
  Button, Flex, Input,
  Space
} from 'antd';

import './AddUnderlyingRateAndCDSForm.scss';

type AddUnderlyingsFormType = {
  onAddUnderlying : (equityLikeList : string[])=> void,
  initialRates ?  : string[],
};

export const AddUnderlyingRateAndCDSForm = ({
  onAddUnderlying, initialRates,
} : AddUnderlyingsFormType) : React.ReactElement => {
  const [rates, setRates] = useState<string[]>([]);

  useEffect(() : void => {
    onAddUnderlying(rates);
  }, [rates]);

  useEffect(() : void => {
    if (initialRates) {
      setRates(initialRates);
    }
  }, [initialRates]);

  return (
    <div className = {'add-underlyings-form'}>

      <Space>
        <Button
          type = {'primary'}
          className = {'add-underlying-button'}
          onClick = {() : void => setRates([...rates, ''])}
        >Add Rate
        </Button>

        <Button disabled type = {'primary'} className = {'add-underlying-button'}>Add CDS</Button>
      </Space>

      <div className = {'equity-like-form'}>
        {rates.map((item, index) => (
          <Flex
            key = {`${index}`}
            justify = {'space-between'}
            style = {{
              marginBottom : '6px',
              marginTop    : '4px',
            }}
          >
            <Space.Compact style = {{ width : '100%' }}>
              <Input
                className = {'product-form-input'}
                value = {item}
                placeholder = {'Enter Underlying Rate'}
                style = {{ width : '100%' }}
                onChange = {(e) : void => setRates(rates.map((r, i) => (i === index ? e.target.value : r)))}
              />

              <Button
                type = {'text'}
                icon = {<DeleteOutlined />}
                onClick = {() : void => setRates(rates.filter((_, i) => i !== index))}
              />
            </Space.Compact>
          </Flex>
        ))}
      </div>

    </div>
  );
};
