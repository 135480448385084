/* eslint-disable @stylistic/multiline-ternary */
import React from 'react';

import { Card, Typography } from 'antd';

import type { MonteCarloDataDisplay } from '../../../data/pricing/MonteCarlo';

const { Text } = Typography;

type MonteCarloResultDisplayProps = {
  displayData : MonteCarloDataDisplay,
};

const MonteCarloResultDisplay : React.FC<MonteCarloResultDisplayProps> = (props : MonteCarloResultDisplayProps) => {
  const formatJsonData = (data : MonteCarloDataDisplay) => Object.entries(data).map(([key, value]) => (
    <div key = {key} style = {{ marginBottom : '8px' }}>
      <Text strong style = {{ color : '#FFA540' }}>{key} :</Text>

      {typeof value === 'object' ? (
        <div style = {{
          paddingLeft : '16px',
          marginTop   : '4px',
        }}
        >
          {Object.entries(value).map(([subKey, subValue]) => (
            <div key = {subKey}>
              <Text strong style = {{ color : '#FF5F45' }}>{Number(subKey) / 252} Years :</Text>

              <Text style = {{
                color      : '#FFFFFF',
                marginLeft : '6px',
              }}
              >{subValue}
              </Text>
            </div>
          ))}
        </div>
      )
        : (
          <Text style = {{
            color      : '#FFFFFF',
            marginLeft : '6px',
          }}
          >{value}
          </Text>
        )}
    </div>
  ));

  return (
    <Card title = {'Results'} style = {{ width : '38%' }}>
      {formatJsonData(props.displayData)}
    </Card>
  );
};

export default MonteCarloResultDisplay;
