import React from 'react';

import type { InputNumberProps } from 'antd';
import { InputNumber } from 'antd';

type CurrencyInputProps = {
  suffix ?    : string,
  className ? : string,
} & Omit<InputNumberProps, 'formatter' | 'parser'>;

/**
 * A special version of Ant Design's InputNumber that formats and parses the
 * input value as a currency string.
 */
const CurrencyInput = ({
  className, suffix, ...props
} : CurrencyInputProps) : React.ReactElement => (
  <InputNumber
    style = {{ width : '100%' }}
    placeholder = {'0'}
    formatter = {(formatValue) : string => `${formatValue}`.replace(/\B(?=(?<temp1>\d{3})+(?!\d))/gu, ',')}
    parser = {(parsedValue) : string => parsedValue?.replace(/,*/gu, '') ?? ''}
    suffix = {suffix}
    min = {0}
    className = {className}
    {...props}
  />
);

export { CurrencyInput };
