import React from 'react';

import {
  Checkbox, Flex, Form, type FormInstance
} from 'antd';

import { defaultIssuerSort } from '../../../../data/Issuers';
import { FieldsNameEnum, type PricingFields } from '../../../../data/enums/pricing';

import type { Issuer } from '../../../../data/Issuers';

type IssuersPricingFormProps = {
  form        : FormInstance<PricingFields>,
  issuersList : Issuer[],
};

export const IssuersPricingForm = ({
  form, issuersList,
} : IssuersPricingFormProps)
: React.ReactElement => {

  const selectedIssuerIdList = Form.useWatch<number[] | null>(FieldsNameEnum.issuerIdList, form) ?? [];
  return (
    <div className = {'issuersWrapper dynamicOrangeWrapper'}>
      <div className = {'issuersCard'}>
        <Flex
          style = {{
            height       : 35,
            marginBottom : 15,
          }}
        >
          <div className = {'cardHeader dynamicOrange '}>
            <h2>Issuers</h2>
          </div>

          <Checkbox
            className = {'selectAllIssuers'}
            indeterminate = {selectedIssuerIdList.length > 0 && selectedIssuerIdList.length < issuersList.length}
            checked = {selectedIssuerIdList.length === issuersList.length}
            onClick = {() : void => {
              if (selectedIssuerIdList.length === issuersList.length) {
                form.setFieldValue(FieldsNameEnum.issuerIdList, []);
              } else {
                form.setFieldValue(
                  FieldsNameEnum.issuerIdList,
                  issuersList.map((e) : number => e.id)
                );
              }
            }}
          >
            Select All
          </Checkbox>
        </Flex>

        <Flex
          wrap
          justify = {'space-around'}
          style = {{
            padding   : '0 10px 20px 10px',
            columnGap : '30px',
            rowGap    : '25px',
          }}
        >
          {issuersList
            .sort(defaultIssuerSort)
            .map((e) : React.JSX.Element => (
              <Form.Item key = {e.id} name = {FieldsNameEnum.issuerIdList} style = {{ marginBottom : 0 }}>
                <div
                  key = {e.id}
                  className = {`issuerWrapper ${selectedIssuerIdList.includes(e.id) ? 'selectedIssuer' : ''}`}
                  onClick = {() : void => {
                    const currentIdList = form.getFieldValue(FieldsNameEnum.issuerIdList) as number[];
                    const index = currentIdList.indexOf(e.id);
                    const issuers = [...selectedIssuerIdList];
                    if (index > -1) {
                      issuers.splice(index, 1);
                    } else {
                      issuers.push(e.id);
                    }
                    form.setFieldValue(FieldsNameEnum.issuerIdList, issuers);
                  }}
                >
                  <img className = {'issuer'} src = {e.logo} alt = {e.name} />
                </div>
              </Form.Item>
            ))}
        </Flex>
      </div>
    </div>
  );
};
