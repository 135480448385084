import React from 'react';

import { FileOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import type { MessageInstance } from 'antd/es/message/interface';

import { myFetch } from '../../../config/api';

import type { UploadRequestError, UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';

type UploadCLNModalProps = {
  isOpen     : boolean,
  messageApi : MessageInstance,
  onClose    : ()=> void,
};

export const UploadCLNModal = (props : UploadCLNModalProps) : React.ReactElement => {

  const { messageApi } = props;

  const updloadTermsheet = ({
    file, onError, onSuccess,
  } : Pick<RcCustomRequestOptions, 'file' | 'onError' | 'onSuccess'>) : void => {
    const formData = new FormData();
    formData.append('file', file as File);

    myFetch('POST', '/utilities/uploadCLNFile', {
      body  : formData,
      query : null,
    }).then((response) => {
      if (onSuccess) {
        onSuccess(response);
      }
      props.onClose();
    })
      .catch((e : unknown) : void => {
        if (onError) {
          onError(e as UploadRequestError);
        }
        if (e instanceof Error) {
          messageApi.open({
            type    : 'error',
            content : 'Failed to upload termsheet, please try again',
          });
        }
      });
  };
  return (
    <Modal
      title = {'Upload CLN'}
      open = {props.isOpen}
      onCancel = {() : void => {
        props.onClose();
      }}
    >

      <Upload.Dragger
        name = {'file'}
        accept = {'.xlsm'}
        multiple = {false}
        maxCount = {1}
        showUploadList = {true}
        customRequest = {({
          file,
          onError,
          onSuccess,
        } : RcCustomRequestOptions) : void => {
          updloadTermsheet({
            file,
            onError,
            onSuccess,
          });
        }}
        style = {{
          backgroundColor : 'rgba(255,255,255,.4)',
          width           : '100%',
        }}
      >
        <p className = {'ant-upload-drag-icon'}>
          <FileOutlined />
        </p>

        <p className = {'ant-upload-text'}>Click or drag file to this area to upload</p>
      </Upload.Dragger>

    </Modal>
  );
};
