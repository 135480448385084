/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { useContext, useEffect, useState } from 'react';
import {
  Outlet, useNavigate, useParams
} from 'react-router-dom';

import { DownOutlined, ExperimentOutlined, HistoryOutlined } from '@ant-design/icons';
import {
  ConfigProvider,
  Drawer,
  Flex,
  FloatButton,
  Form,
  Select,
  message
} from 'antd';

import { MainTitle } from '../../../Shared/MainTitle/MainTitle';
import { myFetch } from '../../../config/api';
import { MESSAGE_API_DURATION } from '../../../config/config';
import { GlobalContext } from '../../../context/GlobalContext';
import { issuerRefToIssuer } from '../../../data/Issuers';
import {
  ProductTypeOptionsList, type UnderlyingEquity,
  autocallableReverseConvertible,
  classicAutocall,
  digital,
  phoenixAutocall,
  reverseConvertible,
  transformPostPriceDataBody
} from '../../../data/ProductType';
import { PricingRoute } from '../../../data/Routes';
import {
  BarrierTypeEnum, FieldsNameEnum, SolveForEnum
} from '../../../data/enums/pricing';
import { BasketTypeEnum, ProductSubTypeEnum, ProductTypeEnum } from '../../../data/product/productEnum';
import { dynamicOrange, expertGreen } from '../../../styles/colors';
import { getFrequencyFromNbCouponPerYear } from '../../../utils/pricing';
import { PricingHistory } from '../History/PricingHistory';
import MonteCarlo from '../MonteCarlo/MonteCarlo';

import { AutocallPricingForm } from './PricingFormComponent/AutocallPricingForm';
import { CouponPricingForm } from './PricingFormComponent/CouponPricingForm';
import { GeneralPricingForm } from './PricingFormComponent/GeneralPricingForm';
import { IssuersPricingForm } from './PricingFormComponent/IssuersPricingForm';
import { ProtectionPricingForm } from './PricingFormComponent/ProtectionPricingForm';
import { ResultPricingForm } from './PricingFormComponent/ResultPricingForm';
import { SchedulePricingForm } from './PricingFormComponent/SchedulePricingForm';
import { SubmitPricingForm } from './PricingFormComponent/SubmitPricingForm';

import type { Issuer } from '../../../data/Issuers';
import type { CurrencyRef } from '../../../data/Ref';
import type { PricingFields } from '../../../data/enums/pricing';
import type { GetPricingBody, PostPricingBody } from '../../../data/pricing/PricingBody';
import type { LabelInValueType } from 'rc-select/lib/Select';

import './../__styles__/responsive.scss';
import './../__styles__/styles.scss';

const PricingFormComponent = () : React.JSX.Element => {
  const { pricingId } = useParams();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage({ duration : MESSAGE_API_DURATION });

  const globalContext = useContext(GlobalContext);
  if (!globalContext) {
    throw new Error('You probably forgot to put <GlobalProvider>.');
  }
  const { allRefData } = globalContext;

  const issuersList : Issuer[] = [...allRefData?.IssuerRefList.filter((e) => e.usableAutoPricing).map((e) => issuerRefToIssuer(e)) ?? []];
  const currencyRefList : CurrencyRef[] = [...allRefData?.CurrencyRefList ?? []];

  const currencyInitialValue = currencyRefList.length > 0 ? currencyRefList.find((e) => e.IsoNomination === 'EUR')!.Id : 0;

  const [form] = Form.useForm<PricingFields>();
  const [couponFloorDisabled, setCouponFloorDisabled] = useState(true);
  const [autocallFloorDisabled, setAutocallFloorDisabled] = useState(true);
  const [savedAutocallFloorValue, setSavedAutocallFloorValue] = useState(0);
  const [underlyingsEquity, setUnderlyingsEquity] = useState<UnderlyingEquity[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [historyVisible, setHistoryVisible] = useState<boolean>(false);

  const productTypeId = Form.useWatch<string>(FieldsNameEnum.productType, form);
  const solveForValue = Form.useWatch<SolveForEnum>(FieldsNameEnum.solveFor, form);
  const nbCouponPerYear = Form.useWatch<number>([FieldsNameEnum.numberPerYear], form);
  const maturityValue = Form.useWatch<number>(FieldsNameEnum.maturity, form);
  const nbStep = Math.floor(maturityValue / getFrequencyFromNbCouponPerYear(nbCouponPerYear));

  const adaptPriceResponseToProductTypeId = (productTypeString : string) : string => {
    switch (productTypeString) {
      case `${ProductTypeEnum.Autocall} ${ProductSubTypeEnum.Phoenix}`:
        return phoenixAutocall.id;
      case `${ProductTypeEnum.Autocall} ${ProductSubTypeEnum.Classic}`:
        return classicAutocall.id;
      case `${ProductTypeEnum.Digital} ${ProductSubTypeEnum.ReverseConvertible}`:
        return reverseConvertible.id;
      case `${ProductTypeEnum.Autocall} ${ProductSubTypeEnum.ReverseConvertible}`:
        return autocallableReverseConvertible.id;
      case `${ProductTypeEnum.Digital} ${ProductSubTypeEnum.Classic}`:
        return digital.id;
      default:
        messageApi.error('Product type not found');
        return phoenixAutocall.id;
    }
  };

  useEffect(() => {
    if (!pricingId) {
      return;
    }
    myFetch<GetPricingBody>('GET', `/price/${pricingId}/product`)
      .then((response) => {
        form.setFieldValue([FieldsNameEnum.productType], adaptPriceResponseToProductTypeId(response.productType));
        form.setFieldValue([FieldsNameEnum.solveFor], response.solveFor);
        form.setFieldValue([FieldsNameEnum.notional], response.notional);
        form.setFieldValue([FieldsNameEnum.currencyRefId], response.currencyRefId);
        form.setFieldValue([FieldsNameEnum.maturity], response.maturity);
        form.setFieldValue([FieldsNameEnum.underlyingBasketType], response.underlyingBasketType);
        form.setFieldValue([FieldsNameEnum.barrierType], response.barrierType);
        form.setFieldValue([FieldsNameEnum.barrierLevel], response.barrierLevel);
        form.setFieldValue([FieldsNameEnum.strike], response.strike);
        form.setFieldValue([FieldsNameEnum.downsideLeverage], (100 / response.strike) * 100);
        form.setFieldValue([FieldsNameEnum.reoffer], response.reoffer);
        form.setFieldValue([FieldsNameEnum.nonCallPeriod], response.autocall.nonCallPeriod);
        form.setFieldValue([FieldsNameEnum.autocallTrigger], response.autocall.initialBarrier);
        form.setFieldValue([FieldsNameEnum.autocallStepdown], response.autocall.stepdown);
        form.setFieldValue([FieldsNameEnum.autocallFloor], response.autocall.autocallFloor);
        form.setFieldValue([FieldsNameEnum.couponTrigger], response.couponBarrier.initialBarrier);
        form.setFieldValue([FieldsNameEnum.couponStepdown], response.couponBarrier.stepdown);
        form.setFieldValue([FieldsNameEnum.couponFloor], response.couponBarrier.couponBarrierFloor);
        form.setFieldValue([FieldsNameEnum.memoryEffect], response.couponBarrier.memoryEffect);
        form.setFieldValue([FieldsNameEnum.couponValue], response.couponFixed.couponValue);
        form.setFieldValue([FieldsNameEnum.numberPerYear], response.couponFixed.numberPerYear);
        form.setFieldValue([FieldsNameEnum.issuerIdList], response.issuerIdList);

        setAutocallFloorDisabled(response.autocall.autocallFloor === null || response.autocall.autocallFloor === 0);
        setCouponFloorDisabled(response.couponBarrier.couponBarrierFloor === null || response.couponBarrier.couponBarrierFloor === 0);
        setUnderlyingsEquity(response.underlyings);
      })
      .catch((e : unknown) : void => {
        messageApi.error(`error while fetching price product : ${e}`);
      });
  }, [pricingId]);

  useEffect(() => {
    if (currencyInitialValue !== 0) {
      form.setFieldValue([FieldsNameEnum.currencyRefId], currencyInitialValue);
    }
  }, [currencyInitialValue, form]);

  const onProductTypeChange = (id : string) : void => {
    switch (id) {
      case autocallableReverseConvertible.id:
        form.setFieldValue([FieldsNameEnum.couponTrigger], 0);
        break;
      case reverseConvertible.id:
        form.setFieldValue([FieldsNameEnum.couponTrigger], 0);
        form.setFieldValue([FieldsNameEnum.autocallTrigger], 100);
        form.setFieldValue([FieldsNameEnum.nonCallPeriod], nbStep - 1);
        break;
      default:

      // No action to take for other product type
    }
  };

  const postForm = (formData : PricingFields) : void => {
    setIsLoading(true);
    const body : PostPricingBody = {
      autocall : {
        basketType     : formData.underlyingBasketType,
        nonCallPeriod  : formData.nonCallPeriod,
        initialBarrier : formData.autocallTrigger,
        stepdown       : formData.autocallStepdown,
        barrierList    : [],
        autocallFloor  : formData.autocallFloor,
      },
      barrierLevel  : formData.barrierLevel,
      barrierType   : formData.barrierType,
      ccMail        : formData.ccMail,
      couponBarrier : {
        basketType         : formData.underlyingBasketType,
        memoryEffect       : formData.memoryEffect,
        initialBarrier     : formData.couponTrigger,
        stepdown           : formData.couponStepdown,
        barrierList        : [],
        couponBarrierFloor : formData.couponFloor,
      },
      couponFixed : {
        couponValue   : formData.couponValue,
        numberPerYear : formData.numberPerYear,
      },
      currencyRefId        : formData.currencyRefId,
      downsideLeverage     : formData.downsideLeverage,
      issuerIdList         : formData.issuerIdList,
      marketingName        : formData.marketingName === '' ? 'Pricing' : formData.marketingName,
      maturity             : formData.maturity,
      notional             : formData.notional,
      productType          : formData.productType,
      realSend             : formData.realSend,
      reoffer              : formData.reoffer,
      solveFor             : formData.solveFor,
      strike               : formData.strike,
      underlyingBasketType : formData.underlyingBasketType,
      underlyings          : underlyingsEquity,
    };
    if (autocallFloorDisabled) {
      body.autocall.autocallFloor = null;
    }
    if (couponFloorDisabled) {
      body.couponBarrier.couponBarrierFloor = null;
    }
    const transformed = transformPostPriceDataBody(body);
    myFetch('POST', '/price', {
      body  : transformed,
      query : null,
    })
      .then((response) : void => {
        navigate(`${PricingRoute.path}/${response as string}`);
      })
      .catch((e : unknown) : void => console.error(e))
      .finally(() : void => setIsLoading(false));

  };

  function labelRender (props : LabelInValueType) : React.JSX.Element {
    const { label } = props;

    return (
      <label
        style = {{
          fontSize : 25,
          color    : 'white',
          cursor   : 'pointer',
        }}
      >
        {label}
      </label>
    );
  }

  const initialValue : PricingFields = {
    autocallFloor        : 0,
    autocallStepdown     : 0,
    autocallTrigger      : 100,
    barrierLevel         : 70,
    barrierType          : BarrierTypeEnum.European,
    ccMail               : [],
    couponValue          : null,
    numberPerYear        : 1,
    couponFloor          : 0,
    couponStepdown       : 0,
    couponTrigger        : 70,
    currencyRefId        : currencyInitialValue,
    downsideLeverage     : 100,
    issuerIdList         : [],
    maturity             : 36,
    marketingName        : '',
    memoryEffect         : true,
    notional             : 500_000,
    nonCallPeriod        : 0,
    productType          : ProductTypeOptionsList[0].value,
    realSend             : true,
    reoffer              : 100,
    solveFor             : SolveForEnum.coupon,
    strike               : 100,
    underlyingBasketType : BasketTypeEnum.SingleStock,
  };

  return (
    <>
      {// to avoid tooltip bug mobile
        historyVisible
          ? (
            <FloatButton
              className = {'historyButton'}
              icon = {<HistoryOutlined />}
              onClick = {() : void => setHistoryVisible(true)}
            />
          )
          : (
            <FloatButton
              className = {'historyButton'}
              tooltip = {<div>History</div>}
              icon = {<HistoryOutlined />}
              onClick = {() : void => setHistoryVisible(true)}
            />
          )
      }

      <Drawer
        className = {'drawer-history'}
        title = {'AyDeal History'}
        width = {'inherit'}
        open = {historyVisible}
        onClose = {() : void => setHistoryVisible(false)}
      >
        <PricingHistory isOpen = {historyVisible} onReloadPricing = {() : void => setHistoryVisible(false)} />
      </Drawer>

      <Form
        form = {form}
        className = {'content'}
        name = {'AyDeal'}
        labelAlign = {'left'}
        layout = {'horizontal'}
        size = {'small'}
        colon = {false}
        requiredMark = {false}
        initialValues = {initialValue}
        disabled = {Boolean(pricingId)}
        onFinish = {postForm}
        onKeyDown = {(e) : void => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >

        {contextHolder}

        <Flex align = {'center'} style = {{ marginBottom : 16 }}>
          <MainTitle prefix = {<ExperimentOutlined />} text = {'AyDeal'} />

          <ConfigProvider
            theme = {{
              components : {
                Select : {
                  selectorBg                : 'rgba(0,0,0,0)',
                  optionActiveBg            : 'rgba(255,255,255,0.3)',
                  optionSelectedColor       : 'white',
                  optionSelectedBg          : 'rgba(255,255,255,0.3)',
                  colorText                 : 'white',
                  optionFontSize            : 18,
                  optionPadding             : 10,
                  showArrowPaddingInlineEnd : 30,
                },
              },
            }}
          >
            <Form.Item
              name = {FieldsNameEnum.productType}
              className = {'pricing-product-type-select'}
              layout = {'horizontal'}
              colon = {false}
            >
              <Select
                style = {{ margin : 'unset' }}
                labelRender = {labelRender}
                size = {'large'}
                variant = {'borderless'}
                options = {ProductTypeOptionsList}
                popupMatchSelectWidth = {false}
                suffixIcon = {(
                  <DownOutlined
                    style = {{
                      color    : 'white',
                      fontSize : 18,
                    }}
                  />
                )}
                dropdownStyle = {{
                  backgroundColor : 'rgb(0,37,45)',
                  border          : '2px solid white',
                  color           : 'white',
                }}
                onChange = {(id : string) : void => onProductTypeChange(id)}
              />
            </Form.Item>
          </ConfigProvider>
        </Flex>

        <Flex className = {'mainContent'} justify = {'space-between'}>
          <Flex className = {'formContent'}>
            <Flex vertical gap = {'middle'}>

              <GeneralPricingForm
                form = {form}
                pricingId = {pricingId}
                underlyingsEquity = {underlyingsEquity}
                setUnderlyingsEquity = {setUnderlyingsEquity}
                currencyRefList = {currencyRefList}
              />

              <ProtectionPricingForm form = {form} pricingId = {pricingId} />
            </Flex>

            <Flex vertical gap = {'middle'}>

              <CouponPricingForm
                form = {form}
                pricingId = {pricingId}
                nbStep = {nbStep}
                couponFloorDisabled = {couponFloorDisabled}
                setCouponFloorDisabled = {setCouponFloorDisabled}
              />

              {![reverseConvertible.id, digital.id].includes(productTypeId) && (
                <AutocallPricingForm
                  form = {form}
                  nbStep = {nbStep}
                  pricingId = {pricingId}
                  autocallFloorDisabled = {autocallFloorDisabled}
                  setAutocallFloorDisabled = {setAutocallFloorDisabled}
                  savedAutocallFloorValue = {savedAutocallFloorValue}
                  setSavedAutocallFloorValue = {setSavedAutocallFloorValue}
                />
              )}

            </Flex>

            <Flex vertical>
              <SchedulePricingForm
                form = {form}
                nbStep = {nbStep}
              />
            </Flex>
          </Flex>

          <Flex vertical className = {'pricingContent'}>
            {pricingId
              ? (
                <Outlet
                  context = {{
                    solveFor              : solveForValue,
                    numberOfCouponPerYear : nbCouponPerYear,
                  }}
                />
              )
              : (
                <IssuersPricingForm
                  form = {form}
                  issuersList = {issuersList}
                />
              )}

            <div className = {'cardWrapperSubmit dynamicOrangeWrapper'}>
              <div className = {'cardSubmit'}>
                <div className = {'dynamicOrange cardHeader'}>
                  <h2>Pricing</h2>
                </div>

                {pricingId
                  ? (
                    <ResultPricingForm
                      form = {form}
                      currencyInitialValue = {currencyInitialValue}
                      setIsLoading = {setIsLoading}
                    />
                  )
                  : (
                    <SubmitPricingForm
                      form = {form}
                      isLoading = {isLoading}
                    />

                  )}

              </div>
            </div>

            <MonteCarlo pricingId = {pricingId} solveFor = {solveForValue} />

          </Flex>
        </Flex>
      </Form>

    </>
  );
};

export const PricingForm = () : React.JSX.Element => (
  <ConfigProvider
    theme = {{
      token : {
        colorPrimary             : dynamicOrange,
        colorBgContainerDisabled : expertGreen,
        colorTextDisabled        : 'white',
      },
      components : {
        Select : {
          selectorBg : 'rgba(255,255,255,0.3)',
        },
        Table : {
          borderColor        : 'unset',
          headerBg           : expertGreen,
          headerColor        : 'white',
          rowSelectedBg      : 'rgba(0, 37, 45, 0.3)',
          rowSelectedHoverBg : 'rgba(0, 37, 45, 0.8)',
          rowHoverBg         : 'rgba(0, 37, 45, 0.8)',
          headerBorderRadius : 0,
        },
      },
    }}
  >
    <PricingFormComponent />
  </ConfigProvider>
);
