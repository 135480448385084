import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, Table, message } from 'antd';
import type { GetProps,
  Input, TableColumnsType } from 'antd';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import i18next from 'i18next';

import { CustomTable } from '../../../Shared/CustomTable/CustomTable';
import { myFetch } from '../../../config/api';
import { haveOneOfTheRoles } from '../../../config/keycloak';
import { UserRoleEnum } from '../../../data/enums/cloak';
import { ProductListPageViewEnum } from '../../../data/product/productEnum';
import { setProductListPageViewState } from '../../../store/productsStore/productSlice';

import { generateGroupedListColumns, productsColumns, tradesListcolumns } from './ProductColumns';
import { CustomSelect } from './ProductFilterSelect/ProductFilterSelect';

import type { CustomTableProps } from '../../../Shared/CustomTable/CustomTable';
import type {
  ProductGroupedByAttribute,
  ProductListItem,
  SearchProductResponseBody
} from '../../../data/ProductType';
import type { ProductListPageViewType } from '../../../store/productsStore/productsStoreTypes';
import type { AppDispatch, RootStateType } from '../../../store/store';

const INITIAL_LIMIT = 20;

dayjs.extend(isBetween);

type ProductColumnType = TableColumnsType<ProductListItem> | TableColumnsType<ProductGroupedByAttribute>;
type ProductListType = ProductListItem[] | ProductGroupedByAttribute[];
type SearchProps = GetProps<typeof Input.Search>;
type TableDataProps = {
  products : ProductListType,
  columns  : ProductColumnType,
};

export const ProductsList = () : React.ReactElement => {
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [totalCountProduct, setTotalCountProduct] = useState(0);
  const [totalCountGrouped, setTotalCountGrouped] = useState(0);
  const [productList, setProductList] = useState<ProductListItem[]>([]);
  const [groupedList, setGroupedList] = useState<ProductGroupedByAttribute[]>([]);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const productViewState = useSelector((state : RootStateType) => state.productsReducer.productListPageView);
  const navigate = useNavigate();
  const { t } = useTranslation('productsPages');

  const isAdminOrAssistant = haveOneOfTheRoles([UserRoleEnum.InternalAdmin, UserRoleEnum.SuperAdmin, UserRoleEnum.InternalAssistant]);

  const [tableData, setTableData] = useState<TableDataProps>({
    products : [],
    columns  : productsColumns([]).map((col) => ({
      ...col,
      title : col.title ? t(col.title as string) : col.title,
    })),
  });

  i18next.on('languageChanged', () => {
    setTableData({
      ...tableData,
      columns : productsColumns(productList).map((col) => ({
        ...col,
        title : col.title ? t(col.title as string) : col.title,
      })),
    });
  });

  const getProducts = (
    pageNumber : number,
    pageSize : number,
    groupedBy ?: ProductListPageViewEnum,
    groupedByValue ?: string,
    toSearch ?: string,
    status ?: 'Alive' | 'Other'
  ) : void => {
    setIsLoading(true);
    myFetch<SearchProductResponseBody>('GET', '/products', {
      body  : null,
      query : {
        offset : (pageNumber - 1) * pageSize,
        limit  : pageSize,
        groupedBy,
        groupedByValue,
        status,
        toSearch,
      },
    }).then((response) => {
      setProductList(response.products);
      setTotalCountProduct(response.totalCount);
      setTableData({
        ...tableData,
        products : response.products,
      });
    })
      .catch((e : unknown) => {
        message.error(e instanceof Error ? e.message : 'Error while fetching products');
      })
      .finally(() => setIsLoading(false));
  };

  const getProductGroupedBy = (
    pageNumber : number,
    pageSize : number,
    groupedBy : ProductListPageViewEnum,
    toSearch ?: string,
    status ?: 'Alive' | 'Other'
  ) : void => {
    setIsLoading(true);
    myFetch<ProductGroupedByAttribute[]>('GET', '/products/groupedBy', {
      body  : null,
      query : {
        offset : (pageNumber - 1) * pageSize,
        limit  : pageSize,
        groupedBy,
        status,
        toSearch,
      },
    })
      .then((response) => {
        setGroupedList(response.map((group) => ({
          ...group,
          products : [],
        })));
        setTotalCountGrouped(response[0]?.totalCount ?? 0);
      })
      .catch((e : unknown) => {
        message.error(e instanceof Error ? e.message : 'Error while fetching products');
      })
      .finally(() => setIsLoading(false));
  };

  const getProductByGrouped = (pageNumber : number, grouped : ProductGroupedByAttribute) : void => {
    myFetch<SearchProductResponseBody>('GET', '/products', {
      body  : null,
      query : {
        limit          : 10,
        offset         : (pageNumber - 1) * 10,
        groupedBy      : productViewState.view,
        groupedByValue : grouped.groupId,
      },
    }).then((response) => {
      setGroupedList((prevState) => {
        const index = prevState.findIndex((item) => item.groupId === grouped.groupId);
        return [
          ...prevState.slice(0, index),
          {
            ...prevState[index],
            products : response.products,
          },
          ...prevState.slice(index + 1),
        ];
      });
    })
      .catch((e : unknown) => {
        message.error(e instanceof Error ? e.message : 'Error while fetching products');
      });
  };

  const setViewState = (viewState : Partial<ProductListPageViewType>) : void => {
    dispatch(setProductListPageViewState({
      ...productViewState,
      ...viewState,
    }));
  };

  useEffect(() => {
    setViewState({
      pageNumber : 1,
      pageSize   : INITIAL_LIMIT,
    });
    getProducts(1, INITIAL_LIMIT);
  }, []);

  const expandedTradesRowRender = (trades : ProductListItem['trades'], currency : string) : React.ReactNode => (
    trades.length
      ? (
        <Table
          key = {productViewState.view}
          columns = {tradesListcolumns(currency)}
          className = {'expanded__table'}
          dataSource = {trades}
          rowKey = {(record) : string => record.id.toString()}
          pagination = {false}
          id = {'expanded__table__inner__table--2'}
          rowClassName = {(_, index) : string => (index % 2 === 0 ? 'inner-table-row-even' : 'inner-table-row-odd')}
          onRow = {(record) => ({ onClick : () : void => {
            window.open(`/trades/${record.id.toString()}`, '_blank');
          } })}
        />
      )
      : <span className = {'no-content'}>No trades</span>
  );

  const expandedProductsRowRender = (grouped : ProductGroupedByAttribute) : React.ReactNode => (
    <Table
      key = {productViewState.view}
      className = {'expanded__table'}
      dataSource = {grouped.products}
      rowKey = {(r) : number => r.id}
      rowClassName = {(_, index) : string => (index % 2 === 0 ? 'table-row-even' : 'table-row-odd')}
      columns = {productsColumns(grouped.products).map((col) => ({
        ...col,
        title : col.title ? t(col.title as string) : col.title,
      }))}
      expandable = {{
        expandedRowRender    : (item) : React.ReactNode => expandedTradesRowRender(item.trades, item.currency),
        onExpandedRowsChange : (expandedKeys) : void => {
          setViewState({ innerListExpandedRowKeys : [...expandedKeys]});
        },
        defaultExpandedRowKeys : productViewState.innerListExpandedRowKeys,
        expandIcon             : ({
          expanded, onExpand, record,
        }) : React.ReactNode => (
          <Button
            type = {'text'}
            icon = {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
            onClick = {(e) : void => {
              e.stopPropagation();
              onExpand(record, e);
            }}
          />
        ),
      }}
      pagination = {{
        position        : ['bottomCenter'],
        defaultPageSize : 10,
        showSizeChanger : false,
        total           : grouped.groupCount,
        showTotal       : (total, range) : string => t('pagination.range', {
          start : range[0],
          end   : range[1],
          total,
        }),
        onChange : (pageNumber : number) : void => {
          getProductByGrouped(pageNumber, grouped);
        },
      }}
      onRow = {(record) => ({ onClick : () : void => {
        window.open(`/products/${record.id.toString()}`, '_blank');
      } })}
    />
  );

  const changeDisplayView = (selectedView : ProductListPageViewEnum) : void => {
    let productsListToDisplay : ProductListType = [];
    let viewColumns : ProductColumnType = [];
    switch (selectedView) {
      case ProductListPageViewEnum.Default:
        productsListToDisplay = productList;
        viewColumns = productsColumns(productList).map((col) => ({
          ...col,
          title : col.title ? t(col.title as string) : col.title,
        }));
        break;
      case ProductListPageViewEnum.ByClient:
        productsListToDisplay = [];
        getProductGroupedBy(1, INITIAL_LIMIT, ProductListPageViewEnum.ByClient);
        viewColumns = generateGroupedListColumns('Client');
        break;
      case ProductListPageViewEnum.ByOwner:
        productsListToDisplay = [];
        getProductGroupedBy(1, INITIAL_LIMIT, ProductListPageViewEnum.ByOwner);
        viewColumns = generateGroupedListColumns('Representative');
        break;
      case ProductListPageViewEnum.ByProductType:
        productsListToDisplay = [];
        getProductGroupedBy(1, INITIAL_LIMIT, ProductListPageViewEnum.ByProductType);
        viewColumns = generateGroupedListColumns('Product Type');
        break;
      case ProductListPageViewEnum.ByIssuer:
        productsListToDisplay = [];
        getProductGroupedBy(1, INITIAL_LIMIT, ProductListPageViewEnum.ByIssuer);
        viewColumns = generateGroupedListColumns('Issuer');
        break;
      case ProductListPageViewEnum.ByValuation:
        productsListToDisplay = [];
        getProductGroupedBy(1, INITIAL_LIMIT, ProductListPageViewEnum.ByValuation);
        viewColumns = generateGroupedListColumns('Valuation Level');
        break;
      case ProductListPageViewEnum.ByNextEvent:
        productsListToDisplay = [];
        getProductGroupedBy(1, INITIAL_LIMIT, ProductListPageViewEnum.ByNextEvent);
        viewColumns = generateGroupedListColumns('Next Event Date');
        break;
      default:
        break;
    }

    setTableData({
      columns  : viewColumns,
      products : productsListToDisplay,
    });
  };

  const onSearch : SearchProps['onSearch'] = (searchText) : void => {
    const {
      view, pageSize,
    } = productViewState;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchValue(searchText);

    const timeout = setTimeout(() => {
      setViewState({ pageNumber : 1 });
      if (searchText) {
        if (view === ProductListPageViewEnum.Default) {
          getProducts(1, pageSize, ProductListPageViewEnum.Default, undefined, searchText);
        } else {
          getProductGroupedBy(1, INITIAL_LIMIT, view, searchText);
        }
      } else if (view === ProductListPageViewEnum.Default) {
        getProducts(1, pageSize);
      } else {
        getProductGroupedBy(1, INITIAL_LIMIT, view);
      }
    }, 200);

    setSearchTimeout(timeout);
  };

  const getTableActions = () : CustomTableProps<ProductListItem>['actions'] => {
    if (isAdminOrAssistant) {
      return [
        {
          title   : t('addProduct'),
          onClick : () : void => navigate('/products/new'),
          variant : 'primary',
        },
      ];
    }
    return [];
  };

  const tableProps : Pick<CustomTableProps<ProductListItem>, 'actions' | 'customComponent' | 'search'> = {
    actions         : getTableActions(),
    customComponent : (<CustomSelect
      defaultValue = {ProductListPageViewEnum.ByNextEvent}
      selectedValue = {productViewState.view}
      label = {`${t('productListPageViewEnum.groupBy')} ${t(`productListPageViewEnum.${productViewState.view.toLowerCase().replace(' ', '')}`)}`}
      options = {Object.values(ProductListPageViewEnum)
        .filter((view) => {
          const canViewGroupedByOwner = haveOneOfTheRoles([UserRoleEnum.InternalAdmin, UserRoleEnum.SuperAdmin, UserRoleEnum.InternalAssistant]);
          const canViewGroupedByClient = canViewGroupedByOwner || haveOneOfTheRoles([UserRoleEnum.InternalSalesman]);
          return (view !== ProductListPageViewEnum.ByOwner || canViewGroupedByOwner)
            && (view !== ProductListPageViewEnum.ByClient || canViewGroupedByClient);
        })
        .map((view) => ({
          value : view,
          label : t(`productListPageViewEnum.${view.toLowerCase().replace(' ', '')}`),
        }))}
      onChange = {(val) : void => {
        changeDisplayView(val as ProductListPageViewEnum);
        setSearchValue('');
        setViewState({
          view                     : val as ProductListPageViewEnum,
          expandedRowKeys          : [],
          pageNumber               : 1,
          innerListExpandedRowKeys : [],
          innerListPageNumber      : 1,
        });
      }}
    />),
    search : {
      searchValue,
      onSearch,
    },
  };

  const { columns } = tableData;

  return (
    <div className = {'products_list_container'}>
      {productViewState.view === ProductListPageViewEnum.Default
        ? (
          <CustomTable<ProductListItem>
            key = {productViewState.view}
            loading = {isLoading}
            columns = {columns as TableColumnsType<ProductListItem>}
            dataList = {productList}
            {...tableProps}
            rowKey = {(record) : number => record.id}
            colsTopApplyDateFilter = {['issueDate', 'maturityDate', 'nextEventDate']}
            colsTopApplySeachFilter = {['isin', 'name']}
            sticky = {true}
            expandable = {{
              expandedRowRender    : (record) : React.ReactNode => expandedTradesRowRender(record.trades, record.currency),
              onExpandedRowsChange : (expandedKeys) : void => {
                setViewState({ expandedRowKeys : [...expandedKeys]});
              },
              defaultExpandedRowKeys : productViewState.expandedRowKeys,
              expandIcon             : ({
                expanded, onExpand, record,
              }) : React.ReactNode => (
                <Button
                  type = {'text'}
                  icon = {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                  onClick = {(e) : void => {
                    e.stopPropagation();
                    onExpand(record, e);
                  }}
                />
              ),
            }}
            pagination = {{
              position        : ['bottomCenter'],
              defaultPageSize : INITIAL_LIMIT,
              total           : totalCountProduct,
              showTotal       : (total, range) : string => t('pagination.range', {
                start : range[0],
                end   : range[1],
                total,
              }),
              onChange : (pageNumber : number, pageSize : number) : void => {
                setViewState({
                  pageNumber,
                  pageSize,
                });

                getProducts(pageNumber, pageSize, ProductListPageViewEnum.Default, undefined, searchValue);
              },
            }}
            onRow = {(record) => ({ onClick : () : void => {
              window.open(`/products/${record.id.toString()}`, '_blank');
            } })}
          />
        )
        : (
          <CustomTable<ProductGroupedByAttribute>
            key = {productViewState.view}
            loading = {isLoading}
            columns = {columns as TableColumnsType<ProductGroupedByAttribute>}
            dataList = {groupedList}
            {...tableProps}
            rowKey = {(record) : string => record.groupName}
            sticky = {true}
            expandable = {{
              onExpand : (expanded, record) : void => {
                if (expanded && record.products.length === 0) {
                  getProductByGrouped(1, record);
                }
              },
              expandedRowRender    : (record) : React.ReactNode => expandedProductsRowRender(record),
              onExpandedRowsChange : (expandedKeys) : void => {
                setViewState({ expandedRowKeys : [...expandedKeys]});
              },
              defaultExpandedRowKeys : productViewState.expandedRowKeys,
              expandIcon             : ({
                expanded, onExpand, record,
              }) : React.ReactNode => (
                <Button
                  type = {'text'}
                  icon = {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                  onClick = {(e) : void => {
                    onExpand(record, e);
                  }}
                />
              ),
              expandedRowClassName : () : string => 'expanded__table-row',
              expandRowByClick     : true,
            }}
            pagination = {{
              position        : ['bottomCenter'],
              defaultPageSize : INITIAL_LIMIT,
              total           : totalCountGrouped,
              showTotal       : (total, range) : string => t('pagination.range', {
                start : range[0],
                end   : range[1],
                total,
              }),
              onChange : (pageNumber : number, pageSize : number) : void => {
                setViewState({
                  pageNumber,
                  pageSize,
                });
                getProductGroupedBy(pageNumber, pageSize, productViewState.view);
              },
            }}
          />
        )}
    </div>
  );
};
