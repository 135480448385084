import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { DeleteOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button, Flex, InputNumber, Select
} from 'antd';
import type { DefaultOptionType } from 'antd/es/select';

import { myFetch } from '../../../config/api';
import CustomDropdownInput from '../../CustomDropdownInput/CustomDropdownInput';

import type { UnderlyingEquity } from '../../../data/ProductType';

import './UnderlyingEquityForm.scss';

type UnderlyingInfosType = {
  companyName : string,
  companyId   : number,
  ticker      : string,
  place       : string,
  isin        : string,
};

export type EquityLikeFormType = {
  ticker           : string,
  place            : string,
  companyName      : string,
  companyId        : number,
  initialValuation : number | null,
  isin             : string,
  currencyId ?     : number,
};

const emptyItem = {
  companyId        : 0,
  isin             : '',
  ticker           : '',
  internetTicker   : '',
  initialValuation : null,
  currencyRefId    : 0,
  place            : '',
  companyName      : '',
};

type CompanyItem = {
  companyId   : number,
  companyName : string,
};

type AddUnderlyingsFormType = {
  underlyingList  : UnderlyingEquity[],
  onAddUnderlying : (equityLikeList : UnderlyingEquity[])=> void,
};

export const UnderlyingEquityForm = ({
  onAddUnderlying, underlyingList,
} : AddUnderlyingsFormType) : React.ReactElement => {

  const TIME_TO_WAIT_BEFORE_SEARCH = 200;
  const LETTER_COUNT_TO_SEARCH = 2;

  const [searching, setSearching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<UnderlyingInfosType[]>([]);
  const [allSearchResults, setAllSearchResults] = useState<UnderlyingInfosType[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [companies, setcompanies] = useState<CompanyItem[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<CompanyItem[]>([]);
  const [lastResearchTimeout, setLastResearchTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    myFetch<CompanyItem[]>('GET', '/companies')
      .then((response) => {
        setcompanies(response);
      })
      .catch(() => {
        setcompanies([]);
      });
  }, []);

  const handleSearch = (searchValue : string) : void => {
    if (searchValue.length < LETTER_COUNT_TO_SEARCH) {
      setSearchQuery(searchValue);
      return;
    }
    if (lastResearchTimeout) {
      clearTimeout(lastResearchTimeout);
    }

    setLastResearchTimeout(setTimeout(
      () => {
        setSearchQuery(searchValue);
        setSearching(true);

        myFetch('GET', `/products/underlyings/search?query=${searchValue}`)
          .then((results) : void => {
            setSearchResults(results ? results as UnderlyingInfosType[] : []);
            setAllSearchResults([...allSearchResults, ...results as UnderlyingInfosType[]]);
          })
          .catch(() : void => {
            setSearchResults([]);
          })
          .finally(() => setSearching(false));
      }
      , TIME_TO_WAIT_BEFORE_SEARCH
    ));
  };

  const onChangeEquityLikeForm = (index : number, paramKey : keyof EquityLikeFormType, paramValue : string | number) : void => {
    const currentItem : EquityLikeFormType = {
      ...underlyingList[index],
      [paramKey] : paramValue,
      ...paramKey === 'companyId' && { companyName : companies.find((c) => c.companyId === Number(paramValue))?.companyName },
    };

    const newList = underlyingList.map((item, i) => (i === index ? currentItem : item));

    onAddUnderlying(newList);
  };

  const isInPricingPage = window.location.pathname.includes('pricing');

  const getPlaceItems = (company : CompanyItem, ticker : string) : {
    value : string,
    label : string,
  }[] => [
    ...new Set(
      allSearchResults
        .filter((result) => result.place
          && company.companyName === result.companyName
          && company.companyId === result.companyId
          && result.ticker === ticker)
        .map((r) => r.place)
    ),
  ].map((place) => ({
    value : place,
    label : place,
  }));

  return (
    <div className = {'add-underlying-equity-form'}>

      {/* Search bar */}
      <Select
        showSearch
        open = {isSearchOpen}
        loading = {searching}
        value = {searchQuery?.length ? searchQuery : null}
        placeholder = {'Search for company name'}
        defaultActiveFirstOption = {false}
        suffixIcon = {<SearchOutlined />}
        filterOption = {false}
        notFoundContent = {searchQuery && (
          <Flex vertical = {true} align = {'start'} justify = {'flex-start'}>
            <Link to = {'/underlying/new'}>
              <Button type = {'text'} icon = {<PlusCircleOutlined style = {{ width : '20px' }} />}>
                Add new underlying
              </Button>
            </Link>
          </Flex>
        )}
        style = {{
          width : '100%',
        }}
        options = {searchResults
          .map((d, index) => ({
            value       : index,
            key         : index,
            label       : `${d.companyName} (${d.ticker})`,
            isin        : d.isin,
            place       : d.place,
            companyId   : d.companyId,
            companyName : d.companyName,
            ticker      : d.ticker,
          }))
          .filter((d) => !selectedCompany.find((c) => c.companyId === d.companyId))
          .reduce<DefaultOptionType[]>((acc, item) => {
            const alreadyExists = acc.some(
              (existingItem) => existingItem.companyId === item.companyId && existingItem.ticker === item.ticker
            );
            return alreadyExists ? acc : [...acc, item];
          }, [])}
        onDropdownVisibleChange = {(visible) : void => setIsSearchOpen(visible)}
        onSearch = {handleSearch}
        onChange = {(_, option : DefaultOptionType[] | DefaultOptionType) : void => {
          const selectedOption = option as EquityLikeFormType;
          setSearchQuery('');
          setSelectedCompany([...selectedCompany, selectedOption]);
          onAddUnderlying([
            ...underlyingList,
            {
              ...emptyItem,
              companyName      : selectedOption.companyName,
              ticker           : selectedOption.ticker,
              internetTicker   : selectedOption.ticker,
              currencyRefId    : 0,
              initialValuation : selectedOption.initialValuation,
              isin             : selectedOption.isin,
              place            : selectedOption.place,
              companyId        : selectedOption.companyId,
            },
          ]);
        }}
      />

      {/* Form to add equity */}
      {underlyingList.map((item, index) => (
        <Flex vertical = {true} key = {index} className = {'underlying-equity__item'}>
          <Flex justify = {'space-between'}>
            <p>{`${item.companyName} (${item.ticker.trim()})`}</p>

            <Button
              type = {'text'}
              size = {'small'}
              icon = {<DeleteOutlined />}
              onClick = {() : void => {
                setSelectedCompany([...selectedCompany.filter((c) => c.companyId !== item.companyId)]);
                onAddUnderlying(underlyingList.filter((_, i) => i !== index));
              }}
            />
          </Flex>

          <Flex>

            {getPlaceItems(item, item.ticker).length > 1 && (
              <CustomDropdownInput
                currentItem = {item.place}
                width = {100}
                placeholder = {'Place'}
                showCustomInput = {!isInPricingPage && item.companyId === 0}
                items = {getPlaceItems(item, item.ticker)}
                onChange = {(val : string) : void => onChangeEquityLikeForm(index, 'place', val)}
              />
            )}
          </Flex>

          {!isInPricingPage && (

            <InputNumber
              placeholder = {'Initial Fixing'}
              style = {{ width : '100%' }}
              value = {item.initialValuation}
              suffix = {item.currencyRefId}
              onChange = {(val : number | null) : void => onChangeEquityLikeForm(index, 'initialValuation', val ?? 0)}
            />

          )}
        </Flex>
      ))}
    </div>
  );
};
