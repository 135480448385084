import React, { useEffect, useState } from 'react';

import { FilePdfOutlined } from '@ant-design/icons';
import {
  Button, Flex, Input, InputNumber, Select
} from 'antd';
import type { TableColumnsType } from 'antd';

import dayjs from 'dayjs';

import { CustomTable } from '../../../Shared/CustomTable/CustomTable';
import { myFetch } from '../../../config/api';
import { parsePercentStringToFloat } from '../../../utils/stringUtils';

import { TermSheetViewer } from './TermsheetViewer';

import type { ProductEventType } from '../../../data/ProductType';

import './ProductEventManager.scss';

const ProductEventManager = () : React.ReactElement => {
  const [products, setProducts] = useState<ProductEventType[]>([]);
  const [srcPDFLink, setSrcPDFLink] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    myFetch<ProductEventType[]>('GET', '/products/nextEvents')
      .then((response : ProductEventType[]) => {
        setProducts(response);
      })
      .catch((e : unknown) => {
        console.error('Fetch failed: ', e);
      });
  }, []);

  const onChangeValue = (value : number | string, isin : string, key : keyof ProductEventType) : void => {
    setProducts((prev) => prev.map((p) => (p.isin === isin
      ? {
        ...p,
        [key] : value,
      }
      : p)));
  };

  const saveEvent = (event : ProductEventType) : void => {
    myFetch('POST', '/products/nextEvents/save', {
      body  : event,
      query : null,
    }).then((_) => {
      const current = [...products];
      setProducts(current.filter((p) => p.isin !== event.isin));
    })
      .catch((e : unknown) => console.error(e));
  };

  const columns : TableColumnsType<ProductEventType> = [
    {
      title     : 'ISIN',
      dataIndex : 'isin',
      fixed     : 'left',
      width     : 120,
    },
    {
      title     : 'Name',
      dataIndex : 'productName',
      width     : 260,
    },
    {
      title            : 'Date',
      dataIndex        : 'valuationDate',
      align            : 'center',
      sorter           : (left, right) : number => new Date(left.valuationDate).getTime() - new Date(right.valuationDate).getTime(),
      defaultSortOrder : 'ascend',
      render           : (value : Date) : string => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      title     : 'Event type',
      dataIndex : 'eventType',
      align     : 'center',
      render    : (value) : React.ReactElement => <span>{value}</span>,
    },
    {
      title     : 'Potential flow',
      dataIndex : 'potentialFlow',
      align     : 'center',
      render    : (value) : React.ReactElement => <span>{value}%</span>,
    },
    {
      title     : 'Memory effect',
      dataIndex : 'memoryEffect',
      align     : 'center',
      render    : (value) : React.ReactElement => <span>{value ? 'Yes' : 'No'}</span>,
    },
    {
      title     : 'Termsheet',
      dataIndex : 'termsheetLink',
      align     : 'center',
      render    : (value : string | null) : React.ReactElement => (
        <Button
          type = {'dashed'}
          onClick = {() : void => {
            setOpen(true);
            setSrcPDFLink(value);
          }}
        >
          <FilePdfOutlined />

          <span>View Termsheet</span>
        </Button>
      ),
    },
    {
      title     : 'Enter actual flow',
      dataIndex : 'actualFlow',
      align     : 'center',
      render    : (_ : number, record) : React.ReactElement => (
        <Flex vertical justify = {'center'} align = {'center'}>
          <InputNumber
            className = {'actual__flow__input'}
            min = {0}
            step = {0.1}
            defaultValue = {parsePercentStringToFloat(record.potentialFlow, record.potentialFlow.length - 1)}
            suffix = {'%'}
            onChange = {(v) : void => onChangeValue(v ?? 0, record.isin, 'actualFlow')}
          />
        </Flex>
      ),
    },
    {
      title     : 'Enter next event flow',
      dataIndex : 'nextEventPotentialFlow',
      align     : 'center',
      render    : (value : string, record) : React.ReactElement => (
        <Flex vertical justify = {'center'} align = {'center'}>
          <Input
            className = {'actual__flow__input'}
            value = {value}
            suffix = {'%'}
            onChange = {(v) : void => onChangeValue(v.target.value, record.isin, 'nextEventPotentialFlow')}
          />
        </Flex>
      ),
    },
    {
      title     : 'Status Change',
      dataIndex : 'productStatus',
      align     : 'center',
      render    : (value : string, record) : React.ReactElement => (
        <Select
          value = {value}
          className = {`status__select ${record.productStatus === 'Alive' ? 'green_positive' : 'red'}`}
          style = {{ width : 100 }}
          options = {[
            {
              value : 'Alive',
              label : 'Alive',
            },
            {
              value : 'Dead',
              label : 'Dead',
            },
          ]}
          onChange = {(v) : void => onChangeValue(v, record.isin, 'productStatus')}
        />
      ),
      filters : ['Alive', 'Dead'].map((t) => ({
        text  : t,
        value : t,
      })),
      onFilter : (value, record) : boolean => record.productStatus.includes(value as string),
    },
    {
      title     : 'Action',
      dataIndex : 'action',
      align     : 'center',
      render    : (_, record) : React.ReactElement => <Button type = {'primary'} onClick = {() : void => saveEvent(record)}>Validate</Button>,
    },
  ];

  return (
    <>
      <TermSheetViewer open = {open} src = {srcPDFLink} onClose = {() : void => setOpen(false)} />

      <div className = {'event_manager_container'}>
        <CustomTable<ProductEventType>
          rowKey = {'id'}
          tableName = {'PRODUCTS EVENT MANAGER'}
          columns = {columns}
          dataList = {products}
          colsTopApplyDateFilter = {['date']}
          colsTopApplySeachFilter = {['name']}
        />
      </div>
    </>
  );
};

export { ProductEventManager };
