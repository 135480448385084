/* eslint-disable no-undefined */

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import type { TableProps } from 'antd';
import {
  Button,
  Flex, Select, Table,
  message
} from 'antd';

import { myFetch } from '../../../config/api';
import { MESSAGE_API_DURATION } from '../../../config/config';
import { GlobalContext } from '../../../context/GlobalContext';

import { ClientAccountModal } from './ClientAccountModal';

import type { CurrencyRef } from '../../../data/Ref';
import type { AccountDisplay, ClientDisplay, CustodianDisplay } from '../../../data/administration/client';

export const ClientPage = () : React.ReactElement => {
  const { clientId } = useParams<{ clientId : string }>();
  const [client, setClient] = useState<ClientDisplay>();
  const [custodianIdToAdd, setCustodianIdToAdd] = useState<number>();
  const [currencyRefList, setCurrencyRefList] = useState<CurrencyRef[]>([]);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [accountInModal, setAccountInModal] = useState<AccountDisplay>();
  const [messageApi, contextHolder] = message.useMessage({ duration : MESSAGE_API_DURATION });

  const globalContext = useContext(GlobalContext);
  if (!globalContext) {
    throw new Error('You probably forgot to put <GlobalProvider>.');
  }
  const { allRefData } = globalContext;
  const custodianList = allRefData?.CustodiansList.filter((e) => !client?.custodianList.map((c) => c.id).includes(e.id)).map((e) => ({
    value : e.id,
    label : e.fullName,
  })) ?? [];

  const getData = () : void => {
    setCustodianIdToAdd(undefined);
    myFetch<ClientDisplay>('GET', `/client/${clientId}`).then((data) => {
      setClient(data);
    })
      .catch((e : unknown) => {
        messageApi.error(`Error while fetching client : ${e}`);
      });
  };

  useEffect(() => {
    if (allRefData?.CurrencyRefList) {
      setCurrencyRefList(allRefData.CurrencyRefList);
      getData();
    }
  }, [allRefData]);

  const custodianTableColumns : TableProps<CustodianDisplay>['columns'] = [
    {
      dataIndex : 'name',
      title     : 'Name',
      key       : 'name',
      render    : (e) : React.ReactElement => <span className = {'tableLabel'}>{e}</span>,
    },
    {
      title  : 'Action',
      key    : 'action',
      render : (_, e) : React.ReactElement => (
        <Button
          onClick = {() : void => {
            myFetch('DELETE', `/client/${clientId}/custodian/${e.id}`)
              .then(() => {
                getData();
              })
              .catch((err : unknown) => {
                messageApi.error(`Error while fetching custodian : ${err}`);
              });
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const accountTableColumns : TableProps<AccountDisplay>['columns'] = [
    {
      dataIndex : 'currencyIdRef',
      title     : 'Devise',
      key       : 'currencyIdRef',
      render    : (_, account) : React.ReactElement => {
        const currency = currencyRefList.find((e) => e.Id === account.currencyRefId);
        return (
          <span className = {'tableLabel'}>
            {currency?.IsoNomination}
          </span>
        );
      },
    },
    {
      title  : 'Action',
      key    : 'action',
      render : (_, e) : React.ReactElement => (
        <>
          <Button
            onClick = {() : void => {
              setShowAccountModal(true);
              setAccountInModal(e);
            }}
          >
            Edit
          </Button>

          <Button onClick = {() : void => {
            myFetch('DELETE', `/client/clientAccount/${e.id}`)
              .then(() => {
                getData();
              })
              .catch((error : unknown) => {
                messageApi.error(`Failed to delete account : ${error}`);
              });
          }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  if (!client) {
    return <></>;
  }
  return (
    <>
      {contextHolder}

      <ClientAccountModal
        clientId = {client.id}
        isOpen = {showAccountModal}
        currencyRefList = {currencyRefList}
        account = {accountInModal}
        messageApi = {messageApi}
        onUpdate = {() : void => getData()}
        onClose = {() : void => {
          setShowAccountModal(false);
        }}
      />

      <Flex gap = {'5%'}>
        <Flex vertical = {true} style = {{ width : '45%' }}>
          <h2>Custodians</h2>

          <Select
            allowClear
            showSearch
            options = {custodianList}
            optionFilterProp = {'label'}
            placeholder = {'Search for custodian'}
            popupMatchSelectWidth = {false}
            value = {custodianIdToAdd}
            onChange = {(e) : void => setCustodianIdToAdd(e)}
          />

          <Button
            disabled = {custodianIdToAdd === undefined}
            onClick = {() : void => {
              myFetch('PUT', `/client/${clientId}/custodian/${custodianIdToAdd}`).then(() => {
                getData();
              })
                .catch((e : unknown) => {
                  messageApi.error(`Error while saving custodian : ${e}`);
                });
            }}
          >
            Add Custodian
          </Button>

          <Table
            title = {() : string => `Custodians for ${client.name}`}
            columns = {custodianTableColumns}
            dataSource = {client.custodianList}
            rowKey = {(e) : number => e.id}
            pagination = {false}
          />
        </Flex>

        <Flex vertical = {true} style = {{ width : '45%' }}>

          <h2>Accounts</h2>

          <Button onClick = {() : void => {
            setAccountInModal(undefined);
            setShowAccountModal(true);
          }}
          >Add Account
          </Button>

          <Table
            title = {() : string => `Accounts for ${client.name}`}
            columns = {accountTableColumns}
            dataSource = {client.accountList}
            rowKey = {(e) : number => e.id}
            pagination = {false}
          />
        </Flex>

      </Flex>
    </>
  );
};
