import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Outlet,
  matchRoutes, useLocation, useMatches, useNavigate
} from 'react-router-dom';

import {
  Layout, Menu,
  Space
} from 'antd';
import { Content, Header } from 'antd/es/layout/layout';

import { UserMenu } from '../../Modules/Administration/UserMenu/UserMenu';
import Image from '../../assets/bg3.png';
import Logo from '../../assets/logo.svg';
import { haveOneOfTheRoles, keycloakInstance } from '../../config/keycloak';
import {
  CLNPageRoute,
  ClientListPageRoute,
  CreateProductPageRoute,
  EditProductPageRoute,
  ExplorerRoute,
  HomeRoute,
  PricingResultRoute,
  PricingRoute,
  ProductEventManagerRoute,
  ProductOpenForSubscriptionRoute,
  ProductPageRoute,
  ProductsRoute,
  TradePageRoute,
  TradesListRoute, UnderlyingListPageRoute, UnderlyingPageRoute, UserRoute
} from '../../data/Routes';
import { UserRoleEnum } from '../../data/enums/cloak';
import { HeaderTabsEnum, isTabAccessible } from '../../data/enums/header';
import { expertGreen } from '../../styles/colors';
import { MainTitle } from '../MainTitle/MainTitle';
import LocaleSwitcher from '../Translation/LocalSwitcher';

import type { KeycloakProfile } from './../../../node_modules/keycloak-js/lib/keycloak';

import './__styles__/styles.css';

export const AppLayout = () : React.JSX.Element => {
  const [tabList, setTabList] = useState<
    { key   : string,
      label : string, }[]
  >([]);
  const [currentTab, setCurrentTab] = useState<HeaderTabsEnum | null>(null);
  const [username, setUserName] = useState<string>('');

  const navigate = useNavigate();
  const matches = useMatches();
  const currentLocation = useLocation();
  const { t } = useTranslation();

  keycloakInstance.loadUserProfile()
    .then((d : KeycloakProfile) => {
      if (!d.username) {
        throw new Error('No username');
      }
      setUserName(d.username);
    })
    .catch((rej : unknown) => console.warn('keycloakInstance.loadUserProfile(): ', rej));

  useEffect(() => {
    if (matches.length <= 1) {
      setCurrentTab(HomeRoute.tab);
    } else {
      switch (matches[1].pathname) {
        case PricingRoute.path:
          setCurrentTab(PricingRoute.tab);
          break;
        case ProductsRoute.path:
          setCurrentTab(ProductsRoute.tab);
          break;
        case TradesListRoute.path:
          setCurrentTab(TradesListRoute.tab);
          break;
        case ProductEventManagerRoute.path:
          setCurrentTab(ProductEventManagerRoute.tab);
          break;
        case ClientListPageRoute.path:
          setCurrentTab(ClientListPageRoute.tab);
          break;
        case ProductOpenForSubscriptionRoute.path:
          setCurrentTab(ProductOpenForSubscriptionRoute.tab);
          break;
        case CLNPageRoute.path:
          setCurrentTab(CLNPageRoute.tab);
          break;
        case UnderlyingListPageRoute.path:
          setCurrentTab(UnderlyingListPageRoute.tab);
          break;
        default:
          break;
      }
    }
  }, [matches]);

  useEffect(() => {
    const tempTabList = Object.values(HeaderTabsEnum).map((e) => ({
      key   : e,
      label : e,
    }))
      .filter((e) => isTabAccessible(e.key));

    setTabList(tempTabList);
  }, []);

  const getLink = (key : string) : string => {
    if (!Object.values(HeaderTabsEnum).includes(key as HeaderTabsEnum)) {
      console.error('Unkown header tab value: ', key);
      return HomeRoute.path;
    }

    const headerTab : HeaderTabsEnum = key as HeaderTabsEnum;
    switch (headerTab) {
      case HeaderTabsEnum.ayDeal:
        return PricingRoute.path;
      case HeaderTabsEnum.products:
        return ProductsRoute.path;
      case HeaderTabsEnum.tradesList:
        return TradesListRoute.path;
      case HeaderTabsEnum.eventsManager:
        return ProductEventManagerRoute.path;
      case HeaderTabsEnum.explorer:
        return ExplorerRoute.path;
      case HeaderTabsEnum.client:
        return ClientListPageRoute.path;
      case HeaderTabsEnum.productsOpenForSubscription:
        return ProductOpenForSubscriptionRoute.path;
      case HeaderTabsEnum.cln:
        return CLNPageRoute.path;
      case HeaderTabsEnum.underlying:
        return UnderlyingListPageRoute.path;
      default:
        console.error('No path for header tab: ', headerTab);
        return HomeRoute.path;
    }
  };

  const routesToCheck = [
    { path : PricingRoute.path },
    { path : PricingResultRoute.path },
    { path : CreateProductPageRoute.path },
    { path : EditProductPageRoute.path },
    { path : ProductPageRoute.path },
    { path : TradePageRoute.path },
    { path : UserRoute.path },
    { path : ProductEventManagerRoute.path },
    { path : UnderlyingPageRoute.path },
  ];
  const match = matchRoutes(routesToCheck, currentLocation);

  return (
    <Layout>
      <Header
        className = {'topnav'}
        style = {{
          display         : 'flex',
          backgroundColor : expertGreen,
        }}
      >
        <img
          className = {'logo'}
          src = {Logo}
          alt = {'Shape Logo'}
          onClick = {() : void => {
            navigate(HomeRoute.path);
          }}
        />

        <Menu
          selectedKeys = {[currentTab ?? '']}
          mode = {'horizontal'}
          items = {tabList.map((e) => ({
            key   : e.key,
            label : <Link to = {getLink(e.key)}> {t(e.label)} </Link>,
          }))}
          style = {{
            flex            : 1,
            minWidth        : 0,
            backgroundColor : expertGreen,
            border          : 'unset',
          }}
        />

        <Space>

          <LocaleSwitcher />

          <UserMenu />

          <span className = {'header_username'}>{username}</span>
        </Space>
      </Header>

      <Layout>
        <Content
          style = {{
            padding              : 24,
            margin               : 0,
            minHeight            : 'calc(100vh - 64px)', // Subtract header height
            backgroundImage      : `url(${Image})`,
            backgroundSize       : 'cover',
            overflow             : 'hidden',
            backgroundAttachment : 'fixed',
          }}
        >
          {!match
          && <MainTitle text = {currentTab === null ? 'Main' : t(currentTab)} />}

          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
