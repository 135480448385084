import React, { useEffect } from 'react';
import { ReadyState } from 'react-use-websocket';

import { useEventSource } from 'react-use-websocket/dist/lib/use-event-source';

import { CONFIG_BACKEND_SERVER_URL } from '../../../config/config';

import type { SolveForEnum } from '../../../data/enums/pricing';
import type { MonteCarloResponse } from '../../../data/pricing/MonteCarlo';

type MonteCarloLaunchProps = {
  pricingId       : string,
  valueField      : number,
  solveFor        : SolveForEnum,
  couponValue     : number,
  closeConnection : ()=> void,
  setGraphData    : (v : MonteCarloResponse)=> void,
};

export const MonteCarloLaunch = (props : MonteCarloLaunchProps) : React.ReactElement => {

  const {
    pricingId,
    valueField,
    solveFor,
    couponValue,
    closeConnection,
    setGraphData,
  } = props;
  const {
    lastEvent, readyState,
  } = useEventSource(`${CONFIG_BACKEND_SERVER_URL}/monteCarlo/${pricingId}?nbSim=${valueField}&solveFor=${solveFor}&couponValue=${couponValue}`);
  useEffect(() => {
    if (readyState === ReadyState.CLOSED) {
      closeConnection();
    } else if (lastEvent) {
      const data = JSON.parse(lastEvent.data as string) as MonteCarloResponse;
      setGraphData(data);
    }
  }, [lastEvent, readyState]);
  return <></>;
};
