import { useEffect, useRef } from 'react';

import dayjs from 'dayjs';

import {
  EventStatusEnum, EventTypesEnum, ProductTypeEnum
} from '../../../data/product/productEnum';
import { MONTHS_IN_YEAR } from '../../../utils/time';
import { getFrequencyMultiplier } from '../lifecycleHelpers/lifecycleHelpers';

import type { ProductEvent } from '../../../data/ProductType';

const useAutoCreateEvent = ({
  frequency,
  onCreateEventsCallback,
  initialValuation,
  issueDate,
  nonCallPeriod = 0,
  coupon,
  productType,
  couponStepdown = 0,
  autocallStepdown = 0,
  couponTrigger,
  autocallTrigger,
  totalDurationInMonths,
  autocallFloor = 0,
  couponFloor = 0,
  hasCustomSchedule,
} : {
  initialValuation       : dayjs.Dayjs | undefined,
  issueDate              : dayjs.Dayjs | undefined,
  frequency              : string | undefined,
  productType            : string,
  nonCallPeriod          : number | undefined,
  coupon                 : number | undefined,
  couponStepdown         : number | undefined,
  autocallStepdown       : number | undefined,
  couponTrigger          : number | undefined,
  autocallTrigger        : number | undefined,
  totalDurationInMonths  : number,
  autocallFloor          : number | undefined,
  couponFloor            : number | undefined,
  hasCustomSchedule      : boolean,
  onCreateEventsCallback : (eventsList : ProductEvent[])=> void,
}) : void => {
  const eventsListRef = useRef<ProductEvent[]>([]);
  useEffect(() => {
    if (!frequency || hasCustomSchedule) {
      return;
    }
    eventsListRef.current = [];
    const makeCustomEvent = (dateDifference : number, freq : number) : ProductEvent[] => {
      const customEvents : ProductEvent[] = [];
      for (let i = 1; i <= dateDifference / freq; i++) {
        const newDate = dayjs(initialValuation).add(i * freq, 'month');
        customEvents.push({
          id            : i,
          paymentDate   : newDate,
          valuationDate : newDate,
          eventType     : EventTypesEnum.Coupon,
          status        : EventStatusEnum.Future,
          potentialFlow : coupon ? coupon.toString() : '0.0',
        });
      }
      return customEvents;
    };

    const populateOtherEvents = () : ProductEvent[] => {
      let customEvents : ProductEvent[] = makeCustomEvent(totalDurationInMonths, MONTHS_IN_YEAR / getFrequencyMultiplier(frequency));
      if (productType === ProductTypeEnum.Autocall.toString()) {
        customEvents = customEvents.map((event, index) => ({
          ...event,
          eventType : index < nonCallPeriod ? EventTypesEnum.Coupon : EventTypesEnum.AutocallAndCoupon,
        }));
      }

      if (couponTrigger) {
        customEvents = customEvents.map((event, index) => ({
          ...event,
          couponTrigger : Math.max(
            couponFloor,
            couponTrigger - (couponStepdown * index)
          ),
        }));
      }

      // Populate custom eventsList with coupon trigger value if provided & skip non-call period
      if (productType === ProductTypeEnum.Autocall.toString() && autocallTrigger) {
        customEvents = customEvents.map((event, index) => {
          if (index < nonCallPeriod) {
            return event;
          }
          return {
            ...event,
            autocallTrigger : Math.max(
              autocallFloor,
              autocallTrigger - (autocallStepdown * (index - nonCallPeriod))
            ),
          };
        });
      }

      if (productType === ProductTypeEnum.Digital.toString() || productType === ProductTypeEnum.CreditLinkedNote.toString()
        || productType === ProductTypeEnum.Rates.toString()) {
        customEvents = customEvents.map((event) => (
          {
            ...event,
            eventType : EventTypesEnum.Coupon,
          }
        ));
      }

      // Change the last event name to final fixing or final coupon
      if (customEvents.length > 0) {
        if (productType === ProductTypeEnum.CreditLinkedNote.toString()) {
          customEvents[customEvents.length - 1].eventType = EventTypesEnum.FinalCoupon;
        } else {
          customEvents[customEvents.length - 1].eventType = EventTypesEnum.FinalFixing;
        }
      }

      return customEvents;
    };

    // Add fixed basic events (maturity date, issue date, initial fixing)
    if (totalDurationInMonths && issueDate) {
      const maturityDateAsDate = dayjs(issueDate).add(totalDurationInMonths, 'month');
      const maturityEvent : ProductEvent = {
        id            : eventsListRef.current.length + 1,
        status        : EventStatusEnum.Future,
        eventType     : EventTypesEnum.Maturity,
        paymentDate   : dayjs(maturityDateAsDate),
        valuationDate : dayjs(maturityDateAsDate),
        potentialFlow : coupon ? coupon.toString() : '0.0',
      };
      eventsListRef.current.push(maturityEvent);
    }

    if (issueDate) {
      const isFuture = dayjs(issueDate).isAfter(dayjs());
      const issueDateEvent : ProductEvent = {
        id            : eventsListRef.current.length + 1,
        status        : isFuture ? EventStatusEnum.Future : EventStatusEnum.Past,
        eventType     : EventTypesEnum.Issue,
        paymentDate   : dayjs(issueDate),
        valuationDate : dayjs(issueDate),
        potentialFlow : coupon ? coupon.toString() : '0.0',
      };
      eventsListRef.current.push(issueDateEvent);
    }

    if (initialValuation) {
      const isFuture = dayjs(initialValuation).isAfter(dayjs());
      const initialFixingDateEvent : ProductEvent = {
        id            : eventsListRef.current.length + 1,
        status        : isFuture ? EventStatusEnum.Future : EventStatusEnum.Past,
        eventType     : EventTypesEnum.InitialFixing,
        paymentDate   : dayjs(initialValuation),
        valuationDate : dayjs(initialValuation),
        potentialFlow : coupon ? coupon.toString() : '0.0',
      };
      eventsListRef.current.push(initialFixingDateEvent);
    }

    const basicEventTypes = [EventTypesEnum.Maturity, EventTypesEnum.Issue, EventTypesEnum.InitialFixing];

    // Add custom frequency-based eventsList
    if (totalDurationInMonths && frequency && issueDate) {
      const customEvents = populateOtherEvents();
      eventsListRef.current.push(...customEvents);
    }

    if (coupon) {
      eventsListRef.current = eventsListRef.current.map((event) => ({
        ...event,
        potentialFlow : (coupon / getFrequencyMultiplier(frequency)).toFixed(2),
      }));
    }

    // Handle productType-specific filtering
    if (productType === ProductTypeEnum.Custom.toString()) {
      eventsListRef.current = eventsListRef.current.filter(
        (event) => basicEventTypes.includes(event.eventType)
      );
    }

    eventsListRef.current.push({
      id            : eventsListRef.current.length + 1,
      status        : EventStatusEnum.Future,
      eventType     : EventTypesEnum.Today,
      paymentDate   : dayjs(),
      valuationDate : dayjs(),
      potentialFlow : '',
    });

    onCreateEventsCallback(
      eventsListRef.current
        .map((event, index) => ({
          ...event,
          id : index,
        })).sort((left, right) => dayjs(left.valuationDate).diff(dayjs(right.valuationDate), 'days'))
    );

  }, [
    hasCustomSchedule,
    autocallFloor,
    autocallStepdown,
    autocallTrigger,
    coupon,
    couponFloor,
    couponStepdown,
    couponTrigger,
    frequency,
    initialValuation,
    issueDate,
    nonCallPeriod,
    productType,
    totalDurationInMonths,
    onCreateEventsCallback,
  ]);
};

export { useAutoCreateEvent };
