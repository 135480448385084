import type { BasketTypeEnum } from '../product/productEnum';

export enum SolveForEnum {
  reoffer = 'reoffer',
  autocall = 'autocall',
  coupon = 'coupon',
  protection = 'protection',
}

export enum BarrierTypeEnum {
  European = 'European',
  UsClose = 'US Close',
  UsIntraday = 'US Intraday',
  None = 'None',
}

export type PricingFields = {
  autocallFloor        : number,
  autocallStepdown     : number,
  autocallTrigger      : number,
  barrierLevel         : number,
  barrierType          : BarrierTypeEnum,
  ccMail               : string[],
  couponValue          : number | null,
  numberPerYear        : number,
  couponFloor          : number,
  couponStepdown       : number,
  couponTrigger        : number,
  currencyRefId        : number,
  downsideLeverage     : number,
  issuerIdList         : number[],
  maturity             : number,
  marketingName        : string,
  memoryEffect         : boolean,
  notional             : number,
  nonCallPeriod        : number,
  productType          : string,
  realSend             : boolean,
  reoffer              : number | null,
  solveFor             : string,
  strike               : number,
  underlyingBasketType : BasketTypeEnum,
};

export enum FieldsNameEnum {
  autocall = 'autocall',
  autocallFloor = 'autocallFloor',
  autocallStepdown = 'autocallStepdown',
  autocallTrigger = 'autocallTrigger',
  barrierLevel = 'barrierLevel',
  barrierType = 'barrierType',
  ccMail = 'ccMail',
  couponValue = 'couponValue',
  numberPerYear = 'numberPerYear',
  couponFloor = 'couponFloor',
  couponStepdown = 'couponStepdown',
  couponTrigger = 'couponTrigger',
  currencyRefId = 'currencyRefId',
  downsideLeverage = 'downsideLeverage',
  issuerIdList = 'issuerIdList',
  maturity = 'maturity',
  marketingName = 'marketingName',
  memoryEffect = 'memoryEffect',
  notional = 'notional',
  nonCallPeriod = 'nonCallPeriod',
  productType = 'productType',
  realSend = 'realSend',
  reoffer = 'reoffer',
  solveFor = 'solveFor',
  strike = 'strike',
  underlyingBasketType = 'underlyingBasketType',
}

export enum QuoteStatusEnum {
  inProgress = 'in_progress',
  quoted = 'quoted',
  failed = 'failed',
}
