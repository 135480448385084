import React from 'react';

import {
  Button, Col, DatePicker, Flex, Form, InputNumber, Row, Select
} from 'antd';

import dayjs from 'dayjs';

import './UnwindPage.scss';

type UnwindFormElement = {
  isin   : string,
  amount : number,
  date   : string,
  client : string,
};

const UnwindPage = () : React.ReactElement => {
  const [form] = Form.useForm<UnwindFormElement>();

  return (
    <Row justify = {'center'}>
      <Col className = {'unwind_form_container'} span = {10}>
        <div className = {'unwind_container__header'}>
          <h2>Unwind</h2>
        </div>

        <Form requiredMark = {false} form = {form} layout = {'vertical'}>
          <Flex vertical>
            <Form.Item label = {'Product ISIN'} name = {'isin'} rules = {[{ required : true }]}>
              <Select
                showSearch
                placeholder = {'Select ISIN'}
                style = {{ width : '100%' }}
                filterOption = {(input, option) : boolean => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}

                /* TODO: replace the clientsList by the real list
                   options = {clientsList.map((trade) => ({
                     value : trade.isin,
                     label : <span>{trade.isin}</span>,
                   }))} */
              />
            </Form.Item>

            <Form.Item label = {'Client'} name = {'client'} rules = {[{ required : true }]}>
              <Select
                showSearch
                placeholder = {'Client'}
                style = {{ width : '100%' }}
                filterOption = {(input, option) : boolean => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
                options = {[].map((name) => ({

                  value : name,
                  label : <span>{name}</span>,
                }))}
              />
            </Form.Item>

            <Form.Item
              label = {'Date'}
              name = {'date'}
              rules = {[{ required : true }]}

              getValueProps = {(date) => ({ value : date && dayjs(Number(date)) })}
            >
              <DatePicker style = {{ width : '100%' }} format = {'DD/MM/YYYY'} placeholder = {'DD/MM/YYYY'} />
            </Form.Item>

            <Form.Item label = {'Unwind Amount'} name = {'amount'} rules = {[{ required : true }]}>
              <InputNumber
                placeholder = {'0'}
                style = {{ width : '100%' }}
                min = {0}
                suffix = {'€'}
              />
            </Form.Item>
          </Flex>

          <Flex justify = {'end'}>
            <Form.Item>
              <Button className = {'submit_button'} type = {'primary'} htmlType = {'submit'}>
                VALIDATE
              </Button>
            </Form.Item>
          </Flex>
        </Form>
      </Col>
    </Row>
  );
};

export { UnwindPage };
