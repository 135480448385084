export const getFrequencyFromNbCouponPerYear = (nbCouponPerYear : number) : number => {
  switch (nbCouponPerYear) {
    case 1:
      return 12;
    case 2:
      return 6;
    case 4:
      return 3;
    case 12:
      return 1;
    default:
      return 12;
  }
};
