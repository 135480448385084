import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';
import type { FormProps } from 'antd';
import {
  Button,
  Flex, Form, Input, Select, Space, Spin, message
} from 'antd';

import { MainTitle } from '../../../../Shared/MainTitle/MainTitle';
import { myFetch } from '../../../../config/api';
import { MESSAGE_API_DURATION } from '../../../../config/config';
import { GlobalContext } from '../../../../context/GlobalContext';
import { createCountryForSelectOptions, sortCurrenciesForSelectOptions } from '../../../../utils/currencyUtils';

import type { FormFields } from '../../../../data/administration/underlying';

export const EditUnderlying = () : React.ReactElement => {
  const { isin } = useParams();

  const [form] = Form.useForm<FormFields>();
  const [messageApi, contextHolder] = message.useMessage({ duration : MESSAGE_API_DURATION });
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  if (!globalContext) {
    throw new Error('You probably forgot to put <GlobalProvider>.');
  }
  const { allRefData } = globalContext;

  useEffect(() => {
    setIsLoading(true);
    if (isin && isin !== 'new' && isin.length === 12) {
      setIsEditing(true);
      myFetch<FormFields>('GET', `/underlyings/${isin}`)
        .then((response : FormFields) => form.setFieldsValue(response))
        .catch((e : unknown) => messageApi.error(`Error while fetching underlying : ${e}`))
        .finally(() => setIsLoading(false));
    } else {
      setIsEditing(false);
    }
    setIsLoading(false);
  }, [isin]);

  const onFinish : FormProps<FormFields>['onFinish'] = (values : FormFields) : void => {
    setIsLoading(true);
    if (isEditing) {
      myFetch('PUT', `/underlyings/${isin}`, {
        body  : values,
        query : null,
      }).then(() => {
        messageApi.success('Underlying updated successfully');
      })
        .catch((e : unknown) => {
          if (e instanceof Error) {
            messageApi.error(e.message);
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      myFetch('POST', '/underlyings', {
        body  : values,
        query : null,
      }).then(() => {
        messageApi.success('Underlying created successfully');
        navigate(`/underlyings/${values.isin}`);
      })
        .catch((e : unknown) => {
          if (e instanceof Error) {
            messageApi.error(e.message);
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Flex vertical>
      <MainTitle text = {`${isEditing ? 'Edit' : 'Create'} Underlying`} showReturnButton = {true} />

      <Form
        form = {form}
        disabled = {isLoading}
        size = {'small'}
        layout = {'vertical'}
        onFinish = {onFinish}
      >
        {contextHolder}

        <Flex justify = {'space-between'} gap = {'middle'} style = {{ width : '100%' }}>
          <Flex vertical style = {{ width : '40%' }}>
            <h2>Company</h2>

            <Form.Item required label = {'Name'} name = {'companyName'}>
              <Input />
            </Form.Item>

            <Form.Item label = {'Description'} name = {'companyDescription'}>
              <Input.TextArea />
            </Form.Item>

            <Form.Item label = {'Sector'} name = {'sector'}>
              <Input />
            </Form.Item>

            <Form.Item label = {'Industry'} name = {'industry'}>
              <Input />
            </Form.Item>

            <Form.Item label = {'Rating'} name = {'rating'}>
              <Input />
            </Form.Item>

            <Form.Item label = {'Country'} name = {'countryRefId'}>
              <Select
                showSearch
                placeholder = {'Country'}
                options = {createCountryForSelectOptions(allRefData?.CountryList ?? [])}
              />
            </Form.Item>
          </Flex>

          <Flex vertical style = {{ width : '40%' }}>
            <h2>Ticker</h2>

            <Form.Item required label = {'Isin'} name = {'isin'}>
              <Input />
            </Form.Item>

            <Form.Item required label = {'Currency'} name = {'currencyRefId'}>
              <Select
                showSearch
                placeholder = {'Currency'}
                options = {sortCurrenciesForSelectOptions(allRefData?.CurrencyRefList ?? [])}
              />

            </Form.Item>

            <Form.Item required label = {'Ticker'} name = {'ticker'}>
              <Input />
            </Form.Item>

            <Form.Item required label = {'Internet ticker'} name = {'internetTicker'}>
              <Input />
            </Form.Item>

            <Form.Item label = {'Place'} name = {'place'}>
              <Select mode = {'tags'} />
            </Form.Item>
          </Flex>
        </Flex>

        <Flex
          justify = {'flex-end'}
          style = {{
            marginTop : '48px',
            width     : '100%',
          }}
        >
          <Space>
            <Form.Item>
              <Button size = {'large'} type = {'text'} htmlType = {'reset'}>
                Reset
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled = {isLoading}
                className = {'submit_product_button'}
                size = {'large'}
                type = {'primary'}
                htmlType = {'submit'}
              >
                {isLoading && <Spin size = {'small'} indicator = {<LoadingOutlined spin />} />}

                {isEditing ? 'Update' : 'Create'}
              </Button>
            </Form.Item>
          </Space>
        </Flex>
      </Form>
    </Flex>
  );
};
