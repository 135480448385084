import React from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Card, Col, Divider, Row, Space, Typography
} from 'antd';

import type { PricingProduct } from '../../../../data/pricing/GetAllPricing';

const {
  Title, Text,
} = Typography;

type RenderPricingCardProps = {
  item            : PricingProduct,
  onReloadPricing : ()=> void,
};

export const RenderPricingCard = ({
  item, onReloadPricing,
} : RenderPricingCardProps) : React.ReactElement => (
  <Space direction = {'vertical'} size = {'small'} style = {{ width : '100%' }}>

    <h2 style = {{ textAlign : 'center' }}> {item.marketingName} </h2>

    <Divider style = {{ margin : '8px 0' }} />

    <Row align = {'middle'} style = {{ marginBottom : 5 }}>
      <Col span = {12}>
        <Text type = {'secondary'}>Creation Date:</Text>
      </Col>

      <Col span = {12} style = {{ textAlign : 'right' }}>
        <Text>
          {new Date(Date.parse(item.creationDate)).toLocaleString()}
        </Text>
      </Col>
    </Row>

    <Row align = {'middle'} style = {{ marginBottom : 5 }}>
      <Col span = {12}>
        <Text type = {'secondary'}>Product Type:</Text>
      </Col>

      <Col span = {12} style = {{ textAlign : 'right' }}>
        <Text>{item.productType} / {item.productSubType}</Text>
      </Col>
    </Row>

    <Divider style = {{ margin : '8px 0' }} />

    <Row align = {'middle'} style = {{ marginBottom : 5 }}>
      <Col span = {12}>
        <Text strong type = {'secondary'}>Owner:</Text>
      </Col>

      <Col span = {12} style = {{ textAlign : 'right' }}>
        <Text>{item.ownerName}</Text>
      </Col>
    </Row>

    <Row align = {'middle'} style = {{ marginBottom : 5 }}>
      <Col span = {12}>
        <Text strong type = {'secondary'}>Solve For:</Text>
      </Col>

      <Col span = {12} style = {{ textAlign : 'right' }}>
        <Text>{item.solveFor}</Text>
      </Col>
    </Row>

    <Divider style = {{ margin : '8px 0' }} />

    <Row align = {'middle'} style = {{ marginBottom : 5 }}>
      <Col span = {12}>
        <Text strong type = {'secondary'}>Best Price:</Text>
      </Col>

      <Col span = {12} style = {{ textAlign : 'right' }}>
        <Text>{item.bestPrice ? item.bestPrice.toFixed(2) : 'In Progress'}</Text>
      </Col>
    </Row>

    <Row align = {'middle'} style = {{ marginBottom : 5 }}>
      <Col span = {12}>
        <Text strong type = {'secondary'}>Quoted Count:</Text>
      </Col>

      <Col span = {12} style = {{ textAlign : 'right' }}>
        <Text>{item.finishedCount ? item.finishedCount : '0'} / {item.totalQuoteCount}</Text>
      </Col>
    </Row>

    <Divider style = {{ margin : '8px 0' }} />

    <Row>
      <Col span = {24} style = {{ textAlign : 'center' }}>
        <Link to = {`/pricing/${item.uuid}`}>
          <Button block type = {'primary'} onClick = {onReloadPricing}>
            Reload Pricing
          </Button>
        </Link>
      </Col>
    </Row>
  </Space>
);
