import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';
import { message } from 'antd/lib';

import { myFetch } from '../../config/api';
import { GlobalContext } from '../../context/GlobalContext';
import { supportedLngs } from '../../i18n/config';

import type { User } from '../../data/administration/user';

import './LocalSwitcher.scss';

import { MESSAGE_API_DURATION } from '../../config/config';

const getLanguageId = (local : string) : number => {
  switch (local) {
    case 'fr':
      return 2;
    case 'en':
    default:
      return 1;
  }
};

const getLanguageCode = (id : number) : string => {
  switch (id) {
    case 2:
      return 'fr';
    case 1:
    default:
      return 'en';
  }
};

export default function LocaleSwitcher () : React.ReactElement {
  const { i18n } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage({ duration : MESSAGE_API_DURATION });

  const globalContext = useContext(GlobalContext);
  if (!globalContext) {
    throw new Error('You probably forgot to put <GlobalProvider>.');
  }
  const { user } = globalContext;

  useEffect(() => {
    void i18n.changeLanguage(localStorage.getItem('i18nextLng') ?? 'en');
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem('i18nextLng', getLanguageCode(user.languageId));
      void i18n.changeLanguage(getLanguageCode(user.languageId));
    }
  }, [user]);

  const onLanguageChange = () : void => {
    const languageId = getLanguageId(i18n.resolvedLanguage ?? '');
    localStorage.setItem('i18nextLng', languageId.toString());
    void myFetch('PATCH', '/user/update-locale', {
      body : {
        languageId,
      },
      query : null,
    }).catch((e : unknown) => {
      messageApi.error(`Error while changing language : ${e}`);
    });
  };

  return (
    <>
      {contextHolder}

      <div style = {{
        width  : '100%',
        height : '100%',
      }}
      >
        <Select
          className = {'language-switcher'}
          value = {i18n.resolvedLanguage}
          onChange = {(e) : void => {
            void (async () => {
              await i18n.changeLanguage(e);
              onLanguageChange();
            })();
          }}
        >
          {Object.entries(supportedLngs).map(([code, name]) => (
            <Select.Option
              value = {code}
              key = {code}
            >
              <span className = {'full-text'}>{name}</span>

              <span className = {'short-text'}>{code.toUpperCase()}</span>
            </Select.Option>
          ))}
        </Select>
      </div>
    </>
  );
}
