/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  DownloadOutlined, FileOutlined, HistoryOutlined, LoadingOutlined
} from '@ant-design/icons';
import {
  Button, Checkbox, Col, Divider, Empty, Flex, Form, Input, Row, Space, Spin,
  Timeline, Upload, message
} from 'antd';

import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import { MainTitle } from '../../../Shared/MainTitle/MainTitle';
import { myFetch } from '../../../config/api';
import { MESSAGE_API_DURATION } from '../../../config/config';
import { haveOneOfTheRoles } from '../../../config/keycloak';
import { invoicesChecklist, tradeStatuses } from '../../../data/TradesTypes';
import { UserRoleEnum } from '../../../data/enums/cloak';
import { TradeStatusEnum } from '../../../data/enums/trades';
import { getSingleTrade } from '../../../store/tradesStore/tradeApi';
import { whiteColor } from '../../../styles/colors';
import { parseCurrency } from '../../../utils/currencyUtils';
import { downloadFile, generateInvoiceFilename } from '../../../utils/fileDownload';

import type { CustodianRef } from '../../../data/Ref';
import type { TradeClientSignedTermsheet, TradeFormSubmissionData } from '../../../data/TradesTypes';
import type { AppDispatch, RootStateType } from '../../../store/store';
import type { UploadRequestError, UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';

import './TradePage.scss';

const TradePage = () : React.ReactElement => {
  const { Dragger } = Upload;
  const { tradeId } = useParams<{ tradeId : string }>();
  const [currentTradeStatus, setCurrentTradeStatus] = useState('');
  const [currentInvoiceStatus, setCurrentInvoiceStatus] = useState<string>('');
  const [currentBusinessProviderStatus, setCurrentBusinessProviderStatus] = useState<string>('');
  const [currentCustodian, setCurrentCustodian] = useState<CustodianRef[]>([]);
  const [messageApi, contextHolder] = message.useMessage({ duration : MESSAGE_API_DURATION });

  const dispatch = useDispatch<AppDispatch>();
  const tradeItem = useSelector((state : RootStateType) => state.tradesReducer.trade);
  const {
    isLoading, item: trade,
  } = tradeItem;

  const userHasCommissionAccess = haveOneOfTheRoles([
    UserRoleEnum.SuperAdmin,
    UserRoleEnum.InternalAdmin,
    UserRoleEnum.InternalSalesman,
    UserRoleEnum.ViewTradeCommission,
  ]);
  const userHasBillingAccess = haveOneOfTheRoles([
    UserRoleEnum.SuperAdmin,
    UserRoleEnum.InternalAdmin,
    UserRoleEnum.InternalSalesman,
  ]);
  const userHaveEditButtonAccess = haveOneOfTheRoles([
    UserRoleEnum.InternalAssistant,
    UserRoleEnum.InternalSalesman,
    UserRoleEnum.InternalAdmin,
    UserRoleEnum.SuperAdmin,
  ]);
  const isSuperAdminRole = haveOneOfTheRoles([UserRoleEnum.SuperAdmin]);
  const isAdminOrSuperAdminRole = haveOneOfTheRoles([UserRoleEnum.InternalAdmin, UserRoleEnum.SuperAdmin]);

  type TradeInfo = {
    field : keyof TradeFormSubmissionData,
    value : string | number | CustodianRef[],
  };

  useEffect(() => {
    if (!tradeId) {
      return;
    }
    void dispatch(getSingleTrade({ tradeId }));
  }, []);

  useEffect(() => {
    if (trade) {
      setCurrentTradeStatus(trade.tradeStatus);
      setCurrentBusinessProviderStatus(trade.businessProviderInvoiceStatus);
      setCurrentInvoiceStatus(trade.clientInvoiceStatus);
      setCurrentCustodian(trade.custodians);
    }
  }, [trade]);

  const updateMultipleTradeInfos = (fields : TradeInfo[]) : void => {
    if (!trade) {
      return;
    }
    let updatedTrade : TradeFormSubmissionData = {
      isExistingProduct : Boolean(trade.productId),
      product           : {
        productId     : trade.productId,
        isinCode      : trade.isin,
        productName   : trade.productName,
        denomination  : trade.denomination,
        issuerRefId   : trade.issuerRefId,
        currencyRefId : trade.currencyRefId,
      },
      tradeSalesmanOwnerId          : trade.salesmanOwnerId,
      tradeStatus                   : currentTradeStatus,
      clientInvoiceStatus           : currentInvoiceStatus,
      tradeDate                     : trade.tradeDate,
      settlementDate                : trade.settementDate,
      sellingPrice                  : trade.sellingPrice,
      buyingPrice                   : trade.buyingPrice,
      notionalAmount                : trade.notionalAmount,
      clientCompanyId               : trade.clientCompany.id,
      clientCommission              : trade.clientCommission,
      businessProviderCommission    : trade.businessProvider ? trade.businessProviderCommission : null,
      bridgeId                      : trade.bridge ? trade.bridge.id : null,
      bridgeCommission              : trade.bridge ? trade.bridgeCommission : null,
      tradingEntityRefId            : trade.tradingEntity.id,
      businessProviderInvoiceStatus : currentBusinessProviderStatus,
      businessProviderRefId         : trade.businessProvider ? trade.businessProvider.id : null,
      signedClientTermsheet         : trade.signClientTermsheet,
      custodians                    : trade.custodians.map((c) => ({
        custodianId   : c.id,
        amount        : c.amount,
        orderReceived : c.orderReceived,
      })),
    };

    fields.forEach(({
      field, value,
    }) => {
      if (Array.isArray(value)) {
        updatedTrade = {
          ...updatedTrade,
          [field] : value.map((c) => ({
            custodianId   : c.id,
            amount        : c.amount,
            orderReceived : c.orderReceived,
          })),
        };
      } else {
        updatedTrade = {
          ...updatedTrade,
          [field] : value,
        };
      }
    });

    myFetch('PUT', `/trades/${trade.id}`, {
      body  : updatedTrade,
      query : null,
    })
      .then(() : void => {
        messageApi.open({
          type    : 'success',
          content : 'Trade updated successfully',
        });
      })
      .catch((e : unknown) : void => {
        if (e instanceof Error) {
          messageApi.open({
            type    : 'error',
            content : e.message,
          });
        }
      });
  };

  const onChangeStatus = (statusRequested : TradeStatusEnum) : void => {
    if (!isAdminOrSuperAdminRole) {
      return;
    }

    if (isSuperAdminRole) {
      updateMultipleTradeInfos([
        {
          field : 'tradeStatus',
          value : statusRequested,
        },
      ]);
      setCurrentTradeStatus(statusRequested);
      return;
    }

    const currentStatus = currentTradeStatus as TradeStatusEnum;

    const invalidAsk = [TradeStatusEnum.Draft, TradeStatusEnum.Cancel, TradeStatusEnum.Booked].includes(statusRequested);

    const isTryingToMoveBack = currentStatus === TradeStatusEnum.Closed
      || (currentStatus === TradeStatusEnum.CommissionPaid && [TradeStatusEnum.InvoiceSent, TradeStatusEnum.OrderReceived].includes(statusRequested))
      || (currentStatus === TradeStatusEnum.InvoiceSent && TradeStatusEnum.OrderReceived === statusRequested);

    if (!invalidAsk && !isTryingToMoveBack) {
      updateMultipleTradeInfos([
        {
          field : 'tradeStatus',
          value : statusRequested,
        },
      ]);
      setCurrentTradeStatus(statusRequested);
    }
  };

  const [form] = Form.useForm<TradeClientSignedTermsheet>();

  const signedClientTermsheetWatch = Form.useWatch<string>('signedClientTermsheet', form);

  const updloadTermsheet = ({
    file, onError, onSuccess,
  } : Pick<RcCustomRequestOptions, 'file' | 'onError' | 'onSuccess'>) : void => {
    const formData = new FormData();
    formData.append('file', file as File);

    myFetch('POST', '/trades/termsheet', {
      body  : formData,
      query : null,
    }).then((response) => {
      const { fileUrl } = response as { fileUrl : string };
      form.setFieldValue(
        'signedClientTermsheet',
        fileUrl
      );
      updateMultipleTradeInfos([
        {
          field : 'signedClientTermsheet',
          value : fileUrl,
        },
      ]);
      if (onSuccess) {
        onSuccess(response);
      }
    })
      .catch((e : unknown) : void => {
        if (onError) {
          onError(e as UploadRequestError);
        }
        if (e instanceof Error) {
          messageApi.open({
            type    : 'error',
            content : 'Failed to upload termsheet, please try again',
          });
        }
      });
  };

  const deleteUploadedTermsheet = ({
    filename, onError, onSuccess,
  } : Pick<RcCustomRequestOptions, 'filename' | 'onError' | 'onSuccess'>) : void => {
    myFetch('DELETE', `/trades/termsheet/${filename}`).then((response) => {
      const { filename } = response as { filename : string };
      form.setFieldValue(
        'signedClientTermsheet',
        undefined
      );
      updateMultipleTradeInfos([
        {
          field : 'signedClientTermsheet',
          value : '',
        },
      ]);
      if (onSuccess) {
        onSuccess(filename);
      }
    })
      .catch((e : unknown) : void => {
        if (onError) {
          onError(e);
        }
        if (e instanceof Error) {
          messageApi.open({
            type    : 'error',
            content : 'Failed to delete termsheetk please try again',
          });
        }
      });
  };

  const getInvoice = (entity : 'client' | 'trading-entity', lang : 'french' | 'english') => () : void => {
    if (!trade) {
      return;
    }
    myFetch('GET', `/trades/${trade.id}/invoice/?entity=${entity}&lang=${lang}`, {
      body  : null,
      query : null,
    }).then((response) => {
      if (!trade.isin) {
        messageApi.error('no isin found to generate invoice');
        return;
      }
      const fileName = generateInvoiceFilename(
        trade.isin,
        trade.id,
        entity === 'client' ? trade.clientCompany.fullName : trade.tradingEntity.fullName
      );
      downloadFile(response as Blob, fileName);
    })
      .catch((e : unknown) : void => {
        if (e instanceof Error) {
          messageApi.open({
            type    : 'error',
            content : `Failed to download invoice : ${e.message}`,
          });
        }
      });
  };

  if (isLoading) {
    return (
      <Flex
        align = {'center'}
        justify = {'center'}
        gap = {'middle'}
        style = {{ height : '80vh' }}
      >
        <Spin indicator = {(
          <LoadingOutlined
            spin
            style = {{
              fontSize : 48,
              color    : whiteColor,
            }}
          />
        )}
        />
      </Flex>
    );
  }

  if (!trade) {
    return <Empty description = {'Trade not found'} image = {Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const {
    currency, sellingPrice, buyingPrice, notionalAmount, bridgeCommission, clientCommission, businessProviderCommission,
  } = trade;

  const totalCommissionAmount = (((buyingPrice - sellingPrice - (bridgeCommission ?? 0)) * notionalAmount)) / 100;
  const clientCommissionAmount = (clientCommission * notionalAmount) / 100;
  const businessProviderCommissionAmount = ((businessProviderCommission ?? 0) * notionalAmount) / 100;
  const aydoCommision = totalCommissionAmount - clientCommissionAmount - businessProviderCommissionAmount;
  const showEditButton = isSuperAdminRole
    || (userHaveEditButtonAccess && (currentTradeStatus === TradeStatusEnum.Draft.toString() || currentTradeStatus === TradeStatusEnum.Booked.toString()));

  return (
    <div className = {'trade__container'}>
      {contextHolder}

      <Flex className = {'trade__header'} align = {'center'} justify = {'space-between'}>
        <MainTitle showReturnButton text = {'Trade'} />

        {showEditButton
        && (
          <Link to = {`/trades/${trade.id}/edit`}>
            <Button type = {'default'}>Edit</Button>
          </Link>
        )}
      </Flex>

      <Row gutter = {[16, 16]}>
        <Col md = {12} lg = {4} xs = {24}>
          <div className = {'content__box'}>
            <Timeline
              className = {'timeline'}
              items = {tradeStatuses.filter((status) => {
                const userIsAdmin = haveOneOfTheRoles([UserRoleEnum.SuperAdmin]);
                const shouldFilter = (status === TradeStatusEnum.Draft.toString() || status === TradeStatusEnum.Cancel.toString())
                  && status !== currentTradeStatus
                  && !userIsAdmin;
                return !shouldFilter;
              }).map((status) => ({
                color    : status === currentTradeStatus ? 'green' : '',
                children : (
                  <a
                    key = {status}
                    className = {`timeline__btn ${status === currentTradeStatus && 'timeline__btn__current'}`}
                    onClick = {() : void => {
                      onChangeStatus(status as TradeStatusEnum);
                    }}
                  >
                    {status}
                  </a>
                ),
              }))}
            />

            {trade.clientCommission > 0
            && (
              <>
                <h4 className = {'box__section__title'}>Client Invoicing Checklist</h4>

                <Timeline
                  className = {'timeline'}
                  items = {invoicesChecklist.map((status) => ({
                    color    : status === currentInvoiceStatus ? 'green' : '',
                    children : (
                      <a
                        key = {status}
                        className = {`timeline__btn ${status === currentInvoiceStatus && 'timeline__btn__current'}`}
                        onClick = {() : void => {
                          updateMultipleTradeInfos([
                            {
                              field : 'clientInvoiceStatus',
                              value : status,
                            },
                          ]);
                          setCurrentInvoiceStatus(status);
                        }}
                      >
                        {status}
                      </a>
                    ),
                  }))}
                />
              </>
            )}

            {trade.businessProviderCommission !== null && trade.businessProviderCommission > 0
            && (
              <>
                <h4 className = {'box__section__title'}>Business Provider Invoicing Checklist</h4>

                <Timeline
                  className = {'timeline'}
                  items = {invoicesChecklist.map((status) => ({
                    color    : status === currentBusinessProviderStatus ? 'green' : '',
                    children : (
                      <a
                        key = {status}
                        className = {`timeline__btn ${status === currentBusinessProviderStatus && 'timeline__btn__current'}`}
                        onClick = {() : void => {
                          updateMultipleTradeInfos([
                            {
                              field : 'businessProviderInvoiceStatus',
                              value : status,
                            },
                          ]);
                          setCurrentBusinessProviderStatus(status);
                        }}
                      >
                        {status}
                      </a>
                    ),
                  }))}
                />
              </>
            )}

          </div>
        </Col>

        <Col md = {12} lg = {14} xs = {24}>
          <div className = {'content__box'}>
            <Row gutter = {32}>
              <Col md = {12} sm = {24}>
                <div className = {'content__box-left'}>
                  <Flex
                    gap = {8}
                    justify = {'space-between'}
                    align = {'center'}
                    style = {{ marginBottom : 15 }}
                  >
                    <h4 style = {{ marginBottom : 0 }}>Main Features</h4>

                    <Link to = {`/products/${trade.productId}`}>
                      <Button
                        type = {'primary'}
                        size = {'middle'}
                        className = {'orange_button'}
                      >
                        View Product
                      </Button>
                    </Link>
                  </Flex>

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>ISIN Code</label>

                    <p>
                      <Link to = {`/products/${trade.productId}`}>
                        {trade.isin ?? 'To Be Determined'}
                      </Link>
                    </p>
                  </Flex>

                  <Divider style = {{ margin : '8px 0' }} />

                  <Flex wrap = {false} className = {'info__item'} align = {'baseline'}>
                    <label>Product Name</label>

                    <p>
                      <Link to = {`/products/${trade.productId}`}>
                        {trade.productName}
                      </Link>
                    </p>
                  </Flex>

                  <Divider style = {{ margin : '8px 0' }} />

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>Denomination</label>

                    <p>{parseCurrency(trade.denomination, trade.currency)}</p>
                  </Flex>

                  <Divider style = {{ margin : '8px 0' }} />

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>Issuer</label>

                    <p>{trade.issuerFullName}</p>
                  </Flex>

                  <Divider style = {{ margin : '8px 0' }} />

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>Currency</label>

                    <p>{currency}</p>
                  </Flex>

                  <Divider style = {{ margin : '8px 0' }} />

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>Notional Amount</label>

                    <p>{parseCurrency(trade.notionalAmount, currency)}</p>
                  </Flex>

                  <h4 className = {'box__section__title'}>Date</h4>

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>Trade Date</label>

                    <p>{trade.tradeDate ? dayjs(trade.tradeDate).format('DD/MM/YYYY') : '-'}</p>
                  </Flex>

                  <Divider style = {{ margin : '8px 0' }} />

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>Issue Date</label>

                    <p>{trade.tradeDate ? dayjs(trade.settementDate).format('DD/MM/YYYY') : '-'}</p>
                  </Flex>

                  {!userHasBillingAccess && (
                    <Flex
                      vertical
                      style = {{ marginTop : '20px' }}
                      wrap = {false}
                      className = {'info__item'}
                    >
                      <Form
                        name = {'signedClientTermsheet'}
                        form = {form}
                        initialValues = {{
                          signedClientTermsheet : trade.signClientTermsheet,
                        }}
                      >
                        <h4 className = {'box__section__title'}>Signed Termsheet</h4>

                        <Dragger
                          name = {'file'}
                          accept = {'.pdf'}
                          multiple = {false}
                          maxCount = {1}
                          disabled = {Boolean(signedClientTermsheetWatch)}
                          showUploadList = {false}
                          style = {{
                            backgroundColor : 'rgba(255,255,255,.3)',
                            width           : '100%',
                            marginTop       : '16px',
                          }}
                          customRequest = {({
                            file,
                            onError,
                            onSuccess,
                          } : RcCustomRequestOptions) : void => {
                            updloadTermsheet({
                              file,
                              onError,
                              onSuccess,
                            });
                          }}
                        >
                          <p className = {'ant-upload-drag-icon'}>
                            <FileOutlined />
                          </p>

                          <Form.Item hidden name = {'signedClientTermsheet'}>
                            <Input type = {'hidden'} />
                          </Form.Item>

                          <p className = {'ant-upload-text'}>Click or drag TS to this area to upload</p>
                        </Dragger>

                        <Flex
                          justify = {'space-between'}
                          style = {{ marginTop : '20px' }}
                          wrap = {false}
                        >
                          {signedClientTermsheetWatch && (
                            <>
                              <Button
                                size = {'small'}
                                onClick = {() : void => {
                                  const filename = signedClientTermsheetWatch.split('/').pop();
                                  deleteUploadedTermsheet({ filename });
                                }}
                              >Delete termsheet
                              </Button>

                              <Button
                                target = {'_blank'}
                                href = {signedClientTermsheetWatch}
                                type = {'text'}
                                icon = {<DownloadOutlined />}
                                size = {'small'}
                              >Download Termsheet
                              </Button>
                            </>
                          )}
                        </Flex>
                      </Form>
                    </Flex>
                  )}
                </div>
              </Col>

              <Col className = {'content__box-right'} md = {12} sm = {24}>
                <h4>Trading Entity</h4>

                <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                  <label>Representative</label>

                  <p>{trade.tradingEntity.fullName}</p>
                </Flex>

                <Divider style = {{ margin : '8px 0' }} />

                <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                  <label>Reoffer Price</label>

                  <p>{trade.sellingPrice}%</p>
                </Flex>

                <Divider style = {{ margin : '8px 0' }} />

                <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                  <label>Cash Amount</label>

                  <p>{parseCurrency((trade.sellingPrice * trade.notionalAmount) / 100, currency)}</p>
                </Flex>

                <h4 className = {'box__section__title'}>Client</h4>

                <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                  <label>Client Name</label>

                  <p>{trade.clientCompany.fullName}</p>
                </Flex>

                <Divider style = {{ margin : '8px 0' }} />

                <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                  <label>Client Price</label>

                  <p>{trade.buyingPrice}%</p>
                </Flex>

                <Divider style = {{ margin : '8px 0' }} />

                <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                  <label>Client Amount</label>

                  <p>{parseCurrency((trade.buyingPrice * trade.notionalAmount) / 100, currency)}</p>
                </Flex>

                {trade.clientCommission > 0 && (
                  <>
                    <Divider style = {{ margin : '8px 0' }} />

                    <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                      <label>Commission</label>

                      <p>{trade.clientCommission.toFixed(2)}%</p>
                    </Flex>

                  </>
                )}

                {!isEmpty(trade.businessProvider) && (
                  <>
                    <h4 className = {'box__section__title'}>Business Provider</h4>

                    <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                      <label>Name</label>

                      <p>{trade.businessProvider.fullName}</p>
                    </Flex>

                    <Divider style = {{ margin : '8px 0' }} />

                    <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                      <label>Commission</label>

                      <p>{trade.businessProviderCommission!.toFixed(2)}%</p>
                    </Flex>
                  </>
                )}

                {!isEmpty(trade.bridge) && (
                  <>
                    <h4 className = {'box__section__title'}>Bridge</h4>

                    <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                      <label>Name</label>

                      <p>{trade.bridge.fullName}</p>
                    </Flex>

                    <Divider style = {{ margin : '8px 0' }} />

                    <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                      <label>Fee</label>

                      <p>{trade.bridgeCommission!.toFixed(2)}%</p>
                    </Flex>
                  </>
                )}

              </Col>
            </Row>
          </div>
        </Col>

        <Col
          className = {'grid__column'}
          md = {24}
          lg = {6}
          xs = {24}
        >
          <div style = {{ justifyContent : 'center' }} className = {'content__box'}>
            <Flex wrap = {false} className = {'info__item'} align = {'center'}>
              <label>Owner</label>

              <span>{trade.salesman}</span>
            </Flex>
          </div>

          <div className = {'content__box'}>
            <Flex vertical className = {'custodians__infos'}>
              <Flex justify = {'space-between'} align = {'center'}>
                <h4>Custodians</h4>

                {/* <Button type = {'text'} onClick = {() : void => navigate('/unwind')}>
                <u>Unwind</u>
                </Button> */}
              </Flex>

              {currentCustodian.map((custodian) => (
                <div style = {{ marginBottom : '8px' }} key = {custodian.id}>
                  <Flex align = {'center'}>
                    <Checkbox
                      defaultChecked = {custodian.orderReceived}
                      disabled = {(custodian.orderReceived && !isSuperAdminRole) || !userHaveEditButtonAccess}
                      onChange = {(e) : void => {
                        const newCustodian = currentCustodian.map((c) => {
                          if (c.id === custodian.id) {
                            return {
                              ...c,
                              orderReceived : e.target.checked,
                            };
                          }
                          return c;
                        });
                        setCurrentCustodian(newCustodian);
                        const allOrdersReceived = newCustodian.every((c) => c.orderReceived);
                        if (currentTradeStatus === TradeStatusEnum.Booked.toString() && allOrdersReceived) {
                          setCurrentTradeStatus(TradeStatusEnum.OrderReceived);
                          updateMultipleTradeInfos([
                            {
                              field : 'tradeStatus',
                              value : TradeStatusEnum.OrderReceived,
                            },
                            {
                              field : 'custodians',
                              value : newCustodian,
                            },
                          ]);
                        } else if (currentTradeStatus === 'Order Received' && !allOrdersReceived) {
                          setCurrentTradeStatus(TradeStatusEnum.Booked);
                          updateMultipleTradeInfos([
                            {
                              field : 'tradeStatus',
                              value : TradeStatusEnum.Booked,
                            },
                            {
                              field : 'custodians',
                              value : newCustodian,
                            },
                          ]);
                        }
                      }}
                    />

                    <Button type = {'text'} icon = {<DownloadOutlined />} size = {'middle'} />

                    <span className = {'custodians__infos__download'}>
                      {`${custodian.fullName} : ${parseCurrency(custodian.amount, currency)}`}
                    </span>
                  </Flex>
                </div>
              ))}

            </Flex>
          </div>

          {userHasCommissionAccess && (
            <div className = {'content__box'}>
              <h4>Commission details</h4>

              <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                <label>Total Commission</label>

                <span>{parseCurrency(totalCommissionAmount, currency)}</span>
              </Flex>

              {clientCommission > 0 && (
                <>
                  <Divider style = {{ margin : '8px 0' }} />

                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>- Client Commission</label>

                    <span>{parseCurrency(clientCommissionAmount, currency)}</span>
                  </Flex>
                </>
              )}

              <Divider style = {{ margin : '8px 0' }} />

              {businessProviderCommission
              && (
                <>
                  <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                    <label>- Business Provider Commission</label>

                    <span>{parseCurrency(businessProviderCommissionAmount, currency)}</span>
                  </Flex>

                  <Divider style = {{ margin : '8px 0' }} />
                </>
              )}

              <Flex wrap = {false} className = {'info__item'} align = {'center'}>
                <label>= AYDO Commission</label>

                <span>{parseCurrency(aydoCommision, currency)}</span>
              </Flex>
            </div>
          )}

          {userHasBillingAccess && (
            <div className = {'content__box'}>
              <h4>Invoices</h4>

              {![TradeStatusEnum.Draft, TradeStatusEnum.Booked, TradeStatusEnum.Cancel].includes(currentTradeStatus as TradeStatusEnum) && (
                <Checkbox
                  className = {'invoices__checkbox'}
                  disabled = {!userHaveEditButtonAccess}
                  checked = {
                    [TradeStatusEnum.InvoiceSent, TradeStatusEnum.CommissionPaid, TradeStatusEnum.Closed]
                      .includes(currentTradeStatus as TradeStatusEnum)
                  }
                  onClick = {() : void => {
                    const isChecked = currentTradeStatus === TradeStatusEnum.InvoiceSent.toString();
                    if (isAdminOrSuperAdminRole && !isChecked) {
                      updateMultipleTradeInfos([
                        {
                          field : 'tradeStatus',
                          value : TradeStatusEnum.InvoiceSent,
                        },
                      ]);
                      setCurrentTradeStatus(TradeStatusEnum.InvoiceSent);
                    } else if (isSuperAdminRole && isChecked) {
                      updateMultipleTradeInfos([
                        {
                          field : 'tradeStatus',
                          value : TradeStatusEnum.OrderReceived,
                        },
                      ]);
                      setCurrentTradeStatus(TradeStatusEnum.OrderReceived);
                    }
                  }}
                >
                  Signed
                </Checkbox>
              )}

              <Flex
                vertical
                align = {'flex-start'}
                className = {'invoices__download__buttons'}
                gap = {'small'}
                justify = {'space-around'}
              >
                <Button
                  type = {'text'}
                  icon = {<DownloadOutlined />}
                  size = {'small'}
                  onClick = {getInvoice('trading-entity', 'french')}
                >Invoice Trading Entity FR
                </Button>

                <Button
                  type = {'text'}
                  icon = {<DownloadOutlined />}
                  size = {'small'}
                  onClick = {getInvoice('trading-entity', 'english')}
                >Invoice Trading Entity EN
                </Button>

                {trade.clientCommission > 0 && (
                  <>
                    <Button
                      type = {'text'}
                      icon = {<DownloadOutlined />}
                      size = {'small'}
                      onClick = {getInvoice('client', 'french')}
                    >Invoice Client FR
                    </Button>

                    <Button
                      type = {'text'}
                      icon = {<DownloadOutlined />}
                      size = {'small'}
                      onClick = {getInvoice('client', 'english')}
                    >Invoice Client EN
                    </Button>
                  </>
                )}

              </Flex>

              <Divider style = {{ margin : '8px 0' }} />

              <Button
                className = {'view__trade__btn'}
                type = {'primary'}
                icon = {<HistoryOutlined />}
                size = {'small'}
              >
                View trade history
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export { TradePage };
