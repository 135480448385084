import React, { useEffect, useState } from 'react';

import {
  DatePicker, Input, InputNumber, Tooltip
} from 'antd';

import dayjs from 'dayjs';

import { expertGreen } from '../../styles/colors';
import { formatValueForDisplay } from '../../utils/stringUtils';

export const EditableText = ({
  defaultValue, valueType, changeCallback, editable, textColor, minValue, defaultOpen,
} : { defaultValue : string,
  valueType        : 'number' | 'string' | 'date',
  editable         : boolean,
  defaultOpen      : boolean,
  textColor ?: string,
  minValue ?: number,
  changeCallback   : (changedValue : string)=> void, }) : React.ReactElement => {
  const [isEditing, setIsEditing] = useState(defaultOpen);
  const [currentValue, setCurrentValue] = useState(defaultValue);

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  const handleDoubleClick = () : void => {
    if (!editable) {
      return;
    }
    setIsEditing(true);
  };

  const onChangeValue = (updatedValue : string) : void => {
    setCurrentValue(updatedValue);
    changeCallback(updatedValue);
  };

  const renderEditor = () : React.ReactElement => {
    switch (valueType) {
      case 'number':
        return (
          <InputNumber
            autoFocus
            value = {Number(currentValue)}
            size = {'small'}
            suffix = {'%'}
            min = {minValue}
            style = {{
              border   : 'none',
              minWidth : '70px',
            }}
            onChange = {(val) : void => onChangeValue(String(val))}
            onBlur = {() : void => setIsEditing(false)}
            onKeyDown = {(e) : void => {
              if (e.key === 'Enter') {
                setIsEditing(false);
              }
            }}
          />
        );
      case 'string':
        return (
          <Input
            autoFocus
            value = {currentValue}
            size = {'small'}
            style = {{
              border   : 'none',
              minWidth : '70px',
            }}
            onChange = {(e) : void => onChangeValue(e.target.value)}
            onBlur = {() : void => {
              if (!currentValue) {
                return;
              }
              setIsEditing(false);
            }}
            onKeyDown = {(e) : void => {
              if (e.key === 'Enter') {
                if (!currentValue) {
                  return;
                }
                setIsEditing(false);
              }
            }}
          />
        );
      case 'date':
        return (
          <DatePicker
            autoFocus
            allowClear = {false}
            value = {dayjs(currentValue)}
            size = {'small'}
            preserveInvalidOnBlur = {true}
            onBlur = {() : void => {
              setTimeout(() => setIsEditing(false), 200);
            }}
            onChange = {(date) : void => {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if (date) {
                onChangeValue(date.format('YYYY-MM-DD'));
              }
            }}
          />
        );
      default:
        return <></>;
    }
  };

  if (isEditing) {
    return renderEditor();
  }
  return editable
    ? (
      <Tooltip title = {'Click to edit'} color = {expertGreen}>
        <span
          style = {{
            color      : textColor,
            fontWeight : '500',
          }}
          onClick = {handleDoubleClick}
        >{formatValueForDisplay(currentValue, valueType)}
        </span>

      </Tooltip>
    )
    : (
      <span
        style = {{
          color      : textColor,
          fontWeight : '500',
        }}
      >{formatValueForDisplay(currentValue, valueType)}
      </span>
    );

};
