import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  CaretDownOutlined, CaretUpOutlined, EyeOutlined, InfoCircleOutlined, LoadingOutlined
} from '@ant-design/icons';
import {
  Button, Col, Empty, Flex, Row, Spin, Table, Tag,
  Tooltip, message
} from 'antd';
import type { TableColumnsType } from 'antd';

import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { CalendarEvents } from '../../../Shared/CalendarEvents/CalendarEvents';
import { CustomTable } from '../../../Shared/CustomTable/CustomTable';
import { MainTitle } from '../../../Shared/MainTitle/MainTitle';
import billIcon from '../../../assets/billIcon.svg';
import userIcon from '../../../assets/user.png';
import { myFetch } from '../../../config/api';
import { GlobalContext } from '../../../context/GlobalContext';
import { ProductStatusEnum, formatProductStatusEnum } from '../../../data/product/productEnum';
import {
  expertGreen, goldenYellow, whiteColor
} from '../../../styles/colors';
import { parseCurrency } from '../../../utils/currencyUtils';
import { formatMonthsToYearsMonths } from '../../../utils/time';
import { ProductValuation } from '../Shared/ProductValuation/ProductValuation';
import { initProductEvents } from '../lifecycleHooks/useCreateInitProductEvents';

import { ProductCustomFields } from './ProductCustomFields';

import type { ProductEvent, SingleProductType } from '../../../data/ProductType';
import type { CurrencyRef, IssuerRef } from '../../../data/Ref';
import type { CustodianEntityType, TradeListItemForProduct } from '../../../data/TradesTypes';

import './ProductPage.scss';

import type { TickerItem } from './Components/ProductGraph';

import ProductGraph from './Components/ProductGraph';
import ProductGraphUnderlyingTab from './Components/ProductGraphUnderlyingTab';

import { MESSAGE_API_DURATION } from '../../../config/config';

dayjs.extend(isSameOrBefore);

const ProductPage = () : React.ReactElement => {
  const navigate = useNavigate();
  const { productId } = useParams<{ productId : string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState<SingleProductType>();
  const [calendarEvents, setCalendarEvents] = useState<ProductEvent[]>([]);
  const [graphData, setGraphData] = useState<TickerItem[]>([]);
  const [productCurrency, setProductCurrency] = useState<CurrencyRef>();
  const [productIssuer, setProductIssuer] = useState<IssuerRef>();
  const [messageApi, contextHolder] = message.useMessage({ duration : MESSAGE_API_DURATION });
  const globalContext = useContext(GlobalContext);
  if (!globalContext) {
    throw new Error('You probably forgot to put <GlobalProvider>.');
  }
  const { allRefData } = globalContext;

  useEffect(() => {
    if (productId) {
      setIsLoading(true);
      myFetch<SingleProductType>('GET', `/products/${productId}`)
        .then((response : SingleProductType) => {
          setProduct(response);
          initProductEvents({
            product : response,
            setCalendarEvents,
          });
        })
        .catch((e : unknown) => console.error(`Error while fetching product : ${e}`))
        .finally(() => setIsLoading(false));
    }
  }, [productId]);

  useEffect(() => {
    if (allRefData && product) {
      setProductCurrency(allRefData.CurrencyRefList.find((e) => e.Id === product.currencyRefId));
      setProductIssuer(allRefData.IssuerRefList.find((e) => e.id === product.issuerRefId));
    }
  }, [allRefData, product]);

  useEffect(() => {
    if (!product || product.underlyings.length === 0 || dayjs(product.initialValuationDate).isAfter(dayjs())) {
      return;
    }
    const tikerList = product.underlyings.map((item) => (item.internetTicker === '' ? item.ticker : item.internetTicker)).join('&ticker=');
    const beginDate = dayjs(product.initialValuationDate).unix();
    const endDate = dayjs(product.maturityDate).unix();
    myFetch<TickerItem[]>('GET', `/underlyings/historicalData?ticker=${tikerList}&start=${beginDate}&end=${endDate}`)
      .then((response : TickerItem[]) => {
        // TODO: Remove the filter when the API handles the date range
        const filteredData = response.map((item) => {
          const underlying = product.underlyings.find((e) => e.ticker === item.Ticker);
          return {
            ...item,
            Fullname       : underlying?.companyName,
            CurrencyRefId  : underlying?.currencyRefId,
            HistoricalData : item.HistoricalData.filter(({ Timestamp }) => dayjs.unix(Timestamp).isBetween(
              dayjs(product.initialValuationDate),
              dayjs(product.maturityDate)
            )).map((d) => {
              const isInitialValuation = dayjs.unix(d.Timestamp).isSame(dayjs(product.initialValuationDate), 'day');
              if (underlying?.initialValuation && isInitialValuation) {
                return {
                  ...d,
                  Value : underlying.initialValuation,
                };
              }
              return d;
            }),
          };
        });
        setGraphData(filteredData);
      })
      .catch((e : unknown) => {
        if (e instanceof Error) {
          messageApi.error(e.message);
        }
      });

  }, [product, messageApi]);

  const expandedRowRender = (custodians : TradeListItemForProduct['custodians'], currency : string | undefined) : React.ReactNode => {
    const innerColumns : TableColumnsType<CustodianEntityType> = [
      {
        title     : 'Custodian Name',
        dataIndex : 'fullName',
        key       : 'fullName',
      },
      {
        title     : 'Amount',
        dataIndex : 'amount',
        key       : 'amount',
        render    : (val) : string => parseCurrency(val as number, currency),
      },
    ];

    return (
      custodians.length === 0
        ? (
          <Flex justify = {'center'}>
            <p>No custodians</p>
          </Flex>
        )
        : (
          <Table
            columns = {innerColumns}
            className = {'expanded__table'}
            id = {'expanded__table__inner__table--2'}
            dataSource = {custodians}
            pagination = {false}
            rowClassName = {(_, index) : string => (index % 2 === 0 ? 'inner-table-row-even' : 'inner-table-row-odd')}
          />
        )
    );
  };

  if (isLoading) {
    return (
      <Flex
        align = {'center'}
        justify = {'center'}
        gap = {'middle'}
        style = {{ height : '80vh' }}
      >
        <Spin indicator = {(
          <LoadingOutlined
            spin
            style = {{
              fontSize : 48,
              color    : whiteColor,
            }}
          />
        )}
        />
      </Flex>
    );
  }

  const getBasketType = () : string | undefined => {
    if (product?.couponBarrier?.basketType) {
      return product.couponBarrier.basketType;
    }
    return product?.autocall?.basketType;
  };

  const makeTradeColumns = (currency : string | undefined) : TableColumnsType<TradeListItemForProduct> => [
    {
      title     : 'Client',
      dataIndex : 'clientName',
      width     : '33%',
    },
    {
      title     : 'Owner',
      dataIndex : 'ownerName',
      width     : '33%',
    },
    {
      title     : 'Outstanding Amount',
      dataIndex : 'outstandingAmount',
      render    : (value : number) : React.ReactNode => (
        <>
          {parseCurrency(value, currency)}
        </>
      ),
      align : 'right',
    },
    {
      title     : '',
      dataIndex : '',
      width     : 50,
      render    : (_, record) : React.ReactNode => (
        <Link to = {`/trades/${record.id.toString()}`}>
          <Button
            icon = {<EyeOutlined />}
            type = {'text'}
          />
        </Link>
      )
      ,
    },
  ];

  if (!product) {
    return <Empty description = {'Product not found'} image = {Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const couponPerPeriod = ((product.couponFixed?.couponValue ?? 0) / (product.couponFixed?.numberPerYear ?? 1)).toFixed(2);
  const customFields = product.fields;

  return (
    <div className = {'product__container'}>
      {contextHolder}

      <Row
        gutter = {[16, 16]}
        align = {'middle'}
        className = {'product__header'}
        justify = {'space-between'}
      >
        <Col lg = {20} md = {12} xs = {24}>
          <MainTitle
            showReturnButton
            text = {product.name}
            customReturnNavigate = {() : void => navigate('/products')}
          />
        </Col>

        <Col lg = {4} md = {12} xs = {24}>
          {(product.status === ProductStatusEnum.Alive.toString() || product.status === ProductStatusEnum.Draft.toString()) && (
            <Flex justify = {'flex-end'}>
              <ProductValuation
                valuationValue = {product.valuation}
                prefixText = {'Valuation: '}
                style = {{
                  padding      : '12px 18px',
                  fontWeight   : 500,
                  border       : 'none',
                  marginBottom : '12px',
                }}
              />
            </Flex>
          )}

          <Flex justify = {'end'} align = {'center'}>
            <span className = {'product__status_text'}>Status: </span>

            <Tag className = {`product__status__${product.status === ProductStatusEnum.Alive.toString() ? 'green' : 'red'}`}>
              {formatProductStatusEnum(product.status as ProductStatusEnum)}
            </Tag>

            <Link to = {`/products/${product.id.toString()}/edit`}>
              <Button
                type = {'primary'}
                size = {'middle'}
                style = {{
                  marginLeft : '16px',
                  background : whiteColor,
                  color      : expertGreen,
                }}
              >Edit
              </Button>
            </Link>
          </Flex>
        </Col>
      </Row>

      <Row gutter = {[16, 16]}>
        <Col lg = {6} md = {12} xs = {24}>
          <div className = {'content__box content__box__left'}>
            <Flex className = {'outstanding-amount-box'}>
              <img src = {billIcon} />

              <p>{parseCurrency(product.trades.reduce((acc, obj) => acc + obj.outstandingAmount, 0), productCurrency?.IsoNomination)}</p>

              <span>Outstanding Amount</span>
            </Flex>

            <div>

              <h4>General</h4>

              <div className = {'content__box__left_section'}>
                <Flex wrap = {false} className = {'info__item info__item__odd'} align = {'center'}>
                  <label>
                    ISIN
                  </label>

                  <p>{product.isin}</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__even'}>
                  <label>
                    Currency
                  </label>

                  <p>{productCurrency?.IsoNomination}</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Product Type
                  </label>

                  <p>{`${product.productType} / ${product.productSubType}`}</p>
                </Flex>

                {getBasketType() && (product.underlyings.length > 0 || product.underlyingsRates.length > 0) && (

                  <Flex wrap = {false} className = {'info__item info__item__odd'}>
                    <label>
                      Underlying{(product.underlyings.length + product.underlyingsRates.length) > 1 && `s (${getBasketType()})`}
                    </label>

                    <Flex style = {{
                      flexDirection : 'column',
                      textAlign     : 'right',
                    }}
                    >

                      {product.underlyings.map((underlying) => (
                        <p key = {underlying.companyId}>
                          {`${underlying.companyName} (${underlying.ticker})`}
                        </p>
                      ))}

                      {product.underlyingsRates.map((rate) => (
                        <p key = {rate}>
                          {rate}
                        </p>
                      ))}
                    </Flex>
                  </Flex>
                )}

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Initial Fixing Date
                  </label>

                  <p>{dayjs(product.initialValuationDate).format('DD/MM/YYYY')}</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Maturity Date
                  </label>

                  <p>{dayjs(product.maturityDate).format('DD/MM/YYYY')}</p>
                </Flex>

                <Flex wrap = {false} className = {'info__item info__item__odd'}>
                  <label>
                    Maturity
                  </label>

                  <p>
                    {formatMonthsToYearsMonths(product.totalDuration)}
                  </p>
                </Flex>
              </div>

              {customFields.length > 0 && <ProductCustomFields customFields = {customFields} product = {product} />}

              <div className = {'action_section'}>
                <Flex align = {'center'}>
                  <img className = {'userIcon'} src = {userIcon} />

                  <p className = {'desc'}>Ready to invest? Need more information?</p>
                </Flex>

                <Button className = {'connect_button'} type = {'primary'}>Contact us</Button>
              </div>
            </div>

          </div>
        </Col>

        <Col
          className = {'grid__column'}
          lg = {11}
          md = {12}
          xs = {24}
        >
          {
            product.trades.length > 0 && (
              <div className = {'content__box__nopadding'} style = {{ marginBottom : '16px' }}>
                <CustomTable<TradeListItemForProduct>
                  columns = {makeTradeColumns(productCurrency?.IsoNomination)}
                  dataList = {product.trades}
                  rowKey = {(record) : string => record.id.toString()}
                  pagination = {false}
                  bordered = {false}
                  headerHidden = {true}
                  expandable = {{
                    expandedRowRender      : (record) : React.ReactNode => expandedRowRender(record.custodians, productCurrency?.IsoNomination),
                    defaultExpandedRowKeys : ['0'],
                    expandRowByClick       : true,
                    expandIcon             : ({
                      expanded, onExpand, record,
                    }) : React.ReactNode => (expanded
                      ? <Button type = {'text'} icon = {<CaretUpOutlined />} onClick = {(e) : void => onExpand(record, e)} />
                      : <Button type = {'text'} icon = {<CaretDownOutlined />} onClick = {(e) : void => onExpand(record, e)} />),
                  }}
                />
              </div>
            )
          }

          {product.underlyings.length > 0 && dayjs(product.initialValuationDate).isBefore(dayjs()) && graphData.length > 0
          && <ProductGraph product = {product} data = {graphData} />}

          {product.termSheet && (
            <div className = {'content__box content__box__nopadding'}>
              <div className = {'pdf__viewer'}>
                <h4 className = {'box__section__title'}>Termsheet</h4>

                <iframe src = {product.termSheet} width = {'100%'} height = {'800px'} />
              </div>
            </div>
          )}
        </Col>

        <Col lg = {7} md = {12} xs = {24}>
          <div className = {'content__box content__box__calendar'}>
            <h4 className = {'box__section__title'}>Calendar</h4>

            <CalendarEvents
              currentEditingIndex = {null}
              events = {calendarEvents.map((event) => ({
                ...event,
                outcomeValue : couponPerPeriod,
              }))}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export { ProductPage };
