import React, { useEffect } from 'react';

import { Card, Table, message } from 'antd';

import dayjs from 'dayjs';
import {
  Legend,
  Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis
} from 'recharts';

import ProductGraphUnderlyingTab from './ProductGraphUnderlyingTab';

import type { SingleProductType } from '../../../../data/ProductType';

type ProductGraphProps = {
  product : SingleProductType,
  data    : TickerItem[],
};

type TickerData = {
  Timestamp : number,
  Value     : number,
};

export type TickerItem = {
  Ticker               : string,
  Fullname      ?: string,
  CurrencyRefId      ?: number,
  HistoricalData       : TickerData[],
};

type Entry = {
  [key : string] : number,
};

export default function ProductGraph ({
  product, data,
} : ProductGraphProps) : React.ReactElement {

  const normalizedData = React.useMemo(() => {
    if (data.length === 0) {
      return [];
    }

    const initialValues : Entry = {};
    for (const item of data) {
      if (item.HistoricalData.length > 0) {
        const sortedData = [...item.HistoricalData].sort((a, b) => a.Timestamp - b.Timestamp);
        initialValues[item.Ticker] = sortedData[0].Value;
      }
    }

    const timestamps = Array.from(
      new Set(
        data.flatMap((item) => item.HistoricalData.map((d) => dayjs.unix(d.Timestamp).startOf('day')
          .unix()))
      )
    ).sort((a, b) => a - b);

    return timestamps.map((timestamp) => {
      const entry : Entry = { timestamp };
      for (const item of data) {
        const point = item.HistoricalData.find(
          (d) => dayjs.unix(d.Timestamp).startOf('day')
            .unix() === timestamp
        );
        if (point) {
          entry[item.Ticker] = (point.Value / initialValues[item.Ticker]) * 100;
        }
      }
      return entry;
    });
  }, [data]);

  // TODO use p_redemption
  const BarrierLevel = product.fields.find((field) => field.customKey === 'Barrier Level')?.customValue;

  const formatDate = (timestamp : number) : string => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('fr-FR', {
      day   : '2-digit',
      month : '2-digit',
      year  : 'numeric',
    });
  };

  const min = React.useMemo(() => {
    if (normalizedData.length === 0) {
      return 0;
    }
    let calcMin = Math.min(
      ...normalizedData.flatMap((entry) => Object.keys(entry)
        .filter((key) => key !== 'timestamp')
        .map((key) => entry[key]))
    );
    if (BarrierLevel) {
      calcMin = Math.min(calcMin, Number(BarrierLevel));
    }
    return Math.floor((calcMin - 1) / 5) * 5;
  }, [normalizedData, BarrierLevel]);

  const max = React.useMemo(() => {
    if (normalizedData.length === 0) {
      return 0;
    }
    let calcMax = Math.max(
      ...normalizedData.flatMap((entry) => Object.keys(entry)
        .filter((key) => key !== 'timestamp')
        .map((key) => entry[key]))
    );
    if (BarrierLevel) {
      calcMax = Math.max(calcMax, Number(BarrierLevel));
    }
    return Math.ceil((calcMax + 1) / 5) * 5;
  }, [normalizedData, BarrierLevel]);

  const generateTicks = (minValue : number, maxValue : number) : number[] => {
    const lowerMid = Math.floor((minValue + ((100 - minValue) / 2)) / 5) * 5;
    const upperMid = Math.ceil((100 + ((maxValue - 100) / 2)) / 5) * 5;
    return [minValue, lowerMid, 100, upperMid, maxValue];
  };

  return (
    <>
      <div className = {'content__box__graph'}>
        <ResponsiveContainer height = {400}>

          <LineChart
            data = {normalizedData}
            style = {{ overflow : 'visible' }}
          >
            <XAxis
              dataKey = {'timestamp'}
              tickFormatter = {formatDate}
              ticks = {normalizedData
                .map((entry) => entry.timestamp)
                .filter((_, index, array) => index % Math.ceil(array.length / 6) === 0)}
            />

            <YAxis
              domain = {[min, max]}
              tickFormatter = {(value : number) : string => `${value.toFixed(0)}%`}
              tickSize = {10}
              ticks = {generateTicks(min, max)}
            />

            <Tooltip
              labelFormatter = {(timestamp : number) : string => formatDate(timestamp)}
              content = {({
                payload, label,
              }) : React.ReactNode => {
                if (!payload || payload.length === 0) {
                  return null;
                }
                return (
                  <Card
                    title = {formatDate(label as number)}
                    styles = {{
                      header : {
                        backgroundColor : 'white',
                        color           : 'black',
                        textAlign       : 'center',
                      },
                    }}
                    style = {{
                      backgroundColor : 'white',
                      width           : 'max-content',
                    }}
                  >
                    {payload.map((item) => (
                      <p
                        key = {item.dataKey}
                        style = {{
                          color     : item.color,
                          textAlign : 'left',
                        }}
                      >
                        {`${item.dataKey} : ${((item.value) as number).toFixed(2)}%`}
                      </p>
                    ))}
                  </Card>

                );
              }}
            />

            <Legend />

            <ReferenceLine
              y = {100}
              stroke = {'red'}
              strokeDasharray = {'3 3'}
            />

            {BarrierLevel && (
              <ReferenceLine
                y = {BarrierLevel}
                stroke = {'gray'}
                strokeDasharray = {'3 3'}
                label = {{
                  value    : `Protection barrier : ${BarrierLevel}%`,
                  position : 'top',
                  fill     : 'gray',
                  fontSize : 12,
                }}
              />
            )}

            {data.map((item, index) => {
              const colors = ['#FFA540', '#25307F', '#ffc658', '#FF5F45', '#006A99'];
              const color = colors[index % colors.length];
              return (
                <Line
                  key = {item.Ticker}
                  type = {'linear'}
                  dataKey = {item.Ticker}
                  stroke = {color}
                  connectNulls = {true}
                  dot = {false}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className = {'content__box content__box__graph__table'}>
        <ProductGraphUnderlyingTab data = {data} />
      </div>
    </>
  );
}
