import { FieldsNameEnum } from './enums/pricing';

import type { TradeListItemForProduct } from './TradesTypes';
import type { PostPricingBody } from './pricing/PricingBody';
import type {
  EventStatusEnum, EventTypesEnum, ProductSubTypeEnum, ProductTypeEnum
} from './product/productEnum';
import type dayjs from 'dayjs';

export type CustomField = {
  customKey   : string,
  customValue : string,
};

export type ProductType = {
  id                : string,
  name              : string,
  transformFunction : (postPriceBody : PostPricingBody)=> PostPricingBody,
  hideFields        : string [],
};

export const phoenixAutocall : ProductType = {
  id                : 'phoenixAutocall',
  name              : 'Phoenix Autocall',
  transformFunction : (postPriceBody) : PostPricingBody => postPriceBody,
  hideFields        : [],
};

export const classicAutocall : ProductType = {
  id                : 'classicAutocall',
  name              : 'Classic Autocall',
  transformFunction : (postPriceBody) : PostPricingBody => ({
    ...postPriceBody,
    couponBarrier : {
      ...postPriceBody.couponBarrier,
      initialBarrier     : postPriceBody.autocall.initialBarrier,
      stepdown           : postPriceBody.autocall.stepdown,
      couponBarrierFloor : postPriceBody.autocall.autocallFloor,
    },
  }),
  hideFields : [FieldsNameEnum.couponTrigger, FieldsNameEnum.couponStepdown, FieldsNameEnum.couponFloor],
};

export const autocallableReverseConvertible : ProductType = {
  id                : 'autocallableReverseConvertible',
  name              : 'Autocallable Reverse Convertible',
  transformFunction : (postPriceBody) : PostPricingBody => ({
    ...postPriceBody,
    couponBarrier : {
      ...postPriceBody.couponBarrier,
      initialBarrier     : 0,
      stepdown           : 0,
      couponBarrierFloor : 0,
    },
  }),
  hideFields : [FieldsNameEnum.couponTrigger, FieldsNameEnum.couponStepdown, FieldsNameEnum.couponFloor, FieldsNameEnum.memoryEffect],
};

export const reverseConvertible : ProductType = {
  id                : 'reverseConvertible',
  name              : 'Reverse Convertible',
  transformFunction : (postPriceBody) : PostPricingBody => ({
    ...postPriceBody,
    autocall : {
      ...postPriceBody.autocall,
      nonCallPeriod  : (postPriceBody.maturity / postPriceBody.couponFixed.numberPerYear) - 1,
      initialBarrier : 100,
      stepdown       : 0,
      autocallFloor  : 0,
    },
    couponBarrier : {
      ...postPriceBody.couponBarrier,
      initialBarrier     : 0,
      stepdown           : 0,
      couponBarrierFloor : 0,
    },
  }),
  hideFields : [
    FieldsNameEnum.nonCallPeriod,
    FieldsNameEnum.autocallTrigger,
    FieldsNameEnum.autocallStepdown,
    FieldsNameEnum.autocallFloor,
    FieldsNameEnum.couponTrigger,
    FieldsNameEnum.couponStepdown,
    FieldsNameEnum.couponFloor,
  ],
};

export const digital : ProductType = {

  id                : 'digital',
  name              : 'Digital',
  transformFunction : (postPriceBody) : PostPricingBody => ({
    ...postPriceBody,
    autocall : {
      ...postPriceBody.autocall,
      nonCallPeriod  : postPriceBody.maturity / postPriceBody.couponFixed.numberPerYear,
      initialBarrier : postPriceBody.couponBarrier.initialBarrier,
    },
  }),
  hideFields : [FieldsNameEnum.autocallTrigger],
};

export const ProductTypeList = [
  phoenixAutocall,
  classicAutocall,
  autocallableReverseConvertible,
  reverseConvertible,
  digital,
];

export const ProductTypeOptionsList = ProductTypeList.map((e) => ({
  value : e.id,
  label : e.name,
}));

export const hideField = (fieldName : string, productId : string) : boolean => ProductTypeList
  .find((p) => p.id === productId)
  ?.hideFields
  .includes(fieldName)
  ?? false;

export const transformPostPriceDataBody = (postPriceBody : PostPricingBody) : PostPricingBody => ProductTypeList
  .find((p) => p.id === postPriceBody.productType)
  ?.transformFunction(postPriceBody)
  ?? postPriceBody;

export type SingleProductType = {
  id                    : number,
  isin                  : string,
  name                  : string,
  productType           : ProductTypeEnum,
  productSubType        : ProductSubTypeEnum,
  issuerRefId           : number,
  issueDate             : Date,
  maturityDate          : Date,
  nextEventDate         : Date,
  initialValuationDate  : Date,
  currencyRefId         : number,
  totalDuration         : number,
  valuation             : number | null,
  denomination          : number,
  status                : string,
  nextEventStatus       : string,
  termSheet             : string,
  finalRedemptionValue  : number,
  cummulativeCouponEarn : number,
  hasCustomSchedule     : boolean,
  autocall              : Autocall | null,
  couponBarrier         : CouponBarrier | null,
  couponFixed           : CouponFixed | null,
  couponVariable        : CouponVariable | null,
  events                : ProductEvent[],
  fields                : ProductField[],
  underlyings           : UnderlyingEquity[],
  underlyingsRates      : string[],
  trades                : TradeListItemForProduct[],
};

export type ProductField = {
  id          ? : number,
  customKey     : string,
  customValue   : string,
};

export type ProductEvent = {
  id                : number,
  status            : EventStatusEnum,
  eventType         : EventTypesEnum,
  valuationDate     : dayjs.Dayjs,
  paymentDate       : dayjs.Dayjs,
  couponTrigger ?   : number,
  autocallTrigger ? : number,
  potentialFlow     : string,
  actualFlow      ? : number,
};

export type ProductEventForm = {
  id                : number,
  status            : EventStatusEnum,
  eventType         : EventTypesEnum,
  valuationDate     : string,
  paymentDate       : string,
  couponTrigger ?   : number,
  autocallTrigger ? : number,
  potentialFlow     : string,
  actualFlow      ? : number,
};

type Autocall = {
  id               : number,
  basketType       : string,
  barrierType      : string,
  nonCallPeriod    : number,
  initialBarrier   : number,
  stepdown         : number,
  autocallFloor    : number,
  stepDownOverride : string,
};

type CouponBarrier = {
  id               : number,
  basketType       : string,
  barrierType      : string,
  memoryEffect     : boolean,
  initialBarrier   : number,
  stepDown         : number,
  couponFloor      : number,
  stepDownOverride : string,
};

type CouponFixed = {
  id            : number,
  couponValue   : number,
  numberPerYear : number,
};

type CouponVariable = {
  id            : number,
  numberPerYear : number,
  cap           : number,
  floor         : number,
  fixedLeg      : number,
  floatingLeg   : string,
};

export type ProductListItem = {
  id              : number,
  isin            : string,
  name            : string,
  productType     : string,
  productSubType  : string,
  sixListingName  : string,
  issuerShortName : string,
  issuerFullName  : string,
  issueDate       : string,
  maturityDate    : string,
  nextEventDate   : string,
  currency        : string,
  totalDuration   : number,
  valuation       : number,
  denomination    : number,
  status          : string,
  nextEventStatus : string,
  trades          : TradeListItemForProduct[],
};

export type UserListItemForPricing = {
  id       : number,
  fullName : string,
  email    : string | null,
};

export type SearchProductResponseBody = {
  products   : ProductListItem[],
  totalCount : number,
};

export type ProductEventType = {
  id                     : number,
  isin                   : string,
  productName            : string,
  valuationDate          : string,
  potentialFlow          : string,
  termsheetLink          : string,
  actualFlow             : number,
  productStatus          : string,
  memoryEffect           : boolean,
  eventType              : string,
  nextEventId            : number | null,
  nextEventPotentialFlow : string | null,
};

export type ProductFormFieldType = {
  productType       : string,
  productSubType    : string,
  isin              : string,
  name              : string,
  issuerId          : number,
  denomination      : number,
  currencyRefId     : number,
  termSheet         : string,
  issueDate         : dayjs.Dayjs,
  maturityLength    : number,
  initialFixingDate : dayjs.Dayjs,
  basketType        : string,

  coupon                   : number,
  frequency                : string,
  couponTrigger            : number,
  couponStepdown           : number,
  couponMemoryEffect       : boolean,
  couponFloor              : number,
  hasCouponFloor           : boolean,
  couponStepDownOverride   : number,
  couponCap                : number,
  targetCoupon             : number,
  firstFloatingPaymentDate : string,

  autocallTrigger          : number,
  autocallStepdown         : number,
  nonCallPeriod            : number,
  autocallStepDownOverride : number,
  hasAutocallFloor         : boolean,
  autocallFloor            : number,

  barrierType      : string,
  barrierLevel     : number,
  strike           : number,
  downsideLeverage : number,

  cap                      : number,
  bonusLevel               : number,
  bonusBarrier             : number,
  upsideLeverage           : number,
  rebate                   : number,
  rebateBarrier            : number,
  rebateBarrierType        : string,
  downsideStrike           : number,
  maturityDownsideLeverage : number,
  upsideStrike             : number,
  capitalGuaranteeLevel    : number,
  participationStrike      : number,
  maturityCap              : number,
  hasMaturityCap           : boolean,
  hasMaturityRebate        : boolean,
  floorValue               : number,

  attachmentPoint        : number,
  detachmentPoint        : number,
  recoveryType           : string,
  callable               : boolean,
  firstCouponPaymentDate : string,
  optionRebate           : number,
  optionRebate2          : number,

  optionType         : string,
  optionPosition     : string,
  optionStrike       : number,
  optionLeverage     : number,
  optionBarrierLevel : number,
  optionBarrierType  : string,
  optionAsBarrier    : boolean,

  optionType2         : string,
  optionPosition2     : string,
  optionStrike2       : number,
  optionLeverage2     : number,
  optionBarrierLevel2 : number,
  optionBarrierType2  : string,
  optionAsBarrier2    : boolean,
};

export type ProductApiSubmissionForm = {
  isin                 : string,
  name                 : string,
  issuerId             : number,
  productType          : string,
  currencyRefId        : number,
  termSheet            : string,
  status               : string,
  totalDuration        : number,
  productSubType       : string,
  maturityDate         : string | null,
  issueDate            : string,
  initialValuationDate : string,
  underlyings          : UnderlyingEquity[],
  underlyingsRates     : string[],
  denomination         : number,
  autocall ?: {
    nonCallPeriod   : number,
    initialBarrier  : number,
    stepdown        : number,
    barrierList ?: number[],
    autocallFloor ?: number,
  },
  couponBarrier ?: {
    basketType             : string,
    memoryEffect           : boolean,
    initialBarrier         : number,
    stepdown               : number,
    barrierList ?: number[],
    couponFloor ?: number,
    targetCoupon ?: number,
    firstFloatingPayment ?: string,
  },
  couponVariable ?: {
    numberPerYear : number,
    cap           : number,
    floor         : number,
    fixedLeg      : number,
    floatingLeg   : string,
  },
  couponFixed ?: {
    couponValue   : number,
    numberPerYear : number,
  },
  redemptions ?: ProductRedemption[],
  basketType        : string,
  strike            : number,
  downsideLeverage  : number,
  hasCustomSchedule : boolean,
  events            : ProductEventForm[],
  fields : {
    customKey   : string,
    customValue : string,
  }[],
};

export type UnderlyingEquity = {
  isin             : string,
  ticker           : string,
  internetTicker   : string | null,
  currencyRefId    : number,
  initialValuation : number | null,
  place            : string,
  companyId        : number,
  companyName      : string,
};

export type ProductRedemption = {
  startValue             : number,
  isStartIncluded        : boolean,
  endValue               : number | null,
  isEndIncluded          : boolean,
  redemptionValueAtStart : number,
  redemptionValueAtEnd   : number,
  isActivated            : boolean,
  addOrReplace           : 'Add' | 'Replace' | null,
  barrierType ?          : string,
};

export type ProductGroupedByAttribute = {
  groupId    : string,
  groupName  : string,
  groupCount : number,
  totalCount : number,
  products   : ProductListItem[],
};

export const Valuation105 = 105;
export const Valuation100 = 100;
export const Valuation90 = 90;
