import React, { useState } from 'react';

import { Tooltip } from 'antd';

import { expertGreen, whiteColor } from '../../styles/colors';

type ClipboardCopyPropTypes = {
  text : string,
};

const ClipboardCopy = ({ text } : ClipboardCopyPropTypes) : React.ReactElement => {
  const [clicked, setClicked] = useState<boolean>(false);

  return (
    <Tooltip
      title = {clicked ? 'Copied' : 'Copy'}
      color = {whiteColor}
      overlayInnerStyle = {{ color : expertGreen }}
      className = {'clipboard-text'}
      style = {{
        cursor     : 'pointer',
        transition : 'all 0.1s ease-in-out',
      }}
      onOpenChange = {() : void => setClicked(false)}
    >
      <div style = {{
        height         : '100%',
        width          : '100%',
        position       : 'absolute',
        top            : 0,
        left           : 0,
        display        : 'flex',
        alignItems     : 'center',
        justifyContent : 'center',
      }}
      >
        <span
          onClick = {(e) : void => {
            e.stopPropagation();
            setClicked(true);
            navigator.clipboard.writeText(text);
          }}
        >
          {text}
        </span>
      </div>
    </Tooltip>
  );
};

export { ClipboardCopy };
