import React, { useEffect } from 'react';

import {
  Button,
  Form, Input, Modal, Select
} from 'antd';
import type { MessageInstance } from 'antd/es/message/interface';

import { myFetch } from '../../../config/api';

import type { CurrencyRef } from '../../../data/Ref';
import type { AccountDisplay } from '../../../data/administration/client';

type ClientAccountModalProps = {
  clientId        : number,
  isOpen          : boolean,
  account ?       : AccountDisplay,
  currencyRefList : CurrencyRef[],
  messageApi      : MessageInstance,
  onUpdate        : ()=> void,
  onClose         : ()=> void,
};

export const ClientAccountModal = (props : ClientAccountModalProps) : React.ReactElement => {
  const [form] = Form.useForm<AccountDisplay>();

  useEffect(() => {
    if (props.isOpen && props.account) {
      form.setFieldsValue(
        {
          ...props.account,
        }
      );
    }
  }, [props.isOpen, props.account]);

  const onFinish = (account : AccountDisplay) : void => {
    account.clientCompanyId = props.clientId;
    const method = account.id ? 'PUT' : 'POST';
    myFetch(method, '/client/clientAccount', {
      body  : account,
      query : null,
    }).then(() => {
      props.onUpdate();
      props.onClose();
      form.resetFields();
    })
      .catch((e : unknown) => {
        props.messageApi.error(`Error while saving account : ${e}`);
      });
  };

  return (
    <>
      <Modal
        title = {'Account'}
        open = {props.isOpen}
        footer = {[
          <Button
            key = {'cancel'}
            type = {'text'}
            onClick = {() : void => {
              props.onClose();
              form.resetFields();
            }}
          >
            Cancel
          </Button>,
          <Button
            key = {'submit'}
            type = {'primary'}
            onClick = {() : void => {
              form.submit();
            }}
          >
            OK
          </Button>,
        ]}
        onCancel = {() : void => {
          props.onClose();
          form.resetFields();
        }}
      >
        <Form
          form = {form}
          layout = {'vertical'}
          size = {'small'}
          colon = {false}
          requiredMark = {false}
          onFinish = {onFinish}
        >
          <Form.Item hidden name = {'id'} />

          <Form.Item required name = {'currencyRefId'} label = {'Currency'}>
            <Select
              showSearch
              options = {props.currencyRefList.map((currency) => ({
                label : currency.IsoNomination,
                value : currency.Id,
              }))}
            />
          </Form.Item>

          <Form.Item required name = {'iban'} label = {'IBAN'}>
            <Input />
          </Form.Item>

          <Form.Item name = {'bic'} label = {'BIC'}>
            <Input />
          </Form.Item>

          <Form.Item name = {'accountNumber'} label = {'Account Number'}>
            <Input />
          </Form.Item>

          <Form.Item name = {'accountOwner'} label = {'Account Owner'}>
            <Input />
          </Form.Item>

          <Form.Item name = {'bankAddress'} label = {'Bank Address'}>
            <Input />
          </Form.Item>

          <Form.Item name = {'bankName'} label = {'Bank Name'}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
