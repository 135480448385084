import React from 'react';

import type { FormInstance } from 'antd';
import {
  Flex, Form, InputNumber, Select
} from 'antd';

import { BarrierTypeEnum, FieldsNameEnum, SolveForEnum } from '../../../../data/enums/pricing';

import type { PricingFields } from '../../../../data/enums/pricing';

type ProtectionPricingFormProps = {
  form        : FormInstance<PricingFields>,
  pricingId ? : string,
};

export const ProtectionPricingForm = ({
  form, pricingId,
} : ProtectionPricingFormProps)
: React.ReactElement => {

  const strikeValue = Form.useWatch<number>(FieldsNameEnum.strike, form);
  const barrierTypeValue = Form.useWatch<BarrierTypeEnum>(FieldsNameEnum.barrierType, form);
  const solveForValue = Form.useWatch<SolveForEnum>(FieldsNameEnum.solveFor, form);
  const barrierLevelValue = Form.useWatch<number>(FieldsNameEnum.barrierLevel, form);

  const onBarrierTypeChange = (type : BarrierTypeEnum) : void => {
    if (type === BarrierTypeEnum.None) {
      form.setFieldValue(FieldsNameEnum.barrierLevel, strikeValue);
    }
  };

  return (
    <div className = {'cardWrapper organicBlueWrapper'}>
      <div className = {'card'}>
        <div className = {'cardHeader organicBlue'}>
          <h2>Protection</h2>
        </div>

        <Flex vertical className = {'cardContent'}>
          <Form.Item
            name = {FieldsNameEnum.barrierType}
            label = {<span className = {'label white'}>Barrier Type</span>}
          >
            <Select onChange = {onBarrierTypeChange}>
              <Select.Option value = {BarrierTypeEnum.European}>European</Select.Option>

              <Select.Option value = {BarrierTypeEnum.UsClose}>US Close</Select.Option>

              <Select.Option value = {BarrierTypeEnum.UsIntraday}>US Intraday</Select.Option>

              <Select.Option value = {BarrierTypeEnum.None}>None</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name = {FieldsNameEnum.barrierLevel}
            label = {<span className = {'label white'}>Barrier Level</span>}
            hidden = {barrierTypeValue === BarrierTypeEnum.None}
            rules = {[
              {
                required : solveForValue !== SolveForEnum.protection,
                message  : 'Please input barrier level',
              },
            ]}
          >
            <InputNumber
              className = {'fieldBgColor pricer-field'}
              controls = {false}
              suffix = {'%'}
              disabled = {solveForValue === SolveForEnum.protection || Boolean(pricingId)}
              min = {0}
              onChange = {(e : number | null) : void => {
                if (e !== null && e > form.getFieldValue('strike')) {
                  form.setFieldValue('strike', e);
                }
              }}
            />
          </Form.Item>

          <Form.Item
            name = {FieldsNameEnum.strike}
            label = {<span className = {'label white'}>Strike</span>}
            rules = {[
              {
                required : true,
                message  : 'Please input strike',
              },
            ]}
          >
            <InputNumber
              className = {'fieldBgColor pricer-field'}
              controls = {false}
              suffix = {'%'}
              min = {barrierTypeValue === BarrierTypeEnum.None ? 0 : barrierLevelValue}
              onChange = {(e : number | null) : void => {
                form.setFieldValue(FieldsNameEnum.downsideLeverage, e === null ? null : (100 / e) * 100);
                if (barrierTypeValue === BarrierTypeEnum.None) {
                  form.setFieldValue(FieldsNameEnum.barrierLevel, e);
                }
              }}
            />
          </Form.Item>

          <Form.Item
            name = {FieldsNameEnum.downsideLeverage}
            label = {<span className = {'label white'}>Downside Leverage</span>}
          >
            <InputNumber
              readOnly
              className = {'fieldBgColor pricer-field'}
              controls = {false}
              disabled = {true}
              suffix = {'%'}
            />
          </Form.Item>
        </Flex>
      </div>
    </div>
  );
};
