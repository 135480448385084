import React from 'react';

import { Progress } from 'antd';

type MonteCarloProgressProps = {
  nbSimulations   : number,
  monteCarloNbSim : number,
};

const MonteCarloProgress : React.FC<MonteCarloProgressProps> = ({
  nbSimulations,
  monteCarloNbSim,
} : MonteCarloProgressProps) : React.ReactElement => {
  const progressPercent = (nbSimulations / monteCarloNbSim) * 100;

  return (
    <div className = {'monteCarloContainer'}>
      <Progress
        percent = {Math.round(progressPercent)}
        status = {nbSimulations === monteCarloNbSim ? 'success' : 'active'}
        strokeColor = {{
          '0%'   : '#FF5F45',
          '60%'  : '#FFA540',
          '100%' : '#FFFFFF',
        }}
      />

      <div className = {'monteCarloStatus'}>
        {`${nbSimulations} / ${monteCarloNbSim}`} simulations completed
      </div>

    </div>
  );
};

export default MonteCarloProgress;
