import Keycloak from './../../node_modules/keycloak-js/lib/keycloak';
import { CONFIG_KEYCLOAK_CLIENT_ID, CONFIG_KEYCLOAK_REALM, CONFIG_KEYCLOAK_URL } from './config';

import type { UserRoleEnum } from '../data/enums/cloak';

export const keycloakInstance = new Keycloak({
  url      : CONFIG_KEYCLOAK_URL,
  realm    : CONFIG_KEYCLOAK_REALM,
  clientId : CONFIG_KEYCLOAK_CLIENT_ID,
});

export const haveOneOfTheRoles = (roles : UserRoleEnum[]) : boolean => roles.some((role) => keycloakInstance.hasRealmRole(role.toString()));

try {
  // @ts-expect-error : we have 2023 so should be working
  const authenticated = await keycloakInstance.init({ onLoad : 'login-required' });
  if (!authenticated) {
    console.warn('User is not authenticated');
  }
} catch (error) {
  console.error('Failed to initialize adapter:', error);
}

// Token is expired or will expire in less than 60 seconds
export async function refreshToken () : Promise<void> {
  try {
    await keycloakInstance.updateToken(60);
  } catch (e) {
    console.error(e);
  }
}
