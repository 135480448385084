import React, { useContext } from 'react';

import { Table } from 'antd';

import { GlobalContext } from '../../../../context/GlobalContext';

import type { TickerItem } from './ProductGraph';

type UnderlyingData = {
  key           : string,
  name          : string,
  currencyRefId : number,
  ticker        : string,
  initialFixing : number,
  currentFixing : number,
  performance   : string,
};

type ProductGraphUnderlyingTabProps = {
  data : TickerItem[],
};

export default function ProductGraphUnderlyingTab ({ data } : ProductGraphUnderlyingTabProps) : React.ReactElement {

  const globalContext = useContext(GlobalContext);
  if (!globalContext) {
    throw new Error('You probably forgot to put <GlobalProvider>.');
  }
  const { allRefData } = globalContext;
  const currencyRefList = allRefData?.CurrencyRefList;

  const dataSource : UnderlyingData[] = React.useMemo(() => data.map((item, index) => {
    const sortedData = [...item.HistoricalData].sort((a, b) => a.Timestamp - b.Timestamp);
    const initialFixing = sortedData[0]?.Value || 0;
    const currentFixing = sortedData[sortedData.length - 1]?.Value || 0;
    const performance = ((currentFixing - initialFixing) / initialFixing) * 100;

    return {
      key           : `${index + 1}`,
      name          : item.Fullname ?? '',
      ticker        : item.Ticker,
      currencyRefId : item.CurrencyRefId,
      initialFixing,
      currentFixing,
      performance   : `${performance.toFixed(2)}%`,
    };
  }), [data]);

  const columns = [
    {
      title     : 'Underlying',
      dataIndex : 'name',
      key       : 'name',
      render    : (_ : string, record : UnderlyingData) : string => `${record.name} (${record.ticker})`,
    },
    {
      title     : 'Initial Fixing',
      dataIndex : 'initialFixing',
      key       : 'initialFixing',
      render    : (value : number, record : UnderlyingData) : string => `${value.toFixed(2)} ${currencyRefList?.find(
        (e) => e.Id === record.currencyRefId
      )?.IsoNomination}`,
    },
    {
      title     : 'Current Fixing',
      dataIndex : 'currentFixing',
      key       : 'currentFixing',
      render    : (value : number, record : UnderlyingData) : string => `${value.toFixed(2)} ${currencyRefList?.find(
        (e) => e.Id === record.currencyRefId
      )?.IsoNomination}`,
    },
    {
      title     : 'Performance',
      dataIndex : 'performance',
      key       : 'performance',
      render    : (value : string) : React.ReactElement => {
        const colorClass = parseFloat(value) >= 0 ? 'green' : 'orange';
        return <span className = {`color-${colorClass}`}>{value}</span>;
      },
    },
  ];

  return <Table dataSource = {dataSource} columns = {columns} pagination = {false} />;
}
