import { useMemo } from 'react';

import { Card } from 'antd';

import {
  Cell, Legend,
  Pie,
  PieChart,
  ResponsiveContainer, Tooltip
} from 'recharts';

import type { MonteCarloResponse } from '../../../data/pricing/MonteCarlo';
import type { Payload } from 'recharts/types/component/DefaultLegendContent';

const MonteCarloPieChart = ({
  monteCarloData, nbSimulations,
} : { monteCarloData : MonteCarloResponse,
  nbSimulations      : number, }) => {
  const pieChartData = useMemo(() => [
    {
      name  : 'Autocall',
      value : monteCarloData.autocallProbability,
      color : '#FFA540',
    },
    {
      name  : 'Maturity & Couponed',
      value : monteCarloData.maturityCouponedCount,
      color : '#25307F',
    },
    {
      name  : 'Below Barrier',
      value : monteCarloData.belowBarrierPercentage,
      color : '#FF5F45',
    },
    {
      name  : 'Protected',
      value : nbSimulations - monteCarloData.autocallProbability - monteCarloData.belowBarrierPercentage - monteCarloData.maturityCouponedCount,
      color : '#006A99',
    },
  ], [monteCarloData, nbSimulations]);

  return (
    <ResponsiveContainer width = {'100%'} height = {200}>
      <PieChart>
        <Pie
          cx = {'40%'}
          cy = {'50%'}
          outerRadius = {80}
          isAnimationActive = {false}
          dataKey = {'value'}
          data = {pieChartData}
        >
          {pieChartData.map((entry, index) => <Cell key = {`cell-${index}`} fill = {entry.color} />)}
        </Pie>

        <Legend
          verticalAlign = {'middle'}
          align = {'right'}
          layout = {'vertical'}
          content = {({ payload } : { payload ?: Payload[] }) : React.JSX.Element => (
            <div style = {{
              display       : 'flex',
              flexDirection : 'column',
              paddingLeft   : '20px',
            }}
            >
              {payload?.map((entry : Payload, index) => (
                <div
                  key = {`item-${index}`}
                  style = {{
                    display      : 'flex',
                    alignItems   : 'center',
                    marginBottom : '5px',
                  }}
                >
                  <div style = {{
                    width           : '12px',
                    height          : '12px',
                    backgroundColor : entry.color as string,
                    marginRight     : '8px',
                    borderRadius    : '3px',
                  }}
                  />

                  <span style = {{
                    color       : 'white',
                    fontSize    : '14px',
                    marginRight : '8px',
                  }}
                  >
                    {entry.value} :
                  </span>

                  <span style = {{
                    color    : 'white',
                    fontSize : '14px',
                  }}
                  >
                    {entry.payload ? ((entry.payload.value / nbSimulations) * 100).toFixed(2) : '0.00'}%
                  </span>
                </div>
              ))}
            </div>
          )}
        />

        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default MonteCarloPieChart;
