import React from 'react';

import { Card, Flex } from 'antd';
import Tooltip from 'antd/lib/tooltip';

import {
  Bar,
  BarChart,
  Cell,
  Line, LineChart,
  ResponsiveContainer, XAxis, YAxis
} from 'recharts';

import MonteCarloPieChart from './MonteCarloPieChart';
import MonteCarloProgressBar from './MonteCarloProgressBar';
import MonteCarloResultDisplay from './MonteCarloResultDisplay';

import type { MonteCarloDataDisplay, MonteCarloResponse } from '../../../data/pricing/MonteCarlo';

type MonteCarloResultsProps = {
  pricingId     : string,
  nbTotalSim    : number,
  nbDoneSim     : number,
  histogramData : {
    [key : number] : number,
  },
  cdfData        : { flow : number,
    probability           : number, }[],
  monteCarloData : MonteCarloResponse,
};

const MonteCarloResults = (props : MonteCarloResultsProps) : React.ReactElement => {

  const {
    pricingId,
    nbTotalSim,
    nbDoneSim,
    histogramData,
    cdfData,
    monteCarloData,
  } = props;

  const colors = ['#FFA540', '#FFFFFF', '#006A99', '#FF5F45', '#25307F'];

  const chartData = Object.entries(monteCarloData.autocallRates)
    .map(([key, value]) => ({
      maturity : Number(key),
      rate     : (Number(value) / (nbDoneSim || 1)) * 100,
    }))
    .sort((a, b) => a.maturity - b.maturity)
    .reduce<{ maturity : number,
    rate               : number, }[]>((acc, curr, index) => {
      if (index > 0) {
        curr.rate = curr.rate + acc[index - 1].rate;
      }
      acc.push(curr);
      return acc;
    }, []);

  const displayedAutocallRates : { [key : string] : string } = Object.fromEntries(
    Object.entries(monteCarloData.autocallRates).map(([key, value]) => [key, value.toString()])
  );

  Object.entries(displayedAutocallRates).forEach(([key, value]) => {
    displayedAutocallRates[key] = `${(Number(value) / nbDoneSim * 100).toFixed(2)}%`;
  });

  const displayData : MonteCarloDataDisplay = {
    autocallRates                 : displayedAutocallRates,
    totalAutocallProbability      : `${(monteCarloData.autocallProbability / nbDoneSim * 100).toFixed(2)}%`,
    averageMaturity               : `${(monteCarloData.averageMaturity / nbDoneSim).toFixed(2)} Years`,
    averageCouponPaid             : `${(monteCarloData.averageCouponPaid / nbDoneSim * 100).toFixed(2)}%`,
    nonProtectedProbability       : `${(monteCarloData.belowBarrierPercentage / nbDoneSim * 100).toFixed(2)}%`,
    averageRedemptionNonProtected : `${(monteCarloData.averageRedemption / monteCarloData.belowBarrierPercentage * 100).toFixed(2)}%`,
    maturityCouponedCount         : `${(monteCarloData.maturityCouponedCount / nbDoneSim * 100).toFixed(2)}%`,
  };

  const totalFlowsChartData = Object.entries(histogramData)
    .map(([key, count]) => ({
      flow  : parseFloat(key),
      count : count / nbDoneSim,
    }))
    .sort((a, b) => a.flow - b.flow);

  const mcResults = () : React.JSX.Element => (
    <>
      <Flex vertical gap = {16}>
        <MonteCarloProgressBar
          nbSimulations = {nbDoneSim}
          monteCarloNbSim = {nbTotalSim}
        />

        <Flex wrap = {'wrap'} justify = {'space-between'} gap = {16}>
          <MonteCarloResultDisplay displayData = {displayData} />

          <Card title = {'Protection'} style = {{ width : '58%' }}>
            <MonteCarloPieChart monteCarloData = {monteCarloData} nbSimulations = {nbDoneSim} />
          </Card>

          {totalFlowsChartData.length > 0 && (
            <Card title = {'Annual Yield Distribution'} style = {{ width : '48%' }}>
              <ResponsiveContainer width = {'100%'} height = {300}>
                <BarChart data = {totalFlowsChartData}>
                  <XAxis
                    dataKey = {'flow'}
                    tick = {{ fill : 'white' }}
                    tickFormatter = {(tick : number) : string => `${(tick * 100).toFixed(0)}%`}
                    label = {{
                      value    : 'Annual Yield',
                      position : 'insideBottom',
                      dy       : 10,
                      fill     : 'white',
                    }}
                  />

                  <YAxis
                    tick = {{ fill : 'white' }}
                    tickFormatter = {(tick) => `${(tick * 100).toFixed(0)}%`}
                    label = {{
                      value    : 'Repartition',
                      angle    : -90,
                      position : 'insideLeft',
                      dy       : -10,
                      fill     : 'white',
                    }}
                  />

                  <Tooltip />

                  <Bar dataKey = {'count'} fill = {'#FF5F45'}>
                    {totalFlowsChartData.map((_, index) => <Cell key = {`cell-${index}`} fill = {colors[index % colors.length]} />)}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Card>
          )}

          {chartData.length > 0 && (
            <Card title = {'Autocall'} style = {{ width : '48%' }}>
              <ResponsiveContainer width = {'100%'} height = {300}>
                <BarChart data = {chartData}>
                  <XAxis
                    dataKey = {'maturity'}
                    tick = {{ fill : 'white' }}
                    tickFormatter = {(tick) => (tick / 252).toFixed(2)}
                    label = {{
                      value    : 'Maturity (Year)',
                      position : 'insideBottom',
                      dy       : 10,
                      fill     : 'white',
                    }}
                  />

                  <YAxis
                    tick = {{ fill : 'white' }}
                    tickFormatter = {(tick : string) : string => `${tick}%`}
                    label = {{
                      value    : 'Autocall Rate (%)',
                      angle    : -90,
                      position : 'insideLeft',
                      dy       : -10,
                      fill     : 'white',
                    }}
                  />

                  <Bar dataKey = {'rate'}>
                    {chartData.map((_, index) => <Cell key = {`cell-${index}`} fill = {colors[index % colors.length]} />)}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Card>
          )}

          {cdfData.length > 0 && (
            <Card title = {'Cumulative Distribution of Annual Returns'} style = {{ width : '48%' }}>
              <ResponsiveContainer width = {'100%'} height = {300}>
                <LineChart data = {cdfData}>
                  <XAxis
                    dataKey = {'flow'}
                    tick = {{ fill : 'white' }}
                    tickFormatter = {(tick) => `${(tick * 100).toFixed(0)}%`}
                    label = {{
                      value    : 'Total Flow Value',
                      position : 'insideBottom',
                      dy       : 10,
                      fill     : 'white',
                    }}
                  />

                  <YAxis
                    tick = {{ fill : 'white' }}
                    tickFormatter = {(tick) => `${(tick * 100).toFixed(0)}%`}
                    domain = {[0, 1]}
                    label = {{
                      value    : 'Cumulative Probability',
                      angle    : -90,
                      position : 'insideLeft',
                      dy       : -10,
                      fill     : 'white',
                    }}
                  />

                  <Line
                    type = {'monotone'}
                    dataKey = {'probability'}
                    stroke = {'#FFA540'}
                    strokeWidth = {2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Card>
          )}
        </Flex>

      </Flex>

    </>
  );

  return (
    <>
      {pricingId ? mcResults() : ''}
    </>
  );
};

export default MonteCarloResults;
