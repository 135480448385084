export enum TradeStatusEnum {
  Draft = 'Draft',
  Cancel = 'Cancel',
  Booked = 'Booked',
  OrderReceived = 'Order Received',
  InvoiceSent = 'Invoice Sent',
  CommissionPaid = 'Commission Paid',
  Closed = 'Closed',
}

export enum CommissionInvoiceStatusEnum {
  Pending = 'Pending',
  InvoiceSent = 'Invoice Sent',
  InvoiceReceived = 'Invoice Received',
  CommissionPaid = 'Commission Paid',
}
