import { useNavigate } from 'react-router-dom';

import { Button, Flex, Result } from 'antd';

export const AccessDeniedPage = () : React.JSX.Element => {
  const navigate = useNavigate();

  return (
    <Flex justify = {'center'} align = {'center'} style = {{ height : '100vh' }}>
      <Result
        status = {'403'}
        title = {'403'}
        subTitle = {'Sorry, you are not authorized to access this page.'}
        extra = {(
          <Button type = {'primary'} size = {'large'} onClick = {() : void => navigate('/')}>
            Return to Home
          </Button>
        )}
      />
    </Flex>
  );
};
