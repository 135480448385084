import dayjs from 'dayjs';

export const MONTHS_IN_YEAR = 12;

/**
 * Converts a number of months into a string in the format "X year(s) Y month(s)",
 * @param {number} months - The number of months to convert.
 * @returns {string} A string representation of the time period.
 */
export const formatMonthsToYearsMonths = (months : number) : string => {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;
  const yearsPart = years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '';
  const monthsPart = remainingMonths > 0 ? `${remainingMonths} month${remainingMonths > 1 ? 's' : ''}` : '';
  return [yearsPart, monthsPart].filter(Boolean).join(' ')
    .trim();
};

export const toUtcIsoFormat = (date : string) : string => `${dayjs(date).locale('en')
  .format('YYYY-MM-DD')}T00:00:00.000Z`;
