import React, { useContext, useEffect } from 'react';

import {
  Button,
  Flex,
  Form, type FormProps, InputNumber, Space, message
} from 'antd';

import { MainTitle } from '../../../Shared/MainTitle/MainTitle';
import { myFetch } from '../../../config/api';
import { MESSAGE_API_DURATION } from '../../../config/config';
import { GlobalContext } from '../../../context/GlobalContext';

import type { User } from '../../../data/administration/user';

export const UserPage = () : React.ReactElement => {
  const [form] = Form.useForm<User>();
  const [messageApi, contextHolder] = message.useMessage({ duration : MESSAGE_API_DURATION });

  const globalContext = useContext(GlobalContext);
  if (!globalContext) {
    throw new Error('You probably forgot to put <GlobalProvider>.');
  }
  const { user } = globalContext;

  useEffect(() => {
    form.setFieldsValue({ ...user });
  }, [user]);

  const onFinish : FormProps<User>['onFinish'] = (values : User) : void => {
    myFetch('POST', '/user', {
      body  : values,
      query : null,
    }).catch((e : unknown) => {
      if (e instanceof Error) {
        messageApi.error(e.message);
      }
    });
  };

  return (
    <Form form = {form} onFinish = {onFinish}>
      {contextHolder}

      <MainTitle text = {'Profil'} />

      <Form.Item name = {'aydealStandardUpfront'} label = {'AyDeal Standard Upfront'}>
        <InputNumber />
      </Form.Item>

      <Form.Item name = {'languageId'} label = {'Language'} hidden = {true} />

      <Flex
        justify = {'flex-end'}
        style = {{
          position : 'absolute',
          bottom   : '3vh',
          right    : '3vw',
        }}
      >
        <Space>
          <Form.Item>
            <Button size = {'large'} type = {'text'} htmlType = {'reset'}>
              Reset
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              size = {'large'}
              type = {'primary'}
              htmlType = {'submit'}
            >
              Save
            </Button>
          </Form.Item>
        </Space>
      </Flex>
    </Form>
  );
};
