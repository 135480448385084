import React, { useEffect, useState } from 'react';

import type { TableColumnsType } from 'antd';
import {
  Button, Form, InputNumber, message
} from 'antd';
import type { ColumnFilterItem } from 'antd/es/table/interface';

import { CustomTable } from '../../../Shared/CustomTable/CustomTable';
import { myFetch } from '../../../config/api';

import { UploadCLNModal } from './UploadCLNModal';

import type { CLNItem, SearchCLNReturnBody } from '../../../data/pricing/CLN';

import './CLNPage.scss';

import { MESSAGE_API_DURATION } from '../../../config/config';

export const CLNPage = () : React.ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [items, setItems] = useState<CLNItem[]>([]);
  const [filteredItems, setFilteredItems] = useState<CLNItem[]>([]);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  const [reoffer, setReoffer] = useState<number>(99);
  const [minCoupon, setMinCoupon] = useState<number | null>(null);
  const [maxCoupon, setMaxCoupon] = useState<number | null>(null);
  const [minBasis, setMinBasis] = useState<number | null>(null);
  const [maxBasis, setMaxBasis] = useState<number | null>(null);

  const [messageApi, contextHolder] = message.useMessage({ duration : MESSAGE_API_DURATION });

  useEffect(() => {
    const filtered = items.filter((item) => (minCoupon === null || item.coupon >= minCoupon)
      && (maxCoupon === null || item.coupon <= maxCoupon)
      && ((minBasis === null && maxBasis === null) || item.bondYield !== null)
      && (minBasis === null || (item.bondYield !== null && item.coupon - item.bondYield >= minBasis))
      && (maxBasis === null || (item.bondYield !== null && item.coupon - item.bondYield <= maxBasis)));

    setFilteredItems(filtered);
  }, [minCoupon, maxCoupon, minBasis, maxBasis, items]);

  const handleReofferChange = (newReoffer : number) : void => {
    setItems((prevState) => prevState.map((item) => ({
      ...item,
      coupon : item.baseCoupon + ((newReoffer - 99) / item.pv01),
    })));
    setReoffer(newReoffer);
  };

  const getData = () : void => {
    setIsLoading(true);
    myFetch<SearchCLNReturnBody>('GET', '/underlyings/cln')
      .then((response) => {
        response.items.forEach((item) => {
          item.coupon = item.baseCoupon;
        });
        setItems(response.items);
        setFilteredItems(response.items);
      })
      .catch((e : unknown) => {
        messageApi.error(`Error while fetching CLN : ${e}`);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const columns : TableColumnsType<CLNItem> = [
    {
      title     : 'Name',
      dataIndex : 'name',
      sorter    : (l, r) : number => l.name.localeCompare(r.name),
    },
    {
      title     : 'Maturity',
      dataIndex : 'maturity',
      render    : (value) : React.ReactNode => <span>{value}Y</span>,
      filters   : Object.values(
        items.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
          acc[current.maturity] = {
            text  : `${current.maturity}Y`,
            value : current.maturity,
          };
          return acc;
        }, {})
      ),
      onFilter : (filterValue, record) : boolean => record.maturity === filterValue as number,
    },
    {
      title     : 'Currency',
      dataIndex : 'currency',
      filters   : Object.values(
        items.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
          acc[current.currency] = {
            text  : current.currency,
            value : current.currency,
          };
          return acc;
        }, {})
      ),
      onFilter : (value, record) : boolean => record.currency.includes(value as string),
    },
    {
      title     : 'Coupon',
      dataIndex : 'coupon',
      render    : (value : number) : React.ReactNode => <span>{value.toFixed(2)}%</span>,
      sorter    : (l, r) : number => l.coupon - r.coupon,
    },
    {
      title     : 'Comparable Bond',
      dataIndex : 'comparableBond',
      render    : (value) : React.ReactNode => <span>{value ? value : ''}</span>,
    },
    {
      title     : 'Bond Yield',
      dataIndex : 'bondYield',
      render    : (value) : React.ReactNode => <span>{value ? `${value}%` : ''}</span>,
    },
    {
      title  : 'Basis',
      render : (_, record) : React.ReactNode => {
        const value = record.bondYield ? record.coupon - record.bondYield : 0;
        return (
          <span className = {value > 0 ? 'green' : 'red'}>
            {record.bondYield ? `${value.toFixed(2)}%` : ''}
          </span>
        );
      },
      sorter : (l, r) : number => {
        const lValue = l.bondYield === null ? 0 : l.coupon - l.bondYield;
        const rValue = r.bondYield === null ? 0 : r.coupon - r.bondYield;
        return lValue - rValue;
      },
    },
    {
      title     : 'Sector',
      dataIndex : 'sector',
      filters   : Object.values(
        items.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
          acc[current.sector] = {
            text  : current.sector,
            value : current.sector,
          };
          return acc;
        }, {})
      ),
      onFilter : (value, record) : boolean => record.sector.includes(value as string),
    },
    {
      title     : 'Country',
      dataIndex : 'country',
      filters   : Object.values(
        items.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
          acc[current.country] = {
            text  : current.country,
            value : current.country,
          };
          return acc;
        }, {})
      ),
      onFilter : (value, record) : boolean => record.country.includes(value as string),
    },
    {
      title     : 'Moody',
      dataIndex : 'ratingMoody',
      filters   : Object.values(
        items.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
          if (current.ratingMoody === null) {
            return acc;
          }
          acc[current.ratingMoody] = acc[current.ratingMoody] ?? {
            text  : current.ratingMoody,
            value : current.ratingMoody,
          };
          return acc;
        }, {})
      ),
    },
    {
      title     : 'Fitch',
      dataIndex : 'ratingFitch',
      filters   : Object.values(
        items.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
          if (current.ratingFitch === null) {
            return acc;
          }
          acc[current.ratingFitch] = acc[current.ratingFitch] ?? {
            text  : current.ratingFitch,
            value : current.ratingFitch,
          };
          return acc;
        }, {})
      ),
    },
    {
      title     : 'S&P',
      dataIndex : 'ratingSAndP',
      filters   : Object.values(
        items.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
          if (current.ratingSAndP === null) {
            return acc;
          }
          acc[current.ratingSAndP] = acc[current.ratingSAndP] ?? {
            text  : current.ratingSAndP,
            value : current.ratingSAndP,
          };
          return acc;
        }, {})
      ),
    },
    {
      title     : 'RiskLevel',
      dataIndex : 'riskLevel',
      filters   : Object.values(
        items.reduce((acc : {[key : string] : ColumnFilterItem }, current) => {
          if (current.riskLevel === null) {
            return acc;
          }
          acc[current.riskLevel] = acc[current.riskLevel] ?? {
            text  : current.riskLevel,
            value : current.riskLevel,
          };
          return acc;
        }, {})
      ),
      onFilter : (value, record) : boolean => (record.riskLevel ? record.riskLevel.includes(value as string) : false),
    },

  ];

  return (
    <div>
      {contextHolder}

      <UploadCLNModal
        isOpen = {showUploadModal}
        messageApi = {messageApi}
        onClose = {() : void => {
          setShowUploadModal(false);
          getData();
        }}
      />

      <CustomTable
        dataList = {filteredItems}
        columns = {columns}
        isLoading = {isLoading}
        colsTopApplySeachFilter = {['name']}
        customComponent = {(
          <>
            <Form.Item label = {'Reoffer'}>
              <InputNumber
                value = {reoffer}
                suffix = {'%'}
                step = {0.5}
                onChange = {(v) : void => handleReofferChange(v ?? 99)}
              />
            </Form.Item>

            <Form.Item label = {'Coupon minimum'}>
              <InputNumber
                value = {minCoupon}
                suffix = {'%'}
                step = {0.5}
                onChange = {(v) : void => setMinCoupon(v)}
              />
            </Form.Item>

            <Form.Item label = {'Coupon maximum'}>
              <InputNumber
                value = {maxCoupon}
                suffix = {'%'}
                step = {0.5}
                onChange = {(v) : void => setMaxCoupon(v)}
              />
            </Form.Item>

            <Form.Item label = {'Basis minimum'}>
              <InputNumber
                value = {minBasis}
                suffix = {'%'}
                step = {0.5}
                onChange = {(v) : void => setMinBasis(v)}
              />
            </Form.Item>

            <Form.Item label = {'Basis maximum'}>
              <InputNumber
                value = {maxBasis}
                suffix = {'%'}
                step = {0.5}
                onChange = {(v) : void => setMaxBasis(v)}
              />
            </Form.Item>

            <Form.Item>
              <Button onClick = {() : void => setShowUploadModal(true)}>Upload CLN</Button>
            </Form.Item>
          </>

        )}
        pagination = {{
          position        : ['topLeft'],
          defaultPageSize : 20,
          showTotal       : (total, range) : string => `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </div>
  );
};
