import { haveOneOfTheRoles } from '../../config/keycloak';

import { UserRoleEnum } from './cloak';

export enum HeaderTabsEnum {
  explorer = 'layoutTitle.explorer',
  ayDeal = 'layoutTitle.ayDeal',
  products = 'layoutTitle.products',
  productsOpenForSubscription = 'layoutTitle.productsOpenForSubscription',
  tradesList = 'layoutTitle.tradesList',
  eventsManager = 'layoutTitle.eventsManager',
  client = 'layoutTitle.client',
  cln = 'layoutTitle.cln',
  underlying = 'Underlying',
}

// If the value is null, it means that the tab is accessible to all users
const HeaderTabsVisibility : {[key in HeaderTabsEnum] : UserRoleEnum[] | null } = {
  [HeaderTabsEnum.explorer] : null,
  [HeaderTabsEnum.ayDeal]   : [
    UserRoleEnum.InternalAdmin,
    UserRoleEnum.SuperAdmin,
    UserRoleEnum.InternalSalesman,
    UserRoleEnum.InternalAssistant,
  ],
  [HeaderTabsEnum.products]   : null,
  [HeaderTabsEnum.tradesList] : [
    UserRoleEnum.InternalAdmin,
    UserRoleEnum.SuperAdmin,
    UserRoleEnum.InternalSalesman,
    UserRoleEnum.LegalObserver,
    UserRoleEnum.InternalAssistant,
  ],
  [HeaderTabsEnum.productsOpenForSubscription] : [
    UserRoleEnum.InternalAdmin,
    UserRoleEnum.SuperAdmin,
    UserRoleEnum.InternalSalesman,
    UserRoleEnum.InternalAssistant,
  ],
  [HeaderTabsEnum.eventsManager] : [
    UserRoleEnum.SuperAdmin,
    UserRoleEnum.InternalAdmin,
    UserRoleEnum.InternalAssistant,
  ],
  [HeaderTabsEnum.client] : [
    UserRoleEnum.SuperAdmin,
    UserRoleEnum.InternalAdmin,
    UserRoleEnum.InternalSalesman,
  ],
  [HeaderTabsEnum.cln] : [
    UserRoleEnum.InternalAdmin,
    UserRoleEnum.SuperAdmin,
    UserRoleEnum.InternalSalesman,
    UserRoleEnum.InternalAssistant,
  ],
  [HeaderTabsEnum.underlying] : [
    UserRoleEnum.InternalAdmin,
    UserRoleEnum.SuperAdmin,
    UserRoleEnum.InternalSalesman,
    UserRoleEnum.InternalAssistant,
  ],
};

export function isTabAccessible (tab : HeaderTabsEnum) : boolean {
  const authorizedRoles = HeaderTabsVisibility[tab];
  return authorizedRoles === null || haveOneOfTheRoles(authorizedRoles);
}
