import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom';

import { Button, Flex, Result } from 'antd';
import type { ResultStatusType } from 'antd/lib/result';

type RouteError = {
  data  : string,
  error : {
    columnNumber : number,
    fileName     : string,
    lineNumber   : number,
    message      : string,
    stack        : string,
  },
  internal   : boolean,
  status     : number,
  statusText : string,
};

export const ErrorPage = () : React.JSX.Element => {
  const error = useRouteError() as RouteError;
  const navigate = useNavigate();

  return (
    <Flex justify = {'center'} align = {'center'} style = {{ height : '100vh' }}>
      <Result
        status = {error.status as ResultStatusType}
        title = {error.statusText}
        subTitle = {error.data}
        extra = {(
          <Button type = {'primary'} size = {'large'} onClick = {() : void => navigate('/')}>
            Return to Home
          </Button>
        )}
      />
    </Flex>
  );
};
