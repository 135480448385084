import type React from 'react';

import dayjs from 'dayjs';

import { EventStatusEnum, EventTypesEnum } from '../../../data/product/productEnum';

import type { ProductEvent, SingleProductType } from '../../../data/ProductType';

export const initProductEvents = ({
  product, setCalendarEvents,
} : {
  product           : SingleProductType | undefined,
  setCalendarEvents : React.Dispatch<React.SetStateAction<ProductEvent[]>>,
}) : void => {
  const events = product?.events ?? [];

  const autocallInitialBarrier = product?.autocall?.initialBarrier;
  const autocallStepdown = product?.autocall?.stepdown;
  const autocallFloor = product?.autocall?.autocallFloor;

  const couponInitialBarrier = product?.couponBarrier?.initialBarrier;
  const couponStepdown = product?.couponBarrier?.stepDown;
  const couponFloor = product?.couponBarrier?.couponFloor;

  const issueDate = product?.issueDate;
  const initialFixingDate = product?.initialValuationDate;
  const maturityDate = product?.maturityDate;

  const hasIssue = events.some((event) => event.eventType === EventTypesEnum.Issue);
  const hasInitialFixing = events.some((event) => event.eventType === EventTypesEnum.InitialFixing);
  const hasToday = events.some((event) => event.eventType === EventTypesEnum.Today);
  const hasMaturity = events.some((event) => event.eventType === EventTypesEnum.Maturity);

  const dayJsEvents : ProductEvent[] = events.map((event) => ({
    ...event,
    status        : event.status,
    valuationDate : dayjs(event.valuationDate),
    paymentDate   : dayjs(event.paymentDate),
  }));

  dayJsEvents.filter((event) => event.eventType === EventTypesEnum.Coupon || event.eventType === EventTypesEnum.AutocallAndCoupon)
    .sort((left, right) => dayjs(left.valuationDate).diff(dayjs(right.valuationDate), 'days'))
    .forEach((event, index) => {
      const originalIndex = dayJsEvents.findIndex((e) => e.id === event.id);
      dayJsEvents[originalIndex] = {
        ...event,
        couponTrigger : couponInitialBarrier
          ? Math.max(couponFloor ?? 0, couponInitialBarrier - ((couponStepdown ?? 0) * index))
          : undefined,
      };
    });

  dayJsEvents.filter((event) => event.eventType === EventTypesEnum.Autocall || event.eventType === EventTypesEnum.AutocallAndCoupon)
    .sort((left, right) => dayjs(left.valuationDate).diff(dayjs(right.valuationDate), 'days'))
    .forEach((event, index) => {
      const originalIndex = dayJsEvents.findIndex((e) => e.id === event.id);
      dayJsEvents[originalIndex] = {
        ...event,
        autocallTrigger : autocallInitialBarrier
          ? Math.max(autocallFloor ?? 0, autocallInitialBarrier - ((autocallStepdown ?? 0) * index))
          : undefined,
      };
    });

  if (!hasIssue && issueDate) {
    const isFuture = dayjs(issueDate).isAfter(dayjs());
    dayJsEvents.push({
      id            : Date.now(),
      status        : isFuture ? EventStatusEnum.Future : EventStatusEnum.Past,
      eventType     : EventTypesEnum.Issue,
      paymentDate   : dayjs(issueDate),
      valuationDate : dayjs(issueDate),
      potentialFlow : '',
    });
  }

  if (!hasInitialFixing && initialFixingDate) {
    const isFuture = dayjs(initialFixingDate).isAfter(dayjs());
    dayJsEvents.push({
      id            : Date.now(),
      status        : isFuture ? EventStatusEnum.Future : EventStatusEnum.Past,
      eventType     : EventTypesEnum.InitialFixing,
      paymentDate   : dayjs(initialFixingDate),
      valuationDate : dayjs(initialFixingDate),
      potentialFlow : '',
    });
  }

  if (!hasMaturity && maturityDate) {
    const isFuture = dayjs(maturityDate).isAfter(dayjs());
    dayJsEvents.push({
      id            : Date.now(),
      status        : isFuture ? EventStatusEnum.Future : EventStatusEnum.Past,
      eventType     : EventTypesEnum.Maturity,
      paymentDate   : dayjs(maturityDate),
      valuationDate : dayjs(maturityDate),
      potentialFlow : '',
    });
  }

  if (!hasToday) {
    dayJsEvents.push({
      id            : Date.now(),
      status        : EventStatusEnum.Future,
      eventType     : EventTypesEnum.Today,
      paymentDate   : dayjs(),
      valuationDate : dayjs(),
      potentialFlow : '',
    });
  }

  const result = dayJsEvents.sort((left, right) => dayjs(left.valuationDate).diff(dayjs(right.valuationDate), 'days'));
  setCalendarEvents(result);
};
