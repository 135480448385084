import type { DefaultOptionType } from 'antd/es/select';

import type { CountryRef, CurrencyRef } from '../data/Ref';

const DEFAULT_CURRENCY = 'EUR';

export const parseCurrency = (amount : number, currency : string = DEFAULT_CURRENCY, locale : string = 'en-EN') : string => {
  if (isNaN(amount)) {
    return 'Invalid number';
  }

  return new Intl.NumberFormat(locale, {
    style : 'currency',
    currency,
  }).format(amount);
};

export const createCountryForSelectOptions = (countryList : CountryRef[]) : DefaultOptionType[] => countryList.map((e) => ({
  value : e.id,
  label : e.countryName,
}));

export const sortCurrenciesForSelectOptions = (
  currencies : CurrencyRef[],
  separatorCount : number = 25,
  withPt : boolean = false
) : DefaultOptionType[] => {
  if (currencies.length < 4) {
    return [];
  }
  const firstCurrencies = ['EUR', 'USD', 'GBP', 'CHF'];

  const preparedCurrencies = currencies.filter(
    (currency) => withPt || currency.IsoNomination !== 'Pt'
  ).sort((a, b) => {
    if (firstCurrencies.includes(a.IsoNomination) && firstCurrencies.includes(b.IsoNomination)) {
      return firstCurrencies.indexOf(a.IsoNomination) - firstCurrencies.indexOf(b.IsoNomination);
    }
    if (firstCurrencies.includes(a.IsoNomination)) {
      return -1;
    }
    if (firstCurrencies.includes(b.IsoNomination)) {
      return 1;
    }
    return a.IsoNomination.localeCompare(b.IsoNomination);
  });

  const separator = {
    value    : 'separator',
    label    : '-'.repeat(separatorCount),
    disabled : true,
  };

  const optionsWithoutSeparator = preparedCurrencies.map((currency) => ({
    value : currency.Id,
    label : currency.IsoNomination,
  }));

  const optionsWithSeparator = [...optionsWithoutSeparator.slice(0, 4), separator, ...optionsWithoutSeparator.slice(4)];

  return optionsWithSeparator;
};
