import { Valuation100, Valuation105, Valuation90 } from '../../../../data/ProductType';

import './ProductValuation.scss';

type ProductValuationPropsType = {
  valuationValue    : number | null,
  // eslint-disable-next-line @stylistic/type-annotation-spacing
  prefixText      ? : string,
  // eslint-disable-next-line @stylistic/type-annotation-spacing
  style           ? : React.CSSProperties,
  // eslint-disable-next-line @stylistic/type-annotation-spacing
  fromList        ? : boolean,
};

const mapValuationToClass = [
  {
    threshold : Valuation105,
    color     : 'green_positive',
  },
  {
    threshold : Valuation100,
    color     : 'green_normal',
  },
  {
    threshold : Valuation90,
    color     : 'orange',
  },
];

export const ProductValuation = ({
  valuationValue,
  prefixText,
  style,
  fromList,
} : ProductValuationPropsType) : React.ReactElement => {
  const className = mapValuationToClass.find((c) => c.threshold <= (valuationValue ?? 0))?.color ?? 'red';

  let valuationText;
  if (valuationValue === null) {
    valuationText = 'Not yet';
  } else if (valuationValue === 0) {
    valuationText = 'TBD';
  } else {
    valuationText = `${valuationValue.toFixed(fromList ? 2 : 3)}%`;
  }

  return (
    <label
      className = {`valuation ${className}`}
      style = {style}
    >
      {`${prefixText ?? ''} ${valuationText}`}
    </label>
  );
};
