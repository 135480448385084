import React from 'react';

import {
  Button,
  Flex, Form, Select,
  Space,
  Switch
} from 'antd';
import type { FormInstance } from 'antd/lib';

import { FieldsNameEnum } from '../../../../data/enums/pricing';
import { dynamicOrange } from '../../../../styles/colors';

import type { PricingFields } from '../../../../data/enums/pricing';

type SubmitPricingFormProps = {
  form      : FormInstance<PricingFields>,
  isLoading : boolean,
};

export const SubmitPricingForm = ({
  form, isLoading,
} : SubmitPricingFormProps)
: React.ReactElement => {
  const realSendValue = Form.useWatch<boolean>(FieldsNameEnum.realSend, form);
  return (
    <Flex
      className = {'cardContent'}
      justify = {'space-around'}
      style = {{
        flexWrap  : 'wrap',
        columnGap : 50,
      }}
    >
      <Form.Item
        name = {FieldsNameEnum.ccMail}
        label = {<span className = {'label white'}>CC Mail</span>}
        style = {{ flexGrow : 1 }}
      >
        <Select
          allowClear
          showSearch
          className = {'fieldColor'}
          size = {'middle'}
          mode = {'multiple'}
          optionFilterProp = {'children'}
          placeholder = {'None'}
          popupMatchSelectWidth = {false}
        >
          <Select.Option value = {'arthur.teixeira@aydofinance.com'}>arthur.teixeira@aydofinance.com</Select.Option>

          <Select.Option value = {'pierre-yves.breton@aydofinance.com'}>pierre-yves.breton@aydofinance.com</Select.Option>

          <Select.Option value = {'jordan.sfez@aydofinance.com'}>jordan.sfez@aydofinance.com</Select.Option>

          <Select.Option value = {'axel.legros@aydofinance.com'}>axel.legros@aydofinance.com</Select.Option>

          <Select.Option value = {'elliot.spadone@aydofinance.com'}>elliot.spadone@aydofinance.com</Select.Option>

          <Select.Option value = {'justine.signouret@aydofinance.com'}>justine.signouret@aydofinance.com</Select.Option>

          <Select.Option value = {'paul.greco@aydofinance.com'}>paul.greco@aydofinance.com</Select.Option>

          <Select.Option value = {'amaury.ducote@aydofinance.com'}>amaury.ducote@aydofinance.com</Select.Option>

          <Select.Option value = {'melissa.bassien@aydofinance.com'}>melissa.bassien@aydofinance.com</Select.Option>

          <Select.Option value = {'lilou.thalamot@aydofinance.com'}>lilou.thalamot@aydofinance.com</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name = {FieldsNameEnum.realSend}
        label = {<span className = {'label white'}>Real send</span>}
        valuePropName = {'checked'}
      >
        <Switch style = {{ backgroundColor : realSendValue ? dynamicOrange : 'grey' }} />
      </Form.Item>

      <Form.Item>
        <Space className = {'submit-button-wrapper'}>
          <Button
            type = {'primary'}
            htmlType = {'submit'}
            loading = {isLoading}
            style = {{
              backgroundColor : dynamicOrange,
              width           : '100px',
              height          : '30px',
              fontWeight      : 'bold',
              fontSize        : '16px',
            }}
          >
            {isLoading ? 'Sending' : 'Price'}
          </Button>

          <Button htmlType = {'reset'}>Reset</Button>
        </Space>
      </Form.Item>
    </Flex>
  );
};
