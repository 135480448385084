import React from 'react';

import type { FormInstance } from 'antd';
import {
  Flex, Form, Input, InputNumber, Select,
  Space
} from 'antd';

import { UnderlyingEquityForm } from '../../../../Shared/AddUnderlyingsForm/AddEquityLikeForm/UnderlyingEquityForm';
import { FieldsNameEnum, SolveForEnum } from '../../../../data/enums/pricing';
import { BasketTypeEnum } from '../../../../data/product/productEnum';
import { sortCurrenciesForSelectOptions } from '../../../../utils/currencyUtils';
import { notionalFormatter } from '../../../../utils/stringUtils';

import type { UnderlyingEquity } from '../../../../data/ProductType';
import type { CurrencyRef } from '../../../../data/Ref';
import type { PricingFields } from '../../../../data/enums/pricing';
type GeneralPricingFormProps = {
  form                 : FormInstance<PricingFields>,
  underlyingsEquity    : UnderlyingEquity[],
  setUnderlyingsEquity : (list : UnderlyingEquity[])=> void,
  currencyRefList      : CurrencyRef[],

  pricingId ? : string,
};

export const GeneralPricingForm = ({
  form, pricingId, underlyingsEquity, setUnderlyingsEquity, currencyRefList,
} : GeneralPricingFormProps)
: React.ReactElement => {
  const solveForValue = Form.useWatch<SolveForEnum>(FieldsNameEnum.solveFor, form);

  let savedReofferValue = 100;
  let savedCouponValue = 3;
  let savedAutocallValue = 100;
  let savedProtectionValue = 70;

  return (
    <div className = {'cardWrapper goldenYellowWrapper'}>
      <div className = {'card'}>
        <div className = {'cardHeader goldenYellow'}>
          <h2>General</h2>
        </div>

        <Flex vertical className = {'cardContent'}>
          <Form.Item
            name = {FieldsNameEnum.marketingName}
            label = {<span className = {'label white'}>Name</span>}
          >

            <Input
              className = {'fieldBgColor pricer-field'}
              placeholder = {'Marketing Name'}
            />

          </Form.Item>

          <Form.Item
            name = {FieldsNameEnum.solveFor}
            label = {<span className = {'label white'}>Solve for</span>}
          >
            <Select
              onSelect = {() : void => {
                form.setFieldValue(FieldsNameEnum.reoffer, savedReofferValue);
                form.setFieldValue([FieldsNameEnum.couponValue], savedCouponValue);
                form.setFieldValue([FieldsNameEnum.autocallTrigger], savedAutocallValue);
                form.setFieldValue(FieldsNameEnum.barrierLevel, savedProtectionValue);
                switch (form.getFieldValue(FieldsNameEnum.solveFor)) {
                  case 'reoffer':
                    savedReofferValue = form.getFieldValue(FieldsNameEnum.reoffer) as number;
                    form.setFieldValue(FieldsNameEnum.reoffer, null);
                    break;
                  case 'coupon':
                    savedCouponValue = form.getFieldValue([FieldsNameEnum.couponValue]) as number;
                    form.setFieldValue([FieldsNameEnum.couponValue], null);
                    break;
                  case 'autocall':
                    savedAutocallValue = form.getFieldValue([FieldsNameEnum.autocallTrigger]) as number;
                    form.setFieldValue([FieldsNameEnum.autocallTrigger], null);
                    break;
                  case 'protection':
                    savedProtectionValue = form.getFieldValue(FieldsNameEnum.barrierLevel) as number;
                    form.setFieldValue(FieldsNameEnum.barrierLevel, null);
                    break;
                  default:
                }
              }}
            >
              <Select.Option value = {SolveForEnum.reoffer}>Reoffer</Select.Option>

              <Select.Option value = {SolveForEnum.coupon}>Coupon</Select.Option>

              <Select.Option value = {SolveForEnum.autocall}>Autocall</Select.Option>

              <Select.Option value = {SolveForEnum.protection}>Protection</Select.Option>
            </Select>
          </Form.Item>

          <Flex
            justify = {'space-between'}
            style = {{ flexDirection : 'column' }}
          >
            <span className = {'label white'}>Notional</span>

            <Space.Compact>
              <Form.Item
                style = {{ flex : 1 }}
                name = {FieldsNameEnum.notional}
                rules = {[
                  {
                    required : true,
                    message  : 'Please input notional amount',
                  },
                ]}
              >
                <InputNumber
                  placeholder = {'10000'}
                  className = {'fieldBgColor pricer-field'}
                  controls = {false}
                  min = {10000}
                  step = {5000}
                  formatter = {notionalFormatter}
                />
              </Form.Item>

              <Form.Item name = {FieldsNameEnum.currencyRefId}>
                <Select
                  showSearch
                  className = {'pricer-field'}
                  options = {sortCurrenciesForSelectOptions(currencyRefList, 4)}
                  placeholder = {'Currency'}
                />
              </Form.Item>
            </Space.Compact>
          </Flex>

          <Form.Item
            name = {FieldsNameEnum.reoffer}
            label = {<span className = {'label white'}>Reoffer</span>}
            layout = {'horizontal'}
            rules = {[
              {
                required : solveForValue !== SolveForEnum.reoffer,
                message  : 'Please input reoffer',
              },
            ]}
          >
            <InputNumber
              className = {'fieldBgColor pricer-field'}
              controls = {false}
              suffix = {'%'}
              disabled = {solveForValue === SolveForEnum.reoffer || Boolean(pricingId)}
              min = {1}
            />
          </Form.Item>

          <Form.Item
            name = {FieldsNameEnum.maturity}
            label = {<span className = {'label white'}>Maturity</span>}
            rules = {[
              {
                required : true,
                message  : 'Please input maturity',
              },
            ]}
          >
            <InputNumber
              className = {'fieldBgColor pricer-field'}
              controls = {false}
              suffix = {'Months'}
              min = {1}
            />
          </Form.Item>

          <Flex vertical>
            <span>Underlyings</span>

            <UnderlyingEquityForm
              underlyingList = {underlyingsEquity}
              onAddUnderlying = {(list) : void => {
                if (list.length > 1) {
                  form.setFieldValue(FieldsNameEnum.underlyingBasketType, BasketTypeEnum.WorstOf);
                } else {
                  form.setFieldValue(FieldsNameEnum.underlyingBasketType, BasketTypeEnum.SingleStock);
                }
                setUnderlyingsEquity(list);
              }}
            />
          </Flex>

          <Form.Item
            name = {FieldsNameEnum.underlyingBasketType}
            label = {<span className = {'label white'}>Basket type</span>}
          >
            <Select disabled>
              {Object.values(BasketTypeEnum).map((e, i) : React.JSX.Element => (
                <Select.Option key = {i} value = {e}>
                  {e}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Flex>
      </div>
    </div>
  );
};
