import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {
  Avatar,
  Flex, Menu
} from 'antd';

import i18next from 'i18next';

import LocaleSwitcher from '../../../Shared/Translation/LocalSwitcher';
import { keycloakInstance } from '../../../config/keycloak';

export const UserMenu = () : React.ReactElement => {
  const navigate = useNavigate();
  type MenuItem = Required<MenuProps>['items'][number];

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>(i18next.language);

  const items : MenuItem[] = [
    {
      key     : 'profilePage',
      label   : 'Profil utilisateur',
      onClick : () : void => {
        setShowMenu(false);
        navigate('/user');
      },
    },
    {
      type : 'divider',
    },
    {
      key     : 'logout',
      label   : 'Logout',
      onClick : () : void => {
        keycloakInstance.logout().catch(() : void => {
          console.error('logout error');
        });
      },
    },
    {
      type : 'divider',
    },
  ];

  return (
    <Flex
      justify = {'end'}
      align = {'center'}
      gap = {'middle'}
      style = {{ flexDirection : 'column' }}
    >

      <Avatar
        size = {'large'}
        icon = {<UserOutlined />}
        style = {{ cursor : 'pointer' }}
        onClick = {() : void => setShowMenu(!showMenu)}
      />

      <Menu
        items = {items}
        style = {{
          visibility : showMenu ? 'visible' : 'hidden',
          position   : 'absolute',
          top        : '65px',
          zIndex     : 1000,
        }}
      />
    </Flex>
  );
};
