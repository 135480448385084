import React from 'react';

import { DownOutlined } from '@ant-design/icons';
import { ConfigProvider, Select } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';

type CustomSelectPropsType = {
  options         : DefaultOptionType[],
  label           : string,
  onChange        : (seletedValue : string)=> void,
  defaultValue ?: string,
  selectedValue ?: string,
};

const ProductFilterSelect = ({
  options, label, onChange, defaultValue, selectedValue,
} : CustomSelectPropsType) : React.ReactElement => {
  const labelRender = () : React.ReactNode => (
    <label style = {{
      fontSize : '2rem',
      color    : 'white',
      cursor   : 'pointer',
    }}
    >{label}
    </label>
  );

  return (
    <ConfigProvider
      theme = {{
        components : {
          Select : {
            selectorBg                : 'transparent',
            optionActiveBg            : 'rgba(255,255,255,0.3)',
            optionSelectedColor       : 'white',
            optionSelectedBg          : 'rgba(255,255,255,0.3)',
            colorText                 : 'white',
            optionFontSize            : 17,
            optionPadding             : 10,
            showArrowPaddingInlineEnd : 30,
          },
        },
      }}
    >
      <Select
        labelRender = {labelRender}
        size = {'middle'}
        variant = {'borderless'}
        defaultValue = {defaultValue}
        value = {selectedValue}
        popupMatchSelectWidth = {false}
        listHeight = {500}
        options = {options}
        style = {{
          margin    : 'unset',
          width     : 'fit-content',
          flexBasis : '320px',
        }}
        suffixIcon = {(
          <DownOutlined style = {{
            color    : 'white',
            fontSize : 18,
          }}
          />
        )}
        dropdownStyle = {{
          backgroundColor : 'rgb(0,37,45)',
          border          : '2px solid white',
          color           : 'white',
        }}
        onChange = {onChange}
      />
    </ConfigProvider>
  );
};

export { ProductFilterSelect as CustomSelect };
