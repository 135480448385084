import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { TableColumnsType } from 'antd';
import { Button } from 'antd';
import { message } from 'antd/lib';

import { CustomTable } from '../../../../Shared/CustomTable/CustomTable';
import { myFetch } from '../../../../config/api';
import { MESSAGE_API_DURATION } from '../../../../config/config';

import type { UnderlyingListItem, UnderlyingListReturnBody } from '../../../../data/administration/underlying';

const INITIAL_LIMIT = 20;

export const UnderlyingsList = () : React.ReactElement => {
  const [messageApi, contextHolder] = message.useMessage({ duration : MESSAGE_API_DURATION });
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [underlyingList, setUnderlyingList] = useState<UnderlyingListItem[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const navigate = useNavigate();

  const getData = (pageSize : number, pageNumber : number, toSearch ?: string) : void => {
    setIsLoading(true);
    myFetch<UnderlyingListReturnBody>('GET', '/underlyings/list', {
      body  : null,
      query : {
        offset : (pageNumber - 1) * pageSize,
        limit  : pageSize,
        toSearch,
      },
    })
      .then((response) => {
        setTotalCount(response.totalCount);
        setUnderlyingList(response.underlyingList);
      })
      .catch((e : unknown) => {
        messageApi.error(`Error while fetching underlying : ${e}`);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData(INITIAL_LIMIT, 1);
  }, []);

  const columns : TableColumnsType<UnderlyingListItem> = [
    {
      title     : 'Isin',
      dataIndex : 'isin',
      key       : 'isin',
    },
    {
      title     : 'Ticker',
      dataIndex : 'ticker',
      key       : 'ticker',
    },
    {
      title     : 'Company name',
      dataIndex : 'companyName',
      key       : 'companyName',
    },
    {
      title  : 'Action',
      key    : 'action',
      render : (_, e) : React.ReactElement => (
        <div style = {{
          display : 'flex',
          gap     : 10,
        }}
        >
          <Button
            onClick = {() : void => navigate(`/underlying/${e.isin}`)}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      {contextHolder}

      <CustomTable
        isLoading = {isLoading}
        dataList = {underlyingList}
        columns = {columns}
        actions = {[
          {
            title   : 'Add underlying',
            onClick : () : void => navigate('/underlying/new'),
          },
        ]}
        search = {
          {
            searchValue,
            onSearch : (value : string) : void => {
              setSearchValue(value);
              getData(INITIAL_LIMIT, 1, value);
            },
          }
        }
        pagination = {{
          defaultPageSize : INITIAL_LIMIT,
          total           : totalCount,
          onChange        : (page : number, pageSize : number) : void => {
            getData(pageSize, page);
          },
        }}
      />
    </>
  );
};
