import React, { useContext, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { ReadyState, useEventSource } from 'react-use-websocket';

import {
  CaretDownOutlined, CaretUpOutlined
} from '@ant-design/icons';
import { Spin, Table } from 'antd';
import type { TableProps } from 'antd';

import capitalize from 'lodash/capitalize';

import { CONFIG_BACKEND_SERVER_URL } from '../../../config/config';
import { GlobalContext } from '../../../context/GlobalContext';
import { issuerRefToIssuer } from '../../../data/Issuers';
import { QuoteStatusEnum, SolveForEnum } from '../../../data/enums/pricing';

import type { Issuer } from '../../../data/Issuers';
import type { GetPriceMessage } from '../../../data/pricing/GetPriceMessageBody';
import type { PriceResultLine } from '../../../data/pricing/PriceResultLine';

import './../__styles__/styles.scss';

type PricingResultProps = {
  solveFor              : SolveForEnum,
  numberOfCouponPerYear : number,
};

export const PricingResult = () : React.JSX.Element => {
  let priceResultBody : GetPriceMessage;
  let result : PriceResultLine | undefined;

  const globalContext = useContext(GlobalContext);
  if (!globalContext) {
    throw new Error('You probably forgot to put <GlobalProvider>.');
  }
  const { allRefData } = globalContext;
  const issuersList : Issuer[] = allRefData ? allRefData.IssuerRefList.map((e) => issuerRefToIssuer(e)) : [];

  const { pricingId } = useParams();
  const context : PricingResultProps = useOutletContext();
  const {
    lastEvent, readyState,
  } = useEventSource(`${CONFIG_BACKEND_SERVER_URL}/price/${pricingId}`);
  const [sortedResultList, setSortedResultList] = useState<PriceResultLine[]>([]);

  let frequencySymbol = '';

  switch (context.numberOfCouponPerYear) {
    case 12:
      frequencySymbol = 'm';
      break;
    case 4:
      frequencySymbol = 'q';
      break;
    case 2:
      frequencySymbol = 's';
      break;
    case 1:
      frequencySymbol = 'a';
      break;
    default:
  }

  useEffect(() : void => {
    if (readyState === ReadyState.CLOSED) {
      sortedResultList.forEach((e) => {
        if (e.quoteStatus === QuoteStatusEnum.inProgress) {
          e.quoteStatus = QuoteStatusEnum.failed;
        }
      });
    }

    if (lastEvent) {
      priceResultBody = JSON.parse(lastEvent.data as string) as GetPriceMessage;

      if (priceResultBody.issuerId === null || isNaN(priceResultBody.issuerId) || priceResultBody.issuerId === 0) {
        return;
      }

      let displayResult = ' ';

      if (priceResultBody.result) {
        if (context.solveFor === SolveForEnum.coupon) {
          displayResult = `${(priceResultBody.result / context.numberOfCouponPerYear).toFixed(4)}% p.${frequencySymbol}.`;
          if (context.numberOfCouponPerYear !== 1) {
            displayResult = `${displayResult} (${priceResultBody.result.toFixed(2)}% p.a.)`;
          }
        } else {
          displayResult = `${priceResultBody.result.toFixed(2)}%`;
        }
      }

      const newResultLine : PriceResultLine = {
        quoteId     : priceResultBody.quoteId,
        result      : priceResultBody.result,
        displayResult,
        issuerId    : priceResultBody.issuerId,
        error       : priceResultBody.error,
        quoteStatus : priceResultBody.quoteStatus,
      };

      result = sortedResultList.find((e) : boolean => e.issuerId === priceResultBody.issuerId);

      if (result) {
        const idx = sortedResultList.indexOf(result);
        sortedResultList[idx] = newResultLine;
      } else {
        sortedResultList.push(newResultLine);
      }

      sortedResultList.sort((l, r) : number => {
        if ((l.quoteStatus === QuoteStatusEnum.inProgress && r.quoteStatus === QuoteStatusEnum.inProgress)
          || (l.quoteStatus === QuoteStatusEnum.failed && r.quoteStatus === QuoteStatusEnum.failed)) {
          return 0;
        }
        if (l.quoteStatus === QuoteStatusEnum.quoted && r.quoteStatus === QuoteStatusEnum.quoted
          && l.result !== null && r.result !== null) {
          if (context.solveFor === SolveForEnum.reoffer) {
            return l.result - r.result;
          }
          return r.result - l.result;
        }
        if (l.quoteStatus === QuoteStatusEnum.failed) {
          return 1;
        }
        if (r.quoteStatus === QuoteStatusEnum.failed) {
          return -1;
        }
        if (l.quoteStatus === QuoteStatusEnum.inProgress && r.quoteStatus === QuoteStatusEnum.quoted) {
          return 1;
        }
        if (r.quoteStatus === QuoteStatusEnum.inProgress && l.quoteStatus === QuoteStatusEnum.quoted) {
          return -1;
        }
        return 0;
      });
    }

    setSortedResultList(sortedResultList);
  }, [lastEvent, readyState, context]);

  const issuersColumn : TableProps<PriceResultLine>['columns'] = [
    {
      dataIndex : 'logo',
      key       : 'logo',
      render    : (_, row) : React.JSX.Element => {
        const issuer = issuersList.find((e) => e.id === row.issuerId);
        return <img className = {'logoIssuers'} src = {issuer?.logo} alt = {issuer?.name} />;
      },
    },
    {
      title     : () : React.JSX.Element => <span className = {'tableLabel'}>{capitalize(context.solveFor)}</span>,
      dataIndex : 'price',
      key       : 'price',
      render    : (_, row) : React.JSX.Element => {
        switch (row.quoteStatus) {
          case QuoteStatusEnum.inProgress:
            return <Spin spinning />;
          case QuoteStatusEnum.quoted:
            return <span className = {'tableLabel'}>{row.displayResult}</span>;
          case QuoteStatusEnum.failed:
            return <span className = {'tableLabel'}>Unable to price</span>;
          default:
            return <span>Unexpected status</span>;
        }
      },
      align : 'center',
    },
    {
      title     : () : React.JSX.Element => <span className = {'tableLabel'}>Quote Status</span>,
      dataIndex : 'quoteStatus',
      key       : 'quoteStatus',
      render    : (e) : React.JSX.Element => <span className = {'tableLabel'}>{e}</span>,
      align     : 'center',
    },
    {
      title     : () : React.JSX.Element => <span className = {'tableLabel'}>Quote Id</span>,
      dataIndex : 'quoteId',
      key       : 'quoteId',
      render    : (e) : React.JSX.Element => <span className = {'tableLabel'}>{e}</span>,
      align     : 'center',
    },
  ];

  return (
    <div className = {'cardWrapperResult dynamicOrangeWrapper'}>
      <div className = {'cardResult'}>
        <div className = {'dynamicOrange cardHeader'}>
          <h2>Issuers</h2>
        </div>

        <div className = {'cardContentResult'}>
          <Table
            columns = {issuersColumn}
            rowKey = {'issuerId'}
            pagination = {false}
            size = {'large'}
            rowClassName = {'rowClassname'}
            dataSource = {sortedResultList.map((elem : PriceResultLine, idx) => {
              if (idx === 0) {
                return {
                  ...elem,
                  displayResult : <span className = {'best-result'}>{elem.displayResult}</span>,
                };
              }
              return elem;
            })}
            scroll = {{
              scrollToFirstRowOnChange : false,
              y                        : '50vh',
            }}
            expandable = {{
              expandedRowRender : (record) : React.JSX.Element => <p style = {{ margin : 0 }}>{record.error}</p>,
              rowExpandable     : (record) : boolean => Boolean(record.error),
              expandIcon        : ({
                expanded, onExpand, record, expandable,
              }) : React.JSX.Element => {
                if (expandable) {
                  if (expanded) {
                    return <CaretUpOutlined onClick = {(e) : void => onExpand(record, e)} />;
                  }
                  return <CaretDownOutlined onClick = {(e) : void => onExpand(record, e)} />;
                }
                return <></>;
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};
