export enum UserRoleEnum {
  Demo = 'Demo',
  ExternalDistributor = 'External Distributor',
  ExternalEndClient = 'External End-Client',
  InternalAdmin = 'Internal Admin',
  SuperAdmin = 'Super Admin',
  InternalSalesman = 'Internal Salesman',
  InternalAssistant = 'Internal Assistant',
  LegalObserver = 'Legal Observer',
  ViewTradeCommission = 'view-trade-commission',
  UsePricingModule = 'use-pricing-module',
  CanExportTradesList = 'can-export-trades-list',
}
