import React, { useContext, useEffect, useState } from 'react';

import type { TableColumnProps } from 'antd';
import { message } from 'antd';

import dayjs from 'dayjs';

import { CustomTable } from '../../../Shared/CustomTable/CustomTable';
import { myFetch } from '../../../config/api';
import { MESSAGE_API_DURATION } from '../../../config/config';
import { GlobalContext } from '../../../context/GlobalContext';

import type { ProductOpenForSubscription, ProductsOpenForSubscriptionResponse } from '../../../data/product/productOpenForSubscribtion';

const INITIAL_LIMIT = 20;

export const ProductOpenForSubscriptionList = () : React.ReactElement => {
  const [totalCount, setTotalCount] = useState(0);
  const [list, setList] = useState<ProductOpenForSubscription[]>([]);
  const [messageApi, contextHolder] = message.useMessage({ duration : MESSAGE_API_DURATION });
  const [isLoading, setIsLoading] = useState(false);

  const globalContext = useContext(GlobalContext);
  if (!globalContext) {
    throw new Error('You probably forgot to put <GlobalProvider>.');
  }
  const { allRefData } = globalContext;

  const getData = (pageSize : number, pageNumber : number) : void => {
    setIsLoading(true);
    myFetch<ProductsOpenForSubscriptionResponse>('GET', '/products/openForSubscriptionList', {
      body  : null,
      query : {
        limit  : pageSize,
        offset : (pageNumber - 1) * pageSize,
      },
    })
      .then((response : ProductsOpenForSubscriptionResponse) => {
        setTotalCount(response.totalCount);
        setList(response.subcriptionProductList);
      })
      .catch((e : unknown) => {
        messageApi.error(e instanceof Error ? e.message : 'Error while fetching products');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData(INITIAL_LIMIT, 1);
  }, []);

  const columns : TableColumnProps<ProductOpenForSubscription>[] = [
    {
      key       : 'issueDate',
      title     : 'Issue date',
      dataIndex : 'issueDate',
      render    : (renderValue : string) : string => dayjs(renderValue).format('DD/MM/YYYY'),
    },
    {
      key       : 'name',
      title     : 'Name',
      dataIndex : 'name',
    },
    {
      key       : 'isin',
      title     : 'ISIN',
      dataIndex : 'isin',
    },
    {
      key       : 'productType',
      title     : 'Product type',
      dataIndex : 'productType',
    },
    {
      key       : 'issuerId',
      title     : 'Issuer',
      dataIndex : 'issuerId',
      render    : (value : number) : React.ReactElement => <span>{allRefData?.IssuerRefList.find((e) => e.id === value)?.fullName}</span>,
    },
  ];

  return (
    <>
      {contextHolder}

      <CustomTable
        columns = {columns}
        loading = {isLoading}
        dataList = {list}
        pagination = {{
          position        : ['bottomCenter'],
          defaultPageSize : INITIAL_LIMIT,
          total           : totalCount,
          showTotal       : (total : number, range : [number, number]) : string => `${range[0]}-${range[1]} of ${total} items`,
          onChange        : (pageNumber : number, pageSize : number) : void => {
            getData(pageSize, pageNumber);
          },
        }}
        onRow = {(record) => ({
          onClick : () : void => {
            window.open(`/products/${record.id.toString()}`, '_blank');
          },
        })}
      />
    </>
  );
};
