import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { productsSlice } from './productsStore/productSlice';
import { api } from './refStore/refStore';
import { tradesSlice } from './tradesStore/tradeSlice';

const rootReducer = combineReducers({
  productsReducer   : productsSlice.reducer,
  tradesReducer     : tradesSlice.reducer,
  [api.reducerPath] : api.reducer,
});

export const store = configureStore({
  reducer    : rootReducer,
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  middleware : (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export type RootStateType = ReturnType<typeof store.getState>;

export type RootReducerType = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export default store;
