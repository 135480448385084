import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { TableProps } from 'antd';
import type { SearchProps } from 'antd/es/input';

import { CustomTable } from '../../../Shared/CustomTable/CustomTable';
import { myFetch } from '../../../config/api';

import type { SearchClientItem, SearchClientResult } from '../../../data/administration/client';

export const ClientList = () : React.ReactElement => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [clientList, setClientList] = useState<SearchClientItem[]>([]);
  const navigate = useNavigate();

  const columns : TableProps<SearchClientItem>['columns'] = [
    {
      dataIndex : 'name',
      title     : 'Name',
      key       : 'name',
      render    : (e) : React.ReactElement => <span className = {'tableLabel'}>{e}</span>,
    },
  ];

  const onSearch : SearchProps['onSearch'] = (searchText) : void => {
    setSearchValue(searchText);
    if (searchText.length > 1) {
      myFetch<SearchClientResult>('GET', '/client/all', {
        body  : null,
        query : { toSearch : searchText },
      }).then((data) => {
        setClientList(data.clientList);
      })
        .catch((error : unknown) => {
          console.error(error);
        });
    }
  };

  return (
    <div>
      <CustomTable<SearchClientItem>
        dataList = {clientList}
        columns = {columns}
        rowKey = {(e) : number => e.id}
        pagination = {false}
        search = {
          {
            onSearch,
            searchValue,
          }
        }
        onRow = {(e) : { onClick : ()=> void } => ({
          onClick : () : void => {
            navigate(`/client/${e.id}`);
          },
        })}
      />
    </div>
  );
};
