import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CaretDownOutlined, CaretUpOutlined
} from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import {
  Button, Checkbox, Col, Flex, Modal, Row, Select, Table,
  message
} from 'antd';

import dayjs from 'dayjs';

import { ClipboardCopy } from '../../../Shared/ClipboardCopy/ClipboardCopy';
import { CustomTable } from '../../../Shared/CustomTable/CustomTable';
import { myFetch } from '../../../config/api';
import { MESSAGE_API_DURATION } from '../../../config/config';
import { haveOneOfTheRoles, keycloakInstance } from '../../../config/keycloak';
import { GlobalContext } from '../../../context/GlobalContext';
import { UserRoleEnum } from '../../../data/enums/cloak';
import { TradeStatusClass } from '../../../data/product/productEnum';
import { fetchTradesList } from '../../../store/tradesStore/tradeApi';
import { setTradeListPageViewState } from '../../../store/tradesStore/tradeSlice';
import { parseCurrency } from '../../../utils/currencyUtils';
import { downloadFile } from '../../../utils/fileDownload';

import type { CustomTableAction } from '../../../Shared/CustomTable/CustomTable';
import type { CustodianEntityType, TradeListItemType } from '../../../data/TradesTypes';
import type { AppDispatch, RootStateType } from '../../../store/store';
import type { TradeListPageViewType } from '../../../store/tradesStore/tradesStoreTypes';

import './TradesList.scss';

const INITIAL_LIMIT = 60;

const TradeList = () : React.ReactElement => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage({ duration : MESSAGE_API_DURATION });

  const globalContext = useContext(GlobalContext);
  if (!globalContext) {
    throw new Error('You probably forgot to put <GlobalProvider>.');
  }
  const { allRefData } = globalContext;

  const dispatch = useDispatch<AppDispatch>();
  const tradesItems = useSelector((state : RootStateType) => state.tradesReducer.trades);
  const tradeViewState = useSelector((state : RootStateType) => state.tradesReducer.tradeListPageView);

  const {
    isLoading, totalCount, list: tradesList,
  } = tradesItems;

  const userHasCommissionAccess = haveOneOfTheRoles([
    UserRoleEnum.SuperAdmin,
    UserRoleEnum.InternalAdmin,
    UserRoleEnum.InternalSalesman,
    UserRoleEnum.ViewTradeCommission,
  ]);
  const userHasStatusEditAccess = haveOneOfTheRoles([
    UserRoleEnum.InternalSalesman,
    UserRoleEnum.InternalAdmin,
    UserRoleEnum.SuperAdmin,
  ]);
  const canExportTradeList = haveOneOfTheRoles([UserRoleEnum.SuperAdmin, UserRoleEnum.CanExportTradesList]);

  const getTrades = (pageNumber : number, pageSize : number, toSearch ?: string, orderBy ?: string) : void => {
    const offset = (pageNumber - 1) * pageSize;
    void dispatch(fetchTradesList({
      offset,
      limit : pageSize,
      orderBy,
      toSearch,
    }));
  };
  const setViewState = (viewState : Partial<TradeListPageViewType>) : void => {
    dispatch(setTradeListPageViewState({
      ...tradeViewState,
      ...viewState,
    }));
  };

  useEffect(() => {
    setViewState({
      pageNumber : 1,
      limit      : INITIAL_LIMIT,
    });
    getTrades(1, INITIAL_LIMIT);
  }, []);

  const columns : TableColumnsType<TradeListItemType> = [
    {
      title     : 'ISIN',
      dataIndex : 'isin',
      width     : 120,
      render    : (renderValue : string) : React.ReactNode => (renderValue ? <ClipboardCopy text = {renderValue} /> : 'To Be Determined'),
    },
    {
      title     : 'Product Name',
      dataIndex : 'productName',
      width     : 320,
      sorter    : (left, right) : number => left.productName.localeCompare(right.productName),
    },
    {
      title     : 'Transaction Date',
      dataIndex : 'settlementDate',
      align     : 'center',
      width     : 180,
      sorter    : (left, right) : number => new Date(left.settlementDate ?? '').getTime() - new Date(right.settlementDate ?? '').getTime(),
      render    : (renderValue) : string => (renderValue ? dayjs(renderValue as string).format('DD/MM/YYYY') : '-'),
    },
    {
      title     : 'Issue Date',
      dataIndex : 'issueDate',
      align     : 'center',
      width     : 140,
      sorter    : (left, right) : number => new Date(left.issueDate ?? '').getTime() - new Date(right.issueDate ?? '').getTime(),
      render    : (renderValue) : string => (renderValue ? dayjs(renderValue as string).format('DD/MM/YYYY') : '-'),
    },
    {
      title     : 'Notional',
      dataIndex : 'notional',
      align     : 'center',
      width     : 170,
      sorter    : (left, right) : number => left.notional - right.notional,
      render    : (renderValue : number, record) : string => parseCurrency(renderValue, record.currency),
    },
    {
      title     : 'Client',
      dataIndex : 'clientName',
      width     : 200,
      sorter    : (left, right) : number => left.clientName.localeCompare(right.clientName),
    },
    {
      title     : 'Trading Entity',
      dataIndex : 'tradingEntity',
      width     : 200,
      sorter    : true,
      filters   : (allRefData?.TradingEntitiesList ?? []).map((t) => ({
        text  : t.fullName,
        value : t.fullName,
      })),
      onFilter     : (filterValue, record) : boolean => record.tradingEntity.startsWith(filterValue as string),
      filterSearch : true,
    },
    {
      title     : 'Total Commission',
      dataIndex : 'totalCommission',
      align     : 'center',
      width     : 150,
      hidden    : !userHasCommissionAccess,
      render    : (_, record) : string => {
        const totalCommission = (((record.buyingPrice - record.sellingPrice - (record.bridgeCommission ?? 0)) * record.notional)) / 100;
        return parseCurrency(totalCommission, record.currency);
      },
    },
    {
      title     : 'Aydo Commission',
      dataIndex : 'aydoCommision',
      align     : 'center',
      width     : 150,
      hidden    : !userHasCommissionAccess,
      render    : (_, record) : string => {
        const totalCommission = (((record.buyingPrice - record.sellingPrice - (record.bridgeCommission ?? 0)) * record.notional)) / 100;
        const otherCommission = ((record.clientCommission + (record.businessProviderCommission ?? 0)) * record.notional) / 100;
        const aydoCommision = totalCommission - otherCommission;
        return parseCurrency(aydoCommision, record.currency);
      },
    },
    {
      title     : 'Status',
      dataIndex : 'status',
      align     : 'center',
      width     : 140,
      render    : (valueValue : string, record) : React.ReactNode => (
        <Select
          style = {{ width : 120 }}
          value = {valueValue}
          disabled = {!userHasStatusEditAccess}
          className = {`status__select ${TradeStatusClass[record.status as keyof typeof TradeStatusClass]}`.trim()}
          options = {Object.keys(TradeStatusClass).map((t) => ({
            title : t,
            value : t,
          }))}
          onClick = {(e) : void => e.stopPropagation()}
        />
      ),
      filters : Object.keys(TradeStatusClass).map((t) => ({
        text  : t,
        value : t,
      })),
      onFilter : (filterValue, record) : boolean => record.status.includes(filterValue as string),
    },
  ];

  const [tableColumns, setTableColumns] = useState(columns);

  const exportExcel = () : void => {
    myFetch('GET', '/trades/export', {
      body  : null,
      query : null,
    }).then((response) => {
      const name = `report-${dayjs().format('YYYY-MM-DD')}.xlsx`;
      downloadFile(response as Blob, name);
    })
      .catch((e : unknown) : void => {
        if (e instanceof Error) {
          messageApi.open({
            type    : 'error',
            content : `Failed to download the file: ${e.message}`,
          });
        }
      });
  };

  const hideOrShowColumns = (checked : boolean, columnTitle : string) : void => {
    if (checked) {
      const columnToAdd = columns.find((col) => col.title === columnTitle);
      if (columnToAdd) {
        setTableColumns((prevColumns) => [...prevColumns, columnToAdd]);
      }
    } else {
      setTableColumns((prevColumns) => prevColumns.filter((col) => col.title !== columnTitle));
    }
  };

  const getActions = () : CustomTableAction[] => {
    if (canExportTradeList) {
      return [
        {
          title   : 'Show/Hide Columns',
          onClick : () : void => setIsModalOpen(true),
          variant : 'primary',
        },
        {
          title   : 'Export',
          onClick : () : void => exportExcel(),
          variant : 'primary',
        },
        {
          title   : 'Add Trade',
          link    : '/trades/new',
          variant : 'primary',
        },
        {
          title   : 'Refresh',
          onClick : () : void => {
            const {
              pageNumber, limit,
            } = tradeViewState;
            getTrades(pageNumber, limit);
          },
          variant : 'primary',
        },
      ];
    }
    return [
      {
        title   : 'Show/Hide Columns',
        onClick : () : void => setIsModalOpen(true),
        variant : 'primary',
      },
      {
        title   : 'Add Trade',
        link    : '/trades/new',
        variant : 'primary',
      },
    ];

  };
  const expandedRowRender = (custodians : CustodianEntityType[], currency : string) : React.ReactNode => {
    const innerColumns : TableColumnsType<CustodianEntityType> = [
      {
        title     : 'Custodian Name',
        dataIndex : 'fullName',
        key       : 'fullName',
      },
      {
        title     : 'Amount',
        dataIndex : 'amount',
        key       : 'amount',
        render    : (val) : string => parseCurrency(val as number, currency),
      },
    ];

    return (
      custodians.length === 0
        ? (
          <Flex justify = {'center'}>
            <p>No custodians</p>
          </Flex>
        )
        : (
          <Table
            columns = {innerColumns}
            rowKey = {(record) : string => record.id.toString()}
            id = {'expanded__table__inner__table--1'}
            className = {'expanded__table'}
            dataSource = {custodians}
            pagination = {false}
            rowClassName = {(_, index) : string => (index % 2 === 0 ? 'inner-table-row-even' : 'inner-table-row-odd')}
          />
        )
    );
  };

  return (
    <>
      {contextHolder}

      <div className = {'trade_list_container'}>
        <CustomTable
          loading = {isLoading}
          dataList = {tradesList}
          columns = {tableColumns}
          rowKey = {(record) : string => record.id.toString()}
          colsTopApplyDateFilter = {['issueDate', 'transactionDate']}
          colsTopApplySeachFilter = {['isin', 'productName', 'clientName']}
          actions = {getActions()}
          pagination = {{
            total            : totalCount,
            hideOnSinglePage : true,
            position         : ['bottomCenter'],
            defaultPageSize  : INITIAL_LIMIT,
            current          : tradeViewState.pageNumber,
            showTotal        : (total, range) : string => `${range[0]}-${range[1]} of ${total} items`,
            onChange         : (pageNumber : number, pageSize : number) : void => {
              setViewState({
                pageNumber,
                limit : pageSize,
              });

              getTrades(pageNumber, pageSize);
            },
          }}
          expandable = {{
            expandedRowRender    : (record) : React.ReactNode => expandedRowRender(record.custodians, record.currency),
            onExpandedRowsChange : (expandedKeys) : void => {
              setViewState({ expandedRowKeys : [...expandedKeys]});
            },
            defaultExpandedRowKeys : tradeViewState.expandedRowKeys,
            expandIcon             : ({
              expanded, onExpand, record,
            }) : React.ReactNode => (
              <Button
                type = {'text'}
                icon = {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                onClick = {(e) : void => {
                  e.stopPropagation();
                  onExpand(record, e);
                }}
              />
            ),
          }}
          search = {{
            searchValue,
            onSearch : (value : string) : void => {
              const {
                pageNumber, limit,
              } = tradeViewState;
              setSearchValue(value);
              getTrades(pageNumber, limit, value);
            },
          }}
          onRow = {(record) => ({
            onClick : () : void => {
              window.open(`/trades/${record.id.toString()}`, '_blank');
            },
          })}
        />
      </div>

      <Modal
        width = {600}
        title = {'Show/Hide Columns'}
        open = {isModalOpen}
        footer = {[
          <Button key = {'reset'} type = {'primary'} onClick = {() : void => setTableColumns(columns)}>
            Reset
          </Button>,
          <Button key = {'back'} type = {'link'} onClick = {() : void => setIsModalOpen(false)}>
            Close
          </Button>,
        ]}
        onCancel = {() : void => setIsModalOpen(false)}
      >
        <Row className = {'trade__list__columns'} gutter = {[16, 16]}>
          {columns.map((c, i) => (
            <Col key = {i} span = {8}>
              <Checkbox
                checked = {Boolean(tableColumns.find((col) => col.title === c.title))}
                onChange = {(e) : void => hideOrShowColumns(e.target.checked, c.title as string)}
              >
                {c.title as string}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export { TradeList };
