import { createAsyncThunk } from '@reduxjs/toolkit';

import { myFetch } from '../../config/api';

import type { SingleProductType } from '../../data/ProductType';
import type { IssuerRef } from '../../data/Ref';

export const getSingleProduct = createAsyncThunk<
  { product : SingleProductType },
  { productId : string },
  { rejectValue : string }
>(
  'products/getSingleProduct',
  async ({ productId } : { productId : string }, { rejectWithValue }) => {
    try {
      const response = await myFetch<SingleProductType>('GET', `/products/${productId}`);

      return { product : response };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getIssuers = createAsyncThunk<
  { issuers : IssuerRef[] },
  void,
  { rejectValue : string }
>(
  'products/getIssuers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await myFetch<IssuerRef[]>('GET', '/issuers');
      return { issuers : response };
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
