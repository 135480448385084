import type {
  BridgeRef, BusinessProviderRef, ClientCompany, CustodianRef, TradingEntityRef
} from './Ref';

export type TradeListItemType = {
  id                         : number,
  isin                       : string,
  productName                : string,
  settlementDate             : Date | null,
  issueDate                  : Date | null,
  notional                   : number,
  buyingPrice                : number,
  sellingPrice               : number,
  clientName                 : string,
  tradingEntity              : string,
  status                     : string,
  currency                   : string,
  clientCommission           : number,
  bridgeCommission           : number | null,
  businessProviderCommission : number | null,
  custodians                 : CustodianEntityType[],
};

export type CustodianEntityType = {
  id            : string,
  fullName      : string,
  amount        : number,
  orderReceived : boolean,
};
export type SingleTradeType = {
  id                            : number,
  productId                     : number,
  isin                          : string | null,
  currency                      : string,
  productName                   : string,
  salesman                      : string,
  salesmanOwnerId               : number,
  currencyRefId                 : number,
  issuerRefId                   : number,
  denomination                  : number,
  issuerFullName                : string,
  isUnwind                      : boolean,
  tradeStatus                   : string,
  clientInvoiceStatus           : string,
  tradeDate                     : string | null,
  settementDate                 : string | null,
  sellingPrice                  : number,
  buyingPrice                   : number,
  notionalAmount                : number,
  clientCommission              : number,
  businessProviderCommission    : number | null,
  bridgeCommission              : number | null,
  signedClientInvoice           : string | null,
  signedTradingEntityInvoice    : string | null,
  businessProviderInvoiceStatus : string,
  signClientTermsheet           : string | null,
  clientCompany                 : ClientCompany,
  tradingEntity                 : TradingEntityRef,
  bridge                        : BridgeRef | null,
  businessProvider              : BusinessProviderRef | null,
  custodians                    : CustodianRef[],
};

export type TradeFormFieldType = {
  isExistingProduct          : boolean,
  isinCode                   : string | null,
  currency                   : string,
  notionalAmount             : number,
  productName                : string,
  denomination               : number,
  owner                      : number,
  issuer                     : number,
  tradeDate                  : string | null,
  issueDate                  : string | null,
  tradingEntity              : number,
  reofferPrice               : number,
  cashAmount                 : number,
  clientCompany              : number,
  clientPrice                : number,
  clientAmount               : number,
  businessProvider           : number | null,
  businessProviderFees       : number | null,
  businessProviderAmount     : number,
  businessProviderCommission : number,
  bridge                     : number | null,
  bridgeFees                 : number | null,
  bridgeAmount               : number,
  totalCommissionAmount      : number,
  clientCommissionFees       : number,
  clientCommissionAmount     : number,
  aydoCommission             : number,
};

export type TradeFormSubmissionData = {
  isExistingProduct : boolean,
  product : {
    productId     : number | null,
    isinCode      : string | null,
    productName   : string,
    denomination  : number,
    issuerRefId   : number,
    currencyRefId : number,
  },
  tradeSalesmanOwnerId          : number,
  tradeStatus                   : string,
  clientInvoiceStatus           : string | null,
  tradeDate                     : string | null,
  settlementDate                : string | null,
  sellingPrice                  : number,
  buyingPrice                   : number,
  notionalAmount                : number,
  clientCompanyId               : number,
  clientCommission              : number,
  businessProviderCommission    : number | null,
  bridgeId                      : number | null,
  bridgeCommission              : number | null,
  tradingEntityRefId            : number | null,
  businessProviderRefId         : number | null,
  businessProviderInvoiceStatus : string | null,
  signedClientTermsheet         : string | null,
  custodians : {
    custodianId   : number | null,
    amount        : number,
    orderReceived : boolean,
  }[],
};

export type ProductSearchResultTrade = {
  productId      : number,
  isin           : string,
  productName    : string,
  issuerId       : number,
  issuerFullName : string,
  currency       : string,
  denomination   : number,
};

export type UserListItemForTrade = {
  id         : number,
  fullName   : string,
  email      : string | null,
  isExternal : boolean,
};

export type TradeListItemForProduct = {
  id                : number,
  settlementDate    : Date | null,
  issueDate         : Date | null,
  notional          : number,
  ownerName         : string,
  clientName        : string,
  outstandingAmount : number,
  custodians        : CustodianEntityType[],
};

export type TradeClientSignedTermsheet = {
  signedClientTermsheet : string,
};

export const tradeStatuses = ['Draft', 'Cancel', 'Booked', 'Order Received', 'Invoice Sent', 'Commission Paid', 'Closed'];
export const invoicesChecklist = ['Pending', 'Invoice Sent', 'Invoice Received', 'Commission Paid'];
