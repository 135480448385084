import React, { useEffect, useState } from 'react';

import {
  Button, Flex, Input, Select
} from 'antd';

type CustomDropdownInputType = {
  items            : { label : string,
    value                    : string | number, }[],
  onChange          : (value : string)=> void,
  currentItem       : string,
  width           ?: number,
  placeholder     ?: string,
  showCustomInput ?: boolean,
  disabled        ?: boolean,
  CustomFooter    ?: React.ReactNode,
};

const CustomDropdownInput = ({
  items,
  onChange,
  currentItem,
  width,
  placeholder,
  showCustomInput,
  disabled,
  CustomFooter,
} : CustomDropdownInputType) : React.ReactElement => {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [customValue, setCustomValue] = useState<string>('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelectedValue(currentItem);
  }, [currentItem]);

  return (
    <Select
      showSearch
      value = {selectedValue || null}
      style = {{ width : width ?? 100 }}
      placeholder = {placeholder}
      open = {open}
      disabled = {disabled}
      options = {items.map((item) => ({
        label : item.label,
        value : item.value,
      }))}
      filterOption = {(input, option) : boolean => {
        const label = option?.label ?? '';
        return Boolean(label.toLowerCase().includes(input.toLowerCase()));
      }}
      dropdownRender = {(menu) : React.ReactElement => (
        <>
          {menu}

          {CustomFooter && <div>{CustomFooter}</div>}

          {showCustomInput && (
            <Flex
              vertical
              style = {{
                padding : '0 4px',
                margin  : '8px 0',
              }}
            >
              <Input
                style = {{ marginBottom : 4 }}
                placeholder = {'Value..'}
                value = {customValue}
                onChange = {(e) : void => setCustomValue(e.target.value)}
                onKeyDown = {(e) : void => e.stopPropagation()}
              />

              <Button
                type = {'primary'}
                onClick = {() : void => {
                  onChange(customValue);
                  setCustomValue('');
                  setOpen(false);
                }}
              >
                Set
              </Button>
            </Flex>
          )}
        </>
      )}
      onDropdownVisibleChange = {(visible) : void => setOpen(visible)}
      onChange = {onChange}
    />
  );
};

export default CustomDropdownInput;
