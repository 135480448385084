import React, { useState } from 'react';

import type { TablePaginationConfig } from 'antd';
import { Card, Flex, Pagination } from 'antd';

type TableCardProps<T> = {
  data       : T[],
  renderItem : (item : T)=> React.ReactNode,
  total      : number,
  pageSize   : number,
  onChange   : (pagination : TablePaginationConfig)=> void,
};

export const TableCard = <T,>({
  data,
  renderItem,
  total,
  pageSize,
  onChange,
} : TableCardProps<T>) : React.ReactElement => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (page : number, size : number) : void => {
    setCurrentPage(page);
    onChange({
      current  : page,
      pageSize : size,
    });
  };

  return (
    <>
      <Flex wrap = {'wrap'} gap = {16} justify = {'space-around'}>
        {data.map((item, index) => (
          <Card key = {index} style = {{ width : 350 }}>
            {renderItem(item)}
          </Card>
        ))}
      </Flex>

      <Flex justify = {'center'} style = {{ marginTop : 20 }}>
        <Pagination
          current = {currentPage}
          pageSize = {pageSize}
          total = {total}
          showSizeChanger = {false}
          onChange = {handleChange}
        />
      </Flex>
    </>
  );
};
