import { createContext, useEffect, useState } from 'react';

import { message } from 'antd';

import { myFetch } from '../config/api';
import { useGetAllRefQuery } from '../store/refStore/refStore';

import type { AllRef } from '../data/Ref';
import type { User } from '../data/administration/user';
import { MESSAGE_API_DURATION } from '../config/config';

export type GlobalContextType = {
  user       : User | null,
  allRefData : AllRef | undefined,
  setUser    : (user : User | null)=> void,
};

export const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const GlobalProvider = ({ children } : { children : React.ReactNode }) : React.JSX.Element => {
  const [user, setUser] = useState<User | null>(null);
  const [messageApi, contextHolder] = message.useMessage({duration: MESSAGE_API_DURATION});

  const allRefData = useGetAllRefQuery('').data as AllRef | undefined;

  useEffect(() => {
    myFetch<User>('GET', '/user').then((response : User) => {
      setUser(response);
    })
      .catch((e : unknown) => {
        if (e instanceof Error) {
          messageApi.error(e.message);
        }
      });
  }, []);

  return (
    <GlobalContext.Provider value = {{
      user,
      allRefData,
      setUser,
    }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
