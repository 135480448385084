import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { haveOneOfTheRoles, keycloakInstance } from '../../config/keycloak';
import { getAllRoutes } from '../../data/Routes';
import { UserRoleEnum } from '../../data/enums/cloak';
import { AccessDeniedPage } from '../../errors/AccessDeniedPage';

type PrivateRoutesProps = {
  children : React.ReactNode,
};

const PrivateRoutes = ({ children } : PrivateRoutesProps) : React.ReactElement => {
  const [isAllowed, setIsAllowed] = React.useState<boolean>(false);
  const [roleIsLoaded, setRoleIsLoaded] = React.useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    keycloakInstance.loadUserProfile()
      .then(() => {
        setRoleIsLoaded(true);
      })
      .catch((error : unknown) => {
        if (error instanceof Error) {
          console.error('Failed to load user profile:', error.message);
        }
      });
  }, []);

  useEffect(
    () => {
      if (haveOneOfTheRoles([UserRoleEnum.SuperAdmin])) {
        setIsAllowed(true);
        return;
      }

      const currentPath = location.pathname;

      const routes = getAllRoutes();

      const rule = routes.find((route) => route.path === currentPath)?.rule;
      if (!rule) {
        console.error(`Route rule for ${currentPath} not found, please check the routes configuration`);
        setIsAllowed(true);
        return;
      }
      if (rule.roles.length === 0) {
        setIsAllowed(true);
        return;
      }
      setIsAllowed(haveOneOfTheRoles(rule.roles));
    }
    , [location]
  );

  if (!roleIsLoaded) {
    return <></>;
  }

  return isAllowed ? <>{children}</> : <AccessDeniedPage />;
};

export default PrivateRoutes;
