import dayjs from 'dayjs';
import { round } from 'lodash';

export const parsePercentStringToFloat = (result : string, precision : number) : number => round(parseFloat(result.trim().replace('%', '')), precision);

export const notionalFormatter
  = (value : number | string | undefined) : string => (value ?? 100000).toString().replace(/\B(?=(?:\d{3})+(?!\d))/gu, ',');

/**
 * Parses a number or string to a percentage string with appropriate precision.
 *
 * @param result - The number or string to be parsed.
 * @returns A string representing the percentage with at least 2 decimal places.
 *          If the input is undefined or null, returns '0%'.
 *          If the input is a string, it trims trailing zeros in the decimal part.
 */
export const parseForDisplayWithPercentSign = (result : number | string | undefined | null) : string => {
  if (result === undefined || result === null) {
    return '0%';
  }

  if (typeof result === 'string') {
    const split = result.split('.');
    if (split.length > 1) {
      const decimalPlaces = split[1].replace(/0+$/, '').length;
      const precision = Math.min(Math.max(2, decimalPlaces), 4);
      return `${parseFloat(result).toFixed(precision)}%`;
    }
    return `${result}%`;
  }

  const decimalPart = result.toString().split('.')[1] || '';
  const precision = Math.max(2, decimalPart.length);

  return `${result.toFixed(precision)}%`;
};

/**
 * Checks if a string represents a boolean value ('true' or 'false').
 *
 * @param str - The string to check.
 * @returns True if the string is 'true' or 'false', false otherwise.
 */
export const isBooleanString = (str : string) : boolean => str === 'true' || str === 'false';

/**
 * Checks if a string represents a numeric value.
 *
 * @param str - The string to check.
 * @returns True if the string is a valid number, false otherwise.
 */
export const isNumberString = (str : string) : boolean => !isNaN(parseFloat(str)) && isFinite(Number(str));

/**
 * Converts camelCase string to title case string. E.g. "camelCaseString" -> "Camel Case String"
 * @param {string} camelCaseString - string to convert
 * @returns {string} - converted string
 */
export const toTitleCase = (camelCaseString : string) : string => camelCaseString
  .replace(/(?:[A-Z])/gu, ' $&')
  .replace(/^./u, (str) => str.toUpperCase());

/**
 * Replaces all whitespace characters in a string with a specified replacement string.
 * If no replacement string is provided, underscores are used by default.
 */
export const replaceStringSpacing = (str : string, replacement : string = '') : string => str.replace(/\s+/gu, replacement);

/**
 * Formats a value for display based on its type.
 */
export const formatValueForDisplay = (valueToDisplay : string | number, valueType : 'date' | 'number' | 'string') : string => {
  switch (valueType as string) {
    case 'date':
      return dayjs(valueToDisplay).format('DD/MM/YYYY');
    case 'number':
      return `${valueToDisplay}%`;
    default:
      return valueToDisplay.toString();
  }
};
