import { t } from 'i18next';

export enum TradeStatusClass {
  Booked = 'booked',
  'Order Received' = 'order__received',
  'Invoice Sent' = 'invoice__sent',
  'Commission paid' = 'commission__Paid',
  Closed = 'closed',
}

export enum EventTypesEnum {
  Coupon = 'Coupon',
  AutocallAndCoupon = 'Autocall & Coupon',
  Autocall = 'Autocall',
  InitialFixing = 'Initial Fixing',
  Issue = 'Issue',
  FinalFixing = 'Final Fixing',
  Maturity = 'Maturity',
  FinalCoupon = 'Final Coupon',
  Today = 'Today',
}

export enum ProductListPageViewEnum {
  Default = 'Default',
  ByClient = 'Client',
  ByOwner = 'Owner',
  ByProductType = 'ProductType',
  ByValuation = 'Valuation',
  ByIssuer = 'Issuer',
  ByNextEvent = 'NextEvent',
}

export enum EventStatusEnum {
  Past = 'Past',
  NextEvent = 'Next event',
  Future = 'Future',
  Cancel = 'Cancel',
}

export enum FrequencyEnum {
  Annual = 'Annual',
  SemiAnnual = 'Semi-Annual',
  Quarterly = 'Quarterly',
  Monthly = 'Monthly',
}

export enum ProductTypeEnum {
  Autocall = 'Autocall',
  Digital = 'Digital',
  CreditLinkedNote = 'Credit Linked Note',
  Participation = 'Participation',
  Rates = 'Rates',
  Custom = 'Custom',
  Draft = 'Draft',
}

export enum ProductSubTypeEnum {
  Phoenix = 'Phoenix',
  Classic = 'Classic',
  BoosterBonus = 'Booster/Bonus',
  TwinWin = 'Twin Win',
  ReverseConvertible = 'Reverse Convertible',
  SingleName = 'Single Name',
  Tranche = 'Tranche',
  TrancheKG = 'Tranche KG',
  Participation = 'Participation',
  TwoOptionsPayoff = '2 Options Payoff',
  SharkNote = 'Shark Note',
  FixedRateNote = 'Fixed Rate Note',
  FloatingRateNote = 'Floating Rate Note',
  FlooredFloater = 'Floored Floater',
  TarnSteepner = 'TARN Steepner',
  Custom = 'Custom',
  Draft = 'Draft',
}

export const productTypeMapping : {[key in ProductTypeEnum] : ProductSubTypeEnum[] } = {
  [ProductTypeEnum.Autocall] : [
    ProductSubTypeEnum.Phoenix,
    ProductSubTypeEnum.Classic,
    ProductSubTypeEnum.ReverseConvertible,
    ProductSubTypeEnum.BoosterBonus,
    ProductSubTypeEnum.TwinWin,
  ],
  [ProductTypeEnum.Digital] : [
    ProductSubTypeEnum.ReverseConvertible,
    ProductSubTypeEnum.Classic,
  ],
  [ProductTypeEnum.CreditLinkedNote] : [
    ProductSubTypeEnum.TrancheKG,
    ProductSubTypeEnum.SingleName,
    ProductSubTypeEnum.Tranche,
  ],
  [ProductTypeEnum.Participation] : [
    ProductSubTypeEnum.TwoOptionsPayoff,
    ProductSubTypeEnum.SharkNote,
    ProductSubTypeEnum.BoosterBonus,
    ProductSubTypeEnum.TwinWin,
  ],
  [ProductTypeEnum.Rates] : [
    ProductSubTypeEnum.FixedRateNote,
    ProductSubTypeEnum.FloatingRateNote,
    ProductSubTypeEnum.FlooredFloater,
    ProductSubTypeEnum.TarnSteepner,
  ],
  [ProductTypeEnum.Custom] : [ProductSubTypeEnum.Custom],
  [ProductTypeEnum.Draft]  : [ProductSubTypeEnum.Draft],
};

export enum BasketTypeEnum {
  SingleStock = 'Single Stock',
  WorstOf = 'Worst Of',
  BestOf = 'Best Of',
  WeightedAverage = 'Weighted Average',
}

export enum BarrierTypeEnum {
  European = 'European',
  UsClose = 'Us Close',
  UsIntraday = 'Us Intraday',
  None = 'None',
}

export const eventTypesSelectableList = [
  EventTypesEnum.AutocallAndCoupon,
  EventTypesEnum.Coupon,
];

export const eventTypesList = [
  EventTypesEnum.AutocallAndCoupon,
  EventTypesEnum.Coupon,
  EventTypesEnum.InitialFixing,
  EventTypesEnum.Issue,
  EventTypesEnum.FinalFixing,
  EventTypesEnum.Maturity,
];

export enum ProductStatusEnum {
  Draft = 'Draft',
  Simulation = 'Simulation',
  PricingRequest = 'Pricing Request',
  Alive = 'Alive',
  Finished = 'Finished',
  EarlySold = 'Early_Sold',
  EarlyRedeemed = 'Early_Redeemed',
  Deleted = 'Deleted',
}

export const formatProductStatusEnum = (productStatus : ProductStatusEnum) : string => {
  switch (productStatus) {
    case ProductStatusEnum.Draft:
      return t('productsPages:productStatusEnum.draft');
    case ProductStatusEnum.Simulation:
      return t('productsPages:productStatusEnum.simulation');
    case ProductStatusEnum.PricingRequest:
      return t('productsPages:productStatusEnum.pricingRequest');
    case ProductStatusEnum.Alive:
      return t('productsPages:productStatusEnum.alive');
    case ProductStatusEnum.Finished:
      return t('productsPages:productStatusEnum.finished');
    case ProductStatusEnum.EarlySold:
      return t('productsPages:productStatusEnum.earlySold');
    case ProductStatusEnum.EarlyRedeemed:
      return t('productsPages:productStatusEnum.earlyRedeemed');
    case ProductStatusEnum.Deleted:
      return t('productsPages:productStatusEnum.deleted');
    default:
      return '';
  }
};
