import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';

import globalEN from './locales/en/global.json';
import productsPagesEN from './locales/en/productsPages.json';
import globalFR from './locales/fr/global.json';
import productsPagesFR from './locales/fr/productsPages.json';

export const supportedLngs = {
  en : 'English',
  fr : 'Français',
};

const resources = {
  en : {
    productsPages : productsPagesEN,
    global        : globalEN,
  },
  fr : {
    productsPages : productsPagesFR,
    global        : globalFR,
  },
};

void i18n
  .use(initReactI18next)
  .use(detector)
  .init({
    resources,
    detection : {
      order              : ['localStorage', 'navigator'],
      lookupLocalStorage : 'i18nextLng',
    },
    lng           : 'en',
    ns            : ['productsPages', 'global'],
    defaultNS     : 'global',
    keySeparator  : '.',
    interpolation : {
      escapeValue : false,
    },
  });
