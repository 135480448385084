import React from 'react';

import { Modal } from 'antd';

const TermSheetViewer : React.FC<{ open : boolean,
  src                                   : string | null,
  onClose                               : ()=> void, }> = ({
  open, src, onClose,
}) : React.ReactElement => (
  <Modal
    centered
    title = {'TERMSHEET'}
    open = {open}
    destroyOnClose = {true}
    okText = {'Close'}
    width = {1000}
    footer = {(_, { OkBtn }) : React.ReactElement => <OkBtn />}
    onOk = {onClose}
    onCancel = {onClose}
  >
    <iframe
      src = {src ?? ''}
      width = {'100%'}
      style = {{
        border    : 'none',
        minHeight : '80vh',
      }}
    />
  </Modal>
);

export { TermSheetViewer };
