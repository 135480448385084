import React, { useState } from 'react';

import {
  Flex, Form, type FormInstance, InputNumber, Select, Switch
} from 'antd';

import {
  autocallableReverseConvertible, classicAutocall, hideField, reverseConvertible
} from '../../../../data/ProductType';

import {
  FieldsNameEnum, type PricingFields, SolveForEnum

, BarrierTypeEnum} from '../../../../data/enums/pricing';
import { dynamicOrange, organicBlue } from '../../../../styles/colors';

type CouponPricingFormProps = {
  form                   : FormInstance<PricingFields>,
  couponFloorDisabled    : boolean,
  setCouponFloorDisabled : (value : boolean)=> void,
  nbStep                 : number,
  pricingId ?            : string,
};

export const CouponPricingForm = ({
  form, pricingId, couponFloorDisabled, setCouponFloorDisabled, nbStep,
} : CouponPricingFormProps)
: React.ReactElement => {
  const [savedCouponFloorValue, setSavedCouponFloorValue] = useState(0);
  const productTypeId = Form.useWatch<string>(FieldsNameEnum.productType, form);
  const solveForValue = Form.useWatch<SolveForEnum>(FieldsNameEnum.solveFor, form);
  const memoryEffectValue = Form.useWatch<boolean>([FieldsNameEnum.memoryEffect], form);
  const barrierTypeValue = Form.useWatch<BarrierTypeEnum>(FieldsNameEnum.barrierType, form);
  const barrierLevelValue = Form.useWatch<number>(FieldsNameEnum.barrierLevel, form);
  const couponTriggerValue = Form.useWatch<number>([FieldsNameEnum.couponTrigger], form);
  const couponFloorValue = Form.useWatch<number>([FieldsNameEnum.couponFloor], form);

  const toggleCouponFloorDisabled = () : void => {
    setCouponFloorDisabled(!couponFloorDisabled);
    if (couponFloorDisabled) {
      setSavedCouponFloorValue(form.getFieldValue([FieldsNameEnum.couponFloor]) as number);
      form.setFieldValue([FieldsNameEnum.couponFloor], 0);
    } else {
      form.setFieldValue([FieldsNameEnum.couponFloor], savedCouponFloorValue);
    }
  };

  return (
    <div className = {'cardWrapper organicBlueWrapper'}>
      <div className = {'card'}>
        <div className = {'cardHeader organicBlue'}>
          <h2>Coupon</h2>
        </div>

        <Flex vertical className = {'cardContent'}>
          <Form.Item
            name = {[FieldsNameEnum.couponValue]}
            label = {<span className = {'label white'}>Coupon</span>}
            rules = {[
              {
                required : solveForValue !== SolveForEnum.coupon,
                message  : 'Please input coupon',
              },
            ]}
          >
            <InputNumber
              className = {'fieldBgColor coupon-field pricer-field'}
              controls = {false}
              suffix = {'% p. a.'}
              disabled = {solveForValue === SolveForEnum.coupon || Boolean(pricingId)}
              min = {0}
            />
          </Form.Item>

          <Form.Item
            name = {[FieldsNameEnum.numberPerYear]}
            label = {<span className = {'label white'}>Frequency</span>}
          >
            <Select>
              <Select.Option value = {1}>Annually</Select.Option>

              <Select.Option value = {2}>Semi-Annually</Select.Option>

              <Select.Option value = {4}>Quarterly</Select.Option>

              <Select.Option value = {12}>Monthly</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name = {[FieldsNameEnum.memoryEffect]}
            label = {<span className = {'label white'}>Memory Effect</span>}
            valuePropName = {'checked'}
            className = {'form-item-switch'}
            hidden = {hideField(FieldsNameEnum.memoryEffect, productTypeId)}
          >
            <Switch
              style = {{ backgroundColor : memoryEffectValue ? dynamicOrange : 'grey' }}
            />
          </Form.Item>

          <Form.Item
            name = {[FieldsNameEnum.couponTrigger]}
            label = {<span className = {'label white'}>Coupon Trigger</span>}
            hidden = {hideField(FieldsNameEnum.couponTrigger, productTypeId)}
            rules = {[
              {
                required : true,
                message  : 'Please input coupon trigger',
              },
            ]}
          >
            <InputNumber
              className = {'fieldBgColor pricer-field'}
              controls = {false}
              suffix = {'%'}
            />
          </Form.Item>

          <Form.Item
            name = {[FieldsNameEnum.couponStepdown]}
            label = {<span className = {'label white'}>Coupon Stepdown</span>}
            hidden = {hideField(FieldsNameEnum.couponStepdown, productTypeId)}
            dependencies = {[
              FieldsNameEnum.couponTrigger,
              FieldsNameEnum.couponFloor,
              FieldsNameEnum.barrierType,
              FieldsNameEnum.barrierLevel,
              FieldsNameEnum.solveFor,
              FieldsNameEnum.productType,
            ]}
          >
            <InputNumber className = {'fieldBgColor pricer-field'} controls = {false} suffix = {'%'} />
          </Form.Item>

          <Flex
            justify = {'space-between'}
            hidden = {productTypeId === classicAutocall.id}
            style = {{ display : hideField(FieldsNameEnum.couponFloor, productTypeId) ? 'none' : 'flex' }}
            className = {`form-switch-and-field ${couponFloorDisabled ? 'form-switch-disabled' : ''}`}
          >
            <div>
              <span className = {'label white'}>Coupon Floor</span>

              <Switch
                checked = {!couponFloorDisabled}
                style = {{
                  backgroundColor : couponFloorDisabled ? 'grey' : organicBlue,
                  visibility      : hideField(FieldsNameEnum.couponFloor, productTypeId) ? 'hidden' : 'visible',
                }}
                onChange = {() : void => toggleCouponFloorDisabled()}
              />
            </div>

            <Form.Item
              name = {[FieldsNameEnum.couponFloor]}
              hidden = {hideField(FieldsNameEnum.couponFloor, productTypeId)}
            >
              <InputNumber
                className = {'fieldBgColor pricer-field'}
                controls = {false}
                suffix = {'%'}
                style = {{ visibility : couponFloorDisabled ? 'hidden' : 'visible' }}
                disabled = {couponFloorDisabled || Boolean(pricingId)}
                min = {0}
              />
            </Form.Item>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};
