export const CONFIG_BACKEND_SERVER_URL = process.env.REACT_APP_BACKEND_URL ?? '';
export const CONFIG_KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL ?? '';
export const CONFIG_KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM ?? '';
export const CONFIG_KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '';
export const MESSAGE_API_DURATION = 5;

export const CONFIG_API_TIMEOUT = process.env.REACT_APP_API_TIMEOUT ? parseInt(process.env.REACT_APP_API_TIMEOUT) : 30_000;

import dayjs from 'dayjs';

/* Put it cause it does not work in its own file
   Solve this issue https://github.com/iamkun/dayjs/issues/934 */
export const JsonPlugin = (option, dayjsClass, dayjsFactory) : void => {
  // overriding existing API
  dayjsClass.prototype.toJSON = function () {
    return this.format();
  };
};

dayjs.extend(JsonPlugin);
