import React, {
  useCallback, useEffect, useState
} from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { FileOutlined, LoadingOutlined } from '@ant-design/icons';
import type { FormProps } from 'antd';
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Upload,
  message
} from 'antd';

import dayjs from 'dayjs';

import { CurrencyInput } from '../../../Shared/CurrencyInput/CurrencyInput';
import { MainTitle } from '../../../Shared/MainTitle/MainTitle';
import { myFetch } from '../../../config/api';
import { MESSAGE_API_DURATION } from '../../../config/config';
import { EditProductPageRoute } from '../../../data/Routes';
import {
  EventStatusEnum,
  EventTypesEnum,
  ProductSubTypeEnum,
  ProductTypeEnum
} from '../../../data/product/productEnum';
import { dynamicOrange } from '../../../styles/colors';
import { toTitleCase } from '../../../utils/stringUtils';
import { createRedemptionsList } from '../lifecycleHelpers/createProductRedemption';
import {
  cleanEvents,
  extractStepdownValues,
  getFrequencyMultiplier, mapCustomFields,
  setNextEvent
} from '../lifecycleHelpers/lifecycleHelpers';
import { useAutoCreateEvent } from '../lifecycleHooks/autoCreateEventHook';
import { initProductEvents } from '../lifecycleHooks/useCreateInitProductEvents';

import { getProductInputFields } from './productInputFields';

import type { ProductFieldMapType } from './productInputFields';
import type {
  ProductApiSubmissionForm,
  ProductEvent, ProductEventForm,
  ProductField,
  ProductFormFieldType,
  SingleProductType,
  UnderlyingEquity
} from '../../../data/ProductType';
import type { UploadRequestError, UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';

import './CreateProductPage.scss';

import CreateProductGeneralSection from './CreateProductComponents/CreateProductGeneralSection';
import CreateProductCalendar from './CreateProductComponents/CreateProductCalendar';

const CreateProductPage = () : React.ReactElement => {
  const [form] = Form.useForm<ProductFormFieldType>();

  const maturityLength = Form.useWatch<number>('maturityLength', form);
  const issueDate = Form.useWatch<dayjs.Dayjs>('issueDate', form);
  const productType = Form.useWatch<string>('productType', form);
  const productSubType = Form.useWatch<string>('productSubType', form);
  const frequency = Form.useWatch<string | undefined>('frequency', form);
  const hasCouponFloor = Form.useWatch<boolean>('hasCouponFloor', form);
  const couponFloor = Form.useWatch<number | undefined>('couponFloor', form);
  const hasAutocallFloor = Form.useWatch<boolean>('hasAutocallFloor', form);
  const autocallFloor = Form.useWatch<number | undefined>('autocallFloor', form);
  const initialFixingDate = Form.useWatch<dayjs.Dayjs>('initialFixingDate', form);
  const optionAsBarrier = Form.useWatch<boolean>('optionAsBarrier', form);
  const hasMaturityCap = Form.useWatch<boolean>('hasMaturityCap', form);
  const hasMaturityRebate = Form.useWatch<boolean>('hasMaturityRebate', form);
  const hasSecondOptionBarrier = Form.useWatch<boolean>('optionAsBarrier2', form);
  const strike = Form.useWatch<number>('strike', form);
  const nonCallPeriod = Form.useWatch<number | undefined>('nonCallPeriod', form);
  const coupon = Form.useWatch<number | undefined>('coupon', form);
  const barrierLevel = Form.useWatch<number | undefined>('barrierLevel', form);
  const couponStepdown = Form.useWatch<number | undefined>('couponStepdown', form);
  const couponTrigger = Form.useWatch<number | undefined>('couponTrigger', form);
  const autocallTrigger = Form.useWatch<number | undefined>('autocallTrigger', form);
  const autocallStepdown = Form.useWatch<number | undefined>('autocallStepdown', form);
  const termSheet = Form.useWatch<string>('termSheet', form);

  const [showSecondOptionsPayOff, setShowSecondOptionsPayOff] = useState<boolean>(false);
  const navigate = useNavigate();
  const defaultCustomFieldValues : ProductField[] = [];
  const [customFields, setCustomFields] = useState<ProductField[]>(defaultCustomFieldValues);
  const [calendarEvents, setCalendarEvents] = useState<ProductEvent[]>([]);
  const [product, setProduct] = useState<SingleProductType | undefined>();

  const [isCreating, setIsCreating] = useState(false);
  const [hasCustomSchedule, setHasCustomSchedule] = useState(false);
  const [messageApi, contextHolder] = message.useMessage({ duration : MESSAGE_API_DURATION });
  const [underlyingsRates, setUnderlyingsRates] = useState<string[]>([]);
  const [initialRates, setInitialRates] = useState<string[]>([]);
  const [underlyingsEquity, setUnderlyingsEquity] = useState<UnderlyingEquity[]>([]);
  const [productFields, setProductFields] = useState<ProductFieldMapType>(getProductInputFields('', ''));

  const { productId } = useParams<{ productId : string }>();

  const isEditing = Boolean(useMatch({ path : EditProductPageRoute.path }));

  const initForm = (initProduct : SingleProductType | undefined) : void => {
    if (!initProduct) {
      return;
    }
    let nbCouponPerYear = 0;
    if (initProduct.couponFixed) {
      nbCouponPerYear = initProduct.couponFixed.numberPerYear;
      form.setFieldsValue({
        coupon : initProduct.couponFixed.couponValue,
      });
    } else if (initProduct.couponVariable) {
      nbCouponPerYear = initProduct.couponVariable.numberPerYear;
      form.setFieldsValue({
        coupon : initProduct.couponVariable.cap,
      });
    }

    switch (nbCouponPerYear) {
      case 1:
        form.setFieldsValue({ frequency : 'Annual' });
        break;
      case 2:
        form.setFieldsValue({ frequency : 'Semi-Annual' });
        break;
      case 4:
        form.setFieldsValue({ frequency : 'Quarterly' });
        break;
      case 12:
        form.setFieldsValue({ frequency : 'Monthly' });
        break;
      default:
        form.setFieldsValue({ frequency : undefined });
        break;
    }

    const currentProductFields = getProductInputFields(initProduct.productType, initProduct.productSubType);

    Object.keys(currentProductFields).forEach((key) => {
      const fieldGroup = currentProductFields[key];
      fieldGroup.forEach((field) => {
        const customValue = initProduct.fields.find((e) => e.customKey === field.fieldName)?.customValue ?? undefined;

        switch (field.fieldType) {
          case 'date':
            form.setFieldsValue({
              [field.fieldKey] : customValue ? dayjs(customValue) : undefined,
            });
            break;
          case 'number':
            form.setFieldsValue({
              [field.fieldKey] : customValue ? Number(customValue) : undefined,
            });
            break;
          case 'toggle':
            form.setFieldsValue({
              [field.fieldKey] : customValue ? customValue : false,
            });
            break;
          case 'select':
          case 'currency':
          default:
            form.setFieldsValue({
              [field.fieldKey] : customValue,
            });
        }
      });
    });

    form.setFieldsValue({
      isin               : initProduct.isin,
      name               : initProduct.name,
      denomination       : initProduct.denomination,
      issuerId           : initProduct.issuerRefId,
      productType        : initProduct.productType,
      productSubType     : initProduct.productSubType,
      initialFixingDate  : initProduct.initialValuationDate,
      issueDate          : initProduct.issueDate,
      maturityLength     : initProduct.totalDuration,
      currencyRefId      : initProduct.currencyRefId,
      basketType         : initProduct.couponBarrier?.basketType,
      couponTrigger      : initProduct.couponBarrier?.initialBarrier,
      couponStepdown     : initProduct.couponBarrier?.stepDown,
      couponMemoryEffect : initProduct.couponBarrier?.memoryEffect,
      autocallTrigger    : initProduct.autocall?.initialBarrier,
      autocallStepdown   : initProduct.autocall?.stepdown,
      nonCallPeriod      : initProduct.autocall?.nonCallPeriod,
      autocallFloor      : initProduct.autocall?.autocallFloor,
      hasAutocallFloor   : Boolean(initProduct.autocall?.autocallFloor),
      termSheet          : initProduct.termSheet,
    });

    setUnderlyingsEquity(initProduct.underlyings.map((e) => ({
      isin             : e.isin,
      companyId        : e.companyId,
      place            : e.place,
      ticker           : e.ticker,
      internetTicker   : e.internetTicker,
      initialValuation : e.initialValuation,
      currencyRefId    : e.currencyRefId,
      companyName      : e.companyName,
    })));
    setUnderlyingsRates(initProduct.underlyingsRates);
    setInitialRates(initProduct.underlyingsRates);
    setProductFields(currentProductFields);
    const fields = getProductInputFields(initProduct.productType, initProduct.productSubType);
    const noCustomFields = Object.values(fields).flat()
      .map((field) => field.fieldName);
    setCustomFields(
      initProduct.fields.filter((field) => !noCustomFields.includes(field.customKey))
    );
    setHasCustomSchedule(initProduct.hasCustomSchedule);
    initProductEvents({
      product : initProduct,
      setCalendarEvents,
    });
    setProduct(initProduct);
  };

  useEffect(() => {
    if (productId && isEditing) {
      myFetch<SingleProductType>('GET', `/products/${productId}`).then((response) => initForm(response))
        .catch((e : unknown) => console.error(`Error while fetching product : ${e}`));
    }
  }, [productId]);

  useEffect(() => {
    setProductFields(getProductInputFields(productType, productSubType));
  }, [productType, productSubType]);

  useEffect(() => {
    if (!strike) {
      return;
    }
    form.setFieldsValue({ downsideLeverage : parseFloat(((100 / Number(strike)) * 100).toFixed(2)) });
  }, [strike]);

  const updloadTermsheet = ({
    file, onError, onSuccess,
  } : Pick<RcCustomRequestOptions, 'file' | 'onError' | 'onSuccess'>) : void => {
    const formData = new FormData();
    formData.append('file', file as File);

    myFetch('POST', '/product/termsheet', {
      body  : formData,
      query : null,
    }).then((response) => {
      const { fileUrl } = response as { fileUrl : string };
      form.setFieldsValue({
        termSheet : fileUrl,
      });
      if (onSuccess) {
        onSuccess(response);
      }
    })
      .catch((e : unknown) : void => {
        if (onError) {
          onError(e as UploadRequestError);
        }
        if (e instanceof Error) {
          messageApi.open({
            type    : 'error',
            content : 'Failed to upload termsheet, please try again',
          });
        }
      });
  };

  const deleteUploadedTermsheet = ({
    filename, onError, onSuccess,
  } : Pick<RcCustomRequestOptions, 'filename' | 'onError' | 'onSuccess'>) : void => {
    myFetch('DELETE', `/product/termsheet/${filename}`).then((response) => {
      const responseFileName = (response as { filename : string }).filename;
      form.setFieldsValue({
        termSheet : undefined,
      });

      if (onSuccess) {
        onSuccess(responseFileName);
      }
    })
      .catch((e : unknown) : void => {
        if (onError) {
          onError(e as Error);
        }
        if (e instanceof Error) {
          messageApi.open({
            type    : 'error',
            content : 'Failed to delete termsheetk please try again',
          });
        }
      });
  };

  const onFinish : FormProps<ProductFormFieldType>['onFinish'] = (values : ProductFormFieldType) : void => {
    setIsCreating(true);
    const cleanEventList = setNextEvent(cleanEvents(calendarEvents));

    const productIsDraft = productType === ProductTypeEnum.Draft.toString();

    if (!cleanEventList.some((e : ProductEventForm) => e.status === EventStatusEnum.NextEvent) && !productIsDraft) {
      messageApi.error('Please add at least one event past the current date');
      setIsCreating(false);
      return;
    }

    if (cleanEventList.filter((e : ProductEventForm) => e.status === EventStatusEnum.NextEvent).length > 1 && !productIsDraft) {
      messageApi.error('Too many next events, please only have one');
      setIsCreating(false);
      return;
    }

    const maturityEvent = cleanEventList.find((e : ProductEventForm) => e.eventType === EventTypesEnum.Maturity) ?? null;
    if (!maturityEvent && !productIsDraft) {
      messageApi.error('Please add a maturity event');
      setIsCreating(false);
      return;
    }

    let newProduct : ProductApiSubmissionForm = {
      productType          : values.productType,
      productSubType       : values.productSubType,
      isin                 : values.isin,
      name                 : values.name,
      issuerId             : values.issuerId,
      denomination         : values.denomination,
      currencyRefId        : values.currencyRefId,
      termSheet            : values.termSheet,
      basketType           : values.basketType,
      status               : 'Alive',
      totalDuration        : values.maturityLength,
      maturityDate         : maturityEvent?.paymentDate ?? null,
      issueDate            : dayjs(issueDate).format('YYYY-MM-DD'),
      initialValuationDate : dayjs(initialFixingDate).format('YYYY-MM-DD'),
      underlyings          : underlyingsEquity,
      underlyingsRates,
      strike               : values.strike,
      downsideLeverage     : values.downsideLeverage,
      hasCustomSchedule,
      events               : cleanEventList,
      fields               : customFields.filter((field) => field.customKey && field.customValue),
    };

    let otherCustomFields : ProductApiSubmissionForm['fields'] = [];

    if (productType === ProductTypeEnum.Autocall.toString()) {
      newProduct.redemptions = createRedemptionsList(form, {
        productType,
        productSubType,
        hasMaturityCap,
        hasMaturityRebate,
        barrierType : values.barrierType,
        strike,
      });

      newProduct.autocall = {
        nonCallPeriod  : values.nonCallPeriod,
        initialBarrier : values.autocallTrigger,
        stepdown       : values.autocallStepdown || 0,
        autocallFloor  : hasAutocallFloor ? values.autocallFloor : 0,
        barrierList    : extractStepdownValues(calendarEvents, 'autocallTrigger'),
      };

      if (hasAutocallFloor) {
        newProduct.autocall = {
          ...newProduct.autocall,
          autocallFloor : values.autocallFloor,
        };
      }

      if (productSubType === ProductSubTypeEnum.Phoenix.toString()) {
        newProduct.couponBarrier = {
          basketType     : values.basketType,
          memoryEffect   : values.couponMemoryEffect,
          initialBarrier : values.couponTrigger,
          stepdown       : values.couponStepdown || 0,
          couponFloor    : hasCouponFloor ? values.couponFloor : 0,
          barrierList    : extractStepdownValues(calendarEvents, 'couponTrigger'),
        };
      }
    }

    if (productType === ProductTypeEnum.Digital.toString()) {
      if (productSubType === ProductSubTypeEnum.Classic.toString()) {
        newProduct = {
          ...newProduct,
          couponBarrier : {
            basketType     : values.basketType,
            memoryEffect   : values.couponMemoryEffect,
            stepdown       : 0,
            initialBarrier : values.couponTrigger,
            barrierList    : [],
          },
        };
      } else {
        newProduct = {
          ...newProduct,
          couponBarrier : {
            basketType     : values.basketType,
            memoryEffect   : values.couponMemoryEffect,
            stepdown       : 0,
            initialBarrier : 0, // Meaning no barrier
            barrierList    : [],
          },
        };
      }
    }

    otherCustomFields.push(...productFields.autocall
      .filter((field) => form.getFieldValue(field.fieldKey))
      .map((field) => mapCustomFields(field, form)));

    otherCustomFields.push(...productFields.coupon
      .filter((field) => form.getFieldValue(field.fieldKey))
      .map((field) => mapCustomFields(field, form)));

    otherCustomFields.push(...productFields.protection
      .filter((field) => form.getFieldValue(field.fieldKey))
      .map((field) => mapCustomFields(field, form)));

    otherCustomFields.push(...productFields.maturity
      .filter((field) => form.getFieldValue(field.fieldKey))
      .map((field) => mapCustomFields(field, form)));

    if (productType === ProductTypeEnum.CreditLinkedNote.toString()) {
      otherCustomFields.push(...productFields.creditLinkedNote
        .filter((field) => form.getFieldValue(field.fieldKey))
        .map((field) => mapCustomFields(field, form)));

      if (otherCustomFields.find((field) => field.customKey === 'First Coupon Payment')) {
        const firstCouponPaymentDate = dayjs(form.getFieldValue('firstCouponPaymentDate') as string).format('DD/MM/YYYY');
        otherCustomFields = otherCustomFields.filter((field) => field.customKey !== 'First Coupon Payment');
        otherCustomFields.push({
          customKey   : 'First Coupon Payment',
          customValue : firstCouponPaymentDate,
        });
      }
    }

    if (productSubType === ProductSubTypeEnum.TwoOptionsPayoff.toString()) {
      otherCustomFields.push(...productFields.option
        .filter((field) => form.getFieldValue(field.fieldKey))
        .map((field) => mapCustomFields(field, form)));

      if (hasSecondOptionBarrier) {
        otherCustomFields.push(...productFields.option2
          .filter((field) => form.getFieldValue(field.fieldKey))
          .map((field) => mapCustomFields(field, form)));
      }
    }

    if (productType !== ProductTypeEnum.Participation.toString() && productType !== ProductTypeEnum.Custom.toString()) {
      newProduct = {
        ...newProduct,
        couponFixed : {
          couponValue   : values.coupon,
          numberPerYear : getFrequencyMultiplier(frequency),
        },
      };
    }

    if (hasCouponFloor) {
      newProduct = {
        ...newProduct,
        couponBarrier : {
          ...newProduct.couponBarrier ?? {
            basketType     : values.basketType,
            memoryEffect   : values.couponMemoryEffect,
            initialBarrier : values.couponTrigger,
            stepdown       : 0,
            barrierList    : [],
          },
          couponFloor : values.couponFloor,
        },
      };
    }

    newProduct.fields = [
      ...newProduct.fields,
      ...otherCustomFields,
    ];

    if (isEditing && productId) {
      myFetch('PUT', `/products/${productId}`, {
        body  : newProduct,
        query : null,
      })
        .then(() : void => {
          messageApi.open({
            type    : 'success',
            content : 'Product edited successfully',
          });
          navigate(`/products/${productId.toString()}`);
        })
        .catch((e : unknown) : void => {
          if (e instanceof Error) {
            messageApi.open({
              type    : 'error',
              content : e.message,
            });
          }
        })
        .finally(() => {
          setIsCreating(false);
        });
    } else {
      myFetch('POST', '/products', {
        body  : newProduct,
        query : null,
      })
        .then((response) : void => {
          const insertedProduct = response as { productId : number };
          messageApi.open({
            type    : 'success',
            content : 'Product created successfully',
          });
          navigate(`/products/${insertedProduct.productId.toString()}`);
        })
        .catch((e : unknown) : void => {
          if (e instanceof Error) {
            messageApi.open({
              type    : 'error',
              content : e.message,
            });
          }
        })
        .finally(() => {
          setIsCreating(false);
        });
    }
  };

  useAutoCreateEvent({
    initialValuation       : initialFixingDate,
    issueDate,
    frequency,
    productType,
    nonCallPeriod,
    coupon,
    couponStepdown,
    autocallStepdown,
    couponTrigger,
    autocallTrigger,
    totalDurationInMonths  : maturityLength,
    autocallFloor          : hasAutocallFloor ? autocallFloor : 0,
    couponFloor            : hasCouponFloor ? couponFloor : 0,
    hasCustomSchedule,
    onCreateEventsCallback : useCallback((updatedEvents : ProductEvent[]) => {
      setCalendarEvents(updatedEvents);
    }, []),
  });

  return (
    <div className = {'createProductPage'}>
      {contextHolder}

      <MainTitle
        showReturnButton
        text = {isEditing ? product?.name : 'Create Product'}
        customReturnNavigate = {() : void => (productId ? navigate(`/products/${productId}`) : navigate('/products'))}
      />

      <Form
        name = {'createProduct'}
        className = {'create-product-form'}
        layout = {'vertical'}
        form = {form}
        autoComplete = {'off'}
        requiredMark = {false}
        initialValues = {{
          productType        : ProductTypeEnum.Autocall,
          productSubType     : ProductSubTypeEnum.Phoenix,
          strike             : 100,
          autocallStepdown   : 0,
          couponStepdown     : 0,
          couponMemoryEffect : true,
          initialFixingDate  : dayjs(),
          issueDate          : dayjs().add(7, 'day'),
          autocallFloor      : 0,
          couponFloor        : 0,
          nonCallPeriod      : 0,
          barrierType        : 'None',
        }}
        onFinish = {onFinish}
        onReset = {() : void => initForm(product)}
        onKeyDown = {(e) : void => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >

        <Row gutter = {24}>
          <Col md = {7} sm = {24}>
            <CreateProductGeneralSection
              form = {form}
              defaultCustomFieldValues = {defaultCustomFieldValues}
              isEditing = {isEditing}
              productId = {productId}
              messageApi = {messageApi}
              setUnderlyingsEquity = {setUnderlyingsEquity}
              underlyingsEquity = {underlyingsEquity}
              setCustomFields = {setCustomFields}
              customFields = {customFields}
              initialRates = {initialRates}
              setUnderlyingsRates = {setUnderlyingsRates}
            />
          </Col>

          <Col className = {'createProductPage__formcol'} md = {8} sm = {24}>
            {Object.keys(productFields).map((fieldGroupKey, groupIndex) => {
              if (productFields[fieldGroupKey].length === 0) {
                return null;
              }

              // Hide fields for custom products
              if (productType === ProductTypeEnum.Custom.toString()) {
                return null;
              }

              // Hide Option 2 fields by defaut for participation products
              if (!showSecondOptionsPayOff && fieldGroupKey === 'option2') {
                return null;
              }

              let sectionTitle = toTitleCase(fieldGroupKey);

              if (productSubType === ProductSubTypeEnum.FixedRateNote.toString()
                || productSubType === ProductSubTypeEnum.FloatingRateNote.toString()) {
                sectionTitle = 'Fixed Leg';
              }

              const fieldsGroups = productFields[fieldGroupKey];

              return (
                <div key = {groupIndex} className = {'form__group'}>
                  <h4 className = {'section__title section__title__background'}>{sectionTitle}</h4>

                  <div className = {'form__group_content'}>
                    <Row gutter = {16}>

                      {fieldsGroups.map((field, fieldIndex) => {
                        const disabledFieldsToOption = new Map([
                          ['optionBarrierLevel', !optionAsBarrier],
                          ['optionRebate', !optionAsBarrier],
                          ['optionBarrierType', !optionAsBarrier],
                          ['optionBarrierLevel2', !hasSecondOptionBarrier],
                          ['optionBarrierType2', !hasSecondOptionBarrier],
                          ['optionRebate2', !hasSecondOptionBarrier],
                          ['couponFloor', !hasCouponFloor],
                          ['autocallFloor', !hasAutocallFloor],
                          ['rebateBarrier', !hasMaturityRebate],
                          ['rebateBarrierType', !hasMaturityRebate],
                          ['rebate', !hasMaturityRebate],
                          ['maturityCap', !hasMaturityCap],
                        ]);

                        const hiddenField = disabledFieldsToOption.get(field.fieldKey);

                        if (field.fieldType === 'number') {
                          let minValue : number;
                          if (field.fieldKey === 'autocallTrigger') {
                            minValue = couponTrigger ?? 0;
                          } else if (field.fieldKey === 'strike') {
                            minValue = barrierLevel ?? 0;
                          } else {
                            minValue = 0;
                          }
                          return (
                            <Col
                              style = {hiddenField ? { display : 'none' } : { display : 'block' }}
                              key = {fieldIndex}
                              md = {12}
                              sm = {24}
                            >
                              <Form.Item
                                hidden = {hiddenField}
                                label = {field.fieldName}
                                name = {field.fieldKey}
                                rules = {[{ required : field.required }]}
                              >
                                <InputNumber
                                  disabled = {field.disabled}
                                  className = {'product-form-input'}
                                  style = {{ width : '100%' }}
                                  placeholder = {`Enter ${field.fieldName}`}
                                  suffix = {field.suffix}
                                  min = {minValue}
                                />
                              </Form.Item>
                            </Col>
                          );
                        }
                        if (field.fieldType === 'select') {
                          return (
                            <Col
                              style = {hiddenField ? { display : 'none' } : { display : 'block' }}
                              key = {fieldIndex}
                              md = {12}
                              sm = {24}
                            >
                              <Form.Item
                                hidden = {hiddenField}
                                label = {field.fieldName}
                                name = {field.fieldKey}
                                rules = {[{ required : field.required }]}
                              >
                                <Select
                                  showSearch
                                  className = {'product-select-input'}
                                  placeholder = {`Select ${field.fieldName}`}
                                  options = {field.options?.map((option) => ({

                                    value : option,
                                    label : option,
                                  })) ?? []}
                                />
                              </Form.Item>
                            </Col>
                          );
                        }

                        if (field.fieldType === 'toggle') {
                          return (
                            <Col
                              key = {fieldIndex}
                              md = {12}
                              sm = {24}
                              style = {{
                                display    : hiddenField ? 'none' : 'flex',
                                alignItems : 'baseline',
                              }}
                            >
                              <Form.Item
                                hidden = {hiddenField}
                                style = {{ margin : 0 }}
                                label = {field.fieldName}
                                name = {field.fieldKey}
                                rules = {[{ required : field.required }]}
                              >
                                <Switch style = {{ backgroundColor : dynamicOrange }} checkedChildren = {'On'} unCheckedChildren = {'Off'} />
                              </Form.Item>
                            </Col>
                          );
                        }

                        if (field.fieldType === 'date') {
                          return (
                            <Col
                              style = {hiddenField ? { display : 'none' } : { display : 'block' }}
                              key = {fieldIndex}
                              md = {12}
                              sm = {24}
                            >
                              <Form.Item
                                hidden = {hiddenField}
                                label = {field.fieldName}
                                name = {field.fieldKey}
                                rules = {[{ required : field.required }]}
                              >
                                <DatePicker style = {{ width : '100%' }} className = {'product-form-input'} placeholder = {field.fieldName} />
                              </Form.Item>
                            </Col>
                          );
                        }

                        return (
                          <Col
                            style = {hiddenField ? { display : 'none' } : { display : 'block' }}
                            key = {fieldIndex}
                            md = {12}
                            sm = {24}
                          >
                            <Form.Item
                              hidden = {hiddenField}
                              label = {field.fieldName}
                              name = {field.fieldKey}
                              rules = {[{ required : field.required }]}
                            >
                              <CurrencyInput className = {'product-form-input'} placeholder = {'0'} />
                            </Form.Item>
                          </Col>
                        );
                      })}
                    </Row>

                    {fieldGroupKey === 'option'
                    && (
                      <Flex justify = {'flex-end'}>
                        <Button
                          style = {{ border : 'none' }}
                          type = {'default'}
                          onClick = {() : void => setShowSecondOptionsPayOff(!showSecondOptionsPayOff)}
                        >
                          {showSecondOptionsPayOff ? 'Remove Option 2' : 'Add Option 2'}
                        </Button>
                      </Flex>
                    )}
                  </div>
                </div>
              );
            })}

            <Flex
              justify = {'space-between'}
              style = {{
                width        : '100%',
                marginBottom : '16px',
              }}
            >
              <h4 className = {'section__title section__title-topmargin'}>Termsheet</h4>

              {termSheet && (
                <Button
                  size = {'small'}
                  onClick = {() : void => {
                    const filename = termSheet.split('/').pop();
                    deleteUploadedTermsheet({ filename });
                  }}
                >Delete termsheet
                </Button>
              )}
            </Flex>

            <Upload.Dragger
              name = {'file'}
              accept = {'.pdf'}
              multiple = {false}
              maxCount = {1}
              disabled = {Boolean(termSheet)}
              showUploadList = {false}
              style = {{
                display         : termSheet ? 'none' : 'block',
                backgroundColor : 'rgba(255,255,255,.4)',
                width           : '100%',
              }}
              customRequest = {({
                file,
                onError,
                onSuccess,
              } : RcCustomRequestOptions) : void => {
                updloadTermsheet({
                  file,
                  onError,
                  onSuccess,
                });
              }}
            >
              <p className = {'ant-upload-drag-icon'}>
                <FileOutlined />
              </p>

              <Form.Item hidden name = {'termSheet'}>
                <Input type = {'hidden'} />
              </Form.Item>

              <p className = {'ant-upload-text'}>Click or drag file to this area to upload</p>
            </Upload.Dragger>

            {termSheet && <iframe src = {termSheet} width = {'100%'} height = {'600px'} />}
          </Col>

          {/* column 3 : Calendar and event creation form */}
          <Col className = {'createProductPage__formcol'} md = {9} sm = {24}>

            <CreateProductCalendar
              setHasCustomSchedule = {setHasCustomSchedule}
              setCalendarEvents = {setCalendarEvents}
              calendarEvents = {calendarEvents}
            />

            <Flex
              justify = {'flex-end'}
              style = {{
                marginTop : '48px',
                width     : '100%',
              }}
            >
              <Space>
                <Form.Item>
                  <Button size = {'large'} type = {'text'} htmlType = {'reset'}>
                    Reset
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    disabled = {isCreating}
                    className = {'submit_product_button'}
                    size = {'large'}
                    type = {'primary'}
                    htmlType = {'submit'}
                  >
                    {isCreating && <Spin size = {'small'} indicator = {<LoadingOutlined spin />} />}

                    {isEditing ? 'Update' : 'Create'}
                  </Button>
                </Form.Item>
              </Space>
            </Flex>

          </Col>
        </Row>
      </Form>
    </div>
  );
};
export { CreateProductPage };
