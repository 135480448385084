import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import type {
  ButtonProps,
  InputRef,
  TableColumnType
} from 'antd';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  Input,
  Row,
  Select,
  Space,
  Table
} from 'antd';
import type { ColumnType, TableProps } from 'antd/es/table';

import dayjs from 'dayjs';

import { goldenYellow, whiteColor } from '../../styles/colors';

import './CustomTable.scss';

import { Link } from 'react-router-dom';

export type CustomTableAction = {
  title      : string,
  onClick  ? : ()=> void,
  link     ? : string,
  variant  ? : ButtonProps['type'],
  size     ? : ButtonProps['size'],
};

export type CustomTableProps<T> = {
  tableName ? : string,
  dataList    : T[],
  columns     : TableColumnType<T>[],
  isLoading ? : boolean,
  actions ?: CustomTableAction[],
  filters ?: {
    title        : string,
    selected     : string,
    values       : string[],
    onClick      : (value : string)=> void,
    showSearch ? : boolean,
  }[],
  colsTopApplySeachFilter ? : string[],
  colsTopApplyDateFilter  ? : string[],
  search ?: {
    onSearch    : (value : string)=> void,
    searchValue : string,
  },
  customComponent ? : React.ReactNode,
  headerHidden ?: boolean,
} & TableProps<T>;

export const CustomTable = <T extends object>({
  dataList,
  columns,
  tableName,
  actions,
  filters,
  colsTopApplySeachFilter,
  colsTopApplyDateFilter,
  search,
  headerHidden = false,
  customComponent,
  isLoading = false,
  ...rest
} : CustomTableProps<T>) : React.ReactElement => {
  const [tableData, setTableData] = useState<T[]>(dataList);
  const [tableColumns, setTableColumns] = useState(columns);
  const searchInput = useRef<InputRef>(null);
  const { Search } = Input;
  const { t } = useTranslation();

  useEffect(() => {
    setTableData(dataList);
    setTableColumns(columns);
  }, [columns, dataList]);

  useEffect(() => {
    setTableData(dataList);
  }, [dataList]);

  const handleDateFilter = (selectedKeys : string[], recordDate : string) : boolean => {
    if (selectedKeys.length) {
      const [start, end] = selectedKeys;
      return dayjs(recordDate).isBetween(start, end, 'day', '[]');
    }
    return false;
  };

  const getDateRangeFilterProps = useCallback(
    (dataIndex : keyof T) : ColumnType<T> => ({
      filterDropdown : ({
        confirm, setSelectedKeys, clearFilters,
      }) : React.ReactNode => (
        <div className = {'date-filter-dropdown'} onKeyDown = {(e) : void => e.stopPropagation()}>
          <DatePicker.RangePicker onChange = {(_, dateStrings) : void => setSelectedKeys([JSON.stringify(dateStrings)])} />

          <div style = {{
            marginTop : 8,
            textAlign : 'right',
          }}
          >
            <Button
              type = {'default'}
              style = {{ marginRight : 8 }}
              onClick = {() : void => {
                (() => (clearFilters ? clearFilters() : null))();
                confirm();
              }}
            >
              Reset
            </Button>

            <Button type = {'primary'} onClick = {() : void => confirm({ closeDropdown : true })}>
              Filter
            </Button>
          </div>
        </div>
      ),
      filterIcon : (filtered : boolean) : React.ReactNode => <CalendarOutlined style = {{ color : filtered ? '#1677ff' : '' }} />,
      onFilter   : (val, record) : boolean => {
        const recordValue = record[dataIndex] as string;
        return handleDateFilter(JSON.parse(val as string) as string[], recordValue);
      },
    }),
    [tableColumns]
  );

  // Function to return search props for a column
  const getColumnSearchProps = useCallback(
    (dataIndex : keyof T) : ColumnType<T> => ({
      filterDropdown : ({
        setSelectedKeys, selectedKeys, confirm, clearFilters, close,
      }) : React.ReactNode => (
        <div style = {{ padding : 8 }} onKeyDown = {(e) : void => e.stopPropagation()}>
          <Input
            ref = {searchInput}
            placeholder = {`${t('search')}...`}
            value = {selectedKeys[0]}
            style = {{
              marginBottom : 8,
              display      : 'block',
            }}
            onChange = {(e) : void => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter = {() : void => confirm()}
          />

          <Space>
            <Button
              type = {'primary'}
              icon = {<SearchOutlined />}
              size = {'small'}
              style = {{ width : 90 }}
              onClick = {() : void => confirm()}
            >
              Filter
            </Button>

            <Button size = {'small'} style = {{ width : 90 }} onClick = {() => (clearFilters ? clearFilters() : null)}>
              Reset
            </Button>

            <Button
              type = {'link'}
              size = {'small'}
              onClick = {() : void => {
                close();
              }}
            >
              Close
            </Button>
          </Space>
        </div>
      ),
      filterIcon : (filtered : boolean) : React.ReactNode => <SearchOutlined style = {{ color : filtered ? '#1677ff' : '' }} />,
      onFilter   : (val, record) : boolean => (record[dataIndex] || '')
        .toString()
        .toLowerCase()
        .includes((val as string).toLowerCase()),
    }),
    [tableColumns]
  );

  useEffect(() => {
    if (!colsTopApplySeachFilter && !colsTopApplyDateFilter) {
      return;
    }

    // Apply search filter and date filter
    setTableColumns(
      columns.map((col) => {
        let column = { ...col };
        if (colsTopApplySeachFilter?.includes(col.dataIndex as string)) {
          column = {
            ...col,
            ...getColumnSearchProps(col.dataIndex as keyof T),
          };
        }
        if (colsTopApplyDateFilter?.includes(col.dataIndex as string)) {
          column = {
            ...col,
            ...getDateRangeFilterProps(col.dataIndex as keyof T),
          };
        }
        return column;
      })
    );
  }, [columns]);

  return (
    <div className = {'custom__table'}>
      <Row gutter = {8}>
        <Col span = {24}>
          <div className = {'items__list'}>
            {!headerHidden
            && (
              <>
                <div className = {'items__list__header'}>
                  <Flex justify = {'space-between'} align = {'center'}>
                    {tableName && <h4>{tableName}</h4>}

                    {customComponent}

                    {filters && (
                      <Space size = {'large'}>
                        {filters.map((f, i) => (
                          <Space key = {i}>
                            <span className = {'filter-title'}>{f.title}</span>

                            <Select
                              value = {f.selected}
                              size = {'large'}
                              showSearch = {f.showSearch}
                              className = {'filter-select'}
                              style = {{
                                width      : '200px',
                                marginLeft : '8px',
                              }}
                              options = {f.values.map((s) => ({

                                value : s,
                                name  : s,
                              }))}
                              onChange = {(val) : void => {
                                f.onClick(val);
                              }}
                            />
                          </Space>
                        ))}
                      </Space>
                    )}

                    {search && (
                      <Search
                        placeholder = {`${t('search')}...`}
                        style = {{ width : 340 }}
                        className = {'items-search-input'}
                        styles = {{ input : { background : 'rgba(255, 255, 255, 0.4' }}}
                        value = {search.searchValue}
                        size = {'large'}
                        onChange = {(e) : void => search.onSearch(e.target.value)}
                        onSearch = {search.onSearch}
                      />
                    )}

                    {actions && (
                      <Space>
                        {actions.map((a, i) => (
                          <React.Fragment key = {i}>
                            {a.link
                              ? (
                                <Link to = {a.link ?? ''}>
                                  <Button
                                    type = {a.variant ?? 'primary'}
                                    size = {a.size ?? 'large'}
                                    style = {{
                                      background : goldenYellow,
                                      color      : whiteColor,
                                    }}
                                    onClick = {a.onClick}
                                  >
                                    {a.title}
                                  </Button>
                                </Link>
                              )
                              : (
                                <Button
                                  key = {i}
                                  type = {a.variant ?? 'primary'}
                                  size = {a.size ?? 'large'}
                                  style = {{
                                    background : goldenYellow,
                                    color      : whiteColor,
                                  }}
                                  onClick = {a.onClick}
                                >
                                  {a.title}
                                </Button>
                              )}
                          </React.Fragment>
                        ))}
                      </Space>
                    )}
                  </Flex>
                </div>

                <Divider style = {{
                  margin          : 0,
                  marginBottom    : 16,
                  height          : '2px',
                  backgroundColor : whiteColor,
                }}
                />
              </>
            )}

            <Table
              columns = {tableColumns}
              id = {'custom-table'}
              dataSource = {tableData}
              rowClassName = {(_, index) : string => (index % 2 === 0 ? 'table-row-even' : 'table-row-odd')}
              showSorterTooltip = {{ target : 'sorter-icon' }}
              loading = {isLoading}
              style = {{
                padding    : 0,
                marginLeft : 0,
              }}
              pagination = {{
                position        : ['bottomCenter'],
                defaultPageSize : 30,
              }}
              scroll = {{
                x : 'max-content',
              }}
              {...rest}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
