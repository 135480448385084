import { myFetch } from '../config/api';

export const isValidIsin = async (isin : string) : Promise<boolean> => {
  if (!isin || isin.length !== 12) {
    return false;
  }

  try {
    const exist = await myFetch<boolean>('GET', `/products/checkIsin/${isin}`);
    return !exist;
  } catch (_ : unknown) {
    return false;
  }
};
